import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router';
import StandaloneVideoComponent from "../Standalone-Video-Component";
import { Tag } from '../../modules/cardtags';
import { updateUrl } from '../../utils/tagupdate';

const defaultProps = {
    keys: [],
    location: {
        search: true,
    },
    filterKey:  'articles', 
    tagKey: 'tags',
    tagClick: () => { },
    isApiProps: false,
    link: "",
    imageSrc: "",
    title: "",
    description: "",
    fields : {},
    isGallery: false,
};

class VideoCardComponent extends React.Component {
    /**    
     * @method contructor
     * @description Lifecycle method
     * @param {object} props incoming props
     */
    constructor(props) {
        super(props);
        this.isGallery = true;
       
        if(props.sitecoreContext.route.templateName === 'IMC Page'){
            this.isGallery = false;
        }
       
        if(!this.props.isApiProps){
            // const { fields, rendering, uid } = this.props;
            
            this.fields = this.props.fields;
            this.renderCardTags = this.renderCardTags.bind(this);
            this.link = this.fields.videoCardLink == null ? '/' : this.fields.videoCardLink.value.href;
            this.imageSrc = this.fields.thumbnail.value.src;
            this.title = this.fields.videoCardTitle == null ? '' : this.fields.videoCardTitle.value;
            this.description = this.fields.videoCardDescription == null ? '' : this.fields.videoCardDescription.value;
            if(this.fields.tags){
                this.keys = this.fields.tags.map(item => { return item.fields.name.value });
            }
            this.tagClick = this.tagClick.bind(this);
        
        }else{
            this.link = this.props.link;
            this.imageSrc = this.props.imageSrc;
            this.title = this.props.title;
            this.linkToPage = this.props.linkToPage
            this.description = this.props.description;
            this.renderCardTags = this.renderCardTags.bind(this);
            if(props.tags){
                this.keys = props.tags.map(item => { return item });
            }
            this.tagClick = this.tagClick.bind(this);
        }
    }
    /**
     * Tag click event
     * @returns {JSX} Card tags
     */
    tagClick(tag) {
        const { history, match, location, filterKey, tagKey } = this.props;
        updateUrl(tag, history, match, location, filterKey, tagKey);
    }

    componentWillReceiveProps(nextProps) {
       
            this.state = {
                ...nextProps

        };
        if (!nextProps.isApiProps) {
            // const { fields, rendering, uid } = this.props;
            this.fields = nextProps.fields;
            this.renderCardTags = this.renderCardTags.bind(this);
            this.link = this.fields.link == null ? '/' : this.fields.link.value.href;
            this.imageSrc = this.fields.thumbnail.value.src;
            this.title = this.fields.videoCardTitle == null ? '' : this.fields.videoCardTitle.value;
            this.description = this.fields.videoCardDescription == null ? '' : this.fields.videoCardDescription.value;
            this.linkToPage = this.fields.linkToPage
            if (this.fields.tags) {
                this.keys = this.fields.tags.map(item => { return item.fields.name.value });
            }
            this.tagClick = this.tagClick.bind(this);

        } else {
            this.link = nextProps.link;
            this.imageSrc = nextProps.imageSrc;
            this.title = nextProps.title;
            this.linkToPage = this.props.linkToPage
            this.description = nextProps.description;
            this.renderCardTags = this.renderCardTags.bind(this);
            if (nextProps.tags) {
                this.keys = nextProps.tags.map(item => { return item });
            }
            this.tagClick = this.tagClick.bind(this);
        
            
        }
    }
    /**
     * Render Card Tags
     * @returns {JSX} Card tags
     */
    renderCardTags() {
         if(this.isGallery){
            const { fields, tagClick } = this.props;
            if (this.keys && this.keys.length > 0) {
                return (
                    <ul className="imc-blogteaser__tags">
                        {this.keys.map(tagItem => (<Tag
                            key={`tag-${this.props.title}-${tagItem}`}
                            className="imc-blogteaser__tag imc-content--border imc-content--zeta imc-content--alt2 imc-cardtags__tag"
                            tag={tagItem}
                            clickEvent={() => this.tagClick(tagItem)}
                        />))}
                    </ul>
                );
            }

            return null;
         }else{
            if (this.keys && this.keys.length > 0) {
                return (
                    <div class="imc-video-details-page__tags">
                        <div class="imc-video-details-page__tags__label">
                            Tag:
                        </div>
                        <div class="imc-video-details-page__tags__items">
                            {this.keys.map(tagItem => (<a
                            key={`tag-${this.props.title}-${tagItem}`}
                            className="imc-video-details-page__tags__items__item"
                            // onClick={() => this.tagClick(tagItem)}
                            >{tagItem} </a>))}
                        </div>
                    </div>
                    
                );
            }

            return null;
        }
    }

    renderVideoDetail(){
       return(
        <section class="imc-section imc-section--padded imc-content--center imc-video-details-page">
             <h1 class="imc-heading imc-heading--omni">{this.title}</h1>
            <div class="imc-video-details-page__videocontainer">
                    <StandaloneVideoComponent fields={{
                             videoLink: { value: this.link},
                             isDetail: true,
                             simplePlaceholderImage: { value: this.imageSrc},
                    }}/>
            </div>
            <div class="imc-video-details-page__description">
                {this.description}
            </div>
            {this.renderCardTags()}
        </section> 
       ); 
    }
    renderCard(){
        return(
            <div className="imc-articlecard--border imc-articlecard imc-gallery__item imc-videoteaser">
                
                <div className="imc-articlecard__body imc-videoteaser__body">
                    <div className="imc-gallery__item imc-videoteaser__video-container">
                        {!this.props.isApiProps &&
                        <StandaloneVideoComponent fields={{
                             videoLink: { value: this.link},
                             simplePlaceholderImage: { value: this.imageSrc},
                        }}/>}
                        {this.props.isApiProps &&
                        <StandaloneVideoComponent fields={{
                            videoLink: { value: this.props.link},
                            simplePlaceholderImage: { value: this.props.imageSrc},
                        }}/>
                        }
                       
                    </div>
                    
                    
                    <div className="imc-gallery__item imc-articlecard__video-description imc-videoteaser__description">
                        <div className="imc-articlecard__title">
                            <a href={this.linkToPage} className="imc-heading imc-heading--mega imc-videoteaser__description__title">{this.title}</a>
                        </div>
                        <div className="imc-articlecard__teasertext">
                            <p>{this.description}</p>
                        </div>
                        {this.renderCardTags()}
                    </div>
                    
                </div>
            </div> 
        );
    }
    render() {
        // if(!this.props.isApiProps){
        //     const { fields, rendering, uid } = this.props;
        //     if (!this.fields) {
        //         if (isExperienceEditorActive()) {
        //             return <h1 className="alarm">Datasource isn't set.</h1>;
        //         }
        //         return (<h1 className="alarm">Data is not provided. Contact administrators, please.</h1>);
        //     }

        //     rendering.fields.visiblePageTags = fields.tags;
        // }
        if(this.isGallery){
            return(
                this.renderCard()
            )
        }else{
            return(
                this.renderVideoDetail()
            )
            }
        }

}

VideoCardComponent.defaultProps = defaultProps;

export default withSitecoreContext()(withRouter(VideoCardComponent));