import React from 'react';
// import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import RegistrationsAPI from 'api/market-planner/registrations';
// import { SubmitButton, DropDown } from 'modules/formelements';
// import RegistrationCardComponent from "../Registration-Card-Component";
// import UserUtility from '../../utils/userutility';
// import {getDictionaryValue} from 'utils/dictionary';
// import { Loading } from 'modules/loading';

class RegistrationComponent extends React.Component {
//   constructor(props) {
//     super(props);
//     if (!props) {
//       if (isExperienceEditorActive()) {
//           return <h1 className="alarm">Datasource isn't set.</h1>;
//       }
//       return (
//           <h1 className="alarm">
//               Data is not provided. Contact administrators, please.
//         </h1>
//       );
//     }
//     this.isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;
//     this.state = { 
//       selectedMarket: "",
//       registrations: [],
//       futuremarkets: [],
//       primaryRegistration: null,
//       secondaryRegistrations: [],
//       nextMarket: null,
//       futureMarketsOpt: [],
//       loading: true,
//       noassociation: false,
//       galleryClass: "imc-gallery imc-gallery--33-66 imc-gallery--1-2--mobile-full-width",
//     };

//     //this.channel = props.sitecoreContext?props.sitecoreContext.site.name:"las-vegas-market";

//     this._registrationsApi = new RegistrationsAPI();

//     this._getDictionaryvalue = this._getDictionaryvalue.bind(this);
//     this.renderMainCard = this.renderMainCard.bind(this);
//     this.registerForNextMarket = this.registerForNextMarket.bind(this);
//     this.registerForAnotherMarket = this.registerForAnotherMarket.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.chooseMarket = this.chooseMarket.bind(this);
//     this.loadRegistrationData = this.loadRegistrationData.bind(this);
//     this.loadMockData = this.loadMockData.bind(this);
//     this.loadData = this.loadData.bind(this);
//     this.prepareDataRegistrations = this.prepareDataRegistrations.bind(this);
//     this.prepareDataFutureMarkets = this.prepareDataFutureMarkets.bind(this);
//     this.noData = this.noData.bind(this);
//   }

//   _getDictionaryvalue(key, defaultValue){
//     return getDictionaryValue(key, defaultValue);
//   }

//   loadData(){
    
//     const channel = UserUtility.getChannelData().name;
//     let demandDriverId = UserUtility.getUserData().demandDriverId;
    
//     if(!!demandDriverId){
//       this._registrationsApi.getRegistrations(demandDriverId, channel)
//         .then(response => {
//             this.prepareDataRegistrations(response);
//         })
//         .catch(err => console.error(this.props.url, err.toString()));
//     }
//     else {
//       this.prepareDataRegistrations(null);
//     }

//       this._registrationsApi.getFutureMarkets(channel)
//       .then(response => {
//         this.prepareDataFutureMarkets(response);
//       })
//       .catch(err => console.error(this.props.url, err.toString()));
//   }

//   loadMockData(){
//     let registrations = [
//       {
//         registrationNumber: "LVMS0719426519",
//         eventCode :'LCM202006',
//         badgeId: '12345678',
//         registrationUser:'Martin Arias',
//         firstName:'Martin',
//         lastName:'Arias',
//         market: {
//           marketId: 13,
//           marketName: "Las Vegas Winter 2020 Market"
//         }
//       },
//       {
//         registrationNumber: "LVMS0011223344",
//         eventCode :'LCM204006',
//         badgeId: '87654321',
//         market: {
//           marketId: 13,
//           marketName: "Las Vegas Market Summer 2020"
//         }
//       }
//     ];

//     let futuremarkets = [
//       {
//         marketId: 12,
//         marketName: "Las Vegas Winter 2021 Market",
//         startDate: "January 28, 2021",
//         endDate: "January 30, 2021",
//         registrationLink: "https://www.xpressreg.net/inquirypage/start.php?sc=LVMS0720&_ga=2.13081463.1910751920.1585923566-624699141.1578669610"
//       },
//       {
//         marketId: 13,
//         marketName: "Las Vegas Winter 2021 Summer",
//         startDate: "July 28, 2021",
//         endDate: "July 30, 2021",
//         registrationLink: "https://www.xpressreg.net/inquirypage/start.php?sc=LVMS0720&_ga=2.13081463.1910751920.1585923566-624699141.1578669610"
//       }
//     ];

//     let galleryClass = "imc-gallery imc-gallery--33-66 imc-gallery--1-2--mobile-full-width";
//     let primaryRegistration = null;
//     let secondaryRegistrations = null;

//     if(!!!registrations || (!!registrations && registrations.length<=0)){
//       galleryClass = "imc-gallery imc-gallery--1-1";
//     }
    

//     if(!!registrations && registrations.length>0){
//       primaryRegistration = registrations.shift();
//     }

//     if(!!registrations && registrations.length>0){
//       secondaryRegistrations = registrations;
//     }

//     let nextMarket = null;
    
//     if(primaryRegistration==null && !!!futuremarkets){
//       nextMarket = futuremarkets.shift();
//     }

//     let listFutMarkAux = [];
//     for (let i = 0; i < futuremarkets.length; i++) {
//       const element = futuremarkets[i];
//       listFutMarkAux.push(
//         {
//           code: element.marketId,
//           displayValue: element.marketName
//         }
//       );
//     }

//     this.state = { 
//       selectedMarket: "",
//       registrations: registrations,
//       futuremarkets: futuremarkets,
//       primaryRegistration: primaryRegistration,
//       secondaryRegistrations: secondaryRegistrations,
//       nextMarket: nextMarket,
//       futureMarketsOpt: listFutMarkAux,
//       galleryClass: galleryClass,
//     };
//   }

//   prepareDataRegistrations(registrations){
//     let galleryClass = "imc-gallery imc-gallery--33-66 imc-gallery--1-2--mobile-full-width";
//     let primaryRegistration = null;
//     let secondaryRegistrations = null;
//     let noassociation = false;

//     if(!!!registrations || (!!registrations && registrations.length<=0)){
//       galleryClass = "imc-gallery imc-gallery--1-1";
//       noassociation = true;
//     }
    
//     if(!!registrations && registrations.length>0){
//       primaryRegistration = registrations.shift();
//     }

//     if (!!registrations && registrations.length>0){
//       secondaryRegistrations = registrations;
//     }
    
//     this.setState({
//       primaryRegistration: primaryRegistration,
//       secondaryRegistrations: secondaryRegistrations,
//       galleryClass: galleryClass,
//       noassociation: noassociation,
//       loading:false
//     });
//   }

//   prepareDataFutureMarkets(futureMarkets){
//     let nextMarket = null;
//     let futureMarketsAux = futureMarkets;
//     if(!!!futureMarkets)
//       return;
//     if(this.state.primaryRegistration==null && !!futureMarkets){
//       nextMarket = futureMarkets.shift();
//     }
//     let listFutMarkAux = [];
//     for (let i = 0; i < futureMarkets.length; i++) {
//       const element = futureMarkets[i];
//       listFutMarkAux.push(
//         {
//           code: element.marketId,
//           displayValue: element.marketName
//         }
//       );
//     }
//     this.setState({
//       futureMarkets: futureMarketsAux,
//       futureMarketsOpt: listFutMarkAux,
//       nextMarket: nextMarket,
//     })
//   }

//   loadRegistrationData(){
    
//     if(!this.isEditing){
//       this.loadData();
//     }else{
//       this.loadMockData();
//     }    
//   }

//   /**
//    * Kick off bound fetch data
//    */
//   componentDidMount() {
//     this.loadRegistrationData();
//   }

//   renderSecondariesRegisterCard(marketName, marketSeason, registrationNumber, registrationQrSrc){
//     var main = null;
    
//     if(this.state.secondaryRegistrations!=null && this.state.secondaryRegistrations.length>0){
//       var secReg = [];
      
//       for (let i = 0; i < this.state.secondaryRegistrations.length; i++) {
//         const element = this.state.secondaryRegistrations[i];
//         secReg.push(
//           <RegistrationCardComponent
//             key={i}
//             fields={this.props.fields}
//             type="secondary"
//             marketName={''}
//             marketSeason={element.market.marketName}
//             registrationNumber={element.badgeId}
//             registrationUser={element.registrationUser}
//             firstName={element.firstName}
//             lastName={element.lastName}
//             companyName={!!element.companyName?element.companyName:""}
//             eventCode={element.eventCode}
//             badgeId={element.badgeId}
//           />
//         );
//       }
//     }

//     return secReg;
//   }
  
//   renderMainCard(){
//     var main = null;
//     if(this.state.primaryRegistration!=null){
//       main = (
//         <div className="imc-gallery__item">
//           <RegistrationCardComponent
//             fields={this.props.fields}
//             type="primary"
//             marketName={''}
//             marketSeason={this.state.primaryRegistration.market.marketName}
//             registrationNumber={this.state.primaryRegistration.badgeId}
//             registrationUser={this.state.primaryRegistration.registrationUser}
//             firstName={this.state.primaryRegistration.firstName}
//             lastName={this.state.primaryRegistration.lastName}
//             companyName={!!this.state.primaryRegistration.companyName?this.state.primaryRegistration.companyName:""}
//             eventCode={this.state.primaryRegistration.eventCode}
//             badgeId={this.state.primaryRegistration.badgeId}
//           />
//         </div>
//       );      
//     }
//     return main;
//   }

//   registerForNextMarket(){
//     var returnPanel = null;
//     if(this.state.nextMarket!=null){
//       returnPanel = (
//         <div className="never-registered-panel">
//           <h1 className="title-reg-market">{this._getDictionaryvalue("registerFor", "Register For")} {this.state.nextMarket.marketName}</h1>
//           <a href={this.state.nextMarket.registrationLink} className="imc-button--secondary imc-button--round">{this._getDictionaryvalue("registerNow", "Register Now")}</a>
//         </div>
//       );
//     }

//     return returnPanel;
//   }
//   noData(){
//     var returnPanel = null;
//     if((!this.state.nextMarket || this.state.nextMarket  == null || this.state.nextMarket  == 'undefined' || this.state.nextMarket.length == 0)&& (!this.state.futureMarketsOpt|| this.state.futureMarketsOpt==null ||this.state.futureMarketsOpt=='undefined' || this.state.futureMarketsOpt.length == 0)){
//       returnPanel = (
//         <div className="never-registered-panel">
//           <h2 className="title-reg-market">{this._getDictionaryvalue("noRegistrationInfo", "Registration Information Unavailable")}</h2>
//           <a href='/Market-Planner' className="imc-button--secondary imc-button--round">{this._getDictionaryvalue("dashboard", "Go To Dashboard")}</a>
//         </div>
//       );
//     }

//     return returnPanel;
//   }
  
//   registerForAnotherMarket(){
//     var anotherMarketSection = null;
//     if(this.state.futureMarketsOpt!=null && this.state.futureMarketsOpt.length>0)
//     {
//       anotherMarketSection = <React.Fragment>
//       <hr/>
//       <div className="register-market-panel">
//         <h2 className="title-reg-market">{this._getDictionaryvalue("registerForAnotherMarket", "Register For Another Market")}</h2>

//         <div className="choose-market-container">
//           <form className="imc-content--display-flex imc-content--display-flex-center" onSubmit={this.handleSubmit}>
//             <DropDown
//               additionalClass={`imc-dropdown--secondary--thin imc-marketplanner--dropdown`}
//               firstOption={this._getDictionaryvalue("chooseMarket", "Choose Market")}
//               hideLabel
//               label={this._getDictionaryvalue("chooseMarket", "Choose Market")}
//               name={'selected-market'}
//               sortedOptions={this.state.futureMarketsOpt}
//               updateValue={this.chooseMarket}
//               value={this.state.selectedMarket}/>
//             <SubmitButton
//               labelSubmit={this._getDictionaryvalue("anotherMarketButton", "GO")}
//               baseButtonClass=''
//               additionalClass="imc-button--secondary-inverted imc-button--round"/>
//           </form>
//         </div>
//       </div>
//       </React.Fragment>;
//     }

//     return(anotherMarketSection);
//   }

//   renderPendingRegistration(){
//     return(
//       <div className="pending-registration">
//         <h1 className="title-reg-market">{this._getDictionaryvalue("submittedRequest", "You've Submitted Your Registration Request")}</h1>
//         <p>{this._getDictionaryvalue("registrationStatus", "Status")}: Pending</p>
//         <p>{this._getDictionaryvalue("submittedDate", "Submitted")}: 9/20/2019</p>
//         <button className="imc-button--invert-gray imc-marketplanner--registration-input" onClick={this.handleClick}>{this._getDictionaryvalue("checkRegistrationButton", "Check Registration")}</button>
//       </div>
//     );
//   }

//   handleSubmit(event) {
//     event.preventDefault();
//     const { futureMarkets, selectedMarket } = this.state;
//     var selectedMarketObj = null;
//     for (let i = 0; i < futureMarkets.length; i++) {
//       const element = futureMarkets[i];
//       if(selectedMarket==element.marketId){
//         selectedMarketObj = element;
//       }
//     }
//     if(selectedMarketObj!=null){
//       window.open(selectedMarketObj.registrationLink, '_blank');
//     }
//   }

//   chooseMarket(updatedName, updatedVal) {
//     this.setState({ selectedMarket: updatedVal });
//   }

//   render() {
//   //  if(!!this.state.primaryRegistration || !!this.state.nextMarket){
//       return (
//         <div className="imc-marketplanner">
//            <Loading showLoading={this.state.loading} />
//           <div className={this.state.galleryClass}>
//             {
//               this.renderMainCard()
//             }
//             <div className="imc-gallery__item">
//               {
//                 this.renderSecondariesRegisterCard()
//               }
//               {this.noData()}
//               { !!this.state.noassociation &&
//                 this.registerForNextMarket()
//               }
//               {
//                 this.registerForAnotherMarket()
//               }
//             </div>
//           </div>
//         </div>
//       );
//     //}
//     // else{
//     //   return(<div className="imc-marketplanner">
//     //     <Loading showLoading={this.state.loading} />
//     //     { this.state.noassociation && this._getDictionaryvalue("noassociation", "Your account does not have an active association.") }
//     //   </div>);
//     // }
//   }
    render() {
        return <></>;
    }
}

export default RegistrationComponent;