
import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {newComponent, returnHeading, contentSectionComponentRendering, richTextRendering, flexGridRendering, imcCtaRendering, standaloneImageRendering, returnMediaSection, returnRichTextHeading, contentColumnSectionRendering} from '../../utils/buildRenderingParameters'
import ContentSectionComponent from '../Content-Section-Component';

interface HeroParenthesisOpenProps {
    fields?: any;
}

const HeroParenthesisOpen: React.FC<HeroParenthesisOpenProps> = ({fields}) => {
    const backgroundSection = contentSectionComponentRendering();
    let spacerSection = contentSectionComponentRendering();
    let gridWrapper = flexGridRendering();
    let parenthesisColor = (fields?.accentColor?.value) ? fields.accentColor.value : "primary-medium";
    let headingFields = newComponent(fields);
    let subHeadingFields = newComponent(fields);
    let heading = contentColumnSectionRendering(headingFields);
    let secondaryHeading = contentColumnSectionRendering(subHeadingFields);
    headingFields.subheading.value = '';
    headingFields.body.value = '';
    // subHeadingFields.headline.value = '';
    // subHeadingFields.body.value = '';
    headingFields.headingExtraClasses.unshift( {
        fields: {
            className: {
                value: "HEADING-1 half-shift-down andmore-width-desktop-67 andmore-width-tablet-67 andmore-width-mobile-67"
            }
        }
    });
    subHeadingFields.secondaryHeadingExtraClasses.unshift( {
        fields: {
            className: {
                value: "HEADING-2 half-shift-up "
            }
        }
    });
    heading = contentColumnSectionRendering(headingFields, false);
    secondaryHeading = contentColumnSectionRendering(subHeadingFields, true, true);

    // ------------------------ //
    // build background section //
    // ------------------------ //
    backgroundSection.fields.backgroundColor = newComponent(fields.backgroundColor);
    backgroundSection.fields.backgroundImage = newComponent(fields.image);
    backgroundSection.fields.styles = {}
    if (fields?.videoFile?.value?.href) {
        backgroundSection.fields.videoFile = {
            value: {
                src: fields.videoFile.value.href,
                description: fields.videoFile.value.description
            }
        };
    }
    if (fields?.backgroundColor?.value || fields?.backgroundOpacity?.value || fields?.backgroundOverride?.value) {
        backgroundSection.fields.backgroundColor.value += ' imc-hero__overlay';
        if (fields?.backgroundColor?.value) {
            backgroundSection.fields.backgroundColor.value += ` imc-hero__overlay--${fields.backgroundColor.value}`;
        }
        if (fields?.backgroundOpacity?.value) {
            backgroundSection.fields.styles["--opacity"] = `${fields.backgroundOpacity.value}%`;
        }
        if (fields?.backgroundOverride?.value) {
            backgroundSection.fields.styles["--overlay"] = `${fields.backgroundOverride.value}`;
        }
    }
    if (fields.minHeight?.length > 0) {
        fields.minHeight.forEach((height?: any) => {
            if (height.fields?.className?.value) {
                if (height.fields.className.value.includes("CLASS-")) {
                    backgroundSection.fields.backgroundColor.value += ` imc-hero__min-height${height.fields.className.value.split("CLASS")[1]}`;
                } else {
                    backgroundSection.fields.styles["minHeight"] = `${height.fields.className.value}`;
                }
            }
        });
    }
    backgroundSection.fields.paddingTop.value = 'xlarge';
    backgroundSection.fields.paddingBottom.value = 'xlarge';
    backgroundSection.fields.paddingLeft.value = 'xlarge';
    backgroundSection.fields.paddingRight.value = 'xlarge';
    backgroundSection.fields.backgroundColor.value += ` am-hero open-middle`;
    backgroundSection.fields.extraClass.fields.className.value += " CONTENT inner-center";
    // ------------------------ //

    // ------------------------ //
    // -- build grid wrapper -- //
    // ------------------------ //
    gridWrapper.params = {
        "columnCount": "{\"className\":{\"value\":\"2\"}}",
        "totalColumns": "{\"className\":{\"value\":\"1\"}}",
        "twoColumnWidths": "{\"className\":{\"value\":\"66-33\"}}",
        "padding": "{\"className\":{\"value\":\"imc-gallery--no-padded\"}}",
        "horizontalAlignment": "{\"className\":{\"value\":\"imc-gallery--justify-center\"}}",
        "verticalAlignment": "{\"className\":{\"value\":\"imc-gallery--align-flex-center\"}}",
        "mobileColumnCount": "{\"className\":{\"value\":\"2\"}}",
        "tabletColumnCount": "{\"className\":{\"value\":\"2\"}}"
    };
    spacerSection.fields.backgroundColor = {
        "value": ` spacer-3x2 boxy am-wrapper am-pare am-pare-${parenthesisColor} hero-am-pare`
    };
    if (fields?.accentOverride?.value) {
        spacerSection.fields.styles = {
            "--pare": `${fields.accentOverride.value}`
        }
    }
    // ------------------------ //

    backgroundSection.placeholders['imc-jss-content-section'].push(heading);
    gridWrapper.placeholders['imc-grid-column-one-placeholder'].push(spacerSection);
    backgroundSection.placeholders['imc-jss-content-section'].push(gridWrapper);
    backgroundSection.placeholders['imc-jss-content-section'].push(secondaryHeading);

    return (
        <ContentSectionComponent {...backgroundSection} rendering={backgroundSection} />
    
    );
};

export default HeroParenthesisOpen;
