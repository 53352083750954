import React, { useState, useRef, useEffect, KeyboardEvent, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from "react-router";
import { padContent, unPadContent } from 'utils/scrollhelpers';
import SvgIcon from 'modules/svgicon';
import { Svg } from '@react-pdf/renderer';

interface DropdownMenuProps extends RouteComponentProps<any> {
  children: ReactNode;
  label: string;
  buttonClassName?: string;
  mobilelabel?: string;
  chevron?: boolean;
  icon?: string;
  color?: string;
  notifications?: number;
  location: any;
  isDrawer?: boolean;
  loading?: boolean;
  hidden?: boolean;
  clickHandler?: (event: any, status: boolean) => void;
  openState?: boolean;
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, children, buttonClassName='', mobilelabel=null, chevron=true, icon=null, color=null, notifications=null, location=null, isDrawer=null, loading=false, hidden=false, clickHandler=null, openState=false }) => {
    const [isOpen, setIsOpen] = useState<boolean>(openState);
    const isOpenRef = useRef(isOpen);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsOpen(openState);
        isOpenRef.current = openState;
      }, [openState]);

    const toggleMenu = (event: any, closeMenu: boolean=false) => {
        const newIsOpen = closeMenu ? false : !isOpenRef.current;
        if (clickHandler) {
            clickHandler(event, newIsOpen)
        } else {
            setIsOpen(newIsOpen);
            isOpenRef.current = newIsOpen;
        }
    };

    const handleClick = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && isOpenRef.current) {
            toggleMenu(event, false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Escape' && isOpenRef.current) {
            toggleMenu(event, false);
        }
    };

    const handleScroll = (event: Event) => {
        if (!isDrawer && isOpenRef.current) toggleMenu(event, false);
    };

    // Add event listener to handle clicks outside the dropdown menu
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('scroll', handleScroll);
        return () => {
        document.removeEventListener('mousedown', handleClick);
        document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isDrawer) {
            if (isOpen) {
                padContent(window.innerWidth - document.documentElement.clientWidth);
                if (dropDownRef?.current) dropDownRef.current.style.marginRight = `0px`;
            } else {
                unPadContent();
                window.innerWidth - document.documentElement.clientWidth
                if (dropDownRef?.current) dropDownRef.current.style.marginRight = `-${window.innerWidth - document.documentElement.clientWidth}px`;
            }
        }
    }, [isOpen]);

    // Listen for route changes
    useEffect(() => {
        toggleMenu(undefined, true); // Close the dropdown on route change
    }, [location.pathname]);

    return (
        <><div className={`top-nav-dropdown ${isOpen ? 'active' : ''}${isDrawer ? ' top-nav-drawer' : ''}`} ref={wrapperRef}>
        {!hidden && <button
            className={`imc-button ${buttonClassName} ${icon} ${(chevron) ? 'top-nav-chevron' : ''}`}
            onClick={toggleMenu}
            aria-expanded={isOpen}
            aria-haspopup="true"
            onKeyDown={handleKeyDown}
            // disabled={loading}
        >
            {(notifications && notifications > 0 && icon) && 
                <div className="top-nav-notification">{notifications}</div>
            }
            {icon && 
                <SvgIcon width={16} height={16} className={`${loading ? `` : ``} top-nav-user-icon ${icon} ${(color) ? color : ''}`} useClassName={`${(color) ? color : ''}`} xlinkHref={`#${(loading) ? icon : icon}`} />
            }
            {label &&
                <span>
                    {mobilelabel != null && <span className={`imc-breakpoint-display--mobile-only`}>{mobilelabel}</span>}
                    <span className={`${mobilelabel != null ? 'imc-breakpoint-display--hide-mobile' : ''}`}>{label}</span>
                </span>
            }
        </button>}
        {(isOpen || isDrawer) && React.Children.map(children, child =>
            React.cloneElement(child as React.ReactElement<any>, { setIsOpen, dropDownRef }))} {/* Pass setIsOpen to children */}
        </div>
        {isDrawer && <div className={`top-nav-overlay imc-modal--overlay ${isOpen ? 'active' : ''}`}></div>}
        </>
    );
};

export default withRouter(DropdownMenu);
