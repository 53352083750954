import { updateDataLayer } from 'utils/datalayer';
import { SHOW_LOADING, CLEAR_SEARCH } from '../../searchresults/actions/actionTypes';
import {
    GET_PREDEFINED_SEARCH,
    GET_MARKETPLANNER_SAVED_SEARCH_SUCCESS,
    SET_EXHIBITORS,
    SET_EXHIBITOR_SEARCH_ERR,
    SET_EXHIBITOR_DATA_OUT_OF_DATA,
    SET_EXHIBITOR_COUNT } from './actionTypes';
// import ExhibitorsAPI from '../../../api/search/exhibitors';
import ExhibitorsAPI from '../../../api/exhibitor';
// import SavedSearchAPI from '../../marketplanner/api/savedsearch';

const _searchExhibitors = new ExhibitorsAPI();

/**
 * Action to get the Saved Searches for the user
 * @param {num} accountId account Id
 * @returns {Function} Promise object with fetch to the SavedSearchAPI
 */
export function getSavedsearches(accountId = 1) {
    return
}

/**
 * Dispatch as action to mark data out of data
 * So next time component loads, new call will be made to api
 * @return {function} function to dispatch action
 */
export function setExhibitorDataOutOfDate() {
    return (dispatch) => {
        dispatch({
            type: SET_EXHIBITOR_DATA_OUT_OF_DATA,
        });
    };
}
/**
 * Action to display loading icon
 * @param {string} searchQuery term used to execute search
 * @returns {Function} Dispatcher used to set loading state
 */
export function clearSearch() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SEARCH,
            status: true,
            searchQuery: '',
        });
    };
}

/**
 * Action to get predefined search.
 * @returns {*} Promise object with fetch of predefined search.
 */
export function getPredefinedSearch() {
    return dispatch => _searchExhibitors.getPredefinedSearch()
        .then((response) => {
            dispatch({
                type: GET_PREDEFINED_SEARCH,
                predefinedSearch: response,
            });
        });
}

/**
 * Action to get exhibitors related to privided search term
 * @param {string} query Query to run
 * @param {object} filters Object of selected filters
 * @param {string} pagenum of page numbers
 * @param {string} type of search
 * @returns {Function} Promise object with fetch to the SearchExhibitors
 */
export function fetchExhibitors(query, filters = {}, pagenum, type) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });
        return _searchExhibitors.getExhibitors(query, filters, pagenum, type).then((response) => {
            const { status, exhibitors, count } = response || {};
            if (status === 200) {
                dispatch({
                    type: SET_EXHIBITORS,
                    exhibitors,
                    count,
                });
                dispatch({
                    type: SET_EXHIBITOR_COUNT,
                    count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_EXHIBITOR_SEARCH_ERR,
                    err: status,
                });
                dispatch({
                    type: SET_EXHIBITOR_COUNT,
                    count,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        });
    };
}
