import React, { Component } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Form, FieldTypes, createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import { SingleLineText, RadioField, DropDown, CheckBox, Button, TextField, Section, SingleLineEmail, TextArea} from '../../modules/Form-Fields';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';


export default class index extends Component {
  /**
   * @method constructor
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      showError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const defaultFieldFactory = createDefaultFieldFactory();
    const { fields, currentEmail, formType } = this.props;
    defaultFieldFactory.setComponent(FieldTypes.Section, (props) => {
      return <Section fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.Email, (props) => {
      return <SingleLineEmail formType={formType} currentEmail={currentEmail} fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => {
      return <SingleLineText fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, (props) => {
      return <TextArea fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.RadioButtonList, (props) => {
      return <RadioField fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.DropdownList, (props) => {
      return <DropDown fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.Checkbox, (props) => {
      return <CheckBox fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.Button, (props) => {
      return <Button fieldData={props} />;
    });
    defaultFieldFactory.setComponent(FieldTypes.TextField, (props) => {
      return <TextField fieldData={props} />;
    });
    if (!fields) {
        if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    const ErrorComponent = (props) => {
      if (props.fieldErrors.length > 0) {
        return (
          <div>
            {props.fieldErrors.map((e, index) => (
              <div className="imc-content imc-content--error" key={`formError-${index}`}>
              </div>
            ))}
          </div>
        )
      } else {
        return (
          <div></div>
        )
      }
    };
    return (
      <div>
        <Form
          form={fields}
          sitecoreApiHost={getSitecoreApiHost()}
          sitecoreApiKey={getSitecoreApiKey()}
          fieldFactory={defaultFieldFactory}
          errorComponent={ErrorComponent}
          // onSubmit={this.handleSubmit}
        />
      </div>
    );
  };
}


