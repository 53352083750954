import React, { Component } from 'react';
import { Placeholder, withSitecoreContext, Image, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';


class AccordionComponent extends Component {
  /**
 * @method constructor
 * @description
 * By default sets the expanded state to true
 * @param {object} props Incoming props
 */
  constructor(props) {
    super(props);
    if (!props) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.title = props.fields.title.value;
    this.content = 'Content Test';

    this.state = {
      expanded: props.expanded,
    };
    this.index = -1;
    for (let i = 0; i < this.props.indexList.length; i++) {
      var item = this.props.indexList[i];
      if (item.uid == this.props.rendering.uid) {
        
        this.state.index = item.index;
      }
    }
    this.showImage = false;
    this.icon = "";
    if (this.props.fields.icon) {

      this.icon = this.props.fields.icon.value
      // this.icon = this.icon.src.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell","")
      this.icon.width = null;
      this.icon.height = null;
      // this.showImage = true;

    }


    // set aliases
    this.toggleExpanded = this.toggleExpanded.bind(this);
    //   this.updateExpanded = this.updateExpanded.bind(this);
    // this.scrollElementToTop = this.scrollElementToTop.bind(this);
    this.expandKeypress = this.expandKeypress.bind(this);

    this.updateStateChild = this.updateStateChild.bind(this);
    this.updateDefaultOpen = this.updateDefaultOpen.bind(this);
  }


  /**
      * Updates the Accordion state from the prop
      */
  componentWillMount() {
    const { currentIndex } = this.state;
    if (typeof window == 'undefined' || !window.Sitecore) {
      if (this.props.isSection) {
        console.log(JSON.stringify(this.props.rendering.uid));
        this.props.addSection(this.title, this.toggleExpanded);
      }
      
    }
  }

  /**
   * Updates the expanded state from the prop
   * @param {object} prevProps Previous props
   */
  componentDidUpdate(prevProps) {
    // if (prevProps.defaultOpen !== this.props.defaultOpen) {
    //     //  this.updateDefaultOpen();
    // }
    // this.setState((state, props) => ({
    //    expanded : state.index == props.currentIndex
    //   }));
  }

  /**
   * Updates the expanded state based on the updated prop
   */
  updateDefaultOpen() {
    const { defaultOpen } = this.props;
    this.setState({
      currentIndex: defaultOpen,
    });
  }



  /**
   * @method toggleExpanded
   * @description Toggles if the element is expanded or collapsed
   * @param {object} e event
   */
  expandKeypress(e) {
    const code = e ? e.keyCode || e.which : undefined;
    if (code && code === 13) {
      this.toggleExpanded();
    }

  }
  /**
   * @method toggleExpanded
   * @description Toggles if the element is expanded or collapsed
   * @param {object} e event
   */
  toggleExpanded() {

    var expandedUpdatedState;

    if (this.props.isSection) {
      //alert(this.props.rendering.uid + ' vs ' + this.props.currentUid);
      expandedUpdatedState = !(this.props.rendering.uid == this.props.currentUid);

      this.setState({ expanded: expandedUpdatedState, })

      this.props.expandedUpdated(expandedUpdatedState);

      if (expandedUpdatedState) {
        //alert('in expandedupdatestate' + this.props.rendering.uid);
        this.props.function(this.state.index);
        this.props.updateUid(this.props.rendering.uid);
       
      } else {
        this.props.function(-1);
        this.props.updateUid('');
      }

    } else {
      //alert('AC - stateIndex: ' + this.state.index + ' currentIndex: ' + this.props.currentIndex);
      expandedUpdatedState = !(this.state.index == this.props.currentIndex);

      this.setState({ expanded: expandedUpdatedState, })

      this.props.expandedUpdated(expandedUpdatedState);

      if (expandedUpdatedState) {
        //alert('in expandedupdatestate' + this.props.rendering.uid);
        this.props.function(this.state.index);

      } else {
        this.props.function(-1);
      }

      if (this.props.isCategory) {
        //alert('AC b4 update called from')
        this.props.updateCalledFromDropdownAC(false);
      }
    }
  }




  /**
       * @method updateStateChild
       * @description updating Which accordion open and scroll
       * @param {object=} event Event object
       */
  updateStateChild(event) {
    this.setState({ currentIndex: event.target.value });
  }

  /**
   * @method renderHeading
   * @description
   * Renders the heading container element
   * @returns {Element} Rendered element heading
   */
  renderHeading() {
    const { title, children, renderAsHTML } = this.props;

    if (renderAsHTML) {
      return React.createElement('div', { dangerouslySetInnerHTML: { __html: this.title } });
    }
    return this.title;
  }

  /**
   * @method renderContent
   * @description
   * Renders the heading container element
   * @returns {Element} Rendered content element
   */
  renderContent() {
    const { content, children, renderAsHTML, contentClass } = this.props;
    if (renderAsHTML) {
      return React.createElement('div', {
        className: `imc-expand-collapse__content ${contentClass}`,
        dangerouslySetInnerHTML: { __html: content },
      });
    }
    return React.createElement('div', {
      className: `imc-expand-collapse__content ${contentClass}`,
    },
      children[1],
    );
  }

  /**
   * @method render
   * @description Renders the DOM element
   * @returns {*} Rendered component
   */
  render() {

    //alert('AC with uid: ' + this.props.rendering.uid + ' currentAMUid: ' + this.props.currentUid);

    const { expandCollapse, addFlex, additionalHeadingClass, heading } = this.props;

    var expanded;
    if (this.props.isSection) {
      expanded = this.props.rendering.uid == this.props.currentUid;
      if(expanded && !this.props.calledFromDropdown){
        var elmnt = document.getElementById(this.props.rendering.uid);
        elmnt.scrollIntoView(true); 
      }
    } else {
      expanded = this.state.index == this.props.currentIndex;
      if(expanded && this.props.currentIndex === 0 && this.props.calledFromDropdown){
        var elmnt = document.getElementById(this.props.rendering.uid);
        elmnt.scrollIntoView(true); 
      }
    }
    
    const expandedClass = expanded ? 'imc-expand-collapse--expanded' : '';
    const isMobile = expandCollapse === 'mobile' ? 'imc-expand-collapse--mobile' : '';
    const isFlex = addFlex ? 'imc-expand-collapse--flex' : '';
    if (typeof window !== 'undefined' && window.Sitecore) {
      return (
        <div
          ref={(elem) => { this.nv = elem; }}
          className={`imc-expand-collapse imc-expand-collapse--expanded ${isMobile} ${isFlex}`}
        // className="imc-expand-collapse__heading imc-accordion--heading  imc-heading--alt"
        > <div
          tabIndex={0}
          role="button"
          // className={`imc-expand-collapse__heading ${additionalHeadingClass}`}
          className="imc-expand-collapse__heading imc-accordion--heading  imc-heading--alt"
          ref={(node) => { this.expandCollapseHeading = node; }}
        >
            <a
              tabIndex={0}
              role="button"
              aria-expanded={this.state.index == this.props.currentIndex}
              //onClick={this.toggleExpanded}
              //onKeyPress={this.expandKeypress}
              aria-label={heading}
            >
              <div  className="imc-content--display-flex imc-content--display-flex-middle imc-heading--secondary">
                <div className="imc-heading--secondary imc-heading--kilo-desktop imc-heading--beta">
                  {this.title}
                </div>
              </div>
              {/* {this.renderHeading()} */}
            </a>
          </div>
          <div className="imc-expand-collapse__content">
            <Placeholder name="imc-accordion-placeholder" rendering={this.props.rendering} />
          </div>

        </div>
      );
    } else {
      return (
        <div id={this.props.rendering.uid} 
          ref={(elem) => { this.nv = elem; }}
          className={`imc-expand-collapse ${expandedClass} ${isMobile} ${isFlex}`}
        // className="imc-expand-collapse__heading imc-accordion--heading  imc-heading--alt"
        > <div
          tabIndex={0}
          role="button"
          // className={`imc-expand-collapse__heading ${additionalHeadingClass}`}
          className="imc-expand-collapse__heading imc-accordion--heading  imc-heading--alt"
          ref={(node) => { this.expandCollapseHeading = node; }}  
        >
            <a
              tabIndex={0}
              role="button"
              aria-expanded={this.state.index == this.props.currentIndex}
              onClick={this.toggleExpanded}
              onKeyPress={this.expandKeypress}
              aria-label={heading}
            >
              {this.showImage &&
                <div className="imc-accordion--margin">
                  <img src={this.icon.src} />
                  <Image field={this.icon} />
                </div>
              }
              <div  className="imc-content--display-flex imc-content--display-flex-middle imc-heading--secondary">
                { this.icon.src ? <img src={this.icon.src} className="imc-accordion--icon" /> : <div className="imc-accordion--icon" />}
                <div className="imc-heading--secondary imc-heading--kilo-desktop imc-heading--beta">
                  {this.title}
                </div>
              </div>
              {/* {this.renderHeading()} */}
            </a>
          </div>
          <div className="imc-expand-collapse__content">
            <Placeholder name="imc-accordion-placeholder" rendering={this.props.rendering} />
          </div>

        </div>
      );
    }
  }
}
export default withSitecoreContext()(AccordionComponent);
/**
* @property propTypes
* @description Defined property types for component
* @type {{heading: *, content: *, expanded: shim, expandCollapse: *, addFlex: shim}}
*/
AccordionComponent.propTypes = {
  heading: PropTypes.string, // Heading string that is clickable
  content: PropTypes.string, // Content that expands or collapses
  expanded: PropTypes.bool, // determines if expanded by default
  expandCollapse: PropTypes.string, // If value is mobile then it will add mobile specific class
  addFlex: PropTypes.bool, // determines if display flex need to add
  // scrollToTop: PropTypes.bool, // determines if we need to scroll up the content
  // scrollOffset: PropTypes.string, // if any offset for scroll,
  // scrollOffsetCategory: PropTypes.string, // if any offset for scroll,
  expandedUpdated: PropTypes.func, // Need to used for updating parent state
  additionalHeadingClass: PropTypes.string, // If any additional heading class needed
  renderAsHTML: PropTypes.bool, // Renders the header and content as HTML
  // accordianIndex: PropTypes.number, // accordianIndex for expand collapse
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  contentClass: PropTypes.string,
};

/**
* @property defaultProps
* @type {{expanded: boolean}}
*/
AccordionComponent.defaultProps = {
  heading: 'Test',
  content: 'Test',
  expanded: false,
  expandCollapse: 'all',
  addFlex: false,
  scrollToTop: true,
  scrollOffset: '',
  scrollOffsetCategory: '',
  expandedUpdated: () => { },
  additionalHeadingClass: '',
  renderAsHTML: true,
  children: {},
  contentClass: '',
  accordianIndex: null,
  defaultOpen: -1,
};