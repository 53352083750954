import React, {Component}from 'react';
import {  withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { createAppState } from 'modules/redux-app-state';
import { getDictionaryValue } from 'utils/dictionary';
import EnquiryItem from './components/enquiryItem';
import enquiriesReducer from './reducers/reducer';
import * as dataActions from './actions/dataActions';
import { Loading } from 'modules/loading';
const theState = createAppState();
class MyInquiriesComponent extends Component {

  constructor(props) {
    super(props);
    theState.reducerRegistry.register({ enquiriesReducer });
    this.state = {
      confirmationOpen : false
    }
    this.fetchData = this.fetchData.bind(this);
    this.renderEnquiryItem = this.renderEnquiryItem.bind(this);
  }

  componentWillMount() {
    
  }

  componentDidMount() {
    if (!isExperienceEditorActive()) {
      this.fetchData();
    }
  }
  
  fetchData(){
    this.props.dataActions.fetchEnquiries();
  }

  handleClosePopup(){
    this.setState({confirmationOpen: false});
  }

  renderEnquiryItem(){
    const enquiryList = this.props.data;

    if (typeof enquiryList == 'undefined' || (!!enquiryList && enquiryList.length == 0) || !Array.isArray(enquiryList))
  return <span>{getDictionaryValue('noEnquiries', 'There are currently no inquiries.')}</span>;
      return enquiryList.map((item, index) => {
        const props = {
          ...item,
          ...this.props
        };
        return (
          <EnquiryItem key={index} {...props} ></EnquiryItem>
        );
      })
  }

  render() {
    return(
      <div>
        {!!isExperienceEditorActive() && <div>Data source not set in Experience Editor. </div>}
        <h3 className="imc-content--center-mobile imc-heading--giga-desktop imc-heading--kilo imc-heading imc-heading--headingh2 imc-vr--large imc-breakpoint-display--hide-mobile">{getDictionaryValue('myInquiries', 'My Inquiries')}</h3>
        <ul className='imc-market-planner-list'>
          {this.renderEnquiryItem()}
        </ul>
        <Loading showLoading={this.props.showLoading} />
      </div>
    )
  }

}

function mapStateToProps(state) {
  if (!!state.enquiriesReducer){
    return {
      data: state.enquiriesReducer.data,
      showLoading: state.enquiriesReducer.showLoading,
      deleteSucess: state.enquiriesReducer.deleteSucess,
    }
  }
  else {
    return {
      data: state.data,
      showLoading: state.showLoading,
      deleteSucess: state.deleteSucess,
    };
  }
}

 /**
  * Maps dispatch to props for connect
  * @param {function} dispatch Dispatcher
  * @returns {object} Action creators
  */
 function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

MyInquiriesComponent.defaultProps = {
};

export default withSitecoreContext()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyInquiriesComponent)));
