import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import IsolatedScroll from 'react-isolated-scroll';
import qs from 'query-string';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withRouter } from 'react-router';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
//Utils
import { getLocalRedirectionUrl } from 'utils/getRedirectionUrl';
import { encode, decode } from 'utils/querystring';
import { getDictionaryValue, SEARCH_RESULTS_DICTIONARY_KEY_PREFIX } from 'utils/dictionary';
import { uniqueid } from 'utils/uniqueid';
import { escapeRegexCharacters } from 'utils/escapecharacters';
import { getSearchTypeFromPath } from 'utils/search';
import ImcDataLayer from 'utils/datalayer';
//actions
import * as searchActions from '../actions/searchActions';
import TypeAheadSearchAPI from '../api/search';

//Local Components
import SavedSearchesButton from './SavedSearchesButton.jsx';
import MultiSelectButton from './MultiSelectButton.jsx';
import SortButton from './SortButton.jsx';
import ShareButton from './ShareButton.jsx';

//Modules
import AlertBox from 'modules/alertbox';
import SearchPubSubService from "modules/search/services/pubsub";



const DESKTOP_BREAKPOINT = 992;

/**
 * Sets the PropTypes for the form
 * @type {
 * {actions, query: *, placeholderText: *, btnText: *, typeaheadsearch,
 * checkIfSelected: *, customSubmit: *, mobileSearchButton: *, btnClickRequired: *, 
 * onChange: *, maxLength: *, inputId, minLengthForSearch: *, sortType: *, typeCheck: *}}
 */
const propTypes = {
    actions: PropTypes.object.isRequired,
    query: PropTypes.string,
    placeholderText: PropTypes.string,
    btnText: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    typeaheadsearch: PropTypes.object.isRequired,
    checkIfSelected: PropTypes.func,
    customSubmit: PropTypes.func,
    mobileSearchButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    btnClickRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputId: PropTypes.string.isRequired,
    minLengthForSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sortType: PropTypes.bool,
    typeCheck: PropTypes.string,
    showClearSearch: PropTypes.object,
    clearSearchTitle: PropTypes.string,
    clearSearchDescription: PropTypes.string,
    disableTypeahead: PropTypes.bool,
    hideSubmitButton: PropTypes.bool,
    useGlobalSearchConfiguration: PropTypes.bool,
    forceShowClearSearch: PropTypes.bool
};

/**
 * @property defaultProps
 * @type {
 * {query: string, placeholderText: string, btnText: string,
 * resultsRedirectUrl: string, checkIfSelected: null, customSubmit: null, btnClickRequired: boolean,
 * mobileSearchButton: boolean, onChange: null, maxLength: number, inputId: *,
 * minLengthForSearch: number, sortType: boolean, typeCheck: string}}
 */
const defaultProps = {
    query: '',
    placeholderText: 'Search',
    btnText: 'Search',
    resultsRedirectUrl: '',
    checkIfSelected: null,
    customSubmit: null,
    btnClickRequired: false,
    mobileSearchButton: false,
    onChange: null,
    maxLength: 0,
    inputId: uniqueid('input-'),
    minLengthForSearch: 3,
    sortType: false,
    typeCheck: '',
    showClearSearch: {value:false},
    clearSearchTitle: 'Clear Search',
    clearSearchDescription: 'Clear the search form.',
    disableTypeahead: false,
    hideSubmitButton: false,
    useGlobalSearchConfiguration: false,
    forceShowClearSearch: false
};

/**
 * Initialize the TypeAheadSearchAPI
 * @type {TypeAheadSearchAPI}
 * @private
 */
const _TypeAheadSearchAPI = new TypeAheadSearchAPI();

/**
 * Get xpath variables for tagging
 * @param {object} suggestion Incoming Suggestion
 * @returns {object} Attributes to be spread on the suggestion item
 */
function getXpathAttrs(suggestion) {
    const attrs = {};
    let nameString = 'data-xpath-';
    let idString = 'data-xpath-';
    switch (suggestion.type) {
        case 'exhibitor':
            nameString = `${nameString}exhibitorname`;
            idString = `${idString}exhibitorid`;
            break;
        case 'product':
            nameString = `${nameString}productname`;
            idString = `${idString}productid`;
            break;
        case 'article':
            nameString = `${nameString}articlename`;
            idString = `${idString}articleid`;
            break;
        case 'event':
            nameString = `${nameString}eventname`;
            idString = `${idString}eventid`;
            break;
        case 'line':
            nameString = `${nameString}linename`;
            idString = `${idString}lineid`;
            break;
        case 'lineproduct':
            nameString = `${nameString}productname`;
            idString = `${idString}productid`;
            break;
        default:
            break;
    }
    if (nameString !== 'data-xpath-') {
        attrs[nameString] = suggestion.label;
        attrs[idString] = suggestion.id;
    }
    return attrs;
}
/**
 * TypeAheadSearchForm component class
 * Wrapper for the auto search and results
 */
class TypeAheadSearchForm extends Component {


    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.runSearch = this.runSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.redirectUrl = this.redirectUrl.bind(this);
        this.getDestinationUrl = this.getDestinationUrl.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.closeOtherMenus = this.closeOtherMenus.bind(this);
        this.renderOptionsBar = this.renderOptionsBar.bind(this);
        this.isOpenedMenu = this.isOpenedMenu.bind(this);
        this.renderAlertBox = this.renderAlertBox.bind(this);
        this.showCompactView = this.showCompactView.bind(this);
        this.updateAlertPosition = this.updateAlertPosition.bind(this);
        this.getResults = debounce(this.props.actions.getResults, 250);
        this.inputSearchRef = (this?.props?.searchRef) ? this.props.searchRef : React.createRef();
        
        this.resetResult = this.props.actions.resetResult;
        this.minLengthForSearch = props.minLengthForSearch;
        this.disableTypeahead = props.disableTypeahead;
        const { query } = props;
        this.state = {
            closeOtherMenusOpenerId: '',
            valueObj: {},
            isValid: (query.length >= this.minLengthForSearch),
            isLoading: false,
            isSuggestionSelected: false,
            messageType: undefined,
            message: undefined,
            alertboxMarginTop: 10,
            types: []
        };
    }

    componentDidMount() {
        if (this.props.showMessage) {
            this.searchPubSubService = SearchPubSubService.getInstance();

            this.searchPubSubService.messageChange().subscribe(messageChange => {
                this.setState({
                    messageType: messageChange.type,
                    message: messageChange.message,
                })
            });
            const searchType = getSearchTypeFromPath(this.props.location.pathname);
            const messageChanged = {
                type: 'info',
                message: getDictionaryValue(`${SEARCH_RESULTS_DICTIONARY_KEY_PREFIX}default_${searchType}`, 'Search & Discover from 4,000+ Lines and 1,000,000+ Products'),
            };
            this.searchPubSubService.emitMessageChanged(messageChanged);
            window.addEventListener('resize', this.updateAlertPosition);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateAlertPosition);
    }

    /**
     * Component Update
     */
    componentDidUpdate(prevProps) {
        const { query } = this.props;
        if (prevProps.query !== query) {
            this.setState({
                isValid: (query.length >= this.minLengthForSearch),
                alertboxMarginTop: this.getAlertBoxMarginTop()
            });
        } else if (this.state.alertboxMarginTop !== this.getAlertBoxMarginTop())
            this.setState({
                alertboxMarginTop: this.getAlertBoxMarginTop()
            });
    }

    updateAlertPosition() {
        if (this.state.alertboxMarginTop !== this.getAlertBoxMarginTop())
            this.setState({
                alertboxMarginTop: this.getAlertBoxMarginTop()
            });
    }

    /**
     * Clear the suggestions from field
     * @param {object} suggestion object for templating the suggestion UI
     * @returns {XML} Rendered component
     */
    static renderSuggestion(suggestion, { query }) {

        const filterInvalidFirstCharacters = (query) => {
            // Define a regular expression pattern that matches non-alphanumeric characters at the start of words
            const invalidStartPattern = /^[^a-zA-Z0-9]+/;
        
            // Split the query into words
            const words = query.split(/\s+/);
        
            // Filter out invalid first characters in each word recursively
            const filteredWords = words.map(word => {
                
                // Handle backslash at the end of the word
                if (word.endsWith('\\')) {
                    word = word.slice(0, -1);
                }

                while (invalidStartPattern.test(word)) {
                    word = word.replace(invalidStartPattern, '');
                }
                return word;
            }).filter(word => word.trim() !== "");
        
            // Join the filtered words back into a single string
            const filteredQuery = filteredWords.join(' ');
            return filteredQuery;
        }

        const suggestionText = `${suggestion.label}`;
        const suggestionType = `${suggestion.type}`;

        // Filter invalid first characters from the query
        const filteredQuery = filterInvalidFirstCharacters(query);

        const queryWords = filteredQuery.split(/\s+/);
        const regex = new RegExp(`(${queryWords.join('|')})`, 'gi');
        const parts = suggestionText.split(regex);
        const xpath = getXpathAttrs(suggestion);
        let extraClass;
        if (suggestionType === 'product') {
            extraClass = 'react-autosuggest__type__product';
        } else if (suggestionType === 'line') {
            extraClass = 'react-autosuggest__type__line';
        } else if (suggestionType === 'exhibitor') {
            extraClass = 'react-autosuggest__type__exhibitor';
        } else if (suggestionType === 'article') {
            extraClass = 'react-autosuggest__type__article';
        } else if (suggestionType === 'info') {
            extraClass = 'react-autosuggest__type__info';
        } else if (suggestionType === 'category') {
            extraClass = 'react-autosuggest__type__category';
        } else {
            extraClass = null;
        }
        return (
            <span {...xpath} className={'react-autosuggest__suggestion-content'}>
                <span className={'react-autosuggest__row'}>
                    {parts.map((part, index) =>
                        (regex.test(part)) ? (
                            <strong key={index}>{part}</strong>
                        ) : (
                            <span key={index}>{part}</span>
                        )
                    )}
                    <span className={`react-autosuggest__type ${extraClass}`}>{suggestionType}</span>
                </span>
            </span>
        );
    }

    /**
     * Extract the value from object
     * @param {object} suggestion object with result key values
     * @return {string} result string only
     */
    static getSuggestionValue(suggestion) {
        return `${suggestion.label}`;
    }



    /**
     * Clear function when 'X' is clicked.
     */
    onClear() {
        this.onChange(null, { newValue: '', method: '' });
        this.setState({
            valueObj: {},
        }, () => {
            this.runSearch(null, null, true);
        });
    }

    /**
     * OnChange event for text field
     * @param {object} event Query to update to the state
     */
    onChange(event, { newValue, method }) {
        const isValid = newValue.length >= this.minLengthForSearch;
        const pageId = this.props.sitecoreContext.route.itemId;
        this.setState({
            isValid,
            isSuggestionSelected: false,
        });

        const { checkIfSelected } = this.props;
        if (checkIfSelected !== null) {
            checkIfSelected(false);
        }

        if (method === 'type' && isValid && !this.disableTypeahead) {
            // dispatches action to fetch
            // suggested results from the api
            // If the typeahead is configured to use global search it won't pass rthe current page Id
            this.getResults(newValue, this.props.exhibitorId, this.props.types, this.props.useGlobalSearchConfiguration?null:pageId);
        }

        // propagate typed input to parent component
        // eslint-disable-next-line no-unused-expressions
        this.props.onChange && this.props.onChange('', newValue);
    }

    /**
     * Updates the suggestion state
     */
    onSuggestionsFetchRequested({ value, reason }) {
        if (reason === 'input-focused') {
            this.getResults(value, this.props.exhibitorId);
        }
    }

    /**
     * Clear the suggestions from field
     */
    onSuggestionsClearRequested() {
        this.resetResult();
    }
    /**
     * Will be called every time suggestion is selected via mouse or keyboard.
     * @param {object} event Query to update to the state
     */
    onSuggestionSelected(event, { suggestion }) {
        
        this.setState({
            isSuggestionSelected: true,
            valueObj: suggestion,
        });
        const { checkIfSelected, btnClickRequired, typeCheck } = this.props;
        if (checkIfSelected !== null) {
            checkIfSelected(true);
        }
        if (!btnClickRequired) {
            // eslint-disable-next-line no-unused-expressions
            this.props.customSubmit ? this.props.customSubmit(suggestion.label, suggestion) : this.runSearch(null, suggestion);
        } else {
            this.redirectUrl(suggestion);
            if(this.props.onAfterSubmit)
                this.props.onAfterSubmit();
        }
    }
    /**
     * Get Suggestion based on the value
     * @param {string} value text feild updated value
     * @return {object} return suggestion matched value
     */
    getSuggestions(value) {
        
        const { typeaheadsearch } = this.props;
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp(`\\b${escapedValue}`, 'i');
        return typeaheadsearch.options.filter(result => regex.test(TypeAheadSearchForm.getSuggestionValue(result)));
    }
    /**
 * Redirect Url according to data selected in Header
 * @param {object} suggestion Suggestion object
 */
    redirectUrl(suggestion) {
        const { query, btnClickRequired, resultsRedirectUrl,history } = this.props;
        let queryText = query;
        let valObj = Object.assign({}, suggestion);
        if (!btnClickRequired && suggestion) {
            queryText = suggestion.label;
        }
        let _resultsRedirectUrl = resultsRedirectUrl || window.location.pathname;
        const siteMainURL = `${window.location.origin}`;
        let type = !!valObj.type?valObj.type.toLowerCase().toLowerCase():'';
        switch (type) {
            case 'exhibitor':
                history.push(`${getLocalRedirectionUrl('exhibitorDetailPageUrl')}`.replace('$1', valObj.id));
                break;
            case 'catalog':
                history.push( `${getLocalRedirectionUrl('exhibitorDetailWithCatalogUrl')}`
                    .replace('$1', valObj.exhibitorId));
                break;
            case 'product':
                if(!valObj.lineId){
                    _resultsRedirectUrl =`${getLocalRedirectionUrl('productDetailUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.id);
                }
                else{
                    _resultsRedirectUrl = `${getLocalRedirectionUrl('lineProductDetailUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.lineId).replace('$3', valObj.id);
                }
                history.push(_resultsRedirectUrl);
                break;
            case 'line':
                history.push(`${getLocalRedirectionUrl('lineDetailPageUrl')}`.replace('$1', valObj.exhibitorId).replace('$2', valObj.id));

                break;
            case 'article':
            case 'event':
                history.push(valObj.destinationUrl);
                break;
            default:
                const queryObj = decode(window.location.search);
                queryObj.q = queryText;
                queryObj.page = 1;
                const queryString = encode(queryObj, false, false);
                history.push(`${_resultsRedirectUrl}?${queryString}`);
                break;
        }
    }

    /**
     * Get Destination Url
     * @param {object} suggestion Suggestion object
     */
    getDestinationUrl(suggestion) {
        const { query, history } = this.props;
        const siteMainURL = `${window.location.origin}`;
        const resultsRedirectUrl = '/search';
        let valObj = Object.assign({}, suggestion);
        _TypeAheadSearchAPI.getDestinationUrl(valObj)
            .then((response) => {
                switch (valObj.type) {
                    case 'line': {
                        const exhibitID = response.lines[0].exhibitorId;
                        history.push(`${getLocalRedirectionUrl('lineDetailPageUrl')}`.replace('$1', exhibitID)
                            .replace('$2', valObj.id));
                        break;
                    }
                    case 'lineproduct': {
                        const exhibitID = response.products[0].exhibitorId;
                        const lineId = response.products[0].lineId;
                        history.push(
                            `${getLocalRedirectionUrl('lineProductDetailUrl')}`.replace('$1', exhibitID).replace('$2', lineId)
                                .replace('$3', valObj.id));
                        break;
                    }
                    case 'article':
                    case 'event': {
                        const url = response.data[0].destinationUrl;
                        history.push(`${url}`);
                        break;
                    }
                    default:
                        history.push(`${resultsRedirectUrl}?q=${encode(query, false, false)}`);
                        break;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    /**
     * Activate Suggestion based on the value lenght
     * @param {string} value text field updated value
     * @return {boolean} return true/false
     */
    shouldRenderSuggestions(value) {
        return !this.disableTypeahead && value.trim().length >= this.minLengthForSearch;
    }

    getAbsoluteHeight(el) {
        var styles = window.getComputedStyle(el);
        var margin = parseFloat(styles['marginTop']) +
            parseFloat(styles['marginBottom']);

        return Math.ceil(el.offsetHeight + margin);
    }

    closeOtherMenus(openerId) {
        this.setState({
            closeOtherMenusOpenerId: openerId,
        })
    }
    isOpenedMenu() {
        return this.state.closeOtherMenusOpenerId !== '';
    }
    isMobile() {
        return window && Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < DESKTOP_BREAKPOINT;
    }

    showCompactView() {
        const { showSavedSearches, showMultiSelect, showSort, showShare } = this.props;
        let count = 0;
        if (showSavedSearches) count++;
        if (showMultiSelect) count++;
        if (showSort) count++;
        if (showShare) count++;
        return count === 1;
    }
    renderSuggestionsContainer({ containerProps, children }) {
        const { navContainer } = this.props;
        const { ref, ...restContainerProps } = containerProps;
        const callRef = isolatedScroll => {
            if (isolatedScroll !== null) {
                ref(isolatedScroll.component);
            }
        };
        // Set container's Height for scrolling.
        let containerHeight;
        // if (typeof document !== "undefined") {
        //     const searchElement = document.querySelector('.imc-navigation-mobile__tierSearchItem');
        //     const headerElement = document.querySelector('.imc-header');
        //     const headerOffset = (headerElement) ? headerElement.offsetTop + headerElement.offsetHeight : 0;
        //     const searchOffset = (searchElement) ? this.getAbsoluteHeight(searchElement) : 0;
        //     const sugestionsTopPosition = headerOffset + searchOffset;
        //     containerHeight = isNaN(navContainer - sugestionsTopPosition) ? 'auto' : navContainer - sugestionsTopPosition;
        // }
        // else
            containerHeight = 'auto';

        return (
            <IsolatedScroll
                ref={callRef}
                {...restContainerProps}
                style={{
                    height: containerHeight
                }}
            >
                {children}
            </IsolatedScroll>
        );
    }
    /**
     * Runs a search query
     * @param {object} e Event object
     * @param {object} suggestion Suggestion object
     */
    runSearch(e, suggestion, clear=false) {
        let isHeader=false;
        if (e) {
            e.preventDefault();
            isHeader = !!e.target.closest(".header-search-wrap");
        }
        
        const { valueObj } = this.state;
        const { customSubmit, query, btnClickRequired } = this.props;
        
        ImcDataLayer.pushInteractionEvent("search", isHeader?"submit-header":"submit-main", query);
        if (customSubmit !== null) {
            if (btnClickRequired) {
                customSubmit(valueObj);
            } else {
                customSubmit(query);
            }
        } else {
            if (!query && this?.inputSearchRef?.current?.input && !(/^\/search\//i.test(window.location.pathname)) && !(/\/exhibitor\/.*directory/i.test(window.location.pathname))) {
                let parsed = decode(window.location.search);
                if (query) {
                    parsed.q = query;
                } else {
                    delete parsed.q;
                }
                this.props.history.push({search: encode(parsed, false, false)});
                this.inputSearchRef.current.input.focus();
            } else {
                if (suggestion) {
                    this.redirectUrl(suggestion);
                } else if (this?.inputSearchRef?.current?.input && (/\/exhibitor\/.*directory/i.test(window.location.pathname))) {
                    let parsed = decode(window.location.search);
                    if (query) {
                        parsed.q = query;
                    } else {
                        delete parsed.q;
                    }
                    this.props.history.push({search: encode(parsed, false, false)});
                    if (query) {
                        this.inputSearchRef.current.input.blur();
                    } else {
                        this.inputSearchRef.current.input.focus();
                    }
                } else {
                    this.redirectUrl(suggestion);
                    if (query) {
                        this.inputSearchRef.current.input.blur()
                    } else {
                        this.inputSearchRef.current.input.focus();
                    }
                }
            }
        }
        if(this.props.onAfterSubmit)
                this.props.onAfterSubmit();
        
    }


    getAlertBoxMarginTop() {
        const openedMenu = document.getElementsByClassName('imc-searchform--bar--dropdown open');
        return (this.isOpenedMenu() && (!this.showCompactView() || !this.isMobile()) && openedMenu && openedMenu.length === 1)
            ? openedMenu[0].clientHeight + 20 : 8;
    };

    getOptions(sortResultsBy) {
        if (Array.isArray(sortResultsBy)) {
            return sortResultsBy.map(el => { return { value: el.fields.name.value, key: el.fields.key.value, } });
        }
        return [];
    }
    renderAlertBox(compactView, extraClass) {
        const { alertboxMarginTop, message, messageType } = this.state;
        const { showMessage } = this.props;
        return (showMessage && message) ?
            <span style={{ marginTop: alertboxMarginTop }} className={extraClass} >
                <AlertBox text={message}
                    extraClass={`${messageType === 'info' ? 'imc-section--padded-top-none imc-section--padded-bottom-none imc-type--color-neutral-heaviest' : ''} ${compactView ? 'compact-view' : ''}`}
                ></AlertBox>
            </span> : null;
    }


    renderOptionsBar() {
        const { showSavedSearches, showMultiSelect, showSort, showShare, hideSearchType, hideFilterBy, hideSortResultBy } = this.props;
        const { closeOtherMenusOpenerId } = this.state;
        const _showSort = showSort && (!hideFilterBy || !hideSearchType || !hideSortResultBy);
        let query="";
        
        if (typeof window !== 'undefined') {
            const queryObj = decode(window.location.search);
            query = queryObj.q;
        }

        return < >
            {
                showSavedSearches &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <SavedSearchesButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        query={query}
                    ></SavedSearchesButton>
                </div>
            }
            {
                showMultiSelect &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <MultiSelectButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                    ></MultiSelectButton>
                </div>
            }
            {
                _showSort &&
                <div className={`imc-searchform--bar--button--wrapper`}>
                    <SortButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        sortOptions={this.getOptions(this.props.fields.sortResultsBy)}
                        searchWithinOptions={this.getOptions(this.props.fields.searchType)}
                        hideSearchType={hideSearchType}
                        hideFilterBy={hideFilterBy}
                        hideSortResultBy={hideSortResultBy}
                    ></SortButton>
                </div>
            }
            {
                showShare &&
                <div className={`imc-searchform--bar--button--wrapper share-wrapper`}>
                    <ShareButton
                        openerId={closeOtherMenusOpenerId}
                        closeOtherMenus={this.closeOtherMenus}
                        query={query}
                        exhibitorId={this.props.exhibitorId}
                    ></ShareButton>
                </div>
            }
        </>;
    }

    /**∫
     * Renders the JSX view
     * @returns {XML} Rendered component
     */
    render() {
        const { isValid } = this.state;
        const { placeholderText, btnText, maxLength, inputId, mobileSearchButton, typeaheadsearch, hideSubmitButton, query, sortType, showMessage, extraClass, exhibitorId, extraFormClass, extraContainerClass } = this.props;           
        const {showClearSearch} = this.props.fields;       
        const inputProps = {
            placeholder: placeholderText,
            value: query,
            onChange: this.onChange,
            className: `imc-searchform--input imc-type--title-1-ui${hideSubmitButton ? ' imc-searchform--input--hidebutton' : ''}`,
            id: inputId,
            name: 'q',
            exhibitorId: exhibitorId,
        };
        if (maxLength) {
            inputProps.maxLength = maxLength;
        }
        const typeaheadsearchoptions = (query) ? (sortType ?
            typeaheadsearch.options.filter(option => option.type !== 'article') : typeaheadsearch.options) : [];
        const isDisabled = !query.length;
        const svgClass = !isDisabled ? 'enabled' : '';
        const compactView = this.showCompactView();
        return (
            <section className={`imc-searchform--section ${extraClass}`} data-xpath="typesearchahead.form">
                <div className={`imc-searchform--row${(typeaheadsearch?.options?.length > 0 && query) ? ` active` : ``}`}>
                    <form onSubmit={this.runSearch} className={`imc-searchform--form ${(extraFormClass) ? extraFormClass : 0}`}>
                        <Autosuggest
                            suggestions={typeaheadsearchoptions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={TypeAheadSearchForm.getSuggestionValue}
                            renderSuggestion={TypeAheadSearchForm.renderSuggestion}
                            renderSuggestionsContainer={this.renderSuggestionsContainer}
                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                            onSuggestionSelected={this.onSuggestionSelected}
                            inputProps={inputProps}
                            focusInputOnSuggestionClick={false}
                            id={inputId}
                            ref={this.inputSearchRef}
                            // containerProps={(extraContainerClass) ? {
                            //     className: ` ${extraContainerClass}`
                            // } : undefined}
                            containerProps={{
                                className: "some-css-class",
                            }}
                        />
                        {(showClearSearch?.value || this.props.forceShowClearSearch) &&
                            <button
                                type="button"
                                onClick={this.onClear}
                                aria-label={getDictionaryValue('clearSearchTitle', 'Clear Search')}
                                className={`imc-searchform--button--clear
                        ${hideSubmitButton ? 'imc-searchform--button--clear--hidesubmit' : ''}`}
                            >
                                <svg
                                    className={svgClass}
                                    width="10"
                                    height="10"
                                    role="img"
                                    aria-labelledby="clear-search-title clear-search-desc"
                                >
                                    <title id="clear-search-title">{getDictionaryValue('clearSearchTitle', 'Clear Search')}</title>
                                    <desc id="clear-search-desc">{getDictionaryValue('clearSearchDescription', 'Clear the Search Form')}</desc>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-close" />
                                </svg>
                            </button>
                        }
                        <label htmlFor={inputId} className="imc-hide">
                            {placeholderText}</label>
                        {!hideSubmitButton &&
                            <div className='imc-searchform--button--search'>
                                <button
                                    type="submit"
                                    value={btnText}
                                    disabled={!isValid}
                                    data-xpath="typesearchahead.submitBtn"
                                >
                                    <svg
                                        className={svgClass}
                                        width="32"
                                        height="32"
                                        role="img"
                                        aria-labelledby="btn-search-title btn-search-desc"
                                    >
                                        <title id="btn-search-title">{getDictionaryValue('searchTitle', 'Clear Search')}</title>
                                        <desc id="btn-search-desc">{getDictionaryValue('searchDescription', 'Clear the Search Form')}</desc>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3" />
                                    </svg>
                                </button>
                            </div>
                        }
                    </form>
                    {this.renderOptionsBar()}
                    {
                        compactView && this.renderAlertBox(compactView, 'imc-searchform--alert-grow imc-break imc-breakpoint-display--hide-desktop')
                    }
                </div>
                {
                    this.renderAlertBox(false, compactView ? 'imc-breakpoint-display--hide-mobile' : '')
                }
            </section>
        );
    }
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    return {
        typeaheadsearch: state.typeaheadsearch,
    };
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators^^^^^^^
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
    };
}

TypeAheadSearchForm.propTypes = propTypes;
TypeAheadSearchForm.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(TypeAheadSearchForm)));

