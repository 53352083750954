import React from 'react';
import { Link, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
export default class ListofLinksComponent extends React.Component {

  render() {
    const { fields, rendering,uid } = this.props;
      const backColor = fields.backgroundColor?fields.backgroundColor.fields.hexColor.value: "#000000";
    const fontColor = fields.fontColor?fields.fontColor.fields.hexColor.value: "#000000";
    const hoverColor = fields.hoverStateColor?fields.hoverStateColor.fields.hexColor.value: "#000000";
    const id = rendering.uid;
    if (!this.props.fields) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    return(
      
       <div class="imc-content-nav-container imc-containerLol">
          <style>{`
          .imc-gallery__item-hover`+id +` a{
            color:`+ fontColor +`;
          }
          .imc-gallery__item-hover`+id +`:hover, .imc-gallery__item-hover`+id +`:hover a {
            background-color:`+ hoverColor +`;
            color:`+ fontColor +`!important;
          }
          `}</style>
            <div class="imc-containerLol" style={{backgroundColor:backColor}}>
              <div class="imc-gallery imc-gallery--1-3 ">
              {fields.list && fields.list.map((link, index) =>(	
                          <div class={`imc-gallery__item imc-gallery__item-hover`+ id} >
                                <Link
                                field={link.fields.link}
                                className="imc-link imc-alert-notification--box-width imc-content--lol" />
                          </div>
                
                ))}
            </div>
          </div>
        </div>
    )
  }
}