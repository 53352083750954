// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// Local dependencies
import NavigationTier1 from './NavigationTier1.jsx';

/**
 * Component for displaying a navigation module and maintaining its state
 */
class Navigation extends Component {

    /**
     * @method constructor
     * @description Constructs the Navigation component and sets the current selected tier to -1 (none)
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            currentTier1: -1,
        };
        this.setTier1 = this.setTier1.bind(this);
        this.nextTier1 = this.nextTier1.bind(this);
        this.previousTier1 = this.previousTier1.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    /**
     * Renders the mobile navigation upon mounting
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('scroll', this.handleScroll);

    }

    /**
     * Remove event Listeners on window
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setTier1(-1);
        }
    }

    /**
     * Sets the currently highlighted tier 1 navigation item
     * @param {number} item Index number to highlight
     */
    setTier1(item) {
        const currentItem = item === this.state.currentTier1 ? -1 : item;
        this.setState({
            currentTier1: currentItem,
        });
    }

    /**
     * Collapses the expanded view of menu onclick on anywhere else on document
     * @param {event} event click triggered outside the menu
     */
    handleClickOutside(event) {
        if (this.tier1 && !this.tier1.contains(event.target)) {
            this.setTier1(-1);
        }
    }

    handleScroll(event) {
        this.setTier1(-1);
    }

    /**
     * Sets the next tier 1 item
     */
    nextTier1() {
        const next = (this.state.currentTier1 < this.props.items.length - 1) ? this.state.currentTier1 + 1 : -1;
        this.setState({
            currentTier1: next,
        });
        if (next === -1) {
            document.querySelector('#input-search-header').focus();
        }
    }

    /**
     * Sets the previous tier 1 item
     */
    previousTier1() {
        const next = this.state.currentTier1 - 1;
        this.setState({
            currentTier1: next,
        });
    }

    /**
     * @method render
     * @description Renders the ModelCard DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { items } = this.props;
        return (
            <React.Fragment>
                {items &&
                    <div className="imc-section">
                        <div
                            className="imc-navigation imc-breakpoint-display--hide-mobile"
                            data-xpath="navigation.container"
                            ref={(node) => { this.tier1 = node; }}
                        >
                            {items.map((item, index) =>
                            (<NavigationTier1
                                key={index}
                                currentIndex={index}
                                label={item.title.data.value}
                                url='/'
                                items={item.navigationColumn}
                                fullWidth={true}
                                target='_self'
                                currentTier1={this.state.currentTier1}
                                nextTier1={() => this.nextTier1()}
                                previousTier1={() => this.previousTier1()}
                                setTier1={this.setTier1}
                            />),
                            )}
                        </div>
                    </div>

                }
            </React.Fragment>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
Navigation.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired, // Navigation configuration
};


export default withRouter(Navigation);