import { ShowroomInterface } from '../reducers/interface';

export function compareShowrooms(showroom: any, item: any, lineGuid?: string) {
    if (lineGuid) {
        if (showroom.exhibitorId != item.exhibitorId) return false;
        if (showroom?.lineGuid) {
            return showroom.lineGuid == lineGuid;
        } else if (showroom?.lines) {
            return showroom.lines.some((line: any) => line.lineGuid == lineGuid);
        } else {
            return false;
        }
    } else {
        if (showroom?.lineGuid) {
            return false;
        } else {
            return showroom.exhibitorId == item.exhibitorId
        }
    }
}

export function addShowroomToList(item: any, list: any[] = [], lineGuid?: string) {
    let newItem = {
        ...item,
        lines: item.lines ? item.lines.map((line: any) => ({ ...line })) : [],
    };
    let newList = list.map((showroom: any) => ({
        ...showroom,
        lines: showroom.lines ? showroom.lines.map((line: any) => ({ ...line })) : [],
    }));
    let newIndex = -1;
    if (lineGuid) {
        let onList = false;
        list.forEach((obj: any, index: number) => {
            if (compareShowrooms(obj, item)) {
                onList = true;
                newIndex = index;
            }
        });
        if (onList) {
            newList[newIndex].lines = [
                ...newList[newIndex].lines,
                {
                    ...newItem.lines[0],
                    lineId: (newItem.lineId)? newItem.lineId : newItem.lineGuid,
                }
            ];
            if (newItem?.marketPlanItemId) {
                newList[newIndex].marketPlanItemId = newItem.marketPlanItemId;
            }
        } else {
            newList.push(newItem);
        }
    } else if (!list.some(obj => compareShowrooms(obj, item, lineGuid))) {
        if (lineGuid) {
            newItem.lineGuid = lineGuid;
        }
        newList.push(newItem);
    }

    return newList;
}

export function removeShowroomFromList(item: any, list: any[] = [], lineGuid?: string) {
    if (lineGuid) {
        return [...list].map(obj => {
            if (compareShowrooms(obj, item, lineGuid)) {
                let newLines = obj.lines.filter((line: any) => line.lineGuid != lineGuid);
                return {
                    ...obj,
                    lines: newLines
                };
            } else {
                return obj;
            }
        });
    } else {
        return [...list].filter(obj => !compareShowrooms(obj, item));
    }
}

export function randomResolveOrReject<T>(resolveValue: T, rejectValue: T, time: number): Promise<T> {
    return new Promise((resolve, reject) => {
        const randomNumber = Math.random();
        const delay = time; // Delay in milliseconds
        setTimeout(() => {
            if (randomNumber < 0.75) {
                resolve(resolveValue);
            } else {
                reject(rejectValue);
            }
            resolve(resolveValue);
        }, delay);
    });
}

export function summarizeActivity(showrooms: any[]) {
    return {
        showroomsCount: countShowrooms(showrooms),
        showroomVisitsCount: countPlanVisits(showrooms),
        photosCount: countPlanPhotos(showrooms),
    };
}

export function countShowrooms(showrooms: any[]) {
    return (showrooms?.length > 0) ? showrooms?.length : 0;
}

export function countPlanVisits(showrooms: any[]) {
    return (showrooms?.length > 0) ? showrooms.filter((showroom: any) => showroom.lastVisited).length : 0;
}

export function countPlanPhotos(showrooms: any[]) {
    return (showrooms?.length > 0) ? showrooms.reduce((s: number, showroom: any) => s + showroom.photosCount, 0) : 0;
}

export function clearParsed(parsed: any) {
    delete parsed.showroomName;
    delete parsed.shownBy;
    delete parsed.exhibitorId;
    delete parsed.lineGuid;
    delete parsed.action;
    return parsed;
}