import React, { Component } from 'react'
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';
import qs from 'query-string';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router';
import { getDictionaryValue } from 'utils/dictionary';
import UserUtility from '../../utils/userutility';
class ViewCatalog extends Component {
    /**
    * @method constructor
    * @param {object} props Incoming props
    */
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            exhibitor: {}
            // errors: [],
            // updatedEmailValue: '',
            // showError: false,
        };
    }


    /**
     * Will get the list data when mounting
     */
    componentDidMount() {
        // const exhibitorId = qs.parse(window.location.search).catalogId === undefined ? '1' : qs.parse(window.location.search).catalogId;
        // fetch events
        // fetch(`${getSitecoreApiHost()}imc-api/v1/search/catalog/catalogId=${'exhibitorId'}&sc_apikey=${ getSitecoreApiKey() }`)
        const qsParsed = qs.parse(this.props.location.search);
        const catID = qsParsed.catalogId;

        fetch(`${getSitecoreApiHost()}/imc-api/v2/catalogs/details?catalogIds=${catID}&searchPage=${this.props.sitecoreContext.route.itemId}&sc_apikey=${getSitecoreApiKey()}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        }
        )
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.count > 0)
                    this.setState({
                        catalog: data.data[0],
                        isLoading: false
                    })
            }).catch(err => console.error(err.toString()))
    }

    render() {
        const { catalog } = this.state;
        const qsParsed = qs.parse(this.props.location.search);
        if (!!catalog) {
            return (
                <div className="imc-pdfview">
                    <div className="imc-section">
                        <div className="imc-pdfview--contact-exhibitor" >
                            <div className="imc-pdfview--display">
                                <div className="exhibitor-info-wrapper">
                                    <h1>{catalog.name}</h1>
                                </div>
                                <a className="imc-button imc-button--download imc-button--primary-inverted" href={catalog.documentLink} download={catalog.documentLink} >
                                    {getDictionaryValue("downloadPdf", "Download PDF")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="imc-section imc-section--padded-none">
                        <iframe src={catalog.documentLink} height="800px" width="100%"></iframe>
                    </div>
                </div>
            )
        }
        else
            return null;
    }
}

export default withSitecoreContext()(
    withRouter(ViewCatalog)
);