import React, { useState, useEffect } from "react";
import { Placeholder, Image, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import ErrorMessage from 'modules/errormessage';
import Link from '../Link';
import { use } from "i18next";
import { getTopMatter } from "utils/scrollhelpers";

interface TopHeaderComponentProps {
  fields?: any;
  rendering?: any;
}

const TopHeaderComponent: React.FC<TopHeaderComponentProps> = ({fields, rendering}) => {

  const [isHeaderStickied, headerStickied] = useState<boolean>(false);
  const [isNavStickied, navStickied] = useState<boolean>(false);
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {

    const handleScroll = () => {
      const {campusHeight, headerHeight, navHeight, currentScrollTop, minimumScrollUp, minimumScrollDown, stickyHeaderMinScroll, stickyNavMinScroll } = getTopMatter();
      const headerPosition = document.getElementsByClassName('imc-header__container-wrapper ')[0].getBoundingClientRect().top;
      const velocity = lastScrollTop - currentScrollTop;
      let scrollingUp = (velocity > minimumScrollUp) || (isScrollingUp && velocity >= minimumScrollDown * -1);

      if (!stickyHeaderMinScroll) {
        scrollingUp = false;
      }

      if (headerPosition == 0) {
        headerStickied(true);
      } else {
        headerStickied(false);
        scrollingUp = false;
      }

      if (stickyNavMinScroll || scrollingUp) {
        navStickied(true)
      } else {
        navStickied(false);
      }

      setIsScrollingUp(scrollingUp);
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [lastScrollTop]);

  if (typeof fields === 'undefined') {
    if (isExperienceEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  if(!(fields.isMobileApp!=null && fields.isMobileApp.value)){
    return (
      <div className={`imc-header__container-wrapper ${(isHeaderStickied) ? ' header-sticky' : ''} ${(isNavStickied) ? ' nav-sticky' : ''}`}>
        <div className="imc-header__container imc-section imc-breakpoint-display--hide-mobile">
          <div className="imc-header__logo">
            { fields.overrideLogoLink && fields.overrideLogoLink.value.href!="" &&
            <Link field={fields.overrideLogoLink} href={!!fields.overrideLogoLink && fields.overrideLogoLink.value && fields.overrideLogoLink.value.url!=""? fields.overrideLogoLink.value.url: "/" } className="imc-content--block" data-xpath="header.logo" title={fields.logo.value.alt}>
              {fields.logo.value.svgCode !== undefined &&
                <div dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
              }
              {fields.logo.value.svgCode === undefined &&
                <Image media={fields.logo.value} />
              }
            </Link>
            }
            { !!!fields.overrideLogoLink || fields.overrideLogoLink.value.href=="" &&
            <Link href="/" className="imc-content--block" data-xpath="header.logo" title={fields.logo.value.alt}>
              {fields.logo.value.svgCode !== undefined &&
                <div dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
              }
              {fields.logo.value.svgCode === undefined &&
                <Image media={fields.logo.value} />
              }
            </Link>
            }
          </div>
          <div className="imc-header__utilities  imc-breakpoint-display--hide-mobile">
            <div className="imc-header-subnav">
              <div className="imc-header-subnav__item header-search imc-breakpoint-display--hide-mobile">
                <div className="header-search-wrap">
                  <Placeholder name="imc-header-placeholder" rendering={rendering} />
                </div>
              </div>
              {fields.exhibitorDirectoryLinks.map((item: any, index: number) => {
                const extraClass = index === 0 ? 'imc-breakpoint-display--hide-mobile' : '';
                return (
                  <div className={`imc-header-subnav__item ${extraClass}`} key={index}>
                    <Link field={item.fields.link}
                      className="imc-header-subnav__link imc-header-subnav__link--account">
                      <Image field={item.fields.icon.value} />
                      {item.fields.icon.value.svgCode !== undefined &&
                        <div dangerouslySetInnerHTML={{ __html: item.fields.icon.value.svgCode }} />
                      }
                      {item.fields.icon.value.svgCode === undefined &&
                        <Image className="imc-image--responsive" media={item.fields.icon} />
                      }
                      <span className="imc-breakpoint-display--hide-mobile imc-header-subnav__link-text max-two-lines" title={item.fields.plannerDesktop.value || item.fields.link.value.text} >{item.fields.plannerDesktop.value || item.fields.link.value.text}</span>
                      <span className="imc-breakpoint-display--hide-desktop imc-header-subnav__link-text imc-breakpoint-display--hide-mobile max-two-lines" title={item.fields.plannerMobile.value}>{item.fields.plannerMobile.value}</span>
                    </Link>
                  </div>
                )
              })}
              <Placeholder name="imc-mp-header-link" rendering={rendering} />
            </div>
          </div>
          <ErrorMessage></ErrorMessage>
        </div>
      </div>
    );
  }else{
    return (
      <div className="imc-header__container imc-header__mobileAppPadding imc-header__borderBottom imc-header__sticky imc-header__whiteBackground">
        <div className="imc-header__logo imc-header__mobileAppHeader">
          <a href="/app/start-page" className="imc-content--block" data-xpath="header.logo" title={fields.logo.value.alt}>
            {fields.logo.value.svgCode !== undefined &&
              <div dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
            }
            {fields.logo.value.svgCode === undefined &&
              <Image  media={fields.logo.value} />
            }
          </a>
        </div>
        <Placeholder name="imc-mp-header-link" rendering={rendering} isMobileApp={fields.isMobileApp}></Placeholder>
      </div>
    );
  }  
};

export default TopHeaderComponent;
