import React from "react";
import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";
import TextTruncate from 'react-text-truncate';
import Moment from 'react-moment';
import moment from 'moment';
// reducers
import digitalShowroomEventsOverviewReducer from "./reducers";
import digitalShowroomInfoReducer from "../Digital-Showroom-Info/reducers/digitalShowroomInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";

//utils
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import { getShopzioUrl } from 'utils/exhibitor';
//modules
import Link from '../Link';
import UserUtility from "../../utils/userutility";
import ImcDataLayer from 'utils/datalayer';
import { rearg } from "lodash";
import {isMarketCtaShouldBeShown} from '../../utils/general';

// swiper
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import renderCarouselItem from '../Featured-Events/EventSlide';
import { Swiper } from 'swiper/react';

const digitalShowroomState = createAppState();
const LINE_PATH_IDENTIFIER = '/line/';
const noImg = process.env.PUBLIC_URL + '/assets/images/175-175.png';

//export const props = require('./props.json');

class DigitalShowroomEventsOverview extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomEventsOverviewReducer,
      digitalShowroomInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
  }

  /**
   * Kick off bound fetch data
  */
  componentDidMount() {
    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
        this.fetchData(this.props.sitecoreContext.exhibitor.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props;
    if (sitecoreContext.exhibitor && prevProps.sitecoreContext.exhibitor && sitecoreContext.exhibitor.id !== prevProps.sitecoreContext.exhibitor.id) {
        this.fetchData(this.props.sitecoreContext.exhibitor.id);
    }
  }

  /**
    * Returns an product carousel item
    * @param {Object} product product object froms earch
    * @returns {XML} A carousel item
  */

    renderCarousel = () => {
        let breakpoints = {
            '500': {slidesPerView: 2, spaceBetween: 25},
            '800': {slidesPerView: 3, spaceBetween: 25},
            '1024': {slidesPerView: 4, spaceBetween: 25},
        };
        const events = [];
        [...this.props.events].forEach(e => {
          events.push(this.translate(e));
        });
        let fields = {
            moreInfoLabel: {
                value: 'More Info',
            }
        };
        console.log("EVENTS", events);
        return (
            <div className="event-features-carousel-component event-card--multi-card-container">

                <div className="events-featured-swiper-container">
                    <Swiper
                        spaceBetween={25}
                        slidesPerView={1}
                        breakpoints={breakpoints}
                        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    >
                        {events.map(event => renderCarouselItem(event, fields))}
                    </Swiper>

                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>
        );
    };

  translate(e) {

    let locations = [];
    locations.push(e.location);

    return Object.assign({
      "id": e.id,
      "title": e.name,
      "smallImage": e?.media?.length > 0 ? e.media[0].url : noImg, //"https://www.lasvegasmarket.com/binaries/content/gallery/LasVegasMarket/seminars--events/w20/bod-logo_black_no_background_sm.png/bod-logo_black_no_background_sm.png/imc%3AcarouselThumbnail",
      "mainImage": e?.media?.length > 0 ? e.media[0].url : noImg,//"https://www.lasvegasmarket.com/binaries/Mobile/content/gallery/LasVegasMarket/background/hero/bod_conference_image---1200x421.png",
      "teaserText": e.description,
      "eventDate": e.startDate,
      "eventEndDate": e.endDate,
      "formattedDate": moment(e.startDate).format("dddd, MMMM D, YYYY"),
      "formattedEndDate": moment(e.endDate).format("dddd, MMMM D, YYYY"),
      "time": {
        "startTime": moment(e.startDate).format("h:mm A"),
        "endTime": moment(e.endDate).format("h:mm A"),
      },
      "destinationUrl": `/plan/events/event-page?id=${e.id}`,
      "location": locations,
      "eventType": e.type,
      "tag": e.tag,
      "featured": false,
      "isAllDay": false
    });

    /*
      {"id":"f8025553-13f0-4951-8f5b-e6e9a8544a98",
      "exhibitorId":"66047",
      "type":"Type 1",
      "name":"test event ",
      "description":"test desc",
      "channelId":"2",
      "marketId":"72",
      "location":"describe your eventtt ",
      "startDate":"2020-08-23T23:00:00Z",
      "endDate":"2020-09-04T23:00:00Z","
      admittance":["everyone"],
      "media":null,
      "isPublished":true,
      "partitionKey":"66047"}

      {
         [{
            "id": "274ce1a0-321b-4873-aa99-afbc7b6418b7",
            "title": "Business of Design? Conference",
            "smallImage": "https://www.lasvegasmarket.com/binaries/content/gallery/LasVegasMarket/seminars--events/w20/bod-logo_black_no_background_sm.png/bod-logo_black_no_background_sm.png/imc%3AcarouselThumbnail",
            "mainImage": "https://www.lasvegasmarket.com/binaries/Mobile/content/gallery/LasVegasMarket/background/hero/bod_conference_image---1200x421.png",
            "teaserText": "Business of Design? Conference is for the highly motivated design professional?interior designer, decorator, stager, architect or stylist?who wants a thriving, profitable, satisfying career!",
            "eventDate": "Saturday, January 25, 2020",
            "time": {
                "startTime": "08:30 AM",
                "endTime": "04:30 PM",
                "duration": 480
            },
            "destinationUrl": "/plan/events/business-of-design%E2%84%A2-conference?id=274ce1a0-321b-4873-aa99-afbc7b6418b7",
            "location": [{
                "showroomBuilding": "Building C",
                "showroomFloor": 13,
                "showroom": "Building C-13 Seminar Room, C1312",
                "showroomBuildingFloor": "Building C.13"
            }],
            "eventType": "Educational",
            "tag": ["Las Vegas Market", "First Time to Market", "Featured", "Decorative Accessories", "Furniture, Bedroom", "Furniture, Accent", "Gift", "Retail Services", "Wall D?cor", "Business News", "CEU", "Designer", "Industry Expert", "International", "Market Highlight", "Trade News", "Trends", "Winter Market 2020"],
            "features": true
        }

      */

  }



  /**
 * Fetch the article data
 * @param { string } exhibitorId  used to getInfo
 */
  fetchData(exhibitorId, lineId) {
    const pageId = this.props.sitecoreContext.route.itemId;
    if (this.locationIsLine()) {
      //  this.props.dataActions.getDigitalShowroomLineEventsOverview(lineId, pageId);
    }
    else {
      this.props.dataActions.getDigitalShowroomEventsOverview(exhibitorId, pageId);
    }
    this.props.dataActions.getExhibitorDetail(exhibitorId, pageId);
  }

  locationIsLine() {
    const { location } = this.props;
    return (location && location.pathname && location.pathname.includes(LINE_PATH_IDENTIFIER)) ? true : false;
  }

  exhibitorIsLine(exhibitor) {
    return (exhibitor && exhibitor.lineName) ? true : false;
  }
  getTitle(exhibitor) {
    return this.exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName;
  }

  render() {
    const { events, eventsCount, shopZioURL, location } = this.props;

    return (<>
      {this.props.eventsCount > 0 &&
        <div className="imc-exhibitors--alternated-section imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
          <div className="imc-section imc-section--width-100-percent">
            <div className="imc-content--display-flex-wrap">
              <span className="imc-heading--h5">{getDictionaryValue('events', 'Events')}</span>
              <span className="imc-type--body-2-ui imc-padding--left--small">
                {eventsCount > 0 ? getDictionaryValue('countEventsShown', `${eventsCount} Events Shown`, { count: eventsCount })
                  : getDictionaryValue('noEventsShown', 'No Events Shown')}
              </span>
            </div>
            <div className="imc-content--display-flex-wrap imc-content--padded-top-bottom">
              {this.renderCarousel()}
            </div>
            <div className="imc-content--display-flex--right imc-content--full-width-mobile imc-products-overview--cta-bar imc-content--display-flex imc-content--display-flex-center">
              {shopZioURL && isMarketCtaShouldBeShown(this.props) &&
                <div className="imc-products-overview--cta-bar__item imc-padding--right--medium--mobile ">
                  <Link
                    onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', shopZioURL)}
                    href={getShopzioUrl(shopZioURL)} target="_blank" rel="noopener noreferrer" className="imc-button imc-button--atmarket imc-button--full-bleed-mobile">{renderDesktopMobileVariations('shopZio', 'Connect on @Market')}</Link>
                </div>
              }
              <div className="imc-products-overview--cta-bar__item imc-padding--left--xlarge--desktop imc-padding--left--medium--mobile">
                {eventsCount > 0 ?

                  <Link href={`${location.pathname}/events`} className="imc-content--inline-block imc-button imc-button--primary-inverted imc-button--full-bleed-mobile">{renderDesktopMobileVariations('seeAllEvents', `See${(eventsCount > 1) ? ' All' : ''} ${eventsCount} Event${(eventsCount > 1) ? 's': ''}`, { count: eventsCount })}</Link>
                  :
                  <button className="imc-button imc-button--muted-inverted imc-button--full-bleed-mobile" disabled>
                    {`0 ${getDictionaryValue('events', 'Events')}`}
                  </button>
                }
              </div>
            </div>

          </div>
        </div>
      }</>);
  }
}
const defaultProps = {
  dataActions: {
    getDigitalShowroomContactInfo: () => { },
  },
  getInfoActions: {
    getDigitalShowroomEventsOverview: () => { },
  },
  events: [],
  eventsCount: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  getInfoActions: PropTypes.object,
  events: PropTypes.array,
  eventsCount: PropTypes.number,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  let _props = {};
  if (state.digitalShowroomEventsOverviewReducer)
    Object.assign(_props, {
      events: state.digitalShowroomEventsOverviewReducer.events,
      eventsCount: state.digitalShowroomEventsOverviewReducer.eventsCount,
      showLoading: state.digitalShowroomEventsOverviewReducer.showLoading,
    });
  if (state.digitalShowroomInfoReducer) {
    Object.assign(_props, {
      shopZioURL: state.digitalShowroomInfoReducer.shopZioURL,
    });
  }
  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomEventsOverview.propTypes = propTypes;
DigitalShowroomEventsOverview.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomEventsOverview))
);
