import React, { useEffect } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import componentFactory from './temp/componentFactory';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import { Provider } from 'react-redux';
import { LastLocationProvider } from 'react-router-last-location';

// new market planner reducer
import marketPlanReducer from 'modules/marketplan/reducers/reducers';

import { createAppState } from 'modules/redux-app-state';
// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// const rootReducer = combineReducers({
//     appState: createAppState(),
//     planState: planStore,
// });

// const mergedStore = createStore(rootReducer, applyMiddleware(thunk));

const appState = createAppState();

// add market plan reducer to app state
appState.reducerRegistry.register({ marketPlanReducer });

const AppRoot = ({ path, Router, graphQLClient }) => {

    const routeRenderFunction = (props) => <RouteHandler route={props} />;

    return (
        <Provider store={appState.store}>
            <ApolloProvider client={graphQLClient}>
                <SitecoreContext componentFactory={componentFactory} contextFactory={SitecoreContextFactory}>
                <Router location={path} context={{}}>
                <LastLocationProvider>
                    <Switch>
                    {routePatterns.map((routePattern) => (
                        <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
                    ))}
                    </Switch>
                    </LastLocationProvider>
                </Router>
                </SitecoreContext>
            </ApolloProvider>
        </Provider>
    );

};

export default AppRoot;
