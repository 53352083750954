import React from 'react';
import RelatedContentAPI from 'api/search/relatedcontent';
import { Image, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

class RelatedContent extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		if (!props) {
	        if (isExperienceEditorActive()) {
	            return <h1 className="alarm">Datasource isn't set.</h1>;
	        }
	        return (
	            <h1 className="alarm">
	                Data is not provided. Contact administrators, please.
	          </h1>
	        );
	    }
		this.state = { data :[]};
		
		this._relatedContent = new RelatedContentAPI();
		this.getData();
	}

	getData() {
		var tags = [];
		for (var i = 0; i < this.props.fields.tags.length; i++) {
			tags.push(this.props.fields.tags[i].fields.name.value);
		}
		this._relatedContent.getRelatedContent(tags)
			.then(response => {
				const { Articles } = response || {};
				this.setState({ data: response })
			})
			
			.catch(err => console.error(this.props.url, err.toString()))
	}

	render() {
		for (var i = 0; i < this.state.data.length; i++) {
			var img = {};
			img.value = { src: this.state.data[i].ImageSrc.replace("/sitecore/shell",""), alt: this.state.data[i].ImageAlt };
			this.state.data[i].img = img;
		}
		return(
			<section className="imc-section imc-section--text-align-left imc-section--alt-gray-light optly-recos-extension">
				<div>
					<h2 className="imc-heading imc-heading--h2 imc-heading--h2-desktop imc-vr--xlarge-desktop" >Related Content</h2>
					{this.state.data.map((item, index) => (
						<section key={index} className="imc-gallery imc-gallery--align-flex-start-desktop imc-gallery--33-66 imc-gallery--padding-1-2 imc-blogteaser">
							<div className="imc-gallery__item">
								<a href="{item.URL}" className="reco-link imc-blogteaser__link imc-link imc-link--alt imc-content--medium imc-content--inline-block imc-vr--small">
									<Image className="imc-content-breaker" field={img} />
								</a>
							</div>
							<div className="imc-content--left-desktop imc-gallery__item imc-blogteaser__details">
								<a href="{item.URL}" className="reco-link imc-blogteaser__link imc-link imc-link--alt imc-content--medium imc-content--inline-block imc-vr--small">
									<h3 className="imc-heading imc-heading--h2 imc-heading--secondary imc-heading--alt2 imc-vr--large">
										{item.Title}
									</h3>
								</a>
								<br/>
								<a href={item.URL} className="imc-content reco-link imc-blogteaser__link imc-link imc-link--alt imc-content--medium imc-content--inline-block imc-vr--small" title="Read More">
									Read More<span className="imc-link--caret-after"></span>
								</a>
							</div>
						</section>
					))}
				</div>
			</section>
		);
	}
}

export default RelatedContent;
