import React from 'react';
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//Utils
import { getDictionaryValue } from 'utils/dictionary'
import UserUtility from "utils/userutility";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
//modules
import ImcDataLayer from 'utils/datalayer';

ReactModal.setAppElement('#root');

class ProductDetailHero extends React.Component {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.renderImageGallery = this.renderImageGallery.bind(this);
        this.renderGalleryThumbs = this.renderGalleryThumbs.bind(this);
        this.renderModalGallery = this.renderModalGallery.bind(this);
        this.openGalleryModal = this.openGalleryModal.bind(this);
        this.closeGalleryModal = this.closeGalleryModal.bind(this);
        this.renderCarousel = this.renderCarousel.bind(this);
        this.galleryBackClick = this.galleryBackClick.bind(this);
        this.setGalleryImageSize = this.setGalleryImageSize.bind(this);

        this.state = {
            showModalGallery: false,
            selectedThumbIndex: 0,
        }
        this.galleryImageRefs = [];
        this.titleRef = null;
    }
    componentDidMount() {
        window.addEventListener('resize', this.setGalleryImageSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setGalleryImageSize);
    }



    productIsLine(product) {
        //return (!!product.lineId) ? true : false;
        return !!this.props.lineId; //comes from the url.
    }
    openGalleryModal(index) {
        const {sitecoreContext } = this.props;
        const route = sitecoreContext.route;
        const pageType = !!window?window.pageType: this.props.location.pathname;
        ImcDataLayer.pushInteractionEvent("page interaction", "image expand", pageType);

        this.setState({
            selectedThumbIndex: index,
            showModalGallery: true,
        })
        setTimeout(() => {
            this.setGalleryImageSize();
        }, 5);
    }
    closeGalleryModal() {
        this.setState({
            showModalGallery: false,
        })
    }
    galleryBackClick(event) {
        if (!(event.target.classList.contains('control-arrow') || event.target.classList.contains('thumb')))
            this.closeGalleryModal();
    }
    setGalleryImageSize() {
        const wrapperHeight = `calc( 100vh - ${158 + (this.titleRef?this.titleRef.clientHeight:0)}px)`;
        this.galleryImageRefs.forEach(ref => {
            ref.style.height = wrapperHeight;
        })
    }
    renderModalGallery() {
        const { productDetail } = this.props;
        const { selectedThumbIndex, showModalGallery } = this.state;
        return (
            <ReactModal
                isOpen={showModalGallery}
                overlayClassName="imc-modal--overlay"
                className={`imc-modal--gallery`}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.closeGalleryModal}
                shouldFocusAfterRender={false}
            >
                <div className="imc-modal--gallery--section" onClick={this.galleryBackClick}>
                    <h2 className="imc-heading--inverted imc-content--center imc-margin--bottom--large imc-product-details--gallery--title">{productDetail.productTitle}</h2>
                    {this.renderCarousel()}
                </div>
            </ReactModal>
        )
    }

    renderCarousel() {
        const { productDetail } = this.props;
        const { selectedThumbIndex } = this.state;
        return (
            <Carousel
                showArrows={true}
                showThumbs={true}
                showIndicators={false}
                showStatus={false}
                className="imc-product-details-carousel"
                selectedItem={selectedThumbIndex}
                thumbWidth={72}
                renderThumbs={(children) => {
                    {
                        return productDetail.images.map((image, index) =>
                            <div className="imc-product-details-carousel--thumbimage-wrapper" key={index}
                                style={{ backgroundImage: `url('${image.fullPath}')` }}
                            >
                            </div>)
                    }
                }}

            >
                {productDetail.images.map((image, index) =>
                    <div className="imc-product-details-carousel--image-wrapper" key={index}
                        id={`imc-product-details-carousel--image_${index}`}
                        ref={(im) => { if (im !== null && !this.galleryImageRefs.some(i => (i.id === im.id))) this.galleryImageRefs.push(im); }}
                        style={{ backgroundImage: `url('${image.fullPath}')`, height:`calc( 100vh - ${158 + (!!this.titleRef?this.titleRef.clientHeight:0)}px)` }}


                    >
                    </div>)}
            </Carousel>
        );
    }


    renderImage(image, withBorder = false) {
        return <div className={withBorder ? 'imc-product-details--gallery--image-border' : ''}>
            <div
                className='imc-product-details--gallery--image'
                style={{
                    backgroundImage: `url('${encodeURI(image.fullPath).replace("?width=","?width=642&height=642")}')`,
                }}
            /></div>
    }
    renderGalleryThumbs() {
        const { productDetail } = this.props;
        return <div className="imc-product-details--gallery--thumbs">
            {productDetail.images.slice(1, 10).map((image, index) =>
                <div className='imc-product-details--gallery--thumbs__item'
                    key={index}
                    onClick={() => { this.openGalleryModal(index + 1) }}>
                    {this.renderImage(image, true)}
                </div>
            )}
        </div>
    }
    renderImageGallery() {
        const { productDetail } = this.props;
        if (productDetail && 'images' in productDetail && productDetail.images.length > 0) {
            return <div className="imc-product-details--gallery">
                <div className="imc-product-details--gallery__item">
                    <div className="imc-product-details--gallery--image-main"
                        onClick={() => { this.openGalleryModal(0) }}
                    >
                        {productDetail.newProductForMarket === true &&
                            <div className="imc-product-details--new">{getDictionaryValue('new', 'NEW')}</div>}
                        <div className="imc-product-details--gallery--image-fullscreen"></div>
                        {this.renderImage(productDetail.images[0], true)}
                    </div>
                </div>
                <div className="imc-product-details--gallery__item">
                    {this.renderGalleryThumbs()}
                </div>
                {this.renderModalGallery()}
            </div>
        }
        return null;
    }

    render() {
        const { productDetail } = this.props;
        return <div className="imc-product-details--section">
            <div className="imc-section imc-section--width-100-percent">
                <h2 className="imc-padding--left--smallmedium imc-padding--bottom--small">{productDetail.productTitle}</h2>
                {this.renderImageGallery()}
            </div>
        </div>
    }
}

export default withSitecoreContext()(ProductDetailHero);