/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_EXHIBITOR_DETAIL = 'SET_EXHIBITOR_DETAIL';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_EXHIBITOR_DETAIL_ERR = 'SET_EXHIBITOR_DETAIL_ERR';

/**
 * Action to set Info on success
 * @type {string}
 */
export const SET_LINE_DETAIL = 'SET_LINE_DETAIL';

/**
 * Action to set error on Info fetch failure
 * @type {string}
 */
export const SET_LINE_DETAIL_ERR = 'SET_LINE_DETAIL_ERR';


/**
 * Show Loading
 * @type {string}
 */
export const SHOW_LOADING = 'SHOW_LOADING';


/**
 * Updating IDs from sitecore context
 * @type {string}
 */
export const UPDATE_FROM_CONTEXT = 'UPDATE_FROM_CONTEXT';

export const CLEAR_STATE = 'UPDATE_FROM_CONTEXT';