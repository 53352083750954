import React from "react";
import {
  isExperienceEditorActive,
  withSitecoreContext, Text
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";

// reducers
import digitalShowroomContactInfoReducer from "./reducers/digitalShowroomContactInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";

const digitalShowroomState = createAppState();


class DigitalShowroomContactInformation extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomContactInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
      this.renderSocialIcons = this.renderSocialIcons.bind(this);
      const { sitecoreContext } = this.props;
      if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
          this.exhibitorId = sitecoreContext.exhibitor.id;
      }
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
      const { sitecoreContext } = this.props;
      if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
          this.exhibitorId = sitecoreContext.exhibitor.id;
      }
      if (this.exhibitorId !== null)
          this.fetchData(this.exhibitorId);
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
  fetchData(exhibitorId) {
    this.props.dataActions.getDigitalShowroomContactInfo(exhibitorId);
  }

  renderSocialIcons(socialIcons) {
    const { fields } = this.props;
    return socialIcons ? socialIcons.map(function (social, index) {
      const icon = fields.icons.find((icon) => icon.fields.type.value === social.type);
      if (icon && icon.fields.icon.value.svgCode)
        return <div className="imc-exhibitor-contact-info__social" key={index}>
          <a href={social.link} target="_blank"
            dangerouslySetInnerHTML={{ __html: icon.fields.icon.value.svgCode }}
          >
          </a>
        </div>
      else
        return <></>;
    }) : <></>;
  }

  render() {
    const { fields } = this.props.rendering;
    const { exhibitor } = this.props;
    if (typeof fields === 'undefined') {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    return (
      <section className="imc-section imc-section--padded-xlarge-xxlarge imc-section--full-width-mobile imc-exhibitor-contact-info">
        <div className="imc-exhibitor-contact-info__content imc-content--center-mobile">
          <div className="imc-vr--jumbo-desktop imc-exhibitor-contact-info__title imc-vr--jumbo">
            <h3 className="imc-heading--tertiary imc-heading--beta imc-heading--alt3" >
              {exhibitor.title}&nbsp;<Text field={fields.contactInformation}></Text>
            </h3>
            <div className="imc-hr imc-hr--center-small-mobile imc-heading--alt3"></div>
          </div>

          <div className="imc-exhibitor-contact-info__information">
            <div className="imc-exhibitor-contact-info__followus imc-vr--jumbo-mobile">
              <h4 className="imc-heading--secondary imc-heading--kilo imc-heading--alt2 imc-vr--small imc-exhibitor-contact-info__labels" >
                <Text field={fields.followUs}></Text>
              </h4>
              <div className="imc-optin-social">
                {this.renderSocialIcons(exhibitor.socialIcons)}
              </div>
            </div>
            <div className="imc-exhibitor-contact-info__mailaddress imc-vr--jumbo-mobile">
              <h4 className="imc-heading--secondary imc-heading--kilo imc-heading--alt2 imc-vr--small imc-exhibitor-contact-info__labels" >
                <Text field={fields.mailingAddress}></Text>
              </h4>
              <p className="imc-content--beta imc-content--secondary  imc-content--alt2" >
                {exhibitor.mailingAddress}
              </p>
            </div>
            <div className="imc-exhibitor-contact-info__phone imc-vr--jumbo-mobile">
              <h4 className="imc-heading--secondary imc-heading--kilo imc-heading--alt2 imc-vr--small imc-exhibitor-contact-info__labels" >
                <Text field={fields.phone}></Text>
              </h4>
              <p className="imc-content--beta imc-content--secondary  imc-content--alt2" >
                {exhibitor.phone}
              </p>
            </div>
            <div className="imc-exhibitor-contact-info__directory">
              <h4 className="imc-heading--secondary imc-heading--kilo imc-heading--alt2 imc-vr--small imc-exhibitor-contact-info__labels" >
                <Text field={fields.directoryContact}></Text>
              </h4>
              <p className="imc-content--beta imc-content--secondary imc-content--alt2" >
                {exhibitor.directoryContact}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

}


const defaultProps = {
  dataActions: {
    getDigitalShowroomContactInfo: () => { },
  },
  exhibitor: {
    title: '',
    socialIcons: [],
    mailingAddress: '',
    phone: '',
    directoryContact: ''
  }
};
const propTypes = {
  dataActions: PropTypes.object
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  if (state.digitalShowroomContactInfoReducer)
    return {
      exhibitor: {
        title: state.digitalShowroomContactInfoReducer.title,
        socialIcons: state.digitalShowroomContactInfoReducer.socialIcons,
        mailingAddress: state.digitalShowroomContactInfoReducer.mailingAddress,
        phone: state.digitalShowroomContactInfoReducer.phone,
        directoryContact: state.digitalShowroomContactInfoReducer.directoryContact,
      }
    };
  else {
    return {
      exhibitor: {
        title: state.title,
        socialIcons: state.socialIcons,
        mailingAddress: state.mailingAddress,
        phone: state.phone,
        directoryContact: state.directoryContact,
      }
    };
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch)
  };
}

DigitalShowroomContactInformation.propTypes = propTypes;
DigitalShowroomContactInformation.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomContactInformation))
);

