import React from 'react';
import { Placeholder, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class GridComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!window.doBuild) {
      this.preloadWidgetScript();
    } else {
      window.doBuild();
    }
  }

  preloadWidgetScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.dataset.pinBuild = 'doBuild';
    script.src = '//assets.pinterest.com/js/pinit.js';
    document.body.appendChild(script);
  }

  render(){
    if (!this.props.fields) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    var columnCount = 2;
    if(this.props.fields.columnCount){
      columnCount = this.props.fields.columnCount.value;
    };
    var mainDivClass = "";
    var columnDivClass = "";
    if(columnCount == 4){
      mainDivClass += " imc-section--padded-inner-1col ";
      columnDivClass = "imc-gallery imc-landingpage__video-results imc-gallery--1-4 imc-gallery--1-4--mobile-full-width ";
    }else if(columnCount == 3){
      columnDivClass = "imc-gallery imc-landingpage__video-results imc-gallery--1-3 ";
    }else if(columnCount == 2){
      columnDivClass = "imc-gallery imc-gallery--1-2 ";
      }
      if (this.props.fields.extraClass) {
          columnDivClass += `  ${this.props.fields.extraClass.fields.className.value}`;
      }
    return(
      
        <div className={`${mainDivClass}  ${columnDivClass}`}>
            <Placeholder currentTab={this.props.currentTab ? this.props.currentTab : ''} tabData={this.props.tabData ? this.props.tabData : ''} name="imc-grid-placeholder" rendering={this.props.rendering} />
        </div>
    );
  }
}

const _withSitecoreContext = withSitecoreContext()(
  GridComponent
);

export default _withSitecoreContext;
