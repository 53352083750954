// Library dependencies
import React, { Component } from 'react';
import { isExperienceEditorActive, Image } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import UserAgent from 'utils/useragent';


/**
 * Component for displaying a expand/collapse module and maintaining its state
 * @example
 * <div data-expand-collapse>
 * <div data-expand-collapse-heading>This is the Heading. It can be HTML.</div>
 * <div data-expand-collapse-content>This is the collapsible content. It can be HTML.</div>
 * </div>
 */
export default class StandaloneVideoComponent extends Component {

    /**
     * @method constructor
     * @description
     * By default sets the expanded state to true
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        if (!props) {
            if (isExperienceEditorActive()) {
                return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
                <h1 className="alarm">
                    Data is not provided. Contact administrators, please.
                </h1>
            );
        }
        this.state = {
            thumbClicked: this.props.thumbClicked,
        };

        this.nv = {
            getBoundingClientRect: () => ({
                height: 0,
                width: 0,
            }),
        };

        // set aliases
        this.playVideo = this.playVideo.bind(this);
        this.updateVideo = this.updateVideo.bind(this);
        this.simpleThumbnailImage = '';

    }

    /**
     * Updates the video state from the prop
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        if (prevProps.thumbClicked !== this.props.thumbClicked) {
            this.updateVideo();
        }
    }

    /**
     * Updates the video state based on the updated prop
     */
    updateVideo() {
        this.setState({
            thumbClicked: this.props.thumbClicked,
        });
    }


    /**
     * @method playVideo
     * @description Toggles if the video thumb clicked
     * @param {object} e Event object
     */
    playVideo(e) {
        const code = e ? e.keyCode || e.which : undefined;

        if (this.props.videoShouldNotPlay) {
            return
        }

        if (code === undefined || code === 13 || code === 32) {
            this.setState({
                thumbClicked: !this.state.thumbClicked,
            });
        }
        if (UserAgent.isIE()) {
            setTimeout(() => {
                const iframeRef = document.querySelector('.imc-videostandalone-wrapper__iframe');
                iframeRef.src = iframeRef.src;
            }, 1500);
        }
    }

    /**
     * @method renderThumb
     * @description
     * Renders the heading container element
     * @returns {Element} Rendered element heading
     */
    renderThumb() {
        let thumbnailImage = "";
        //   let simpleThumbnailImage="";

        if (this.props.fields.placeholderImage) {

            thumbnailImage = this.props.fields.placeholderImage

            // thumbnailImage.src = thumbnailImage.src.value.replace('/-/media','/-/jssmedia');
            thumbnailImage.width = null;
            thumbnailImage.height = null;
        } else if (this.props.fields.simplePlaceholderImage.value) {

            this.simpleThumbnailImage = this.props.fields.simplePlaceholderImage.value.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell", "");
            // this.simpleThumbnailImage = this.simpleThumbnailImage
        }
        const { className } = this.props;
        return (
            <a
                tabIndex={0}
                role="switch"
                className={`imc-videostandalone-wrapper__image-wrapper ${className ? `${className}__image-wrapper` : ''}`}
                onClick={this.playVideo}
                onKeyDown={this.playVideo}
                aria-checked={this.state.thumbClicked}
            >
                {thumbnailImage !== "" && < Image className="imc-videostandalone-wrapper__image" field={thumbnailImage} />}
                {this.simpleThumbnailImage !== "" && <img className="imc-imageteaser__image" src={this.simpleThumbnailImage}></img>}
                {/* {this.simpleThumbnailImage !== "" && < Image className="imc-videostandalone-wrapper__image" field={this.simpleThumbnailImage}/>} */}
                <svg width="80" height="80" className="imc-videostandalone-wrapper__image-play-icon-standalone">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#video-play" />
                </svg>
            </a>
        );
    }

    /**
     * @method renderContent
     * @description
     * Renders the heading container element
     * @returns {Element} Rendered content element
     */
    renderVideo() {
        const { className } = this.props;
        return React.createElement('iframe',
            {
                className: `imc-videostandalone-wrapper__iframe ${className ? `${className}__iframe` : ''}`,
                src: this.props.fields.videoLink.value || this.props.fields.videoLink.href,
                'data-xpath': 'richcontent.videoIframe',
                frameBorder: 0,
                allowFullScreen: true,
                autoPlay: true,
                style: {

                    width: '100%',
                },
            });
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { className } = this.props;
        if (!this.props.fields.isDetail) {
            return (

                <div
                    ref={(elem) => { this.nv = elem; }}
                    className={`imc-videostandalone-wrapper ${className ? `${className}__wrapper` : ''}`}
                >

                    {this.state.thumbClicked ? this.renderVideo() : this.renderThumb()}

                </div>

            );
        } else {
            return (

                <div
                    ref={(elem) => { this.nv = elem; }}
                    className={`imc-videostandalone-wrapper ${className ? `${className}__wrapper` : ''}`}
                >

                    {this.renderVideo()}

                </div>

            );
        }
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{videoUrl: *, thumbUrl: *, imageAltText: *, thumbClicked: shim}}
 */
// StandaloneVideoComponent.propTypes = {
//     videoUrl: PropTypes.string.isRequired, // Heading string that is clickable
//     thumbUrl: PropTypes.string.isRequired, // Content that expands or collapses
//     thumbAlt: PropTypes.string.isRequired, // alt text to be shown for image
//     thumbClicked: PropTypes.bool, // determines if expanded by default
//     className: PropTypes.string,
// };

/**
 * @property defaultProps
 * @type {{thumbClicked: boolean}}
 */
StandaloneVideoComponent.defaultProps = {
    thumbClicked: false,
    className: '',
    isDetail: false,
    videoShouldNotPlay: false,
};


