import DownloadResultsAPI from '../../../api/search/download';
import SaveSearchAPI from '../../../api/search/saveSearch';
import * as types from './actionTypes';
/**
 * Action to download search results
 * @param {string} filterKey Filter key indicating which filters to use
 * @returns {Function} Dispatcher used to download search results
 */
export function downloadSearch(filterKey) {
    const _downloadSearch = new DownloadResultsAPI();
    return _downloadSearch.downloadResults(filterKey);
};


/**
 * Action to open save search
 * @returns {Function} Dispatcher used to download search results
 */
export function openSaveSearchModal() {
    return (dispatch) => { dispatch({ type: types.OPEN_SAVE_SEARCH, saveSearchModalOpen: true }); };
}

/**
 * Action to close save search
 * @returns {Function} Dispatcher used to download search results
 */
export function closeSaveSearchModal() {
    return (dispatch) => { dispatch({ type: types.CLOSE_SAVE_SEARCH, saveSearchModalOpen: false }); };
}

/**
 * Action to save search results
 * @param {string} searchName Query to generate download
 * @param {string} invokedLocation location from where saveSearch is called
 * @returns {Function} Dispatcher used to download search results
 */
export function saveSearch(searchName, invokedLocation) {
    const _saveSearch = new SaveSearchAPI();
    return (dispatch) => {
        return _saveSearch
            .saveSearch(searchName, invokedLocation)
            .then(() => {
                dispatch({ type: types.CLOSE_SAVE_SEARCH, saveSearchModalOpen: false });
            });
    };
}
