import { InitialState } from './initialState';
import { GET_MARKETPLANNER_SAVED_SEARCH_SUCCESS } from '../actions/actionTypes';

const initialState = {
    ...InitialState.exhibitorData.savedSearches,
};

/**
 * Reducer for exhibitor information
 * @param {object} state State of the exhibitor information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function searchReducer(state = initialState, action) {
    switch (action.type) {
    case GET_MARKETPLANNER_SAVED_SEARCH_SUCCESS:
        return {
            ...state,
            savedSearches: action.savedSearches,
        };
    default:
        return state;
    }
}
