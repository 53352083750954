// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Local dependencies
import NavigationMobileTier1 from './NavigationMobileTier1.jsx';
import NavigationMobileTier2 from './NavigationMobileTier2.jsx';

/**
 * Component for displaying a navigation mobile item (tier 1) and maintaining its state
 */
export default class NavigationMobileMenu extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the tier 1 navigation item
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            currentTier1: -1,
            currentTier2: -1,
            translateX: '0px',
            eventType: 'click',
            menuTopPosition: 0,
            randomKey: Math.round(Math.random() * 999999),
            windowWidth: 548,
            scrollHeight: 1000,
            navContainer: 800,
            isLoggedIn: props.isLoggedIn
        };
        this.unsetTiers = this.unsetTiers.bind(this);
        this.setTier1 = this.setTier1.bind(this);
        this.setTier2 = this.setTier2.bind(this);
        this.setTranslateX = this.setTranslateX.bind(this);
        this.updateWindowSize = this.updateWindowSize.bind(this);
        this.setTopMenuPosition = this.setTopMenuPosition.bind(this);
        this.forceTopMenuPosition = this.forceTopMenuPosition.bind(this);
        this.updateTranslateX = this.updateTranslateX.bind(this);
    }

    /**
     * Adds an event listener to set the container sizes
     */
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize);
        setTimeout(() => {
            this.forceTopMenuPosition();
            this.setTopMenuPosition();
        }, 500);

    }

    /**
     * Unsets the tiers when the menu is closed
     * @param {object} prevProps Previous props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        // Unsets the tiers if the menu is closed
        if (this.props.showMenu !== prevProps.showMenu) {
            if (!this.props.showMenu) {
                this.unsetTiers();
            }
            else {
                this.updateWindowSize();
            }
        }
        if (this.state.currentTier1 !== prevState.currentTier1 || this.state.currentTier2 !== prevState.currentTier2) {
            this.updateTranslateX();
        }

    }
    updateTranslateX() {
        // Sets the translateX to animate the menu

        if (this.state.currentTier2 !== -1) {
            this.setTranslateX(2);
        } else if (this.state.currentTier1 !== -1) {
            this.setTranslateX(1);
        } else {
            this.setTranslateX(0);
        }

    }

    /**
     * Get the menu offset top
     */
    setTopMenuPosition() {
        const menuHeader = document.querySelector('#imc-navigation-mobile-menu');
        const headerElement = document.querySelector('.imc-header__nav-mobile');

        const menuOffset = (headerElement) ? headerElement.getBoundingClientRect().top : 0;
        const headerOffset = (headerElement) ? headerElement.getBoundingClientRect().height : 0;
        this.setState({
            menuTopPosition: menuOffset + headerOffset,
            windowWidth: document.documentElement.clientWidth,
            scrollHeight: document.body.scrollHeight,
            navContainer: window.innerHeight,
        });
    }


    /**
     * Sets the current tier1 item
     * @param {number} idx Tier 1 index number
     * @param {string=} eventType Event Type
     */
    setTier1(idx, eventType) {
        this.setState({
            currentTier1: idx,
            randomKey: Math.round(Math.random() * 999999),
            eventType: eventType || this.state.eventType,
        });

        if (idx === -1 && this.state.currentTier2 === -1) {
            setTimeout(() => {
                this.props.resetTrapFocus();
            }, 500);
        }
    }

    /**
     * Sets the current tier2 item
     * @param {number} idx Tier 2 index number
     * @param {string=} eventType Event Type
     */
    setTier2(idx, eventType) {
        this.setState({
            currentTier2: idx,
            randomKey: Math.round(Math.random() * 999999),
            eventType: eventType || this.state.eventType,
        });
    }

    /**
     * Sets the translateX for the mobile menu container
     * @param {number} idx Setting for the translateX offset (0|1|2)
     */
    setTranslateX(idx) {
        const translateX = (idx === 0) ? 0 : (this.state.windowWidth * idx) * -1;
        this.setState({
            translateX: `${parseInt(translateX, 10)}px`,
        });
    }

    /**
     * Get the menu offset top
     */
    forceTopMenuPosition() {
        // const menuHeader = document.querySelector('#imc-navigation-mobile-menu');
        // const headerElement = document.querySelector('.imc-header__nav-mobile');
        // const menuOffset = (menuHeader) ? menuHeader.offsetTop : 0;
        // const headerOffset = (headerElement) ? headerElement.offsetTop : 0;
        // this.state.menuTopPosition = menuOffset + headerOffset;
    }

    /**
     * Updates the window size on resize
     */
    updateWindowSize() {
        this.setState({
            windowWidth: document.documentElement.clientWidth,
            scrollHeight: document.body.scrollHeight,
            navContainer: window.innerHeight,
        });
        this.updateTranslateX();
    }

    /**
     * Unsets the stored tiers
     */
    unsetTiers() {
        this.setState({
            currentTier1: -1,
            currentTier2: -1,
            randomKey: Math.round(Math.random() * 999999),
        });
    }


    /**
     * @method render
     * @description Renders the Mobile Navigation DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { showMenu, items, menuLabel, closeMenu } = this.props;
        const {
            currentTier1,
            currentTier2,
            windowWidth,
            navContainer,
            menuTopPosition,
            randomKey,
            translateX,
            scrollHeight,
            eventType,
            isLoggedIn
        } = this.state;
        let active, menuTitleElement;
        if (typeof document !== 'undefined') {
            this.forceTopMenuPosition();
            active = showMenu ? 'imc-navigation-mobile__menu--active imc-navigation-mobile--overlay' : '';
            menuTitleElement = document.querySelector('.imc-navigation-mobile__Title');
        }
        const menuTitleHeight = menuTitleElement ? menuTitleElement.clientHeight : 0;
        const navigationItems = this.props.items.filter((item) => item.type.name === "Navigation 1st Level Mobile");
        return (
            <div
                className={`imc-navigation-mobile__menu ${active}`}
                id="imc-navigation-mobile-menu"
                data-xpath="navigation.mobile.menu"
                ref={(node) => { this.navContainer = node; }}

            >
                <button className="imc-navigation-mobile__closeContainer" onClick={() => { closeMenu() }}>
                    <svg width="36" height="36" className="imc-navigation-mobile__icon">
                        <title>Close Menu</title>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" className={`svg-white`} xlinkHref="#juniper-close"></use>
                    </svg>
                </button>
                <div
                    className="imc-navigation-mobile__menuContainer"
                    style={{
                        width: windowWidth,
                    }}
                >
                    <div className="imc-navigation-mobile__menuContainerInner">

                        <div
                            key={randomKey}
                            className="imc-navigation-mobile__menuContainerShift"
                            style={{
                                width: windowWidth * 3,
                                transform: `translate3d(${translateX},0,0)`,
                            }}
                        >
                            <div
                                className="imc-navigation-mobile__tierContainer imc-navigation-mobile__tierContainer--tier1"
                                style={{
                                    flexBasis: windowWidth,
                                }}
                                data-xpath="navigation.mobile.tier1"
                            >
                                <div className='imc-navigation-mobile__Title'>
                                    {menuLabel}

                                </div>
                                <div className="imc-navigation-mobile__areaScroll"
                                    style={{
                                        height: 'auto',
                                        maxHeight: navContainer - menuTopPosition - menuTitleHeight,
                                    }}
                                >
                                    <NavigationMobileTier1
                                        items={items}
                                        setTier1={this.setTier1}
                                        currentTier1={currentTier1}
                                        currentTier2={currentTier2}
                                        windowWidth={windowWidth}
                                        scrollHeight={scrollHeight}
                                        translateX={translateX}
                                        eventType={eventType}
                                        menuLabel={menuLabel}
                                        isLoggedIn={isLoggedIn}
                                        closeMenu={this.props.closeMenu}
                                    />

                                </div>
                            </div>
                            <NavigationMobileTier2
                                currentTier1={currentTier1}
                                currentTier2={currentTier2}
                                translateX={translateX}
                                backLabel={currentTier1 !== -1 ? navigationItems[currentTier1].title.value : ''}
                                setTier1={this.setTier1}
                                setTier2={this.setTier2}
                                items={currentTier1 !== -1 ? navigationItems[currentTier1].navigation2st : []}
                                windowWidth={windowWidth}
                                scrollHeight={navContainer - menuTopPosition - menuTitleHeight}
                                eventType={eventType}
                                closeMenu={this.props.closeMenu}
                            />

                            {/* 
                            <NavigationMobileTier3
                                currentTier1={currentTier1}
                                currentTier2={currentTier2}
                                setTier2={this.setTier2}
                                tier2Url={currentTier2 !== -1 ? items[currentTier1].items[currentTier2].url : ''}
                                tier3Content={tier3Content}
                                windowWidth={windowWidth}
                                scrollHeight={scrollHeight}
                                translateX={translateX}
                                eventType={eventType}
                                overviewLabel={tier3OverviewLabel}
                                backLabel={backLabel}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationMobileMenu.propTypes = {
    showMenu: PropTypes.bool.isRequired, // Active or inactive flag
    items: PropTypes.array, // Array of items
    overviewLabel: PropTypes.string, // Label for overview items
    resetTrapFocus: PropTypes.func, // Function to reset the trap focus
    closeMenu: PropTypes.func, // Active or inactive flag
};

/**
 * @property defaultProps
 * @type {{overviewLabel: string}}
 */
NavigationMobileMenu.defaultProps = {
    overviewLabel: 'Overview',
    resetTrapFocus: () => { },
    closeMenu: () => { },
};
