import React from 'react';

class SavedEventsComponent extends React.Component {

  render() {
    return <></>;
  }
}


export default SavedEventsComponent;