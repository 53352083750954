import React from 'react';
// import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import {  withRouter } from 'react-router';
// import {Loading} from 'modules/loading';
// // reducers
// import listsReducer from 'modules/lists/reducers/listsReducer';
// import * as dataActions from 'modules/lists/actions/dataActions';
// import { createAppState } from 'modules/redux-app-state';
// import {getDictionaryValue} from 'utils/dictionary';
// import Link from '../Link';

// const YourListsState = createAppState();
// const SLICE_SIZE=4;

class YourListsCardComponent extends React.Component {
    render() {
        return <></>;
    }
}
//   constructor(props) {
//     super(props);
    
//     YourListsState.reducerRegistry.register({ listsReducer });

//     this.renderItems = this.renderItems.bind(this);
//     this.fetchData = this.fetchData.bind(this);
//     this.renderCreateMore = this.renderCreateMore.bind(this);
//     this._getDictionaryvalue = this._getDictionaryvalue.bind(this);
//     this.isFavoritesList = this.isFavoritesList.bind(this);
//     this.getEditLink = this.getEditLink.bind(this);
    
//   }

//   /**
//    * Kick off bound fetch data
//    */
//   componentDidMount() {
//     if (!isExperienceEditorActive()) {
//       this.fetchData();
//     }
//   }
  

//   /**
//  * Updates state with new props
//  * @param {object} nextProps Incoming props
//  */
//   componentWillReceiveProps(nextProps) {
//   }

//   fetchData() {
//     this.props.dataActions.fetchLists();
//   }

//   getEditLink(listId){
//     return this.props.fields.editList.value.href + "/" + listId.toString();
//   }

//   renderItems() {
//     let lists = this.props.data;

//     if (!!!lists || !Array.isArray(lists))
//       return (<></>);
    
//       let listsSlice = lists.slice(0,SLICE_SIZE);

//     return listsSlice.map((item, index) => {
//       let favClass = this.isFavoritesList(item.listName)?"imc-content--bold no-border":null;
//       return(
//         <li key={item.listId} className={favClass+" imc-type--title-3-ui imc-type--color-primary-medium"}>{item.listName}
//         <span>
//           <Link className="imc-type--title-5-ui-link imc-type--color-primary-medium" href={this.getEditLink(item.listId)}>Edit List</Link>
//         </span></li>
//       );
//     })
//   }

//   renderCreateMore() {
//     let lists = this.props.data;

//     if (typeof lists == 'undefined' || typeof lists[0] == 'undefined')
//       return null;
    
//       if (lists.listCount > 1)
//       return null;
    
//       if (!this.isFavoritesList(lists[0].listName))
//       return null;

//     return (
//       <React.Fragment>
//         <p>{this._getDictionaryvalue("createMoreMessage", "Create custom lists to get the most out of your market experience")}</p>
//         <div className="imc-content--right imc-content--padded-mediumsmall">
//           <Link field={this.props.fields.newList.value} className="imc-button--invert-green-small imc-button--round imc-button--add" />
//         </div>
//       </React.Fragment>
//         );
    
    
//   }

//   isFavoritesList(listName){
//     return listName==this._getDictionaryvalue("favorites", "Favorites");
//   }

//   _getDictionaryvalue(key, defaultValue){
//     return getDictionaryValue(key, defaultValue);
//   }

//   render() {
//     return(
//       <div className="imc-card imc-loading-relative">
//         <div className="imc-card-section imc-card-title">
//           <div className="imc-type--title-3">{
//           this._getDictionaryvalue("yourListsTitle", "Your Lists")
//           }</div>
//         </div>
//         <div className="imc-card-body">
//         {!!isExperienceEditorActive() && <div>Data source not set in Experience Editor. </div>}
//         {!isExperienceEditorActive() && <React.Fragment>
//             {          
//             (!!this.props && !!this.props.data) &&
//               <ul className="card-list">
//                 {this.renderItems()}
//               </ul>
//             }
//             {
//               (this.props.showLoading===true) &&
//                 <Loading showLoading={true} showPageLoading={false}></Loading>
            
//             }
//             {this.renderCreateMore()}
//           </React.Fragment>}
//         </div>
//         <div className="imc-card-section imc-card-footer imc-card-centered">
//           <h6>
//           <Link field={this.props.fields.viewAllLists.value}
//                 className="imc-type--title-3-ui-link imc-type--bold" />
//             {/* <a href="#" onClick={this.onViewAllClick}>{this.getDictionaryvalue("viewAll", "View All")}</a> */}
//             </h6>
//         </div>
//       </div>
//       );
//   }
// }

// function mapStateToProps(state) {
  
//   if (!!state.listsReducer)
//     return {
//       data: state.listsReducer.data,
//       showLoading: state.listsReducer.showLoading,
//     }
//   else {
//     return {
//       data: state.data,
//       showLoading: state.showLoading,
//     };
//   }
// }

//  /**
//   * Maps dispatch to props for connect
//   * @param {function} dispatch Dispatcher
//   * @returns {object} Action creators
//   */
//  function mapDispatchToProps(dispatch) {
//   return {
//     dataActions: bindActionCreators({ ...dataActions }, dispatch),
//   };
// }

// export default withSitecoreContext()(withRouter(connect(mapStateToProps, mapDispatchToProps)(YourListsCardComponent)));
export default YourListsCardComponent;