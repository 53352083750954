import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router';
import './meridianMap.component.scss';
import * as mapHelper from './actions/mapHelper';
import * as mapActions from './actions/mapActions';
import { getDictionaryValue } from 'utils/dictionary';
import ExhibitorModal from './components/exhibitorModal';
import scriptLoader from 'react-async-script-loader';
import mapReducer from './reducers/mapreducer';
import { createAppState } from 'modules/redux-app-state';

const DEFAULT_MAP_HEIGHT = "500px";

const MeridianMap = (props) => {
    const theState = createAppState();
    const isAvailable = useRef(false);

    const getID = () => "meridian-map-".concat(!!props.ID ? props.ID : "1");

    useEffect(() => {
        theState.reducerRegistry.register({ mapReducer: mapReducer });
        isAvailable.current = typeof window !== "undefined" && window.location.search;
    }, []);



    useEffect(() => {
        const {isScriptLoaded, isScriptLoadSucceed} = props;
        if (isAvailable && isScriptLoaded && isScriptLoadSucceed) {
            initMap();
        }
    }, [isAvailable, props.isScriptLoaded]);


    useEffect(() => {
        if (!!props.floorNo && !!props.floors) {
            const newFloorID = mapHelper.GetFloorIDFromBuilding(props.floors, props.buildingName, props.floorNo);
            mapActions.setFloorID(newFloorID);
            updateMapFloor(newFloorID);
        }
    }, [props.floorNo]);

    const checkForHighlightedIcon = () => {
        const highlightIcon = props.highLightPlacemarkId;
        const locationId = props.locationID;
        const building = props.buildingName;
        const floorNo = props.floorNo;
        let retries = 0;

        const checkMapDomUpdated = (iconId) => {
            //finds the element for the placemarkID
            const el = document.querySelector(`button[data-meridian-placemark-id='${iconId}']`);
            if (!!el) {
                el.classList.add("bounce");
            } else {
                retries++;
                console.log("retry");
                if (retries < 10) {
                    setTimeout(() => {
                        checkMapDomUpdated(iconId);
                    }, 2500);
                }
            }
        }

        if (!!highlightIcon) {
            //set an event to look for the corresponding placemark and highlight it.
            mapHelper.GetPlacemark(locationId, highlightIcon, building, floorNo).then(p => {
                if (!!p) {
                    const placemarkId = p.id.split('_')[1];
                    //waits for the placemarks dom to be generated and then tries to highlight the right  placemark
                    setTimeout(() => {
                        checkMapDomUpdated(placemarkId);
                    }, 2500);
                } else
                    console.warn("MAP - PlacemarkID  not found for the provided UID");
            });
        }
    }

    const checkParameters = () => {
        const locationId= props.locationID;
        const building= props.buildingName;
        const floorNo= props.floorNo;

        return (!!locationId && !!building && !!floorNo);
    };

    const updateMapFloor = (floorId) => {

        if (!props.map)
            console.warn("Map not set, cannot set new floor ID");

        let map = props.map;
        map.update({
            floorID: floorId
        });
    }

    const closePopUp = () =>{
        props.mapActions.closeExhibitorDetails();
    }

    const nextFloorClick = () => {
        if (!!props.floorId) {
            let floors = props.floors;
            let idx = floors.findIndex(f => f.id === props.floorId);
            let currentFloor = floors[idx];

            if (floors.length > idx + 1) {
                let nextFloor = floors[idx + 1];
                if (nextFloor.group_name === currentFloor.group_name) {
                    let newID = floors[idx + 1].id;
                    props.mapActions.setFloorID(newID);
                }
            }
        }
    }

    const initMap = () => {
        const Id = getID();
        const MeridianSDK = window.MeridianSDK;
        const locationId = props.locationID;
        const building = props.buildingName;
        const floorNo = props.floorNo;
        const pageId = props.sitecoreContext.itemId;
        const channelId = (isAvailable && window.channel != null ? window.channel.code : "lvm");
        console.log("initing map");
        const meridianToken = props.token ? props.token : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0IjoxNTkxNzk0NDE2LCJ2YWx1ZSI6Ijk1MzkxZTg5MjQ4YWEwN2FjMDRlZjA3YWZkOTJhNTkyMTQxN2Y1OGIifQ.1wgR6hO9kpFfk7hqZ3RWn_WmB3QPFWGsIFASMzg3NSg"
        const token = getDictionaryValue("meridian.token." + channelId, meridianToken);

        MeridianSDK.init({
            api: new MeridianSDK.API({
                environment: "production",
                token: token
            })
        });

        const mapElement = document.querySelector('#' + Id);

        mapHelper.GetFloorID(locationId, building, floorNo).then(floorId => {
            if (!floorId) {
                mapElement.innerHTML = `Floor not found (${building} / ${floorNo}) `;
                mapElement.classList.toggle("alert-box")
                return;
            }

            var map = MeridianSDK.createMap(mapElement, {
                locationID: locationId,
                floorID: floorId,
                loadTags: false,
                height: !!props.height ? props.height : DEFAULT_MAP_HEIGHT,
                showFloorsControl: false,
                showTagsControl: false,
                shouldMapPanZoom: MeridianSDK.restrictedPanZoom,
                placemarks: {
                    labelZoomLevel: (props.labelZoomLevel !== "" ? parseFloat(props.labelZoomLevel) : 0.2),
                    showHiddenPlacemarks: true
                },
                onFloorChange: () => nextFloorClick,
                onPlacemarkClick: ((data, options) => {
                    if (!!data.uid && data.type_category === "Generic") {
                        options.preventDefault();
                        props.mapActions.showExhibitorDetails(data.uid, data.custom_4, pageId);
                    }
                }).bind(this),
                onFloorsUpdate: (floors) => {
                    props.mapActions.setMapData(map, floorId, floors);
                }
            });

            window['map'] = map;

            window.addEventListener("resize", function () {
                map.update();
                map.zoomToDefault();

            });
            checkForHighlightedIcon();
        });
    }

    const building = props.buildingName;
    const floorNo = props.floorNo;

    if (!checkParameters()) {
        return (<p>Building / Floor / Location Parameters missing</p>);
    }

    return (
        <div style={{display: 'flex'}}>
            <div className="imc-map-container" style={{minHeight: !!props.height ? props.height : DEFAULT_MAP_HEIGHT}}>
                <div className={!!props.selectedEx ? "imc-meridian-map" : ""} id={getID()}>
                </div>

            </div>

            {
                !!props.showExModal &&
                props.selectedEx &&
                <ExhibitorModal exhibitor={props.selectedEx}
                            building={building}
                            floorNo={floorNo}
                            maxHeight={!!props.height ? props.height : DEFAULT_MAP_HEIGHT}
                            closePopup={closePopUp}
                />
            }
        </div>
    )
}


function mapStateToProps(state) {
    if (!!state.mapReducer)
    {
        return {
            floorId: state.mapReducer.floorId,
            floors: state.mapReducer.floors,
            map: state.mapReducer.map,
            showExModal: state.mapReducer.showExModal,
            selectedEx: state.mapReducer.selectedEx,
        }}
    else {
        return {
            floorId: state.floorId,
            floors: state.floors,
            map: state.map,
            showExModal: state.showExModal,
            selectedEx: state.selectedEx,
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
        mapActions: bindActionCreators({ ...mapActions }, dispatch),
    };
}

const meridianSrc=(process.env.PUBLIC_URL!==undefined?process.env.PUBLIC_URL:'/dist/las-vegas-market') + "/meridian-sdk.js";

export default withSitecoreContext()(withRouter(connect(mapStateToProps, mapDispatchToProps)
    (
        scriptLoader(
            meridianSrc
        )(MeridianMap)
    )
));
