import React from 'react';
// import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import UserUtility from '../../utils/userutility';
// import { getDictionaryValue } from 'utils/dictionary';
// import Moment from 'react-moment';
// import moment from 'moment';
// import Link from './../Link';
// //import QRCode from 'qrcode.react';
// import QRCode from "react-qr-code";

class RegistrationCardComponent extends React.Component {
//   constructor(props) {

//     console.log("RegistrationCardComponent");
//     super(props);
//     if (!props) {
//       if (isExperienceEditorActive()) {
//         return <h1 className="alarm">Datasource isn't set.</h1>;
//       }
//       return (
//         <h1 className="alarm">
//           Data is not provided. Contact administrators, please.
//         </h1>
//       );
//     }
//     this.isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;

//     this.state = {
//       nextRegistration: null,
//       nextMarket: null,
//     };

//     this._getDictionaryvalue = this._getDictionaryvalue.bind(this);
//     this.loadData = this.loadData.bind(this);
//   }

//   _getDictionaryvalue(key, defaultValue) {
//     return getDictionaryValue(key, defaultValue);
//   }

//   componentDidMount() {

//     if (!this.isEditing) {
//       this._registrationsApi = new RegistrationsAPI();
//       this.loadData();
//     } else {
//       this.loadMockData();
//     }
//   }


//   loadData() {
//     //var accoundId = UserUtility.getUserData().accountId;
//     const channel = UserUtility.getChannelData().name;
//     var demandDriverId = UserUtility.getUserData().demandDriverId;

//     if (!!demandDriverId) {
//       this._registrationsApi.getNextRegistration(demandDriverId, channel)
//         .then(response => {
//           if (!!response)
//             this.setState({ nextRegistration: response });
//           else
//             this.setState({ noassociation: true, loading: false });
//         })
//         .catch(err => { // ErrorUtility.logError(err, getDictionaryValue('error.show', 'An error ocurred while performing the products search.'),'ERROR')
//           console.error(this.props.url, err.toString());
//           this.setState({ noassociation: true, loading: false });
//         });

//       this._registrationsApi.getNextMarket(channel)
//         .then(response => {
//           this.setState({ nextMarket: response });
//         })
//         .catch(err => console.error(this.props.url, err.toString()));


//     }
//     else {
//       this.setState({ noassociation: true, loading: false });
//       this._registrationsApi.getNextMarket(channel)
//         .then(response => {
//           this.setState({ nextMarket: response });
//         })
//         .catch(err => console.error(this.props.url, err.toString()));
//     }

//   }

//   loadMockData() {
//     this.setState({
//       nextRegistration: {
//         registrationNumber: "LVMS0719426519",
//         eventCode :'LCM202006',
//         badgeId: '12345678',
//         registrationUser:'Martin Arias',
//         firstName:'Martin',
//         lastName:'Arias',
//         market: {
//           marketId: 13,
//           marketName: "Las Vegas Winter 2020 Market"
//         }
//       },
//       nextMarket: {
//         imageUrl: "/-/media/Project/IMC/shared/Mock-Next-Market.jpg",
//         marketId: 12,
//         marketName: "Las Vegas Winter 2021 Market",
//         startDate: "January 28, 2021",
//         endDate: "January 30, 2021",
//         registrationLink: "https://www.xpressreg.net/inquirypage/start.php?sc=LVMS0720&_ga=2.13081463.1910751920.1585923566-624699141.1578669610"
//       }
//     });
//   }

//   gimmeEmpty(value)
//   {
//     return !!value?value:"";
//   }

//   renderRegisterCardComponent() {
//     let registrationNumer = "";
    
//     let marketSeason = "";
//     let marketName = "";
//     let qrCode = "";
//     let registerCard = null;

//     if (!!this.state.nextRegistration) {
//       registrationNumer = this.state.nextRegistration.badgeId;
//       qrCode = `${this.gimmeEmpty(this.state.nextRegistration.badgeId)}$${ this.gimmeEmpty(this.state.nextRegistration.eventCode)}$${this.gimmeEmpty(this.state.nextRegistration.firstName)}$${this.gimmeEmpty(this.state.nextRegistration.lastName)}$${this.gimmeEmpty(this.state.nextRegistration.companyName)}`;
//       marketSeason = this.state.nextRegistration.market.marketName;
//       registerCard = (
//         <div className="imc-card imc-register-card">
//           <div className="imc-card-section">
//             <div className="imc-type--title-3 card-message">{this._getDictionaryvalue("youAreRegistered", "You're Registered For")}</div>
//             <div className="imc-type--title-3 card-market">{marketSeason}</div>
//           </div>
//           <div className="imc-card-section">
//             <div className="imc-type--title-1-ui registration-number-label">{this._getDictionaryvalue("registrationNumber", "Registration number")}</div>
//             <div className="imc-type--title-3-ui imc-type--bold imc-type--color-primary-medium registration-number">{registrationNumer}</div>
//           </div>
//           <div className="imc-card-body">

//           </div>
//           <div className="imc-card-section imc-card-footer imc-card-centered">
//             <Link className="imc-type--title-3-ui-link imc-type--bold registration-info" field={this.props.fields.registrationInfo}></Link>
//           </div>
//           <div className="imc-card-centered imc-type--title-3-ui imc-type--color-primary-medium scan-go">{this._getDictionaryvalue("scanAndGo", "Scan&Go")}</div>
//          <div class="imc-card-centered"> <QRCode value={qrCode} width="150" size="150"></QRCode> </div>
//         </div>
//       );     
//     }
//     else {
//       const { nextMarket } = this.state;
//       let startDate;
//       let endDate;
//       if (!!nextMarket) {
//         startDate = nextMarket.startDate.replace("Z", "");
//         endDate = nextMarket.endDate.replace("Z", "");
//       }

//       registerCard = (!!nextMarket && <div className="imc-card  imc-register-card">
//         {!!nextMarket.imageUrl && <div className="imc-register-card-section imc-img-card-header" style={{ backgroundImage: nextMarket.imageUrl.replace('/-/media', '/-/jssmedia').replace("/sitecore/shell", "") }}>
//         </div>}
//         <div className="imc-card-body">
//           <div className="imc-heading imc-heading--beta imc-heading--alt2 registration-number-label">{this._getDictionaryvalue("nextMarket", "Don't miss the next market")}</div>
//           <p>&nbsp;</p>
//           <div className="card-market imc-heading imc-heading--alpha imc-heading--headingh2">{nextMarket.marketName}</div>
//           <div className="card-message imc-heading imc-heading--beta imc-heading--headingh2">
//             {/* same month range */}
//             {(moment(startDate).month() == moment(endDate).month()) &&
//               <>
//                 <Moment format="MMMM DD">
//                   {startDate}
//                 </Moment> - &nbsp;
//              <Moment format="DD">
//                   {endDate}
//                 </Moment>, &nbsp;
//              <Moment format="YYYY">
//                   {endDate}
//                 </Moment>
//               </>}
//             {/* different month range */}
//             {(moment(startDate).month() != moment(endDate).month()) &&
//               <>
//                 <Moment format="MMMM DD">
//                   {startDate}
//                 </Moment> - &nbsp;
//              <Moment format="MMMM DD">
//                   {endDate}
//                 </Moment>, &nbsp;
//              <Moment format="YYYY">
//                   {endDate}
//                 </Moment>
//               </>}
//           </div>
//           <p>&nbsp;</p>
//           <div className='imc-register-link'>
//             <Link href={nextMarket.registrationLink} className="imc-button--secondary imc-button--round">{this._getDictionaryvalue("registerNow", "Register Now")}</Link>
//           </div>
//         </div>
//         <div className="imc-card-section imc-card-footer imc-card-centered">
//           <Link className="imc-type--title-3-ui-link imc-type--bold registration-info" field={this.props.fields.registrationInfo}></Link>
//         </div>
//       </div>
//       );
//     }
//     const { nextMarket } = this.state;
//     if (!this.state.nextRegistration && !nextMarket) {
//       registerCard = (<div className="imc-card  imc-register-card">
//         <div className="imc-card-body">
//           <div className="imc-heading imc-heading--beta imc-heading--alt2 registration-number-label"><p>&nbsp;</p></div>
//           <p>&nbsp;</p>
//           <div className="card-market imc-heading imc-heading--alpha imc-heading--headingh2">{this._getDictionaryvalue("noMarketComingUp", "No Market Coming Up ")}</div>
//           <div className="card-message imc-heading imc-heading--beta imc-heading--headingh2">
//             <p>&nbsp;</p>
//           </div>
//           <p>&nbsp;</p>
//         </div>
//         <div className="imc-card-section imc-card-footer imc-card-centered">
//           <p>&nbsp;</p>
//         </div>
//       </div>
//       );
//     }
//     return (registerCard);

//   }

//   renderRegisterCard() {
//     let qrCode = "EMPTY";
   
//      qrCode = `${this.gimmeEmpty(this.props.badgeId)}$${this.gimmeEmpty(this.props.eventCode)}$${this.gimmeEmpty(this.props.firstName)}$${this.gimmeEmpty(this.props.lastName)}$${this.gimmeEmpty(this.props.companyName)}`;
//     return (
//       <div className="imc-register-card">
//         <div className="imc-register-card-section">
//           <div className="card-message">{this._getDictionaryvalue("youAreRegistered", "You're Registered For")} {this.props.marketName}</div>
//           <div className="card-market">{this.props.marketSeason}</div>
//         </div>
//         <div className="imc-register-card-section">
//           <div className="registration-number-label">{this._getDictionaryvalue("registrationNumber", "Registration number")}</div>
//           <div className="registration-number">{this.props.registrationNumber}</div>
//         </div>
//         <div className="imc-register-card-section">
//           <div className="scan-go">{this._getDictionaryvalue("scanAndGo", "Scan&Go")}</div>
//           <QRCode value={qrCode} width="150" size="150"></QRCode> 
//         </div>
//        </div>   
//     );
//   }

//   renderMiniRegisterCard() {
//     let qrCode = "EMPTY";
    
//     qrCode = `${this.gimmeEmpty(this.props.badgeId)}$${ this.gimmeEmpty(this.props.eventCode)}$${this.gimmeEmpty(this.props.firstName)}$${this.gimmeEmpty(this.props.lastName)}$${this.gimmeEmpty(this.props.companyName)}`;
//     return (
//       <div className="imc-register-card-mini">
//         <div className="imc-register-card-section">
//           <div className="imc-register-card-section">
//             <div className="card-message">{this._getDictionaryvalue("youAreRegistered", "You're Registered For")} {this.props.marketName}</div>
//             <div className="card-market">{this.props.marketSeason}</div>
//           </div>
//           <div className="imc-register-card-section">
//             <div className="registration-number-label">{this._getDictionaryvalue("registrationNumber", "Registration number")}</div>
//             <div className="registration-number">{this.props.registrationNumber}</div>
//           </div>
//         </div>
//          <div className="imc-register-card-vertical-section-40">
//             <span className="helper"></span>
//             <QRCode value={qrCode} width="150" size="150"></QRCode> 
//         </div> 
//       </div>
//     );
//   }

  render() {
    return <></>
  }
}

export default RegistrationCardComponent;