import React from 'react';
import { isExperienceEditorActive, Image } from '@sitecore-jss/sitecore-jss-react';
import { fi } from 'date-fns/locale';
import Link from '../Link';



const StandaloneImageComponent = ({ fields }) => {

  if (!fields) {
    if (isExperienceEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    }
    return (
      <h1 className="alarm">
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  const extraClass = (fields.extraClass && fields.extraClass.fields && fields.extraClass.fields.className) ? fields.extraClass.fields.className.value : '';
  if (fields.link.value.href) {
    return (
      <Link href={fields.link.value.href} target={fields.link.value.target}><Image className={`keep-aspect-ratio ${extraClass}`} field={fields.image} /></Link>
    )
  } else {
    return (
      <Image className={`keep-aspect-ratio ${extraClass}`} field={fields.image} />
    )
  }
};

export default StandaloneImageComponent;
