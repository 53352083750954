/* eslint-disable no-underscore-dangle */
/**
 * Module for creating and configuring a redux store
 */

// Library Dependencies
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

let reduxImmutableStateInvariant;
let composeWithDevTools;
/* eslint-disable global-require */
if (process.env.NODE_ENV !== 'production') {
    reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default;
    composeWithDevTools = require('remote-redux-devtools').composeWithDevTools;
}
/* eslint-enable global-require */

/**
 * @description Sets the compose enhancer to enable Redux dev tools if the
 * build is for a dev environment; otherwise returns the default compose
 */
const composeEnhancers = process.env.NODE_ENV !== 'production' ?
composeWithDevTools({ realtime: true }) :
compose;

/**
 * @description Sets middleware based on environment
 * @type {*[]}
 */

const middleware = process.env.NODE_ENV !== 'production' ?
    [reduxImmutableStateInvariant(), thunk] :
    [thunk];

/**
 * @method
 * @param {Function} rootReducer Root reducer of application
 * @param {Object} initialState The initial state to set at root
 * @description Creates a redux app store with initial reducers and registers the
 * store to update its reducers when a new reducer is added to the reducerRegistry
 *
 * @returns {*} instance of a redux store
 */
export default function configureStore(rootReducer, initialState) {
    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
        ),
    );
}
