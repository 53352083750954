export function getTotalPreviousSiblingsHeight(element: Element): number {
    let totalHeight = 0;
    let prevSibling = element.previousSibling;

    while (prevSibling) {
        if (prevSibling.nodeType === 1) {
            totalHeight += (prevSibling as HTMLElement).offsetHeight;
        }
        prevSibling = prevSibling.previousSibling;
    }

    return totalHeight;
}

interface TopMatter {
    campusHeight: number;
    headerHeight: number;
    navHeight: number;
    currentScrollTop: number;
    minimumScrollUp: number;
    minimumScrollDown: number;
    stickyHeaderMinScroll: boolean;
    stickyNavMinScroll: boolean;
    stickyNavTop: number;
}

export function getTopMatter(): TopMatter {
    const header = document.getElementsByTagName('header');
    const campusHeight =(header.length > 0) ? getTotalPreviousSiblingsHeight(header[0]) : 0;
    const headerHeight = (document?.getElementsByClassName('imc-header__container-wrapper')?.length > 0) ? document.getElementsByClassName('imc-header__container-wrapper')[0].getBoundingClientRect().height : 0;
    const navHeight = (document?.getElementsByClassName('imc-navigation-container')?.length > 0) ? document.getElementsByClassName('imc-navigation-container')[0].getBoundingClientRect().height : 0;
    return {
        campusHeight: campusHeight,
        headerHeight: headerHeight,
        navHeight: navHeight,
        currentScrollTop: window.scrollY || document.documentElement.scrollTop,
        minimumScrollUp: 25,
        minimumScrollDown: 10,
        stickyHeaderMinScroll: window.scrollY >= campusHeight,
        stickyNavMinScroll: window.scrollY >= campusHeight + navHeight,
        stickyNavTop: headerHeight - navHeight,
    }
}

export function padContent(scrollBarWidth: number, classNames: string[] = [], scrollY?: number) {
    let scrollPosition = (scrollY) ? scrollY : window.scrollY;
    document.body.classList.add('imc-navigation-mobile--body-open');
    let elements = [
        document.body,
        document.getElementsByClassName('top-nav')[0],
        document.getElementsByClassName('imc-header')[0],
        document.getElementsByClassName('imc-header__container imc-header__nav-mobile')[0],
    ];
    classNames.forEach((className) => {
        document.querySelectorAll(className).forEach((element: any) => {
            elements.push(element);
        });
    });
    elements.forEach((el) => {
        if (el) {
            let element = <HTMLElement> el;
            element.style.removeProperty('padding-right');
            let paddingRight = window.getComputedStyle(element, null).getPropertyValue('padding-right');
            element.style.paddingRight = `calc(${paddingRight} + ${scrollBarWidth}px)`;
            element.style.marginRight = `-${scrollBarWidth}px`;
            element.style.maxWidth = `none`;
        }
    });
    window.scrollTo({top: scrollPosition, behavior: 'instant'});
}

export function unPadContent(classNames: string[] = []) {
    document.body.classList.remove('imc-navigation-mobile--body-open');
    let elements = [
        document.body,
        document.getElementsByClassName('top-nav')[0],
        document.getElementsByClassName('imc-header')[0],
        document.getElementsByClassName('imc-header__container imc-header__nav-mobile')[0],
    ];
    classNames.forEach((className) => {
        document.querySelectorAll(className).forEach((element) => {
            elements.push(element);
        });
    });
    elements.forEach((el) => {
        if (el) {
            let element = <HTMLElement> el;
            element.style.removeProperty('padding-right');
            element.style.removeProperty('margin-right');
            element.style.removeProperty('max-width');
        }
    });
}