import React from 'react';
import { createAppState } from 'modules/redux-app-state';
import { isExperienceEditorActive, withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Link from '../../Link';
import UserUtility from 'utils/userutility';
import { showMarketPlan } from 'utils/general';
import { getBooth } from 'utils/floorplan';
import MarketPlanButton from 'modules/marketplan/components/MarketPlanButton';
const noImg = process.env.PUBLIC_URL + '/assets/images/175-175.png';

class ExhibitorAd extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = {exhibitorDetail :null};
        this.getActionItem = this.getActionItem.bind(this);
    }

    componentDidMount()
    {
        const {exhibitor, exhibitorData} = this.props;
        if(!!exhibitorData){
            const exhibitorDetail = exhibitorData.find(x=>x.directoryContactInfo.organizationId === exhibitor.featureExhibitorsId.data.value);
            this.setState({ exhibitorDetail : exhibitorDetail });
        }
    }

    componentWillReceiveProps(nextProps)
    {
        const {exhibitor, exhibitorData} = nextProps;
        if(!!exhibitorData){
            const exhibitorDetail = exhibitorData.find(x=>x.directoryContactInfo.organizationId === exhibitor.featureExhibitorsId.data.value);
            this.setState({ exhibitorDetail : exhibitorDetail });
        }
    }

    getShowrooms(exhibitorDetail)
    {
        var showRooms =[];
        if (!!exhibitorDetail.companyDetails.activeLeases){
            exhibitorDetail.companyDetails.activeLeases.map((lease)=>{
                if(lease.channel.siteCode===UserUtility.getChannelData().name)
                {
                    lease.showrooms.map((showroom)=>{
                        showRooms.push(getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)); 
                    })
                }  
            })
            return showRooms.join(', ');
        }
        return "";
    }

    getActionItem(exhibitor) {
        return {
            showroomName: exhibitor.companyDetails.companyName,
            exhibitorId: exhibitor.directoryContactInfo.organizationId,
            lineId: null,
            lastVisited: null,
            photosCount: 0,
        }
    }

    render() {
        const { exhibitorDetail } = this.state;
        const { exhibitor } = this.props;

        let imagePath;

        if (exhibitor.featureExhibitorsImage != null && exhibitor.featureExhibitorsImage.data != null && exhibitor.featureExhibitorsImage.data.value != null 
            && exhibitor.featureExhibitorsImage.data.value.src != null) {
            imagePath = exhibitor.featureExhibitorsImage.data.value.src;
        } else {
            imagePath = !!exhibitorDetail && !!exhibitorDetail.logoAndImage ? exhibitorDetail.logoAndImage.heroImageOriginal : noImg;
        }

        let titleString = exhibitorDetail?.companyDetails?.companyName;

        

        if (exhibitor.featureExhibitorsTitle != null && exhibitor.featureExhibitorsTitle.data != null && exhibitor.featureExhibitorsTitle.data.value != null 
            && exhibitor.featureExhibitorsTitle.data.value.trim().length > 0) {
            titleString = exhibitor.featureExhibitorsTitle.data.value;
        }

        let subTitleString = '';

        if(exhibitorDetail!=null){subTitleString = this.getShowrooms(exhibitorDetail)};

        if (exhibitor.featureExhibitorsSubTitle != null && exhibitor.featureExhibitorsSubTitle.data != null 
            && exhibitor.featureExhibitorsSubTitle.data.value != null && exhibitor.featureExhibitorsSubTitle.data.value.trim().length > 0) {
            subTitleString = exhibitor.featureExhibitorsSubTitle.data.value;
        }

        let exhibitorUrl = '';

        if(exhibitorDetail!=null && exhibitorDetail.directoryContactInfo!=null){
            exhibitorUrl = '/exhibitor/' + exhibitorDetail.directoryContactInfo.organizationId;
        }

        let urlTarget = '';
        let showMarketPlanButton = true;

        if (exhibitor.featureExhibitorsLink != null && exhibitor.featureExhibitorsLink.data != null && exhibitor.featureExhibitorsLink.data.value != null
            && exhibitor.featureExhibitorsLink.data.value.linktype != null && exhibitor.featureExhibitorsLink.data.value.linktype != null
            && exhibitor.featureExhibitorsLink.data.value.linktype.trim().length > 0 && exhibitor.featureExhibitorsLink.data.value.href != null
            && exhibitor.featureExhibitorsLink.data.value.href.trim().length > 0) {
            showMarketPlanButton = false;
            let linkType = exhibitor.featureExhibitorsLink.data.value.linktype;

            if (linkType == 'internal') {
                exhibitorUrl = exhibitor.featureExhibitorsLink.data.value.href;
            } else if (linkType == 'external') {
                exhibitorUrl = exhibitor.featureExhibitorsLink.data.value.href;
            }        

            if (exhibitor.featureExhibitorsLink.data.value.target != null && exhibitor.featureExhibitorsLink.data.value.target.trim().length > 0) {
                urlTarget = exhibitor.featureExhibitorsLink.data.value.target.trim();
            }
        }

        if (!!exhibitorDetail) {
            return (<Link href={exhibitorUrl} target={urlTarget} className="imc-gallery__item imc-predefinedsearch__items__item" alt="">
                <div className="imc-exhibitorad--container">
                    <img src={imagePath} alt="" className="imc-exhibitorad--item" />
                    <div className="imc-predefinedsearch__items__item__overlay">
                        <div className="imc-predefinedsearch__items__item__overlay__title">{titleString}</div>
                        <div className="imc-predefinedsearch__items__item__overlay__description">{subTitleString}</div>
                        <div className="imc-predefinedsearch__items__item__overlay__background"></div>
                        {showMarketPlanButton && showMarketPlan(this.props) && <MarketPlanButton item={this.getActionItem(exhibitorDetail)} compact={true} color={`white`} />}
                    </div>
                </div>
            </Link>);
        }
        else
            return "";
    }
    
}

export default withSitecoreContext()(ExhibitorAd);