import {
    CLEAR_STATE,
    SET_EXHIBITOR_DETAIL,
    SET_EXHIBITOR_DETAIL_ERR,
    SET_LINE_DETAIL,
    SET_LINE_DETAIL_ERR,    
    SHOW_LOADING,
    UPDATE_FROM_CONTEXT
} from '../actions/actionTypes';
const initialState = {
    companyDirectoryImage: null,
    companyLogoImage: null,
    companyName: '',
    companySite: '',
    copyBlock: '',
    lineName: '',
    activeLeases: [],
    productCategories: [],
    shopZioURL: '',
    productsCount: 0,
    showroom3DUrl: '',
    contactInfo: {},
    socialMedia: {},
    showLoading: true,
};

/**
 * Reducer for digital showroom information
 * @param {object} state State of the video information
 * @param {object} action Action to pass
 * @returns {*} Returned state
 */
export default function digitalShowroomInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign({}, state, {
                showLoading: action.status,
            });
        case SET_EXHIBITOR_DETAIL:
            return Object.assign({}, state, {
                companyDirectoryImage: action.companyDirectoryImage,
                companyLogoImage: action.companyLogoImage,
                companyName: action.companyName,
                companySite: action.companySite,
                copyBlock: action.copyBlock,
                lineName: action.lineName,
                activeLeases: action.activeLeases,
                productCategories:action.productCategories,
                shopZioURL:action.shopZioURL,
                productsCount:action.productsCount,
                showroom3DUrl:action.showroom3DUrl,
                showLoading: true,
                exhibitId: action.exhibitId,
                lineId: action.lineId,
                errCode: null
            });
        case SET_EXHIBITOR_DETAIL_ERR:
            return Object.assign({}, state, {
                companyDirectoryImage: initialState.companyDirectoryImage,
                companyLogoImage: initialState.companyLogoImage,
                companyName: initialState.companyName,
                companySite: initialState.companySite,
                copyBlock: initialState.copyBlock,
                lineName: initialState.lineName,
                activeLeases: initialState.activeLeases,
                productCategories:initialState.productCategories,
                shopZioURL:initialState.shopZioURL,
                productsCount:initialState.productsCount,
                showroom3DUrl:initialState.showroom3DUrl,
                errCode: action.status || 404,
            });
            case SET_LINE_DETAIL:
                return Object.assign({}, state, {
                    companyDirectoryImage: action.companyDirectoryImage,
                    companyLogoImage: action.companyLogoImage,
                    companyName: action.companyName,
                    companySite: action.companySite,
                    copyBlock: action.copyBlock,
                    lineName: action.lineName,
                    activeLeases: action.activeLeases,
                    productCategories:action.productCategories,
                    shopZioURL:action.shopZioURL,
                    productsCount:action.productsCount,
                    showroom3DUrl:action.showroom3DUrl,
                    showLoading: true,
                    exhibitId: action.exhibitId,
                    lineId: action.lineId,
                    errCode: null
                });
            case SET_LINE_DETAIL_ERR:
                return Object.assign({}, state, {
                    companyDirectoryImage: initialState.companyDirectoryImage,
                    companyLogoImage: initialState.companyLogoImage,
                    companyName: initialState.companyName,
                    companySite: initialState.companySite,
                    copyBlock: initialState.copyBlock,
                    lineName: initialState.lineName,
                    activeLeases: initialState.activeLeases,
                    productCategories:initialState.productCategories,
                    shopZioURL:initialState.shopZioURL,
                    productsCount:initialState.productsCount,
                    showroom3DUrl:initialState.showroom3DUrl,
                    errCode: action.status || 404,
                });   
            case UPDATE_FROM_CONTEXT:
                return  Object.assign({}, state, {
                    exhibitId: action.exhibitId,
                    lineId: action.lineId,
                    errCode: null
                });

            case CLEAR_STATE:
                return  {};
        default:
            return state;
    }
}