import React from 'react';
import { Link, isExperienceEditorActive, ReactFragment } from '@sitecore-jss/sitecore-jss-react';
class IFrameComponent extends React.Component {

    render() {
        const { fields, rendering } = this.props;
        if (!fields) { // Experience editor view
            if (isExperienceEditorActive()) {
                return <div className="stripe-1 imc-iframe--ee">Datasource isn't set.{JSON.stringify(this.props)}</div>;
            }
            else
            {
                return (<div className=""></div>);
            }
        }
        else
        {
            const width = fields.width? fields.width.value: 100;
            const height = fields.width? fields.height.value:400;
            if (typeof window !== 'undefined' && window.Sitecore) {
                return (<React.Fragment>
                    <div className="stripe-1">Edit IFRAME Component</div>
                    <iframe frameBorder="0" scrolling="yes" src={fields.link.value.url} style={{minHeight: height +`px`}} width={width +`%`}></iframe>
                    </React.Fragment>
                );
            }
            else{
            return (
                <iframe frameborder="0" scrolling="yes" src={fields.link.value.url} style={{minHeight: height +`px`}} width={width +`%`}></iframe>
            );
            }
        }
    }
}

export default IFrameComponent;
