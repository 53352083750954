import React, { useState, useEffect, useRef, ReactNode } from "react";
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Loading } from 'modules/loading';
import { divideUpText, getCharacterLength } from './actions/helper';
import { buildClassesFromProps } from "utils/buildRenderingParameters";
import Link from '../Link';

interface HeadingComponentProps {
    fields?: any;
}

interface HeadingState {
    phrases: string[];
    breakpoint: string;
    loaded: boolean;
    spreadLines?: boolean;
    width: number;
}

const HeadingComponent: React.FC<HeadingComponentProps> = (props) => {

    // define state for the component
    const [headingState, setSize] = useState<HeadingState>({
        phrases: divideUpText(props.fields.text.value, "auto"),
        breakpoint: 'desktop',
        loaded: false,
        spreadLines: false,
        width: 1.0,
    });

    // build classes from the extra classes field and set heading tag
    const classes = buildClassesFromProps(props.fields.headingExtraClasses);

    // define refs for elements needed for calculating font size
    const wrapperRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    let HeadingLevel: keyof JSX.IntrinsicElements = "h2";

    if (classes.includes("HEADING-")) {
        // this seems like nonsense but you cannot directly assign a JSX.IntrinsicElements from a string variable
        let heading = `h${classes.split("HEADING-")[1].split(" ")[0]}`;
        switch(heading) {
            case "h1":
                HeadingLevel = "h1";
                break;
            case "h2":
                HeadingLevel = "h2";
                break;
            case "h3":
                HeadingLevel = "h3";
                break;
            case "h4":
                HeadingLevel = "h4";
                break;
            case "h5":
                HeadingLevel = "h5";
                break;          
            case "h6":
                HeadingLevel = "h6";
                break;
        }
    }

    if (classes.includes("spread-lines")) {
        headingState.spreadLines = true;
    }

    const setFontSize = (width: any, wrapper: any, inner: any, recurse:number=1) => {
        const fontSize = parseFloat(width) * (parseFloat(wrapper.current.clientWidth) / parseFloat(inner.current.clientWidth)) * parseFloat(window.getComputedStyle(inner.current).getPropertyValue("font-size").replace("px", ""));
        // @ts-ignore: Object is possibly 'null'.
        inner.current.style.setProperty("font-size", `${fontSize}px`, "important");
        if ((recurse < 10) && Math.abs((parseFloat(inner.current.clientWidth) / parseFloat(wrapper.current.clientWidth)) - width) > 0.01) {
            recurse++;
            setFontSize(width, wrapper, inner, recurse);
        }
    }

    // resize event handler to compute the font size
    const resizeHandler = () => {

        let phrases = headingState?.phrases;
        let breakpoint = 'mobile';
        let width = 1.0;
        if (window.innerWidth >= 600) {
            breakpoint = 'tablet';
        }
        if (window.innerWidth >= 992) {
            breakpoint = 'desktop';
        }
        if (props?.fields[`${breakpoint}Width`]?.value) {
            width = parseFloat(props.fields[`${breakpoint}Width`].value) / 100.0;
        }
        if (headingState?.breakpoint != breakpoint || !headingState.loaded) {
            phrases = divideUpText(props.fields.text.value, props.fields[breakpoint].value, true);
            // @ts-ignore: Object is possibly 'null'.
            Array.from(innerRef.current.children).map((phrase: Element) => {
                const htmlElement = phrase as HTMLElement;
                htmlElement.style.setProperty("font-size", `1em`);
            })
            if (props.fields[breakpoint].value == "auto") {
                // @ts-ignore: Object is possibly 'null'.
                innerRef.current.style.removeProperty("font-size");
            } else {
                setFontSize(width, wrapperRef, innerRef);
            }
            setSize({
                phrases: phrases,
                breakpoint: breakpoint,
                loaded: true,
                width: width,
            });
        } else {
            if (props.fields[breakpoint].value == "auto") {
                // @ts-ignore: Object is possibly 'null'.
                innerRef.current.style.removeProperty("font-size");
            } else {
                setFontSize(width, wrapperRef, innerRef);
            }
        }
    };

    // add and remove event listener for resize event
    useEffect(() => {
        // set timeout for everything to render or values will be wrong
        resizeHandler();
    }, []);
    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        // set timeout for everything to render or values will be wrong
        if (headingState.loaded == true && props.fields[headingState.breakpoint].value != "auto") {
            setFontSize(headingState.width, wrapperRef, innerRef);
            if (headingState.spreadLines && innerRef && innerRef.current) {
                // @ts-ignore: Object is possibly 'null'.
                Array.from(innerRef.current.children).map((phrase: Element) => {
                    const htmlElement = phrase as HTMLElement;
                    let rect = htmlElement.getBoundingClientRect();
                    // @ts-ignore: Object is possibly 'null'.
                    const childSize = (innerRef.current.clientWidth / rect.width) * (parseFloat(window.getComputedStyle(htmlElement).getPropertyValue("font-size").replace("px", "")) / parseFloat(window.getComputedStyle(innerRef.current).getPropertyValue("font-size").replace("px", "")));
                    htmlElement.style.setProperty("font-size", `${(childSize) ? childSize : 1}em`);
                })
                setFontSize(headingState.width, wrapperRef, innerRef);
            }
        }
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [headingState]);


    let Wrapper = ({ children }: { children: ReactNode }) => <>{children}</>

    if (props?.fields?.link?.value?.href) {
        Wrapper = ({ children }: { children: ReactNode }) => <Link field={props.fields.link} target={(props.fields.link.value.linktype === 'internal') ? '' : props.fields.link.value.target} href={props.fields.link.value.href} title={props.fields.link.value.text}>{children}</Link>
    }

    // try to alleviate FOUC by preloading the font size(s)
    let preLoadWidth = (props?.fields[`desktopWidth`]?.value) ? parseFloat(props.fields[`desktopWidth`].value) / 100.0 : 1.0;
    let preLoadLines = (props?.fields[`desktop`]?.value && props?.fields[`desktop`]?.value != 'auto') ? parseFloat(props.fields[`desktop`].value) : 1.0;
    let preLoadPhrases = divideUpText(props.fields.text.value, preLoadLines, true);
    let preLoadSpreadLines = false;
    let noPreLoad = false;
    let preLoadCharacters = (props.fields.text.value.length + preLoadLines) / preLoadLines;
    if (classes.includes("spread-lines")) {
        preLoadSpreadLines = true;
        preLoadPhrases.forEach((phrase) => {
            if (phrase.length > preLoadCharacters) {
                preLoadCharacters = phrase.length;
            }
        });
    }
    if (classes.includes("no-preload")) {
        noPreLoad = true;
    }
    preLoadPhrases.forEach((phrase) => {
        if (phrase.length > preLoadCharacters) {
            preLoadCharacters = phrase.length;
        }
    });
    return (
        <div ref={wrapperRef} className={`h-wrap ${(props?.fields?.align?.value) ? `andmore-${props.fields.align.value}` : ``} ${(!headingState?.loaded || (headingState?.loaded && props.fields[headingState?.breakpoint].value == "auto")) ? ' no-js' : ''}`}>
            <Wrapper>
                <HeadingLevel
                    ref={innerRef}
                    className={`
                        ${(headingState?.loaded) ? `am-heading` : ``} 
                        ${(props.fields.desktop.value != "auto" && !(!headingState?.loaded && noPreLoad)) ? `am-char-${getCharacterLength(preLoadWidth, preLoadCharacters)}` : ``} 
                        ${(headingState?.phrases && headingState?.loaded && props.fields[headingState?.breakpoint].value != "auto") ? 'max-content' : ''} 
                        ${classes}`}
                    style={{
                        ...(props?.fields?.headlineOverride?.value != "" && { color: `${props.fields.headlineOverride.value}` }),
                        ...(!headingState?.loaded && props.fields[headingState?.breakpoint].value != "auto" && { maxWidth: `${preLoadWidth * 100.0}%` }),
                    }}>
                    {!headingState?.loaded && preLoadPhrases.map((line, index) => {
                        return <span
                            className={`inline 
                                ${(props.fields.desktop.value != "auto") ? 'no-wrap' : ''}
                                ${(props.fields.desktop.value != "auto" && preLoadSpreadLines) ? `am-char-${getCharacterLength(preLoadWidth, line.length + 1)}` : ``} `}
                            key={index}><span>{line.replace(/&nbsp;/g," ")}{(index < preLoadPhrases.length - 1) && <br /> }</span></span>;
                    })}
                    {headingState?.loaded && headingState.phrases.map((line, index) => {
                        return <span className={`inline ${(props.fields[headingState?.breakpoint].value != "auto") ? 'no-wrap' : ''}`} key={index}><span>{line.replace(/&nbsp;/g," ")}{(index < headingState.phrases.length - 1) && <br /> }</span></span>;
                    })}
                </HeadingLevel>
            </Wrapper>
        </div>
    );
};

export default HeadingComponent;