import React from 'react';
import ReactModal from 'react-modal';
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import { getDictionaryValue } from 'utils/dictionary';
import Link from '../Link';


ReactModal.setAppElement('#root');


export default class WelcomeModalWindowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.state = {
      showModal: false,
    }
  }
  closeModal() {
    this.setState({
      closeModal: true,
    })
  }
  render() {

    const { fields, dataSource } = this.props;
    if (!dataSource && isExperienceEditorActive()) {
      return <h1 className="alarm">Datasource isn't set.</h1>;
    } else {
      if (isExperienceEditorActive()) {
        return <h1 >Welcome Modal Window Component</h1>;
      }
    }
    const showModal = fields && "welcome" in fields;
    const { welcome, exhibitorPortalLink, marketPlannerLink } = fields;
    const { closeModal } = this.state;
    if (!showModal)
      return null;
    return <ReactModal
      isOpen={showModal && !closeModal}
      overlayClassName="imc-modal--overlay"
      className="imc-modal--box imc-modal--welcome imc-modal--box-height-auto"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={this.closeGalleryModal}
      shouldFocusAfterRender={false}
    >
      <div className="imc-modal--content imc-padding--all--xlarge">
        <div className="imc-type--title-1  imc-margin--bottom--xsmall">{welcome.value}</div>
        <div className="imc-type--title-4 imc-margin--bottom--medium">{getDictionaryValue('whatWouldYouLike', 'What would you like to do today?')}</div>
        {marketPlannerLink &&
          <>
            <Link className="imc-button imc-content--left imc-button--caret-right imc-margin--bottom--mediumsmall" field={marketPlannerLink.fields.link.value}></Link>
            <div className="imc-type--title-5-ui  imc-type--color-neutral-heavy imc-padding--bottom--largemedium">{getDictionaryValue('useMarketPlanner', 'Use Market Planner, Explore Products & Exhibitors, View Market Details')}</div>
          </>
        }
        {exhibitorPortalLink &&
          <>
            <Link className="imc-button imc-content--left imc-button--caret-right imc-margin--bottom--mediumsmall" field={exhibitorPortalLink.fields.link.value}></Link>
            <div className="imc-type--title-5-ui imc-type--color-neutral-heavy imc-padding--bottom--large">{getDictionaryValue('manageProducts', 'Manage Products, Markets, and Representatives')}</div>
          </>
        }
      </div>
      <button className="imc-modal--close imc-button--modal-close imc-button--round" onClick={this.closeModal}>
        {getDictionaryValue('close', 'Close')}</button>
    </ReactModal>
  }
}

