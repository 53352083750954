import React from 'react';
import {
  withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { getDictionaryValue } from 'utils/dictionary';
// modules
import { Loading } from 'modules/loading';
import { createAppState } from 'modules/redux-app-state';
import Link from '../Link';
// reducers
import marketPlannerMyInfoReducer from './reducers/marketPlannerMyInfoReducer';
// action imports
import * as dataActions from './actions/dataActions';

const marketPlannerMyInfoState = createAppState();


class MyInfoComponent extends React.Component {
  constructor(props) {
    super(props);
    marketPlannerMyInfoState.reducerRegistry.register({
      marketPlannerMyInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
    const { sitecoreContext } = this.props;

    this.editCTA = null;
    if (typeof this.props.fields.editCTA !== 'undefined') {
      this.editCTA = this.props.fields.editCTA.value;
    }
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    //if (this.accountId !== null)
    this.fetchData();
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   */
  fetchData() {
    this.props.dataActions.getAccountMyInfo();
    //this.props.dataActions.fetchMockAccountMyInfo();
  }

  renderCTA() {
    if (this.editCTA) {
      return (
        <Link href={this.editCTA.href} target={this.editCTA.target} className='imc-button--primary-inverted imc-button--round'>
          {getDictionaryValue(this.editCTA.text, this.editCTA.text)}
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    const { sitecoreContext, account, fields } = this.props;
    const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    if (typeof account === 'undefined') {
      if (isEditing) {
        return <h1 className='alarm'>Datasource isn't set.</h1>;
      }
      return (
        <h1 className='alarm'>
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    return (
      <div className='imc-marketplanner-myinfo imc-content--left-desktop'>
        <div className="imc-gallery">
          <div className="imc-gallery__item imc-gallery__item--no-padding-left">
            <h3 className="imc-content--center-mobile imc-heading--giga-desktop imc-heading--kilo imc-heading imc-heading--headingh2 imc-vr--large imc-content--padded-large-right imc-breakpoint-display--hide-mobile">
              {getDictionaryValue("myInfo", "My Info")}
            </h3>
          </div>
          <div className="imc-gallery__item">
           {/*
           TODO: https://imcenters.atlassian.net/browse/UXP-2123

           {this.renderCTA()}*/}
          </div>
        </div>
        <div className='imc-vr--large imc-vr--smallmedium-desktop'>
          <p className='imc-heading--secondary imc-heading--alt imc-heading--gamma imc-vr--smallmedium'>{getDictionaryValue('myName', 'My Name')}</p>
          <p className='imc-vr--smallmedium imc-heading--secondary imc-heading--alt3 imc-heading--beta'>{account.name}</p>
        </div>
        <div className='imc-vr--large imc-vr--smallmedium-desktop'>
          <p className='imc-heading--secondary imc-heading--alt imc-heading--gamma imc-vr--smallmedium'>{getDictionaryValue('nameOfMyOrganization', 'Name of My Organization')}</p>
          <p className='imc-vr--smallmedium imc-heading--secondary imc-heading--alt3 imc-heading--beta'>{account.organizationName}</p>
        </div>
        <div className="imc-gallery imc-gallery--1-2">
          <div className='imc-vr--large imc-vr--smallmedium-desktop imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right'>
            <p className='imc-heading--secondary imc-heading--alt imc-heading--gamma imc-vr--smallmedium'>{getDictionaryValue('attendeeType', 'Attendee Type')}</p>
            <p className='imc-vr--smallmedium imc-heading--secondary imc-heading--alt3 imc-heading--beta'>{account.attendeeType}</p>
          </div>
          <div className='imc-vr--large imc-vr--smallmedium-desktop imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right'>
            <p className='imc-heading--secondary imc-heading--alt imc-heading--gamma imc-vr--smallmedium'>{getDictionaryValue('interiorDesigner', 'Interior Designer')}</p>
            <p className='imc-vr--smallmedium imc-heading--secondary imc-heading--alt3 imc-heading--beta'>{account.interiorDesigner}</p>
          </div>
        </div>
        <div className="imc-gallery imc-gallery--1-2">
          <div className='imc-vr--large imc-vr--smallmedium-desktop imc-gallery__item imc-gallery__item--no-padding-left imc-gallery__item--no-padding-right'>
            <p className='imc-heading--secondary imc-heading--alt imc-heading--gamma imc-vr--smallmedium'>{getDictionaryValue('primaryCategoryOfInterest', 'Primary Category of Interest')}</p>
            <p className='imc-vr--smallmedium imc-heading--secondary imc-heading--alt3 imc-heading--beta'>{getDictionaryValue(account.primaryProductCategory, account.primaryProductCategory)}</p>
          </div>
        </div>
        <div className='imc-vr--large imc-vr--smallmedium-desktop'>
          <p className='imc-heading--secondary imc-heading--alt imc-heading--alpha imc-vr--smallmedium '>{getDictionaryValue('alsoInterestedIn', 'I´m Also Interested In...')}</p>
        </div>
        <div>

          <div className='imc-vr--large imc-vr--smallmedium-desktop' >
            {account.productInterests && account.productInterests.map((interest, index) =>
              <span className='imc-vr--smallmedium  imc-heading--delta' key={index}>{
                ` ${interest}` + ((account.productInterests.length - 1) != index ? `,` : ``)
              }</span>
            )
            }
          </div>
        </div>
        <Loading showLoading={!isEditing && this.props.showLoading} showPageLoading={false}></Loading>
      </div>
    );
  }
}

const defaultProps = {
  dataActions: {
    getAccountMyInfo: () => { },
    fetchMockAccountMyInfo: () => { },
  }
};
const propTypes = {
  dataActions: PropTypes.object
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  if (state.marketPlannerMyInfoReducer) {
    return {
      account: state.marketPlannerMyInfoReducer.account,
      showLoading: state.marketPlannerMyInfoReducer.showLoading,
    };
  }
  else {
    return {
      account: state.account,
      showLoading: state.showLoading,
    };
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch)

  };
}

MyInfoComponent.propTypes = propTypes;
MyInfoComponent.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MyInfoComponent))
);
