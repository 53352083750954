// Library dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
/**
 * Component for displaying a Loading component
 * @example
 * <div className="imc-loading__anchor"> include also .imc-loading-relative if the components needs to be rendered inside a parent div instead of taking the whole screen
 *      <Loading showLoading={true} />
 * </div>
 *
 * @returns {Element} Rendered loading component
 */
class Loading extends PureComponent {
    constructor() {
        super();
    }

    render() {
        var { showLoading, showPageLoading } = this.props;
        if (isExperienceEditorActive())
            return null;
        return (
            <div
                className={`imc-loading ${showLoading && !isExperienceEditorActive ? 'imc-loading--active' : ''}
            ${showPageLoading ? 'imc-loading--page-active' : ''}`}
                aria-hidden="true">
                <svg width="200" height="200">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading" />
                </svg>
            </div>);
    }
}
/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{title: (*), poster: (*), overview: (*), releaseDate: (*)}}
 */
Loading.propTypes = {
    showLoading: PropTypes.bool,
    showPageLoading: PropTypes.bool,
};

/**
 * @property defaultProps
 * @type {{applicationProperties: {}}}
 */
Loading.defaultProps = {
    showLoading: false,
    showPageLoading: false,
};

// Export the react component
export default Loading;
