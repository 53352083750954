import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// reducers
import videosDirectoryReducer from './reducers/videoDirectoryReducer';
// action imports
import * as dataActions from './actions/dataActions';
import { createAppState } from 'modules/redux-app-state';
import Pagination from 'modules/search/Pagination.jsx';
import { decode } from 'utils/querystring';
import { getDictionaryValue } from 'utils/dictionary/index';
import VideoCardComponent from '../Video-Card-Component'

const VideoGalleryState = createAppState();

const defaultProps = {
  dataActions: {
    fetchVideos: () => { },
  },
  searchQuery: null,
  defaultView: null,
  errCode: null,
  hideFilters: true,
  location: {
    search: true,
  },
  filterKey: 'articles',
  tagKey: 'tags',
  pageSize: 20,
  pagenumber: 1,
  totalPages: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  defaultView: PropTypes.object,
  filterKey: PropTypes.string,
  tagKey: PropTypes.string,
};

class VideoGallery extends React.Component {
  constructor(props) {
    super(props);
    VideoGalleryState.reducerRegistry.register({ videosDirectoryReducer });
    this.boundFetchData = this.fetchData.bind(this);
    this.renderCards = this.renderCards.bind(this);
    let pageNum = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page || 0;
    this.state = {
      currentPage: pageNum,

    };
  }

  /**
   * Kick off bound fetch data
   */
  componentDidMount() {
    const { location } = this.props;
    this._fetchingInfo = true;
    this.boundFetchData(qs.parse(location.search), 0);
  }


  /**
 * Updates state with new props
 * @param {object} nextProps Incoming props
 */
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let pageNum = qs.parse(nextProps.location.search, { ignoreQueryPrefix: true }).page || 0;
      this.state = {
        currentPage: pageNum,

      };
      this.boundFetchData(qs.parse(nextProps.location.search), this.state.currentPage);
    }
  }

  /**
   * Fetch the article data
   * @param { string } queryObj term used to conduct search for
   * @param { string } pagenum of new page
   * @param { string } pageSize number of items
   */
  fetchData(queryObj, pagenum) {
    let filters = [];
    const { filterKey, tagKey } = this.props;
    const size = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;
    const tabFilters = decode(queryObj[filterKey]);
    if (tabFilters && tabFilters[tagKey]) {
      filters = tabFilters[tagKey];
    }

    const { match } = this.props;
    const { dataSourceIds } =this.props.fields;

    this.pagenumber = pagenum || this.state.currentPage;
    this.props.dataActions.fetchVideos(filters, this.pagenumber, size, !!dataSourceIds?dataSourceIds.value:null);

  }

  renderCards() {
    const { videos } = this.props;
    //alert(JSON.stringify(videos));
    if (typeof videos == 'undefined') {
      return null;
    } else {
      return videos.map((card, index) => {
        const props = {
          isApiProps: true,
          ...card
        };
        return (
          <VideoCardComponent {...props} key={index} />
        );
      });
    }
  }

  renderPagination() {
    const { count } = this.props;
    const size = this.props.sitecoreContext.route.fields.size.value || this.props.pageSize;

    if (count > size) {
      const integerPart = parseInt(count / size)
      if (integerPart < (count / size)) {
        this.totalPages = integerPart + 1;
      } else {
        this.totalPages = integerPart;
      }
    } else {
      this.totalPages = 1;
    }

    if (count == 0) {
      return (<div class="imc-tagcloud__tagcontainer"><p class="imc-content imc-tagcloud__now-viewing"><span>{getDictionaryValue("noVideoAvailable", 'Videos are not available. Please try again soon.')}</span></p></div>);
    } else {

      return (
        <Pagination
          totalPages={this.totalPages}
          paginationContainerId={this.pagenumber}
        />
      );
    }
  }

  render() {
    const { videos } = this.props;
    return (

      <div className="imc-gallery imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge imc-searchresults">
        <div className="imc-vr--xxxlarge imc-landingpage__result-container  imc-section imc-section--full-width">
          {(typeof videos === 'undefined' || videos.length === 0)
            ? <span className="imc-type--title-3-ui">{getDictionaryValue('videosNotFound', 'Videos are not available. Please try again soon.')}</span>
            : <>
              <div
                className="imc-vr--xxlarge imc-landingpage__image-results imc-gallery imc-gallery--image-gallery imc-gallery--1-5 imc-gallery--horizontal-padding">
                {this.renderCards()}
              </div>
              {this.renderPagination()}
            </>
          }
        </div>
      </div>
    );
  }
}
/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
  if (state.videosDirectoryReducer)
    return {
      resultsUpToDate: state.videosDirectoryReducer.resultsUpToDate,
      videos: state.videosDirectoryReducer.videos,
      count: state.videosDirectoryReducer.count,
      numResults: state.videosDirectoryReducer.numResults,
      showLoading: state.videosDirectoryReducer.showLoading,
      errCode: state.videosDirectoryReducer.errCode,
    }
  else {
    return {
      resultsUpToDate: state.resultsUpToDate,
      videos: state.videos,
      count: state.count,
      numResults: state.numResults,
      showLoading: state.showLoading,
      errCode: state.errCode,
    };
  }
}

/**
* Maps dispatch to props for connect
* @param {function} dispatch Dispatcher
* @returns {object} Action creators
*/
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}


VideoGallery.propTypes = propTypes;
VideoGallery.defaultProps = defaultProps;


export default withSitecoreContext()(withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoGallery)));


