import { getDictionaryValue } from 'utils/dictionary';
import ErrorUtility from 'utils/errorutility';
import ExhibitorsAPI from '../../../api/exhibitor.js';
import {
    END_LOADING, GET_ALLSPECIALS_PRODUCTS_FAIL, GET_ALLSPECIALS_PRODUCTS_SUCCESS,
    SHOW_LOADING 
} from './actionTypes';

const _exhibitorAPI = new ExhibitorsAPI();

export function fetchSpecials(exhibitorId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
        });

        return _exhibitorAPI.getExhibitorSpecials(exhibitorId).then((response) => {

            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_SUCCESS,
                specials: response,
                specialsCount: (response) ? response.length : 0,
                exhibitorId: exhibitorId,
            });
            dispatch({
                type: END_LOADING,
            });

        }).catch(err => {
            ErrorUtility.logError(err, getDictionaryValue('error.specialsData', 'An error ocurred while getting show specials data.'), 'ERROR')
            dispatch({
                type: GET_ALLSPECIALS_PRODUCTS_FAIL,
                err: err,
            });
            dispatch({
                type: END_LOADING,
            });
        })
            ;
    };
}