import React, { Component } from 'react'
import EnquiryItemModal from './enquiryItemModal'
import ReactModal from 'react-modal';
import moment from 'moment';

class EnquiryItem extends Component {

    constructor(props) {
		super(props);
		// if (!props) {
	    //     if (isExperienceEditorActive()) {
	    //         return <h1 className="alarm">Datasource isn't set.</h1>;
	    //     }
	    //     return (
	    //         <h1 className="alarm">
	    //             Data is not provided. Contact administrators, please.
	    //       </h1>
	    //     );
	    // }

	    this.state = { 	
            showEnquirylModal: false,
            selectedEnquiryId : null
		};

		this.handleClosePopup = this.handleClosePopup.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
        this.handleOpenPopup = this.handleOpenPopup.bind(this);
        this.renderConfirm = this.renderConfirm.bind(this);
        this.selectToDelete = this.selectToDelete.bind(this);
        this.closeDeletePopup = this.closeDeletePopup.bind(this);
    }

    deleteItem(){
        this.props.dataActions.deleteEnquiry(this.state.selectedEnquiryId);
    }

    selectToDelete(selectedEnquiryId){
        this.setState({selectedEnquiryId: selectedEnquiryId});
    }

    closeDeletePopup(){
        this.setState({selectedEnquiryId: null});
    }

    renderConfirm(){
        const { selectedEnquiryId } = this.state;
        return (
            <ReactModal
                isOpen={selectedEnquiryId!=null}
                ariaHideApp={false}
                overlayClassName="imc-modal--overlay"
                className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto"
            >
                <div className="imc-modal--content imc-section">
                    <div
                        className={`imc-market-planner-quick-add-modal__content-box
                        imc-vr--large imc-vr--xlarge-desktop`}
                    >
                        <h2 className="imc-heading--giga imc-heading--secondary imc-vr--large">
                            Are you sure you want to delete Inquiry?
                        </h2>
                        <button
                            className="imc-button--secondary imc-button--round"
                            onClick={this.deleteItem}
                        >{'Confirm'}</button>
                    </div>
                </div>
                <button
                    className="imc-modal--close"
                    onClick={this.closeDeletePopup}
                >X</button>
            </ReactModal>
        );
    }

    handleOpenPopup() {
        this.setState({ showEnquirylModal: true });
    }
    handleClosePopup() {
        this.setState({ showEnquirylModal: false });
    }
    render() {
        return (
            <li>
                <div className="imc-market-planner-list_row_title imc-content--display-flex  imc-content--display-flex-center"> 
                    <a  onClick={this.handleOpenPopup} >
                        {this.props.exhibitorName}
                    </a>
                </div>
                <EnquiryItemModal {...this.props} selectToDelete={(selectedEnquiryId) => this.selectToDelete(this.props.organizationEnquiryId)} showModal={this.state.showEnquirylModal} state={{selectedEnquiryId: this.state.selectedEnquiryId}} closePopup={this.handleClosePopup} deleteItem={(itemId) => this.deleteItem(itemId)}></EnquiryItemModal>
                {this.renderConfirm()}
                <div className='imc-market-planner-list_row_right_column imc-content--epsilon'>
                    <span className='imc-type--title-5-ui-link imc-type--color-primary-medium imc-market-planner-list_right_item'>{moment(this.props.dateSent,'YYYY-MM-DD[T]HH:mm:ss[Z]').format('MMMM DD, YYYY hh:mm') } </span>
                    <span className='imc-market-planner-list_right_item'><a className="imc-type--title-5-ui-link imc-type--color-primary-medium" onClick={() => this.selectToDelete(this.props.organizationEnquiryId)} >
                        Delete
                    </a></span>
                </div>
            </li>
        )
    }
}

export default EnquiryItem;