import React from 'react';
import { withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAppState } from 'modules/redux-app-state';
import classNames from 'classnames';
import navState from './reducers/navReducer';
import * as navActions from './actions/navActions';

const appState = createAppState();


class MPPageLinkListComponent extends React.Component {
  constructor(props) {
    super(props);
    if (!props) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = {
      expandMenu: false
    }

    appState.reducerRegistry.register({
      navState,
    });

    this.renderLink = this.renderLink.bind(this);
    this.renderMobileLink = this.renderMobileLink.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.changeNavStatus = this.changeNavStatus.bind(this);
  }


  componentDidMount() {
    const path = this.props.history.location.pathname;
    if (path === '/Market-Planner' || path === '/Market-Planner/') {
      this.props.actions.changeNavStatus(true);
    } else {
      this.props.actions.changeNavStatus(false);
    }
  }

  changeNavStatus(value) {
    this.props.actions.changeNavStatus(value);
    this.toggleExpanded(false)
  }

  toggleExpanded(value) {
    this.setState({
      expandMenu: value
    });
  }

  renderLink(link, currentUrl) {
    if (currentUrl != null) {
      var hrefLink = link.fields.link.value.href.split("?")[0];
      var active = currentUrl.toLowerCase() == hrefLink.toLowerCase();
      return (

        <Link key={link.id} className={"imc-button imc-button--rectangle-small" + ((active) ? "" : " imc-button--only-text")} href={link.fields.link.value.href} target={link.fields.link.value.target}>{link.fields.link.value.text}</Link>

      );
    } else {
      return null;
    }
  }
  renderMobileLink(link, currentUrl, index) {
    if (currentUrl != null) {
      var hrefLink = link.fields.link.value.href.split("?")[0];
      var active = currentUrl.toLowerCase() == hrefLink.toLowerCase();
      return (
        <div
          key={link.id}
          onClick={() => this.changeNavStatus(link.fields.link.value.href === '/Market-Planner' ? true : false)}>
          <Link
            className={classNames("imc-button", {
              'imc-button--only-text': !active,
            })}
            href={link.fields.link.value.href}
            target={link.fields.link.value.target}
          >
            {link.fields.link.value.text}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let currentUrl = typeof window != "undefined" ? window.location.pathname : null
    return (
      <React.Fragment>
        {/* Desktop View */}
        <div className="imc-mp-toolbar imc-breakpoint-display--hide-mobile">
          {this.props.fields.links && this.props.fields.links.map((link, index) => {
            return (this.renderLink(link, currentUrl));
          })}
        </div>
        {/* Mobile View */}
        <div className="imc-navigation-mobile imc-breakpoint-display--hide-desktop imc-content imc-mp-toolbar-mobile">
          <>
            <a
              className="imc-navigation-mobile__link imc-mp-toolbar-mobile--close imc-mp-menu-icon"
              onClick={() => this.toggleExpanded(!this.state.expandMenu)}
            >
              <svg width="36" height="36" className="imc-navigation-mobile__icon">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu"></use>
              </svg>
            </a>
            {this.state.expandMenu && (
              <div id="imc-navigation-mobile-menu"
                className={"imc-content--full-width-block imc-mp-menu-modal"}>
                <div className='imc-mp-first-div'>
                  <svg
                    onClick={() => this.toggleExpanded(!this.state.expandMenu)}
                    width="36"
                    height="36"
                    className="imc-navigation-mobile__icon imc-mp-modal-close">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                  </svg>
                </div>
                {this.props.fields.links && this.props.fields.links.map((link, index) => {
                  return (this.renderMobileLink(link, currentUrl, index));
                })}
              </div>
            )}
          </>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, navActions), dispatch),
  };
}

/**
* Maps state to props for connect
* @param {object} state State object
* @returns {{search: *}} State to props mapping
*/
function mapStateToProps(state) {
  const showNav = state.navState ? state.navState.showMainNav : '';
  return {
    showNav: showNav,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MPPageLinkListComponent));