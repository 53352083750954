import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { resetPagination } from 'utils/resetpagination';

const propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

/**
 * SearchApp component class
 * Wrapper for the search and results
 */
export class SearchController extends Component {
    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.runSearch = this.runSearch.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.updateUrl = this.updateUrl.bind(this);

        const queryObj = qs.parse(this.props.location.search);

        this.state = {
            query: queryObj.q || '',
            type: queryObj.type ? queryObj.type : '',
        };
    }

    // /**
    //  * Need to ensure query state is set,
    //  * the constructor does it right, yet sometimes
    //  * componentDidMount runs before the state update is complete?
    //  */
    // componentWillMount() {
    //     const { query } = this.state;
    //     if (query === undefined) {
    //         const { q } = qs.parse(this.props.location.search);
    //         this.setState({
    //             query: q,
    //         });
    //     }
    // }

    // /**
    //  * Runs a query search if there is a query string in tow
    //  */
    // componentDidMount() {
    //     const { query } = this.state;
    //     if (query !== '') {
    //         this.runSearch(query);
    //     } else if (query === '' && this.props.actions && this.props.actions.getCount) {
    //         this.props.actions.getCount('').then(() => {
    //             this.props.actions.runSearch('');
    //         });
    //     }
    // }

    /**
     * Updates the URL and fetch result counts
     * @param { string } query string to kick off search with
     * @param { bool } pageReset should we set the page back to 1
     */
    runSearch(query, pageReset = false) {
        this.props.actions.getCount(query).then(() => {
            this.updateUrl(query, pageReset);
        });
    }

    /**
     * Updates the URL
     * @param { string } query string to update url with
     * @param {bool} pageReset Determines if pagination should be set back to 1
     * @param { string } type of search
     */
    updateUrl(query, pageReset = false, type) {
        let newType = this.state.type;
        // console.log('newType: '+newType);
        // console.log('type: '+type);
        const { history, match, location } = this.props;
        if (type != null) {
            newType = type;
            this.setState({
                type,
            });
        }
        const querystring = Object.assign({}, qs.parse(location.search), {
            q: query,
        });
        if (newType !== '') {
            querystring.type = newType;
        } else if (querystring.type) {
            delete querystring.type;
        }
        if (query === '') {
            // console.log('delete querystring.q;');
            delete querystring.q;
        }
        const newHistory = resetPagination(match, location);
        newHistory.search = qs.stringify(querystring);
        history.push(newHistory);
    }

    /**
     * Updates the query in the state
     * @param { string } fieldName name of field being updated
     * @param { string } query Query to update to the state
     */
    updateQuery(fieldName, query) {
        this.setState({
            query,
        });
    }

    /**
     * Placeholder for comp rendering
     * @returns {XML} Rendered component
     */
    render() {
        return null;
    }
}

SearchController.propTypes = propTypes;
SearchController.defaultProps = {};

export default SearchController;
