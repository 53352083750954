import React from 'react';
import PropTypes from 'prop-types';

import { CardTags } from 'modules/cardtags';
import { getDictionaryValue } from 'utils/dictionary/index';
import { updateUrl } from 'utils/tagupdate';

import { INFORMATION_IDENTIFIER } from 'utils/exhibitor';
import { TAGS_IDENTIFIER } from 'utils/search';
import { withRouter } from 'react-router';

const propTypes = {
    title: PropTypes.string,
    metaData: PropTypes.object,
    destinationUrl: PropTypes.string,
    labels: PropTypes.object,
    tag: PropTypes.array,
    id: PropTypes.string,
    moreLabel: PropTypes.string,
    pageDescription: PropTypes.string,
    pageBreadcrumbs: PropTypes.string,
};

const defaultProps = {
    title: '',
    publishDate: '',
    metaData: {},
    destinationUrl: '#',
    filterKey: INFORMATION_IDENTIFIER,
    tagKey: TAGS_IDENTIFIER,
    tag: [],
    id: '',
    pageDescription: '',
    pageBreadcrumbs: '',
};

/**
 * @method ExhibitorCard
 * @description
 * Display Img, Title, details,etc provided in a card format
 * @param {object} props Incoming props
 * @returns {NodeList} DOM elements to render
 */
class InformationCard extends React.Component {
    constructor(props) {
        super(props);
        this.updateTagUrl = this.updateTagUrl.bind(this);
    }

    /**
     * Updates the URL in the following manner:
     * ?{props.filterKey}=encode(filter=true&{props.tagKey=tag1|tag2|tag3})
     * @param { string } tag string to update url with
     */
    updateTagUrl(event) {
        const { history, match, location, filterKey, tagKey } = this.props;
        const tag = event.target.firstChild.nodeValue;
        updateUrl(tag, history, match, location, filterKey, tagKey);
    }


    render() {
        const { title, metaData, pageBreadcrumbs, pageDescription, destinationUrl, id, tags } = this.props;
        return (
            <div className="imc-content--border imc-vr--xlarge imc-informationcard">
                <h2 className="imc-informationcard__title">{title || metaData.title}</h2>
                <div className="imc-informationcard__body">
                    <p className="imc-informationcard__breadcrumbs">
                        {pageBreadcrumbs ||  /* TODO:remove mock data  */ 'Home / Market Info / About Las Vegas Market'}
                    </p>
                    <p className="imc-informationcard__description">{pageDescription || /* TODO:remove mock data  */ 'Vivamus lobortis justo eu enim blandit congue. Sed nulla nulla, hendrerit ac justo id, scelerisque imperdiet ligula. Fusce convallis enim sed sodales efficitur. Vivamus sollicitudin ante libero, vitae auctor mauris malesuada sed. Quisque pharetra fermentum dui, et dignissim augue ultrices id. Nam vel ultricies eros. Aenean vel massa eu augue euismod fermentum. Vivamus velit elit, pretium ut malesuada ut, tempus dignissim lectus. Aliquam convallis quis mi eu mollis. Nam facilisis malesuada ex, ac rhoncus mauris ultricies non. Maecenas placerat leo sed sapien blandit vestibulum. Vivamus ac condimentum mi.'}</p>
                    <div className="imc-informationcard__bottomrow imc-gallery imc-gallery--1-2">
                        <div className="imc-gallery__item imc-gallery__item--no-padding-left">
                            <a
                                className={'imc-button--primary-inverted imc-informationcard__link'}
                                href={destinationUrl}
                            >{getDictionaryValue('seeMoreDetails', 'See More Details')}</a>
                        </div>
                        <div className="imc-articlecard__tags imc-gallery__item">
                            <CardTags
                                className='imc-informationcard__tag'
                                labelMoreTags='More Tags'
                                id={id}
                                tags={tags}
                                destinationUrl={destinationUrl}
                                maxTagsToDisplay={2}
                                clickEvent={this.updateTagUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
// function mapStateToProps(state) {
//     return {
//         labels: state.labelConfigReducer.labels,
//         moreLabel: state.labelConfigReducer.labels.searchResults.moreLabel,
//     };
// }

InformationCard.propTypes = propTypes;
InformationCard.defaultProps = defaultProps;

export default withRouter(InformationCard);
