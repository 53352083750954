
import React from 'react';

class CustomListsComponent extends React.Component {
  render() {
    return <></>
  }
}


export default CustomListsComponent;