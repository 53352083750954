import React from 'react';
// import { createAppState } from 'modules/redux-app-state';
// import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
// import { bindActionCreators } from 'redux';
// import { getDictionaryValue } from 'utils/dictionary';
// import ListElementComponent from 'modules/lists/components/listElementComponent';
// import ListHeaderComponent from 'modules/lists/components/listsHeaderComponent';
// import {Loading} from 'modules/loading';

// import * as dataActions from './actions/dataActions';
// import {exReducer, exhibitorsSortReducer} from './reducers/reducer';

// import * as sortActions from 'modules/lists/actions/sortActions';

// const theState = createAppState();

class SavedExhibitorsComponent extends React.Component {
//   constructor(props) {
//     super(props);
//     theState.reducerRegistry.register({ exReducer, exhibitorsSortReducer });
   
//     this.fetchData = this.fetchData.bind(this);
//     this.renderHeader = this.renderHeader.bind(this);
//     this.renderExhibitorList = this.renderExhibitorList.bind(this);
//     this.onSortChange = this.onSortChange.bind(this);
//     this.state = {showLoading : true};
//   }

//   componentDidMount() {
//     if (!isExperienceEditorActive()) {
//       this.fetchData();
//     }
//   }

//   componentWillReceiveProps(nextProps) {
//   }

//   onSortChange(sortBy){
//     this.props.sortActions.sort(this.props.data, sortBy);
// }

//   fetchData() {
//     this.props.dataActions.fetchExhibitors();
//   }

//   renderHeader(){
//     return (
//       <ListHeaderComponent onSortChanged={this.onSortChange} 
//       title={getDictionaryValue("allSavedExhibitors", "All Saved Exhibitors")} 
//       description={getDictionaryValue("listOfAllExhibitors", "A simple list if  all Exhibitors you have either Favorited or Saved to a list.")}></ListHeaderComponent>
//         );
//   }

//   renderExhibitor(item){
//     return <ListElementComponent key={item.itemType + item.itemId} item={item}></ListElementComponent>
//   }

//   renderExhibitorList(){
//     const list = this.props.data;
//     if (this.props.data==undefined)
//       return null;

//     return list.map((item, index) => {
//       return this.renderExhibitor(item) 
//       });
//   }

  render(){
    return <></>;
  }
}
//   }
    
//     return (<div className="imc-loading-relative">
//       {this.renderHeader()}
//       {!!this.props.showLoading && 
//         <Loading showLoading={true} showPageLoading={false}></Loading>}
//       {!this.props.showLoading && 
//         this.renderExhibitorList()}
//     </div>);
//   }

// function mapStateToProps(state) {
//   if (!!state.exReducer || !!state.exhibitorsSortReducer)
//   {
//     var map = {};
//     if (!!state.exReducer && !!state.exReducer.data){
//       map.data = state.exReducer.data;
//       map.showLoading=state.exReducer.showLoading;
//     }
//     if (!!state.exhibitorsSortReducer && !!state.exhibitorsSortReducer.data){
//       map.data = state.sortReducer.data;
//     }
//     return map;
//   }
//   else {
//     return {
//       data: state.data,
//     };
//   }
// }

//  function mapDispatchToProps(dispatch) {
//   return {
//     dataActions: bindActionCreators({ ...dataActions }, dispatch),
//     sortActions: bindActionCreators({ ...sortActions }, dispatch),
//   };
// }

// export default withSitecoreContext()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedExhibitorsComponent)));
export default SavedExhibitorsComponent;