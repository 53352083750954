import React, { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import qs from 'query-string';
import { encode } from 'utils/querystring';
import { compareShowrooms } from 'modules/marketplan/helpers/helpers';
import { toggleDrawerState, removeFromPlan, addToPlan } from '../actions/actions';
import UserUtility from "utils/userutility/index";
import { Environment } from '../../../Environment';

interface MarketPlanButtonProps extends RouteComponentProps<any> {
    item: any;
    lineGuid?: string;
    compact?: boolean;
    color?: string;
    history: any;
    extraClassName?: string;
    wrapperClassName?: string;
}

const MarketPlanButton: React.FC<MarketPlanButtonProps> = ({item, lineGuid, extraClassName, wrapperClassName, compact=false, color, history}) => {

    const isLoggedIn = Environment.isHot != undefined || UserUtility.isLoggedIn();
    const dispatch = useDispatch();
    const plan = useSelector((state: any) => state.marketPlanReducer);
    const [planItem, setPlanItem] = useState<any>(null);
    const [isLoading, setLoading] = useState<any>(null);
    const [lastVisited, setlastVisited] = useState<any>(null);
    
    useEffect(() => {
        let NewItem = undefined;
        let NewlastVisited = undefined;
        let loading = false;
        plan.showrooms.forEach((showroom: any) => {
            if (compareShowrooms(showroom, item, lineGuid)) {
                NewItem = {...showroom};
                NewlastVisited = showroom.lastVisited;
            }
        });
        plan.loading.forEach((showroom: any) => {
            if (compareShowrooms(showroom, item, lineGuid)) {
                loading = true;
            }
        });
        setlastVisited(NewlastVisited);
        setPlanItem(NewItem);
        setLoading(loading);
    }, [plan, item.exhibitorId, lineGuid]);

    const add = (event: MouseEvent) => {
        event.preventDefault();
        if (isLoggedIn) {
            if (!plan.isRegistered) {
                dispatch(toggleDrawerState(true));
            } else {
                if (lineGuid) {
                    dispatch(addToPlan(item, {...plan}, lineGuid));
                } else {
                    dispatch(addToPlan(item, {...plan}));
                };
            }
        } else {
            let parsed = qs.parse(location.search);
            for (let key in parsed) {
                const value = parsed[key];
                if (typeof value === 'string') {
                    parsed[key] = value.replace(/&/g, '%26');
                }
            }
            parsed.showroomName = item.showroomName;
            parsed.exhibitorId = item.exhibitorId;
            parsed.action = `add`;
            if (lineGuid) {
                parsed.lineGuid = lineGuid;
                parsed.shownBy = item.showroomName;
                parsed.showroomName = item.lines.find((line: any) => line.lineGuid === lineGuid).name;
            }
            history.push({search: encode(parsed, false, false)});
            dispatch(toggleDrawerState(true));
        }
    }

    const remove = (event: MouseEvent) => {
        event.preventDefault();
        if (isLoggedIn) {
            dispatch(removeFromPlan(planItem, {...plan}, lineGuid));
        } else {
            let parsed = qs.parse(location.search);
            parsed.showroomName = encode(item.showroomName);
            parsed.exhibitordId = item.exhibitorId;
            parsed.action = `remove`;
            if (lineGuid) {
                parsed.lineGuid = lineGuid;
                parsed.lineName = encode(item.lines.find((line: any) => line.lineGuid === lineGuid).lineName);
            }
            history.push({search: encode(parsed, false, false)});
        }
    }

    let buttonClassName = `${lastVisited ? `` : `imc-button imc-button--transform-none imc-button--atmarket--sizing imc-button--radius-3 imc-button--atmarket`}`;
    buttonClassName += `${!planItem ? `-inverted` : ``}`;
    buttonClassName += `${extraClassName ? ` ${extraClassName}` : ``}`;
    if (compact) {
        buttonClassName = `imc-button--atmarket--add-to-plan`;
    }

    return (
        <div className={`market-plan-button-wrapper ${(wrapperClassName) ? wrapperClassName : ``}`}>
            {(!lastVisited) && <button className={`${buttonClassName} imc-button--svg${isLoading || !plan.isRegistered ? ' imc-button--atmarket-disabled' : '' }${(isLoading && !compact) ? ' imc-button--atmarket--loading' : '' }${(plan.isFetching) ? ' imc-button--atmarket--fetching' : '' }`} onClick={(event) => !planItem ? add(event) : remove(event) }>
                <svg
                    className={`${isLoading ? `svg-rotating` : ``} imc-breakpoint-display--hide-mobile`}
                    width="16"
                    height="16"
                    role="img"
                    aria-labelledby="add-to-market-plan"
                >
                    <title id="clear-search-title">{`Add to Market Plan`}</title>
                    <desc id="clear-search-desc">{`Add to Market Plan`}</desc>
                    <use className={color ? `svg-${color}` : ``}
                        xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`${isLoading ? `#juniper-refresh` : (!planItem ? `${(compact) ? `#juniper-market-plan` : `#juniper-on-plan`}` : `#juniper-on-plan`)}`}
                    />
                </svg>
                <svg
                    className={`${isLoading ? `svg-rotating` : ``} imc-breakpoint-display--mobile-only`}
                    width="16"
                    height="16"
                    role="img"
                    aria-labelledby="add-to-market-plan"
                >
                    <title id="clear-search-title">{`Add to Market Plan`}</title>
                    <desc id="clear-search-desc">{`Add to Market Plan`}</desc>
                    <use className={color ? `svg-${color}` : ``}
                        xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`${isLoading ? `#juniper-refresh` : (!planItem ? `#juniper-market-plan` : `#juniper-on-plan`)}`}
                    />
                </svg>
                {!compact && <span>{`${isLoading ? (!planItem ? `Adding` : `Removing`) : (!planItem ? `Add To Plan` : `On My Plan`)}`}</span>}
            </button>}
            {lastVisited && <div className={`imc-button--svg imc-button--plan-visited`}>
                {!compact && <span>
                    <svg
                        className={``}
                        width="16"
                        height="16"
                        role="img"
                        aria-labelledby="add-to-market-plan"
                    >
                        <title id="clear-search-title">{`Add to Market Plan`}</title>
                        <desc id="clear-search-desc">{`Add to Market Plan`}</desc>
                        <use
                            xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#juniper-added-to-plan`}
                        />
                    </svg>
                    <span className="imc-breakpoint-display--hide-mobile">Visited</span>
                </span>}
                {(!lineGuid) && !compact && planItem?.photosCount > 0 && <span>|</span>}
                {(!lineGuid) && planItem?.photosCount > 0 && <>
                    {compact && <span>{planItem.photosCount}</span>}
                        <span>
                            <svg
                                className={``}
                                width="16"
                                height="16"
                                role="img"
                                aria-labelledby="add-to-market-plan"
                            >
                                <title id="clear-search-title">{`Add to Market Plan`}</title>
                                <desc id="clear-search-desc">{`Add to Market Plan`}</desc>
                                <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#juniper-photo-gallery`}
                                />
                            </svg>
                        {!compact && <span>{planItem.photosCount}</span>}
                    </span>
                </>}
                {(!lineGuid) && !compact && planItem?.note > 0 && <span>|</span>}
                {(!lineGuid) && planItem?.note > 0 &&
                    <span>
                        <svg
                            className={``}
                            width="16"
                            height="16"
                            role="img"
                            aria-labelledby="add-to-market-plan"
                        >
                            <title id="clear-search-title">{`Add to Market Plan`}</title>
                            <desc id="clear-search-desc">{`Add to Market Plan`}</desc>
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#juniper-orders`}
                            />
                        </svg>
                </span>}
            </div>}
        </div>
    )
}

export default withRouter(MarketPlanButton);

