import qs from 'query-string';
import { decode, encode } from '../querystring';
import { resetPagination } from '../resetpagination';

/**
 * Key to indicate all tags
 * @type {string}
 */
const ALL_TAGS = 'All Topics';

/**
 * Removes an item from an array
 * @param {object} array Array of elements
 * @param {*} element element to be removed
 */
const remove = (array, element) => {
    const index = array.indexOf(element);
    if (index !== -1) {
        array.splice(index, 1);
    }
};

/**
 * Updates the URL in the following manner:
 * ?{props.filterKay}=encode(filter=true&{props.tagKey=tag1|tag2|tag3})
 * @param {*} tag Incoming tag
 * @param {*} history Incoming history object from router
 * @param {*} match Match object from router
 * @param {*} location Location from router
 * @param {*} filterKey Filter key to be used
 * @param {*} tagKey Tag key to be used
 */
export function updateUrl(tag, history, match, location, filterKey, tagKey) {
    let selectedArray = [];
    const queryObject = qs.parse(location.search);
    const filters = decode(queryObject[filterKey]);
    if (filters[tagKey] && typeof filters[tagKey] === 'object') {
        selectedArray = filters[tagKey];
    }
    const thisTabQueryObject = queryObject[filterKey] ?
        decode(queryObject[filterKey]) :
        {};
    if (tag && tag.length > 0 && tag !== ALL_TAGS) {
        remove(selectedArray, ALL_TAGS);
        if (selectedArray.includes(tag)) {
            remove(selectedArray, tag);
        } else {
            selectedArray.push(tag);
        }
        thisTabQueryObject[tagKey] = selectedArray;
        thisTabQueryObject.filter = true;
        queryObject[filterKey] = encode(thisTabQueryObject);
    }

    if (selectedArray.length === 0 || tag === ALL_TAGS) {
        selectedArray = [ALL_TAGS];
        delete queryObject[filterKey];
    }
    delete queryObject['page'];
    const newHistory = resetPagination(match, location);
    newHistory.search = qs.stringify(queryObject);
    newHistory.pathname = location.pathname;
    newHistory.pagenum = 1;
    history.replace(newHistory);
}

export function updateSearchUrl(tag, history, match, location, filterKey, tagKey) {
    let selectedArray = [];
    const queryObject = qs.parse(location.search);
    const filters = decode(queryObject[filterKey]);
    if (filters[tagKey] && typeof filters[tagKey] === 'object') {
        selectedArray = filters[tagKey];
    }
    const thisTabQueryObject = queryObject[filterKey] ?
        decode(queryObject[filterKey]) :
        {};
    if (tag && tag.length > 0 && tag !== ALL_TAGS) {
        remove(selectedArray, ALL_TAGS);
        if (selectedArray.includes(tag)) {
            remove(selectedArray, tag);
        } else {
            selectedArray.push(tag);
        }
        thisTabQueryObject[tagKey] = selectedArray;
        thisTabQueryObject.filter = true;
        queryObject[filterKey] = encode(thisTabQueryObject);
    }

    if (selectedArray.length === 0 || tag === ALL_TAGS) {
        selectedArray = [ALL_TAGS];
        delete queryObject[filterKey];
    }
    delete queryObject['page'];
    const newHistory = resetPagination(match, location);
    newHistory.search = qs.stringify(queryObject);
    newHistory.pathname = '/search/articles';
    console.log(newHistory.pathname);
    newHistory.pagenum = 1;
    history.push(newHistory);
}
