import React from "react";
import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";


// reducers
import digitalShowroomLinesOverviewReducer from "./reducers";
import digitalShowroomInfoReducer from "../Digital-Showroom-Info/reducers/digitalShowroomInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";

//utils
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import { getTitleName, getCurrentPathLinesURL } from 'utils/exhibitor';
import {isMarketCtaShouldBeShown} from '../../utils/general';

// API
import exhibitorsAPI from 'api/exhibitor';

//modules
import { isMobile } from "react-device-detect";
import TypeAheadSearchForm from '../Type-Ahead-Search';
import Line from '../Exhibited-On-Floor/components/Line';

import Link from '../Link';
import UserUtility from "../../utils/userutility";
const digitalShowroomState = createAppState();
const LINE_PATH_IDENTIFIER = '/line/';
const searchFields = { "showMultiSelect": { "value": false }, "showSort": { "value": false }, "showShare": { "value": false }, "disableTypeahead": { "value": false }, "showClearSearch": { "value": true }, "maxLength": { "value": "" }, "hideSubmitButton": { "value": false }, "minLengthForSearch": { "value": "0" }, "showSavedSearches": { "value": false }, "extraClass": { "value": "imc-searchform--lines-overview" }, "resultsRedirectUrl": { "value": "/search/exhibitors" }, "showMessage": { "value": false }, "hideSortResultBy": { "value": false }, "searchType": [], "sortResultsBy": [], "hideFilterBy": { "value": false }, "hideSearchType": { "value": false } }
const SPECFLOW_ID = "digitalShowRoomLines";

class DigitalShowroomLinesOverview extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomLinesOverviewReducer,
      digitalShowroomInfoReducer
    });

    this.api = new exhibitorsAPI()
    this.fetchData = this.fetchData.bind(this);
    this.sortOption = this.sortOption.bind(this);
    this.filterByTerm = this.filterByTerm.bind(this)
    this.getLineCount = this.getLineCount.bind(this);

    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      this.exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      this.lineId = sitecoreContext.lineProduct.lineId;
    }

    this.state = {
      query: '',
      order: '',
      lineCount: 0,
      sortBy: "organizationname-asc",
      selectedFilter: "Sort A-Z",
    }
  }

  /**
 * Kick off bound fetch data
 */
  componentDidMount() {
    if (this.exhibitorId !== null || this.lineId !== null) {
      this.fetchData(this.exhibitorId, this.lineId);
      this.getLineCount()
    }
  }

  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props;
    let exhibitorId, lineId;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
      lineId = sitecoreContext.lineProduct.lineId;
    }

    if (lineId != this.lineId || exhibitorId != this.exhibitorId) {
      this.exhibitorId = exhibitorId;
      this.lineId = lineId;
      this.fetchData(this.exhibitorId, this.lineId);
      this.getLineCount()
    }
  }

  async getLineCount() {
    const data = await this.api.getExhibitorCountArray([this.exhibitorId]); /// this shows all of their lines between channels and can be innacurate
    const lineCount = data[0]?.lineCount;

    if (lineCount && lineCount >= 0) {
        this.setState({ lineCount })
    }
  }

  /**
 * Fetch the article data
 * @param { string } exhibitorId  used to getInfo
 */
  fetchData(exhibitorId, lineId, alpha = 'linename-asc', numProd = false, prodOnly = false, shopZioOnly = false, term = '') {

    const pageId = this.props.sitecoreContext.route.itemId;
    if (this.locationIsLine()) {
      this.props.dataActions.getDigitalShowroomLineCatalogsOverview(lineId);
    }
    else {
      this.props.dataActions.getDigitalShowroomLinesOverview(exhibitorId, alpha, numProd, prodOnly, shopZioOnly, pageId, term);
    }
    this.props.dataActions.getExhibitorDetail(exhibitorId, pageId);
  }

  locationIsLine() {
    const { location } = this.props;
    return (location && location.pathname && location.pathname.includes(LINE_PATH_IDENTIFIER)) ? true : false;
  }

  exhibitorIsLine(exhibitor) {
    return (exhibitor && exhibitor.lineName) ? true : false;
  }
  getTitle(exhibitor) {
    return this.exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName;
  }

  /**
   * Sort Option
   * @param { string } type Query to update to the state
  */
  sortOption(type) {
    this.props.dataActions.setOrder(type)
  }

  filterByTerm(label, suggestion) {
    this.fetchData(this.exhibitorId, this.lineId, 'Relevancy', false, false, false, label)
  }
  getActionItem(line) {
      return {
          showroomName: line.exhibitorName,
          exhibitorId: line.exhibitorId,
          lines: [{
            name: line.lineName,
            lineGuid: line.lineId,
          }],
          lastVisited: null,
          photosCount: 0,
      }
  }

  renderLines(lines) {
    const { sitecoreContext, linesCount, order } = this.props;
    const exhibitId = sitecoreContext.exhibitor.id;
    let l;

    if (order === 'alpha') {
      l = [...this.props.lines].sort(function (a, b) {
        if (a.lineName.toLowerCase() < b.lineName.toLowerCase()) { return -1; }
        if (a.lineName.toLowerCase() > b.lineName.toLowerCase()) { return 1; }
        return 0;
      })
    } else if (order === 'products') {
      l = [...this.props.lines].sort(function (a, b) {
        if (a.matchedProductsCount > b.matchedProductsCount) { return -1; }
        if (a.matchedProductsCount < b.matchedProductsCount) { return 1; }
        return 0;
      })
    } else if (order === 'productsNum') {
      l = [...this.props.lines].filter(line => line.matchedProductsCount > 0);
    } else if (order === 'shopZio') {
      l = [...this.props.lines].filter(line => line.shopZioLink !== null);
    } else {
      l = lines;
    }

    

    return (
        <div className={"imc-gallery am-grid imc-gallery--1-3 imc-gallery--1-2--tablet imc-gallery--1-1--mobile imc-gallery--50-50 imc-gallery--align-flex-stretch imc-gallery--justify-left imc-gallery--xlarge-padded"}>
            {l.map((line, index) => {
            line.name = line.lineName;
            return (
                <Line
                    key={index}
                    line={
                        {
                            ...line,
                            directoryLogos: line.manufacturerLogoURL
                        }
                    }
                    index={index}
                    getActionItem={this.getActionItem}
                />
            )
            })}
        </div>
    )
  }

  render() {
    const { lines, linesCount, location, unfilteredLinesCount } = this.props;
    const { query, lineCount } = this.state;
    const exhibitorId = location.pathname.split('/')[2];

    if (unfilteredLinesCount > 0) {
      return (
        <div id={SPECFLOW_ID} className="imc-exhibitors--alternated-section imc-lines-overview imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
          <div className="imc-section imc-section--width-100-percent">
            <div className="imc-content--display-flex-wrap">
              <span className="imc-heading--h5">{getDictionaryValue('lines', 'Lines')}</span>
              <span className="imc-type--body-2-ui imc-padding--left--small">
                {linesCount > 0 ? getDictionaryValue('countLinesShown', `${linesCount} Lines Shown`, { count: linesCount })
                  : getDictionaryValue('noLinesShown', 'No Lines Shown')}
              </span>
            </div>
            <div className={'imc-content--display-flex imc-content--display-flex-space-between imc-lines-overview-actions'}>
              <div className={'imc-content--display-flex imc-lines-overview-sort-bar imc-lines-overview-sort-bar-col1'}>
                <TypeAheadSearchForm
                  customSubmit={(label, suggestion) => this.filterByTerm(label, suggestion)}
                  formClass="imc-type-ahead imc-searchform__form"
                  inputClass="imc-searchform__input"
                  btnClass="imc-button imc-searchform__submit"
                  secClass="imc-searchform"
                  extraClass="imc-lines-overview--clear"
                  query={query}
                  inputId="product-search"
                  includeClearSearch

                  mobileSearchButton
                  fields={searchFields}
                  placeholderText={getDictionaryValue("filterByLineName", "Filter By Line Name")}
                  exhibitorId={exhibitorId}
                  minLengthForSearch={0}
                  types={['Line']}
                />
                <div className={'imc-content--display-flex'}>
                  {!isMobile &&
                    <a
                      href={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        this.sortOption('alpha');
                      }}
                      className={'imc-type--body-1-link imc-lines-overview-sort-bar-link hide-mobile imc-breakpoint-display--hide-mobile'}
                    >
                      Sort A-Z
                    </a>
                  }
                  {!isMobile &&
                    <a
                      href={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        this.sortOption('products');
                      }}
                      className={'imc-type--body-1-link imc-lines-overview-sort-bar-link hide-mobile imc-breakpoint-display--hide-mobile'}
                    >
                      Sort by # of Products
                    </a>
                  }
                  <a
                    href={'#'}
                    onClick={(e) => {
                      e.preventDefault();
                      this.sortOption('productsNum');
                    }}
                    className={'imc-type--body-1-link imc-lines-overview-sort-bar-link'}
                  >
                    Lines with Products Only
                  </a>
                  {isMarketCtaShouldBeShown(this.props) && <a
                    href={'#'}
                    onClick={(e) => {
                      e.preventDefault();
                      this.sortOption('shopZio');
                    }}
                    className={'imc-type--body-1-link imc-lines-overview-sort-bar-link'}
                  >
                    Lines on @Market
                  </a>}
                </div>
              </div>
              <div className={'imc-content--display-flex imc-lines-overview-sort-bar'}>
                {lineCount > 0 ?

                  <Link href={getCurrentPathLinesURL(location.pathname)} className="imc-button imc-button--primary-inverted imc-button--full-bleed-mobile">{renderDesktopMobileVariations('seeAllLines', `See${(lineCount > 1) ? ' All' : ''} ${lineCount} Line${(lineCount > 1) ? 's': ''}`, { count: lineCount })}</Link>
                  :
                  <button className="imc-button imc-button--muted-inverted imc-button--full-bleed-mobile" disabled>
                    {`0 ${getDictionaryValue('lines', 'Lines')}`}
                  </button>
                }
              </div>
            </div>
            <div className={'imc-content--padded-top-bottom'}>
              {(lines && lines.length > 0) &&
                this.renderLines(lines)
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <></>
      )
    }
  }
}
const defaultProps = {
  dataActions: {
    getDigitalShowroomContactInfo: () => { },
  },
  getInfoActions: {
    getDigitalShowroomLinesOverview: () => { },
  },
  lines: [],
  linesCount: 0,
  unfilteredLinesCount: 0
};
const propTypes = {
  dataActions: PropTypes.object,
  getInfoActions: PropTypes.object,
  lines: PropTypes.array,
  linesCount: PropTypes.number,
  unfilteredLinesCount: PropTypes.number
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  let _props = {};
  if (state.digitalShowroomLinesOverviewReducer)
    Object.assign(_props, {
      lines: state.digitalShowroomLinesOverviewReducer.lines,
      linesCount: state.digitalShowroomLinesOverviewReducer.linesCount,
      showLoading: state.digitalShowroomLinesOverviewReducer.showLoading,
      order: state.digitalShowroomLinesOverviewReducer.order,
      unfilteredLinesCount: state.digitalShowroomLinesOverviewReducer.unfilteredLinesCount
    });
  if (state.digitalShowroomInfoReducer) {
    Object.assign(_props, {
      shopZioURL: state.digitalShowroomInfoReducer.shopZioURL,
    });
  }
  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomLinesOverview.propTypes = propTypes;
DigitalShowroomLinesOverview.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomLinesOverview))
);