import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import qs from 'query-string';
import { isMobileOnly } from "react-device-detect";

import { ReactComponent as LeftArrowButton } from '../../../assets/svg/common/LeftArrowCircle.svg';
import { ReactComponent as RightArrowButton } from '../../../assets/svg/common/RightArrowCircle.svg';

// Module dependencies
import NewSearchItemThumb from 'modules/newSearchItemThumb/NewSearchItemThumb';

import { getBooth } from 'utils/floorplan';

import Link from '../../../components/Link';

//actions
import * as multiSelectActions from '../../../components/Type-Ahead-Search/actions/multiSelectActions';

//Utils
import { getDictionaryValue } from 'utils/dictionary';
import { cleanObjKeys } from 'utils/cleanObjKeys';
import { serialize } from 'utils/serialize';
import { getFloorPlanURL } from 'utils/floorplan';
import {
    getExhibitorProductsURL,
    getExhibitorLineProductsURL,
    getExhibitorCatalogURL,
    getExhibitorURL,
    getExhibitorLineURL,
    getExhibitorLineProductURL,
    getExhibitorProductURL,
    getAllExhibitorLinesURL,
} from "utils/exhibitor";
import ImcDataLayer from "utils/datalayer";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMarketCtaShouldBeShown, showMarketPlan } from '../../../utils/general';

import MarketPlanButton from '../../marketplan/components/MarketPlanButton';

const LINE_TYPE = 'Line';
const propTypes = {
    actions: PropTypes.object,
    allowFavoriting: PropTypes.bool,
    title: PropTypes.string,
    products: PropTypes.array,
    catalogs: PropTypes.array,
    inSelectionState: PropTypes.bool,
    onMultiSelect: PropTypes.func,
    lease: PropTypes.array,
    siteUrlSlug: PropTypes.string,
    exhibitorId: PropTypes.any,
    labels: PropTypes.object,
    type: PropTypes.string,
    lineId: PropTypes.string,
    siteUrls: PropTypes.object,
    lineName: PropTypes.string,
    shownLabel: PropTypes.string,
    exhibitorName: PropTypes.string,
    fallBackImagePath: PropTypes.string,
    savedContent: PropTypes.object,
    productCount: PropTypes.number,
    labelFloorPlan: PropTypes.object,
    searchResultsLabels: PropTypes.object,
    exhibitorCount: PropTypes.object,
};

const defaultProps = {
    actions: {
    },
    allowFavoriting: false,
    title: 'Exhibitor Name',
    products: [],
    catalogs: [],
    inSelectionState: false,
    onMultiSelect: null,
    lease: [],
    siteUrlSlug: '',
    exhibitorId: '',
    labels: {
        account: {
            loginUrl: 'http://someurl.com',
        },
        exhibitor: {
            productDetailUrl: '/exhibitor/$1/prod/$2',
        },
    },
    type: 'exhibitor',
    lineId: '',
    siteUrls: {},
    lineName: null,
    shownLabel: 'Shown by ',
    exhibitorName: '',
    fallBackImagePath: '',
    savedContent: {},
    productCount: 0,
    labelFloorPlan: {},
    searchResultsLabels: {},
};

/**
 * @method ExhibitorCard
 * @description
 * Display Img, Title, details,etc provided in a card format
 */
class ExhibitorCard extends Component {
    /**
     * Constuctor
     @param {object} props incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            productSliceSize: 4,
            currentProductIndex: 0,
        }

        this.changeImageIndex = this.changeImageIndex.bind(this);
        this.getParams = this.getParams.bind(this);
        this.getActionItem = this.getActionItem.bind(this);
        this.multiSelectChange = this.multiSelectChange.bind(this);
        this.updateMobileView = this.updateMobileView.bind(this);
        this.isMobileOrResponsiveMobile = this.isMobileOrResponsiveMobile.bind(this);

        //datalayer prodImpressions
        ImcDataLayer.PushProductImpressions(props.products.slice(0, this.state.productSliceSize));
    }



    updateMobileView() {
        let productSliceSize;

        if (document.documentElement.clientWidth < 481) {
            productSliceSize = 1;
        } else if (document.documentElement.clientWidth < 1095) {
            productSliceSize = 2;
        }
        else if (document.documentElement.clientWidth < 1294) {
            productSliceSize = 3;
        }
         else {
            productSliceSize = 4;
        }

        this.setState({ productSliceSize });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMobileView);
    }


    componentDidMount() {
        window.addEventListener('resize', this.updateMobileView);

       /* let productSliceSize;

        if (isMobileOnly) {
            productSliceSize = 1;
        } else if (isTablet) {
            productSliceSize = 2;
        } else if (document.documentElement.clientWidth < 1294) {
            productSliceSize = 3;
        } else {
            productSliceSize = 4;
        }
        this.setState({ productSliceSize });*/
        this.updateMobileView();



    }

    isMobileOrResponsiveMobile()
    {
        return document.documentElement.clientWidth < 1294;
    }

    componentDidUpdate(lastProps) {
        if (lastProps.addToListSuccess !== this.props.addToListSuccess) {
            if (this.props.addToListSuccess) {
                this.props.actions.multiSelectReset();
            }
        }
        if (lastProps.selectedItems !== this.props.selectedItems) {
            if (this.multiSelect !== null) {
                this.multiSelect.checked = this.props.selectedItems.some(e => e.id === this.multiSelect.id);
            }
        }
    }

    multiSelectChange() {
        const { exhibitorId, actions, type, lineId } = this.props;
        const actionItem = this.getActionItem();
        const selectionItemId = type === 'Line' ? lineId : exhibitorId;

        actions.itemChanged({ actionItem: actionItem, selected: this.multiSelect.checked, id: `SearchItemThumb${selectionItemId}` });
    }

    changeImageIndex(isIncrementing = true, arrayLength) {
        const { currentProductIndex } = this.state;
        let newProductIndex = currentProductIndex;
        let subtractor = isMobileOnly ? 0 : 1;

        isIncrementing ? newProductIndex++ : newProductIndex--;

        if (newProductIndex >= 0 && newProductIndex < arrayLength - subtractor) {
            this.setState({ currentProductIndex: newProductIndex })
        };
    }

    isLocalMarket(lease) {
        return (typeof window !== 'undefined' && 'channel' in lease && lease.channel.siteCode === window.channel.name);
    }
    getLocalMarket(exhibitor) {
        return exhibitor.activeLeases && exhibitor.activeLeases.filter(this.isLocalMarket);
    }

    getShowrooms(localLeases) {
        let filterdShowroom = [];
        let added = []
        if (!!localLeases) {
            localLeases.map(function (lease, indexa) {
                return (lease.showrooms.map(function (showroom, index) {

                    if (!added.includes(showroom.showroom)) {
                        filterdShowroom.push(showroom);
                        added.push(showroom.showroom);
                    }
                })
                )
            })
        }
        return filterdShowroom;
    }

    /**
     * Gets the product filters to pass into the detail URL
     * @returns {*} Query param to append to the detail page link
     */
    getParams() {
        const { location } = this.props;
        const searchQuery = cleanObjKeys(Object.assign({}, qs.parse(location.search)));
        const params = {};
        if (searchQuery.q) {
            params.q = searchQuery.q;
        }
        if (searchQuery.exhibitor) {
            params.products = searchQuery.exhibitor;
        }

        return Object.keys(params).length > 0 ? `?${serialize(params)}` : '';
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getActionItem() {
        const { exhibitor } = this.props;
        return {
            showroomName: this.props.exhibitorName,
            exhibitorId: exhibitor.exhibitorId,
            lines: (exhibitor.type === "Line") ? [{
                    name: this.props.lineName,
                    lineGuid: exhibitor.uniqueId
                }] : [],
            lastVisited: null,
            photosCount: 0,
        }
    }

    /** Render Exhibitor Card
     * @returns {XML} elements to render
     */
    render() {
        const {
            title,
            exhibitorId,
            type,
            lineId,
            exhibitorName,
            products,
            exhibitor,
            multiSelectMode,
            exhibitorCount,
            plan,
        } = this.props;
        const { currentProductIndex, productSliceSize } = this.state;
        const detailUrl = (type === 'Line') ? getExhibitorLineProductsURL(exhibitorId, lineId) : getExhibitorProductsURL(exhibitorId);
        const exhibitorUrl = getExhibitorURL(exhibitorId);
        const selectionItemId = type === 'Line' ? lineId : exhibitorId;
        const lineUrl = getExhibitorLineURL(exhibitorId, lineId);
        const allLinesUrl = getAllExhibitorLinesURL(exhibitorId);
        const catFav = this.getActionItem();
        const searchItems = [...products];
        const juniperMarketLink = exhibitor.shopZioLink || exhibitor.shopZioUrl;
        let localLease;
        if (exhibitor.companyDetails) {
            localLease = this.getLocalMarket(exhibitor.companyDetails);
        }
        let filterdShowrooms = this.getShowrooms(localLease);
        let hasMobileCookie = false;
        if (typeof document !== "undefined") {
            hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
        }

        let planState = plan.showrooms.filter((showroom) => {
            if (type === 'Line') {
                return showroom.exhibitorId == exhibitorId && showroom.id == lineId;
            } else  {
                return showroom.exhibitorId == exhibitorId;
            }
        });

        return (
            <div
                className="imc-gallery__item imc-exhibitorcard imc-line"
            >
                <div>
                    {/* Title Row Container */}
                    <div className="imc-gallery__item">
                        <div className={`imc-exhibitorcard-title-row ${(exhibitor.productCount > 0 || searchItems.length > 0) ? '' :'no-border-bottom'}`}>
                            {/* Multiselect Card */}
                            <div className="item">
                                {multiSelectMode &&
                                    <div className="imc-padding--right--large">
                                        <input
                                            className="multiSelectItem"
                                            ref={(ref) => { this.multiSelect = ref }} type="checkbox"
                                            id={`SearchItemThumb${selectionItemId}`}
                                            onChange={this.multiSelectChange} />
                                    </div>
                                }
                                <Link href={type === 'Line' ? lineUrl : exhibitorUrl}>
                                    <h2 className={'imc-exhibitorcard__exhibitorname imc-exhibitorcard--title-hover'}>{title}</h2>
                                </Link>
                                {/* Shown By or Lines Shown container */}
                                <div className='imc-exhibitorcard--text-container-row'>
                                    {type === LINE_TYPE ?
                                        // Shown by if line
                                        <p className="imc-exhibitorcard--subtext">
                                            {getDictionaryValue("shownby", "Shown By")}
                                            &nbsp;
                                            <Link className='imc-exhibitorcard--link' href={exhibitorUrl}>{exhibitorName ? exhibitorName : 'Exhibitor Name Not Available'}</Link>
                                        </p>
                                        :
                                        // Lines shown if exhibitor
                                        exhibitorCount?.lineCount > 0 && type !== LINE_TYPE &&
                                        <>
                                            <p
                                                className="imc-exhibitorcard--subtext"
                                            >
                                                {getDictionaryValue("linesShown", `${exhibitorCount?.lineCount} Lines Shown`)}
                                                &nbsp;
                                            </p>
                                            <Link className='imc-exhibitorcard--link' href={allLinesUrl}>See All</Link>
                                        </>}
                                </div>
                            </div>
                            <div className='imc-exhibitorcard--text-container-col'>
                                {/* Location */}
                                <div className="imc-exhibitorcard--text-wrapper">
                                    <p className="imc-exhibitorcard--subtext"><span className="imc-icon-Location imc-margin--right--xxsmall imc-icon--center"></span></p>
                                    {filterdShowrooms &&
                                        filterdShowrooms.map(function (showroom, index) {
                                            return (
                                                <div key={index}>
                                                    {!hasMobileCookie && <Link href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link'}>
                                                        {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)}
                                                    </Link>}
                                                    {hasMobileCookie && <a href={getFloorPlanURL(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)} className={'imc-exhibitorcard--link'}>
                                                        {getBooth(showroom.showroomBuildingName, showroom.showroomFloor, showroom.showroom)}
                                                    </a>}
                                                    {index + 1 < filterdShowrooms.length && <>,&nbsp;</>}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                {/* Action Icons */}
                                {showMarketPlan(this.props) && <div className="imc-exhibitorcard-actions-bar">
                                    <ul className={'actions-bar'}>
                                        <li className='imc-exhibitorcard--action imc-exhibitorcard--contact imc-exhibitorcard--contact--heart'>
                                            <MarketPlanButton
                                                item={catFav}
                                                lineGuid={(type === 'Line') ? lineId : null}
                                                extraClassName={`imc-button--atmarket--icon-only--tablet`}
                                                wrapperClassName={` imc-button--atmarket--top-right--mobile`}
                                            />
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Title Card */}

                {/* Images */}
                {(exhibitor.productCount > 0 || searchItems.length > 0) && <div className="imc-gallery imc-gallery--80-20">
                    {/* if has products */}
                    <div
                        className="imc-gallery__item imc-gallery__item--prod imc-content--display-flex imc-content--display-flex-self-stretch"
                    >
                        {(searchItems.length > 0) && (
                            <div style={{ width: '100%' }}>
                                <div className={`imc-exhibitorcard-products-row imc-content--display-flex-space-between-mobile ${searchItems.length < 4 ? '' : ''}`}>
                                <div className="imc-exhibitorcard--button-container">{this.isMobileOrResponsiveMobile() && (currentProductIndex > 0) &&  <LeftArrowButton
                                        className='imc-exhibitorcard--svg-button-start'
                                        onClick={() => this.changeImageIndex(false, products.length)}
                                    />}</div>
                                    {searchItems.slice(currentProductIndex, currentProductIndex + productSliceSize).map((item, ix) => {
                                        const { lineDetail } = this.props;
                                        let index = 2;
                                        let isLine = false;
                                        let productId = item.type == "Catalog" ? item.productId : item.uniqueId;//Catalogs are returning an empty uniqueId. remove after fix from BE
                                        if (lineDetail) {
                                            index = 4;
                                            isLine = true;
                                        }

                                        const catFav = {
                                            itemId: type.toLowerCase() == 'line' ? lineId : exhibitorId,
                                            itemType: this.capitalizeFirstLetter(type),
                                            label: item.exhibitorName,
                                            contentName: '',
                                            itemContents: [{
                                                contentName: item.productTitle,
                                                itemId: productId,
                                                itemType: item.type.toLowerCase(),
                                                deleted: true,
                                            }],
                                        };
                                        const imagePath = item.images && item.images.length > 0 ? encodeURI(item.images[0].fullPath) : null;
                                        const catalogUrl = getExhibitorCatalogURL(exhibitorId, productId);
                                        const productUrl = type === 'Line' ? getExhibitorLineProductURL(exhibitorId, lineId, productId) : getExhibitorProductURL(exhibitorId, productId);
                                        return (
                                            <NewSearchItemThumb
                                                key={item.uniqueId}
                                                url={item.type == "Catalog" ? catalogUrl : productUrl}
                                                image={imagePath}
                                                newWindow={false}
                                                showActionBar={true}
                                                actionItem={catFav}
                                                name={item.productTitle}
                                                itemID={productId}
                                                product={item}
                                                isNew={item.newProductForMarket}
                                                multiSelectMode={multiSelectMode}
                                                style={ix==0?"grid-column-start: 2;":""}
                                            />
                                        )
                                    })}
                                    <div className="imc-exhibitorcard--button-container">
                                    {this.isMobileOrResponsiveMobile() && (currentProductIndex < products.length-productSliceSize) &&  <RightArrowButton
                                        className='imc-exhibitorcard--svg-button-end'
                                        onClick={() => this.changeImageIndex(true, products.length)}
                                    />}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="imc-exhibitorcard-button-wrapper">
                        <div className='imc-exhibitorcard--mobile-width-max'>
                            {/* Matching Products */}
                            {/* only show matching product count if exhibitor has any products so it can be 0 */}
                            {exhibitor.productCount > 0 && searchItems.length > 0 && (
                                <div className='imc-exhibitorcard--prod-item-container imc-exhibitorcard--prod-item-container--divider'>
                                    <p className="imc-exhibitorcard--subtext imc-exhibitorcard--subtext--prod">
                                        {getDictionaryValue("matchingProducts", `${exhibitor.matchedProductsCount} Matching Products`)}
                                    </p>
                                    {/* only show this button if the matching product count is greater than 0 */}
                                    {exhibitor.matchedProductsCount > 0 && (<p
                                        className='imc-exhibitorcard--link imc-exhibitorcard--link--prod'
                                        onClick={() => { this.props.history.push(detailUrl + this.getParams()) }}
                                    >
                                        See All
                                    </p>)}
                                </div>)}
                            {/* Total Products */}
                            {exhibitor.productCount > 0 && (
                                <div className='imc-exhibitorcard--prod-item-container'>
                                    <p className="imc-exhibitorcard--subtext imc-exhibitorcard--subtext--prod">{`${exhibitor.productCount} Total Products`}</p>
                                    <p
                                        className='imc-exhibitorcard--link imc-exhibitorcard--link--prod'
                                        onClick={() => this.props.history.push(detailUrl)}
                                    >
                                        See All
                                    </p>
                                </div>)}
                        </div>
                        {/* Juniper Market Button */}
                        {juniperMarketLink && juniperMarketLink.length > 0 && isMarketCtaShouldBeShown(this.props) &&(
                            <a
                                href={juniperMarketLink}
                                target='_blank'
                                className={'imc-button--atmarket imc-exhibitorcard--mobile-margin'}
                                onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', juniperMarketLink)}
                            >
                                {getDictionaryValue("orderOnShopZio", "Connect on @Market")}
                            </a>
                        )}
                    </div>
                </div>}
            </div>
        );
    }
}


function mapStateToProps(state) {
    let _state = {
        addToListSuccess: state.isSuccess,
    };
    if (state.multiSelectReducer) {
        _state.selectedItems = state.multiSelectReducer.selectedItems;
        _state.multiSelectMode = state.multiSelectReducer.multiSelectMode;
    }
    _state.plan = state.marketPlanReducer;
    return _state;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...multiSelectActions }, dispatch),
    };
}

ExhibitorCard.propTypes = propTypes;
ExhibitorCard.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(ExhibitorCard)));
