import React from "react";
import {
    withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";

// reducers
import digitalShowroomRelatedProductsOverviewReducer from "../reducers/relatedProductOverviewReducer";
// action imports
import * as dataActions from "../actions/relatedProductOverviewActions";

//modules
import ProductsStripe from 'modules/productsStripe';
//utils
import { locationIsLine } from 'utils/exhibitor'
import { getDictionaryValue } from "utils/dictionary/index";

const digitalShowroomState = createAppState();

class RelatedProductsOverview extends React.Component {
    constructor(props) {
        super(props);
        digitalShowroomState.reducerRegistry.register({
            digitalShowroomRelatedProductsOverviewReducer,
        });
        this.fetchData = this.fetchData.bind(this);
    }

    /**
   * Kick off bound fetch data
   */
    componentDidMount() {

        this.fetchData();
    }

    // componentDidUpdate(lastProps) {
    //     if (lastProps.productCategories !== this.props.productCategories
    //         || lastProps.exhibitorId !== this.props.exhibitorId
    //         || lastProps.lineId !== this.props.lineId) {
    //         this.fetchData();
    //     }
    // }

    /**
   * Fetch the article data
   */
    fetchData() {
        const { productCategories, exhibitorId, lineId } = this.props;
        if (productCategories !== null) {
            if (locationIsLine()) {
                if (lineId)
                    this.props.dataActions.getDigitalShowroomLineRelatedProductsOverview(lineId, productCategories);
            }
            else {
                if (exhibitorId)
                    this.props.dataActions.getDigitalShowroomRelatedProductsOverview(exhibitorId, productCategories);
            }
        }
    }

    render() {
        const { products, location } = this.props;

        return (
            <div className="imc-product-details--section imc-section--neutral-light">
                <div className="imc-section imc-section--width-100-percent">
                    <ProductsStripe
                        products={products}
                        location={location}
                        compact={true}
                        title={getDictionaryValue('relatedProducts', 'Other Products You May Be Interested In')}
                    ></ProductsStripe>
                </div>
            </div>
        );
    }
}
const defaultProps = {
    dataActions: {
        getDigitalShowroomContactInfo: () => { },
    },
    products: [],
    productCategories: null,
    lineId: '',
    exhibitorId: '',
};
const propTypes = {
    productCategories: PropTypes.array,
    dataActions: PropTypes.object,
    products: PropTypes.array,
    lineId: PropTypes.any,
    exhibitorId: PropTypes.any,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
    let _props = {};
    if (state.digitalShowroomRelatedProductsOverviewReducer)
        Object.assign(_props, {
            products: state.digitalShowroomRelatedProductsOverviewReducer.products,
        });
    return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
    };
}

RelatedProductsOverview.propTypes = propTypes;
RelatedProductsOverview.defaultProps = defaultProps;

export default withSitecoreContext()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(RelatedProductsOverview))
);