import { Environment } from './Environment';

export class GlobalData {
    sitecoreJssAppName;
    sitecoreApiKey;
    sitecoreApiHost;
    sitecoreDefaultLanguage;
}


export function getGlobalData() {
    return window.app;
}

export function getSitecoreApiKey() {
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY : getGlobalData().sitecoreApiKey;
}

export function getGtmEnvrionmentName() {
    return Environment.isServer ? Environment.reactAppProcessEnv.GTM_ENVIRONMENT_NAME : getGlobalData().gtmEnvironmentName;
}

export function getSitecoreApiHost() {

    //hack - replaced getGlobalData().sitecoreApiHost with new function to use window.origin. 
    // return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
    //return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
    //hack# added if statement in order to avoid local development 404 error in pages.
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
}
export function getSitecoreLayoutApiHost() {

    //hack - replaced getGlobalData().sitecoreApiHost with new function to use window.origin. 
    // return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
    //return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
    //hack# added if statement in order to avoid local development 404 error in pages.
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : getSitecoreReactApiHost(); 
}

export function getSitecoreDefaultLanguage() {
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_DEFAULT_LANGUAGE : getGlobalData().sitecoreDefaultLanguage;
}

export function getSitecoreAppName() {
    return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME : getGlobalData().sitecoreJssAppName;
}

export function getSitecoreReactApiHost() {
    try {
        if (typeof window !== "undefined" && !(window.location.origin.indexOf('localhost') > -1))
            return window.location.origin;
        else
            return getGlobalData().sitecoreApiHost;
    }
    catch
    {
        return getGlobalData().sitecoreApiHost;
    }
}

export function getSitecoreDictionaryServiceRoute() {
    let sitecoreApiHost, sitecoreJssAppName, sitecoreApiKey;
    if (Environment.isServer) {
        sitecoreApiHost = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST;
        sitecoreJssAppName = Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME;
        sitecoreApiKey = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY;
    } else {
   const globalData = getGlobalData();
        sitecoreApiHost = ''; // use proxy on current domain, to prevent CORS issues
        if(window.location.origin.indexOf('localhost') > 0) {
            sitecoreApiHost = getGlobalData().sitecoreApiHost;
        }
        sitecoreJssAppName = globalData.sitecoreJssAppName;
        sitecoreApiKey = globalData.sitecoreApiKey;
    }
    return `${sitecoreApiHost}/sitecore/api/jss/dictionary/${sitecoreJssAppName}/{{lng}}?sc_apikey=${sitecoreApiKey}`;
}

export function getSitecoreGraphqlEndpoint() {
    let sitecoreApiHost, sitecoreJssAppName, sitecoreApiKey;
    if (Environment.isServer) {
        sitecoreApiHost = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST;
        sitecoreJssAppName = Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME;
        sitecoreApiKey = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY;
    } else {
        const globalData = getGlobalData();
        sitecoreApiHost = globalData.sitecoreApiHost;
        sitecoreJssAppName = globalData.sitecoreJssAppName;
        sitecoreApiKey = globalData.sitecoreApiKey;
    }
    const url = `${sitecoreApiHost}/api/${sitecoreJssAppName}?sc_apikey=${sitecoreApiKey}`;
    return url;
}