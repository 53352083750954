import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router';

import { ImageTeaser } from 'modules/imageTeaser'
import { updateUrl } from 'utils/tagupdate';
import ReactModal from 'react-modal';

const defaultProps = {
    location: {
        search: true,
    },
    filterKey: 'articles',
    tagKey: 'tags',
    isApiProps: false
};

class ImageCardComponent extends React.Component {
    /**
  * @method contructor
  * @description Lifecycle method
  * @param {object} props incoming props
  */
    constructor(props) {
        super(props);
        this.state = {
            expandedView: false,
        };
        this.tagClick = this.tagClick.bind(this);
        this.setExpanded = this.setExpanded.bind(this);
    }

    /**
     * Open the modal
     * @param {string} expanded the image to show in the modal
     */
    setExpanded(expandedView) {
        this.setState({
            expandedView,
        });
    }

    tagClick(tag) {
        const { history, match, location, filterKey, tagKey } = this.props;
        updateUrl(tag, history, match, location, filterKey, tagKey);
    }

    render() {
        const { fields, isApiProps } = this.props;
        const { expandedView } = this.state;
        if (!fields && !isApiProps) {
            if (isExperienceEditorActive()) {
                return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
                <h1 className="alarm">
                    Data is not provided. Contact administrators, please.
          </h1>
            );
        }
        let props = {
            tagClick: this.tagClick,
        }
        props = {
            ...props,
            ...(isApiProps ? {
                altText: this.props.imageAlt,
                description: this.props.description == null ? '' : this.props.description,
                imagePath: this.props.imageSrc,
                title: this.props.title == null ? '': this.props.title,
                expandedView: this.state.expandedView,
                imageObject: null,
                filename: this.props.imageSrc,
                keys: this.props.tags,
                setExpanded: this.setExpanded
            } : {
                altText: fields.image == null?'Image not set':fields.image.value.alt,
                description: fields.imageCardDescription == null ? '' : fields.imageCardDescription.value,
                imagePath: fields.image == null?'':fields.image.value.src,
                title: fields.imageCardTitle == null ? '':fields.imageCardTitle.value,
                expandedView: this.state.expandedView,
                imageObject: fields.image,
                filename: fields.image == null? 'Image not set':fields.image.value.src,
                keys: fields.tags.map(item => { return item.fields.name == null?'':item.fields.name.value }),
                setExpanded: this.setExpanded
            })
        };
        return (
            <div className={'imc-articlecard--border imc-articlecard imc-gallery__item imc-imageteaser '}>
                {expandedView && (
                    <ReactModal
                        isOpen
                        overlayClassName="imc-modal--overlay"
                        bodyOpenClassName="ReactModal__Body--open ReactModal__Body--image-gallery"
                        className={'imc-modal--box' +
                            ' imc-carousel__modal imc-carousel__modal--landing imc-landingpage__modal ReactModal__Body--image-gallery'}
                    >
                        <button
                            className={'imc-modal--close imc-addnote-modal__close'}
                            onClick={() => this.setExpanded(!this.state.expandedView)}
                        >
                        X
                        </button> 
                        <ImageTeaser {...props} />
                    </ReactModal>
                )}
                <ImageTeaser {...props} />
            </div>
        );
    }

}

ImageCardComponent.defaultProps = defaultProps;

export default withRouter(ImageCardComponent);