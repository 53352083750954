import React from 'react';

interface SvgIconProps {
    xlinkHref: string;
    width?: number;
    height?: number;
    className?: string;
    useClassName?: string;
}

const SvgIcon: React.FC<SvgIconProps> = ({
    xlinkHref,
    width = 24,
    height = 24,
    className = '',
    useClassName = '',
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            className={className}
        >
            <use xlinkHref={xlinkHref} className={useClassName} />
        </svg>
    );
};

export default SvgIcon;