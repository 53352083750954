import React from 'react';
import { isExperienceEditorActive, } from "@sitecore-jss/sitecore-jss-react";
import Building from "./components/Building.jsx";
import Pavilions from "./components/Pavilions.jsx";
import cloneDeep from 'lodash/cloneDeep';
import { sortAscElements, sortDescElements } from "./utils";
import { getDictionaryValue } from 'utils/dictionary/index.js';
import Link from '../Link';

const MAX_DESKTOP = 992;

class CampusViewComponent extends React.Component {

  constructor(props) {
    super(props);
    this.addWalkwaysInfo = this.addWalkwaysInfo.bind(this);
    this.updateMobileView = this.updateMobileView.bind(this);
    this.structureClick = this.structureClick.bind(this);
    this.resetSelectedStructure = this.resetSelectedStructure.bind(this);
    this.prevStructureClick = this.prevStructureClick.bind(this);
    this.nextStructureClick = this.nextStructureClick.bind(this);
    this.getPrevStructureId = this.getPrevStructureId.bind(this);
    this.getNextStructureId = this.getNextStructureId.bind(this);
    this.state = {
      mobileView: false,
    }

  }

  /**
 * Adds an event listener to set the mobile view
 */
  componentDidMount() {
    window.addEventListener('resize', this.updateMobileView);
    this.updateMobileView();
  }

  updateMobileView() {
    this.setState({
      mobileView: document.documentElement.clientWidth < MAX_DESKTOP,
    });
  }
  isBuilding(structure) {
    return 'floors' in structure.fields;
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMobileView);
  }

  /********
   * 
   * Methods to preprocess buildings and get sidewalk information.
   */
  getWalkwayLabelFloorId(buildings, building) {
    const _b = cloneDeep(building);
    const _buildings = cloneDeep(buildings).sort(sortAscElements);
    const hasRightBuilding = _buildings.length > 1 && parseInt(building.fields.order.value) < parseInt(_buildings[_buildings.length - 1].fields.order.value);
    let floorId = null;
    if (hasRightBuilding) {
      const _f = cloneDeep(_b.fields.floors).sort(sortDescElements);
      for (let floorIndex = 0; floorIndex < _f.length - 1; floorIndex++) {
        if (_f[floorIndex].fields.rightWalkways.value !== '') {
          if (floorIndex > 1)
            floorId = _f[floorIndex - 1].id;
          else
            floorId = -1;
          break;
        }
      }
      return floorId;
    }
  }


  getFloorLevel(building, floorId) {
    const floors = cloneDeep(building.fields.floors).sort(sortAscElements);
    if (floors.length > 0) {
      let floorLevel = parseInt(floors[0].fields.order.value) - 1;
      for (let floorIndex = 0; floorIndex < floors.length; floorIndex++) {
        const _floor = floors[floorIndex];
        const _size = isNaN(_floor.fields.size.value) ? 1 : parseInt(_floor.fields.size.value);
        floorLevel += _size;
        if (_floor.id.toLowerCase() === floorId)
          return floorLevel;
      }
    }
  }
  findFloor(buildings, floorId) {
    let resp = {};
    for (let buildingIndex = 0; buildingIndex < buildings.length; buildingIndex++) {
      const building = buildings[buildingIndex];
      for (let floorIndex = 0; floorIndex < building.fields.floors.length; floorIndex++) {
        const floor = building.fields.floors[floorIndex];
        if (floor && floor.id.toLowerCase() === floorId) {
          resp = {
            title: `${building.fields.walkwayCode.value}-${floor.fields.number.value}`,
            floorLevel: this.getFloorLevel(building, floor.id),
          }
          break;
        }
      }
    }
    return resp;
  }
  getWalkway(buildings, building, floor, walkwayFloorId) {
    if (walkwayFloorId === '')
      return null;
    const _wwFloorId = walkwayFloorId.replace(/^\{+|\}+$/g, '').toLowerCase();
    const walkwayFloor = this.findFloor(buildings, _wwFloorId)
    const currentFloorLevel = this.getFloorLevel(building, floor.id);
    if (walkwayFloor) {
      return {
        level: (walkwayFloor.floorLevel < currentFloorLevel ? 'up' : walkwayFloor.floorLevel > currentFloorLevel ? 'down' : ''),
        title: walkwayFloor.title,
      }
    }
    return null;
  }
  addWalkwaysInfo(building) {
    const buildings = this.props.fields.buildingsAndPavilions.filter(this.isBuilding);
    let _b = cloneDeep(building);
    const walkwayLabelFloorId = this.getWalkwayLabelFloorId(buildings, building);
    _b.fields.roofWalkwayLabel = walkwayLabelFloorId === -1;
    const _floors = Array.isArray(building.fields.floors) ? building.fields.floors.map(floor => {
      let _f = cloneDeep(floor);
      _f.fields.hasWalkwayLabel = walkwayLabelFloorId && floor.id === walkwayLabelFloorId;

      const lw = floor.fields.leftWalkways.value ? floor.fields.leftWalkways.value.split('|') : [];
      _f.fields.leftWalkways = Array.isArray(lw) ? lw.map(walkwayFloorId => this.getWalkway(buildings, building, floor, walkwayFloorId)) : [];
      const rw = floor.fields.rightWalkways.value ? floor.fields.rightWalkways.value.split('|') : [];
      _f.fields.rightWalkways = Array.isArray(rw) ? rw.map(walkwayFloorId => this.getWalkway(buildings, building, floor, walkwayFloorId)) : [];
      return _f;
    }) : [];
    _b.fields.floors = _floors;
    return _b;
  }
  /******** */

  /***
   * Methods for mobile view
   * 
   */
  findParentWithClass(element, classname) {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) return element;
    return element.parentNode ? this.findParentWithClass(element.parentNode, classname) : null;
  }

  structureClick(event) {
    const parent = this.findParentWithClass(event.target, 'imc-campus-view--clickeable');
    this.setState({
      selectedStructure: parent ? parent.id : undefined,
    })
  }
  resetSelectedStructure() {
    this.setState({
      selectedStructure: undefined,
    })
  }
  prevStructureClick(event, structurId) {
    if (!event) var event = window.event
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();

    const sId = event.target ? event.target.dataset.structureid : structurId;
    const id = this.getPrevStructureId(sId);
    this.setState({
      selectedStructure: id,
    })
  }
  nextStructureClick(event, structurId) {
    if (!event) var event = window.event
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();

    const sId = event.target ? event.target.dataset.structureid : structurId;
    const id = this.getNextStructureId(sId);
    this.setState({
      selectedStructure: id,
    })
  }
  getPrevStructureId(structureId) {
    const orderedBuildingsAndPavilions = cloneDeep(this.props.fields.buildingsAndPavilions).sort(sortAscElements);
    let id = undefined;
    for (let structureIndex = 0; structureIndex < orderedBuildingsAndPavilions.length; structureIndex++) {
      const structure = orderedBuildingsAndPavilions[structureIndex];
      if (structure.id === structureId) {
        if (structureIndex > 0) {
          id = orderedBuildingsAndPavilions[structureIndex - 1].id;
          break;
        }
        else {
          id = orderedBuildingsAndPavilions[orderedBuildingsAndPavilions.length - 1].id;
          break;
        }
      }
    }
    return id;
  }
  getNextStructureId(structureId) {
    const orderedBuildingsAndPavilions = cloneDeep(this.props.fields.buildingsAndPavilions).sort(sortAscElements);
    let id = undefined;
    for (let structureIndex = 0; structureIndex < orderedBuildingsAndPavilions.length; structureIndex++) {
      const structure = orderedBuildingsAndPavilions[structureIndex];
      if (structure.id === structureId) {
        if (structureIndex < (orderedBuildingsAndPavilions.length - 1)) {
          id = orderedBuildingsAndPavilions[structureIndex + 1].id;
          break;
        }
        else {
          id = orderedBuildingsAndPavilions[0].id;
          break;
        }
      }
    }
    return id;
  }
  formatBytes(a, b = 2) { if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] }

  render() {
    const { fields } = this.props.rendering;
    if (!fields) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    const { mobileView, selectedStructure } = this.state;
    const orderedBuildingsAndPavilions = fields.buildingsAndPavilions.map(structure =>
      (this.isBuilding(structure)) ? this.addWalkwaysInfo(structure) : structure).sort(sortAscElements);
    return <>
      <div className="imc-campus-view">
        {
          mobileView &&
          <div className="imc-campus-view-mobiletitle">
            {getDictionaryValue('chooseBuildingToExplore', 'Choose a Building to Explore')}
          </div>
        }
        {orderedBuildingsAndPavilions.map((structure, index) => {
          return this.isBuilding(structure) ? <Building id={structure.id} {...structure.fields} mobileView={mobileView} selectedStructure={selectedStructure} onClick={this.structureClick} prevClick={this.prevStructureClick} nextClick={this.nextStructureClick} resetSelectedStructure={this.resetSelectedStructure} key={index}></Building> : <Pavilions id={structure.id} {...structure.fields} mobileView={mobileView} selectedStructure={selectedStructure} onClick={this.structureClick} prevClick={this.prevStructureClick} nextClick={this.nextStructureClick} resetSelectedStructure={this.resetSelectedStructure} key={index}></Pavilions>
        })}
      </div>
      {(fields.downloadPDF && fields.downloadPDF.value) &&
        <div className="imc-content--display-flex imc-content--display-flex-center imc-content--display-flex-space-around imc-padding--top--jumbo imc-padding--bottom--jumbo">
          <Link className="imc-button" download field={fields.downloadPDF}>{`${fields.downloadPDF.value.title} (${fields.downloadPDF.value.extension.toUpperCase()},${this.formatBytes(fields.downloadPDF.value.size)})`}</Link>
        </div>
      }
    </>
  }
}
export default CampusViewComponent;
