import React from 'react';
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import { Link as RouterLink } from 'react-router-dom';

class Link extends React.Component {
    
    constructor(props) {
        super(props);    
        this.render=this.render.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    
    handleOnClick(e){
        if (!!this.props.onClick){
            this.props.onClick(e);
        }
    }

    render ()
    {
        const hasValidHref = (this.props.field && this.props.field.value && this.props.field.value.href) || this.props.href;
        const isEditing = this.props.editable && this.props.field.editable;

        if(hasValidHref && !isEditing) {
            var value;
            if(this.props.field)
                value = this.props.field.value;
            else 
                value = {
                        href : this.props.href,
                        target : this.props.target,
                        title : this.props.title
                };

            if(value.href.startsWith('/')) {
                return (
                    <RouterLink onClick={this.handleOnClick} to={value.href} title={value.title} target={value.target} className={this.props.className} style={this.props.style}>
                    {this.props.children || value.text || value.href}
                    </RouterLink>
                );
            }
        }
        if(this.props.field)
            return <SitecoreLink {...this.props} />;
        else //recreate props from href and other values
            return <SitecoreLink {... this.props} field={ value } />
    }
}

// usage - drop-in replacement for JSS' Link component
export default Link;