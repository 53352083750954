import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router";
import UserUtility from 'utils/userutility';
import { connect } from 'react-redux';
import { RichText, isExperienceEditorActive  } from '@sitecore-jss/sitecore-jss-react';
import { showMarketPlan } from 'utils/general';
import { toggleDrawerState } from 'modules/marketplan/actions/actions'

// solution was based on this post
// https://kamsar.net/index.php/2018/09/Routing-Sitecore-links-with-JSS/

class RichTextSafe extends React.Component {

    constructor(props) {
        super(props);
        this.linkRouteHandler = this.linkRouteHandler.bind(this);
        this.linkHashHandler = this.linkHashHandler.bind(this);
    }
    
    componentDidMount() {
        this.addLinkEventListeners();
        this.linkHashHandler(false);
    }
    componentWillUnmount() {
    }
    componentDidUpdate() {
        this.addLinkEventListeners();
    }
    componentWillReceiveProps() {
        this.linkHashHandler();
    }

    linkRouteHandler(event) {
        // let click with CTRL / SHIFT / META (for OSX) open in new Tab / Window / tab
        if (event.currentTarget.href.toLowerCase().includes('sitecoreidentityserver')) {
            // stop default click
            event.preventDefault();
            // redirect to login with query params
            UserUtility.redirectToLogin();
            return;
        }
        if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
            // stop default click
            event.preventDefault();
            // send to react router
            this.props.dispatch(toggleDrawerState(false));
            let queries = (event.currentTarget.href.includes('?')) ? '?' + event.currentTarget.href.split('?').pop() : '';
            if (event.currentTarget.href.toLowerCase().includes("exhibitorId".toLowerCase()) && showMarketPlan(this.props)) {
                this.props.history.replace(event.currentTarget.pathname + queries);
            } else {
                this.props.history.push(event.currentTarget.pathname + queries);
            }
        }
    }

    addLinkEventListeners() {
        const hasText = this.props.field && this.props.field.value;
        if (hasText && !isExperienceEditorActive()) {
            // get all <a> elements in the rich text element through the DOM
            const thisNode = ReactDOM.findDOMNode(this);
            const links = thisNode.querySelectorAll('a[href^="?"], a[href^="/"], a[href*="//' + window.location.host + '" i], a[href*="//www.' + window.location.host + '" i]');
            // loop through links and add event listener
            links.forEach((link) => {
                // if href has these substrings
                const alternativeLinkSubstrings = ['tel:', 'mailto:', 'sms:', 'callto:'];
                if (alternativeLinkSubstrings.some(v => link.pathname.includes(v))) return;
                // if opening in new tab
                if (link.hasAttribute('target') && link.target.toLowerCase() == '_blank') return;
                // if <a> has download attribute
                if (link.hasAttribute('download')) return;
                // if link is to identity server
                // if (link.href.toLowerCase().includes('sitecoreidentityserver')) return;
                // clear any previous event listeners and add this one
                link.removeEventListener('click', this.linkRouteHandler, false);
                link.addEventListener('click', this.linkRouteHandler, false);
            });
        }
    }

    linkHashHandler = (verifyScroll = true) => {
        // if hash in url
        if (window.location && window.location.hash) {
            const hash = window.location.hash.replace("#","");
            // if element with id equal to hash then scrollto
            const node = ReactDOM.findDOMNode(this);
            if (node) {
                const elements = node.querySelectorAll(`*[id='${hash}']`)
                if (elements.length > 0) {
                    const currentLocation = window.scrollY;
                    setTimeout(() => {
                        const element = elements[0];
                        const top = element.getBoundingClientRect().top + window.scrollY;
                        if (element && !(currentLocation != window.scrollY && verifyScroll)) {
                            window.scrollTo(0, top);
                        }
                    }, 100, currentLocation);
                }
            }
        }
    }

    render() {
        const { fields } = this.props;
        return (
            <RichText className={this.props.className} field={!!fields && fields.content} innerref={this.ref} />
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}


export default withRouter(connect(null, mapDispatchToProps)(RichTextSafe));