/**
 * Initial state for the store
 * @type {{}}
 */
export const InitialState = {
    predefinedSearch: {},
    exhibitorData: {
        directory: {
            exhibitorInfo: [],
            resultsUpToDate: false,
            searchQuery: null,
            showLoading: false,
            errCode: null,
            numResults: '',
        },
        filterData: {
            filters: null,
        },
        exhibitorSort: {
            sort: null,
        },
        exhibitorJumpTo: {
            jumpto: null,
            err: null,
        },
        savedSearches: {},
    },
};
