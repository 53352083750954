import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {withSitecoreContext, Text} from '@sitecore-jss/sitecore-jss-react';
import {NavLink, useHistory} from 'react-router-dom';
import qs from 'query-string';
import {serialize, serializeTags} from 'utils/serialize';
import {filterquerymodifier} from 'utils/filterquerymodifier';
import {resetFilter} from 'utils/search';
import {osName} from 'react-device-detect';

const SEARCH_PAGE = 'search';
const DIGITAL_SHOWROOM_PAGE = 'exhibit';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.tabClick = this.tabClick.bind(this);
    }

    componentDidUpdate(lastProps) {
        if (lastProps.location !== this.props.location) {
        }
    }

    renderCount(type, data) {
        if (typeof data !== 'undefined')
            switch (type) {
                case 'Exhibitors':
                    return data.exhibitorsCount > 0 ? ` (${data.exhibitorsCount})` : null;
                case 'Products':
                    return data.prodCount > 0 ? ` (${data.prodCount})` : null;
                case 'Catalogs':
                    return data.catCount > 0 ? ` (${data.catCount})` : null;
                case 'Lines':
                    return data.lineCount > 0 ? ` (${data.lineCount})` : null;
                case 'Events':
                    return data.eventsCount > 0 ? ` (${data.eventsCount})` : null;
                case 'Information':
                    return data.informationCount > 0
                        ? ` (${data.informationCount})`
                        : null;
                case 'Articles':
                    return data.articlesCount > 0 ? ` (${data.articlesCount})` : null;
                case 'Specials':
                    return data.specialsCount > 0 ? ` (${data.specialsCount})` : null;
            }
        return '';
    }

    countIsZero(type, data) {
        if (typeof data !== 'undefined')
            switch (type) {
                case 'Exhibitors':
                    return (
                        typeof data.exhibitorsCount === 'undefined' ||
                        data.exhibitorsCount === 0
                    );
                case 'Products':
                    return typeof data.prodCount === 'undefined' || data.prodCount === 0;
                case 'Catalogs':
                    return typeof data.catCount === 'undefined' || data.catCount === 0;
                case 'Lines':
                    return typeof data.lineCount === 'undefined' || data.lineCount === 0;
                case 'Events':
                    return (
                        typeof data.eventsCount === 'undefined' || data.eventsCount === 0
                    );
                case 'Information':
                    return (
                        typeof data.informationCount === 'undefined' ||
                        data.informationCount === 0
                    );
                case 'Articles':
                    return (
                        typeof data.articlesCount === 'undefined' ||
                        data.articlesCount === 0
                    );
                case 'Specials':
                    return (
                        typeof data.specialsCount === 'undefined' ||
                        data.specialsCount === 0
                    );
            }
        return true;
    }

    tabClick(itemId, exhibitID, lineID, type) {
        const {setActive, setHistory, location} = this.props;
        setActive(itemId);
        const isLine = lineID != null;
        const isDigitalShowroomPage =
            this.props.searchType === DIGITAL_SHOWROOM_PAGE;

        const url = isDigitalShowroomPage
            ? isLine
                ? `/exhibitor/${exhibitID}/line/${lineID}/${type.toLowerCase()}`
                : `/exhibitor/${exhibitID}/${type.toLowerCase()}`
            : `/search/${type.toLowerCase()}`;
        setHistory(url);
    }

    /**
     * Renders a single tab item
     * @param {number} index tab index
     * @param {string} heading Heading text
     * @param {function} onSelect Method to call when selected
     * @param {boolean} active Flag to determine active or inactive tab
     * @returns {XML} Tab markup
     */

    renderTab(
        itemId,
        fields,
        onSelect,
        active,
        path,
        setHistory,
        index,
        tabsCount
    ) {
        let title;
        if (fields != null && fields.title != null) {
            title = fields.title.value;
        }
        const type = fields.type.value;
        const exhibitID = this.props.exhibitorID;
        const lineID = this.props.lineID;
        let qsParsed = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        delete qsParsed.products;
        if (type) qsParsed = resetFilter(type, qsParsed);

        let querystring = serializeTags(qsParsed);
        querystring = filterquerymodifier(querystring);
        if (tabsCount === 1) {
            return <h2>{title}</h2>;
        }

        if (active) {
            return (
                <div
                    key={index}
                    id={type}
                    className='imc-tabs-nav__item imc-tabs-nav__item--digital imc-tabs-nav__item--digital--active imc-content--beta imc-padding--top--none imc-padding--bottom--xsmall imc-padding--left--micro imc-padding--right--micro  imc-margin--right--xlarge'
                >
                    {this.props.sitecoreContext.pageEditing ? (
                        <Text field={fields.title}></Text>
                    ) : (
                        title
                    )}
                    {this.renderCount(type, this.props.tabData)}
                </div>
            );
        }

        if (this.countIsZero(type, this.props.tabData)) {
            return (
                <div
                    key={index}
                    id={type}
                    className='imc-tabs-nav__item imc-tabs-nav__item--digital imc-tabs-nav__item--digital--disabled imc-content--beta imc-padding--top--none imc-padding--bottom--xsmall imc-padding--left--micro imc-padding--right--micro  imc-margin--right--xlarge'
                >
                    {this.props.sitecoreContext.pageEditing ? (
                        <Text field={fields.title}></Text>
                    ) : (
                        title
                    )}
                    {this.renderCount(type, this.props.tabData)}
                </div>
            );
        }

        if (!this.props.sitecoreContext.pageEditing) {
            return (
                <NavLink
                    id={type}
                    onClick={() => this.tabClick(itemId, exhibitID, lineID, type)}
                    onKeyDown={(e) => onSelect(itemId, e)}
                    className={`imc-tabs-nav__item imc-tabs-nav__item--digital imc-tabs-nav__item--digital-link imc-link imc-link--alt imc-content--beta imc-padding--top--none imc-padding--bottom--xsmall imc-padding--left--none imc-padding--right--none  imc-margin--right--xlarge`}
                    to={
                        this.props.searchType === DIGITAL_SHOWROOM_PAGE
                            ? lineID != null
                                ? `/exhibitor/${exhibitID}/line/${lineID}/${type.toLowerCase()}`
                                : `/exhibitor/${exhibitID}/${type.toLowerCase()}`
                            : `/search/${type.toLowerCase()}?${querystring}`
                    }
                    key={index}
                >
                    {title}
                    {this.renderCount(type, this.props.tabData)}
                </NavLink>
            );
        } else {
            return (
                <div className={'imc-tabs-nav__item imc-tabs-nav__item--digital'}>
                    <Text field={fields.title}></Text>
                    {this.renderCount(type, this.props.tabData)}
                </div>
            );
        }
    }

    render() {
        const {
            tabData,
            rendering,
            tabsCount,
            setActive,
            path,
            setHistory,
        } = this.props;
        const wrapperClass =
            tabsCount === 1
                ? ''
                : 'imc-tabs-nav imc-tabs-nav-digital imc-tabs-nav__container';
        const currentRenderings =
            rendering.placeholders['imc-digital-tabbed-navigation-content'];

        if (!currentRenderings.some((x) => x.dataSource === tabData.currentIndex)) {
            const [first] = currentRenderings;
            if (first) {
                setActive(first.dataSource);
                tabData.exhibitorsCount = 0;
                tabData.exhibitors = [];
            }
        }

        return (
            <div className={wrapperClass}>
                {currentRenderings
                    .filter((item) => item.componentName === 'Digital-Tab')
                    .map((item, index) => {
                        if (typeof item.fields !== 'undefined')
                            return (
                                this.renderTab(
                                    item.dataSource,
                                    item.fields,
                                    setActive,
                                    item.dataSource === tabData.currentIndex,
                                    path,
                                    setHistory,
                                    index,
                                    tabsCount
                                )
                            );
                    })}
            </div>
        );
    }
}

export default withSitecoreContext()(withRouter(Tabs));
