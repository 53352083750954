import React from 'react';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withLastLocation } from 'react-router-last-location';
import { createAppState } from 'modules/redux-app-state';
import { Loading } from 'modules/loading';
import qs from 'query-string';
import { getDictionaryValue } from 'utils/dictionary';
// reducers
import floorPlanReducer from './reducers/floorPlanReducer';
// data actions
import * as dataActions from './actions/dataActions';
import MeridianMap from './../Meridian-Map-Component';
import Link from './../Link';

const FloorPlanState = createAppState();

class FloorPlanComponent extends React.Component {
  constructor(props) {
    super(props);

    FloorPlanState.reducerRegistry.register({ floorPlanReducer });

    if (!props) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }

    this.state = {
      data: null,
      prevPath: null
    }

    this.renderMain = this.renderMain.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderNextfloorButton = this.renderNextfloorButton.bind(this);
    this.renderPrevfloorButton = this.renderPrevfloorButton.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);

    this.isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;
    this.floorPlanDatasource = this.props.fields.floorPlanDatasource.value;
    this.buildingName = null;
    this.floor = null;
    this.getFloorAndBuildingData();
  }

  componentDidMount() {

    if (typeof window !== 'undefined') {
      this.loadFloorPlanData();
    }

  }

  getFloorAndBuildingData() {
    let routeParams = this.props.location.pathname.split("/");
    if (!routeParams.length - 3 < 0) {
      this.floor = routeParams[routeParams.length - 1];
      this.buildingName = routeParams[routeParams.length - 3];
    }
  }

  componentDidUpdate(prevProps) {

    //if (!!this.props.data && !!prevProps.data && this.props.data.building.floor.number != prevProps.data.building.floor.number){
    if (this.props.location.key != prevProps.location.key) {

      this.getFloorAndBuildingData();
      this.loadFloorPlanData();
    }
  }

  isComingFromBuildingsMap() {
    const ret = !!this.state.prevPath && this.state.prevPath.contains("Market-Map");
    return ret;
  }

  loadFloorPlanData() {
    if (!isExperienceEditorActive()) {
      this.props.dataActions.fetchFloorPlan(this.floorPlanDatasource, this.buildingName, this.floor);
    } else {
      this.props.dataActions.fetchMockFloorPlan();
    }
  }

  renderMain() {
    if (this.props.data != null) {
      let { building } = this.props.data;
      let locationID = !!building ? building.meridianLocationID : undefined;
      let token = !!building ? building.meridianToken : undefined;
      const showroomID = (typeof window !== 'undefined') ? qs.parse(window.location.search).showroom : null;
      if (!locationID) {
        return (<div className="alert-box">
          Meridian LocationID is not provided. Contact administrators, please.
        </div>);
      }

      return (<>
        {!!building && !!building.floor &&
          <MeridianMap
            buildingName={building.name}//"Building 3"
            floorNo={building.floor.number}//"1"
            locationID={locationID}//"6334295885479936"
            token={token}
            highLightPlacemarkId={showroomID}
            labelZoomLevel={building.floor.labelZoomLevel}
          ></MeridianMap>
        }

        {(!building || !building.floor) &&
          <div className="alert-box">No floor plan data</div>
        }

      </>
      );
    } else {
      return <Loading showLoading={true}></Loading>;
    }
  }

  renderHeader() {
    if (this.props.data != null && this.props.data.building != null) {
      let { building } = this.props.data;

      return (

        <div className="imc-floor-plan-header imc-section--padded-bottom-large imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large">
          <span className="imc-floor-plan-name">
            <h1>{building.name}: {getDictionaryValue("floor", "Floor")} {building.floor.number}</h1>
            <div className="imc-type--title-6 imc-section--padded-top-large">{building.floor.name}</div>
          </span>
          <div className="imc-floor-plan-adress">
            {building.address && building.address.split("\n").map(function (item, index) {
              return (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              )
            })}
          </div>
        </div>
      );
    } else {
      return <Loading showLoading={true}></Loading>;
    }
  }

  renderPdfDownload() {
    console.log("renderPdfDownload", this.props.data);
    if (this.props.data != null && !!this.props.data.building && this.props.data.building.floor.pdfLink) {
      return (
        <a href={this.props.data.building.floor.pdfLink} target="_blank" className="imc-button--primary-inverted imc-section--padded-small" >
          <span className="imc-icon-Download imc-margin--right--small--desktop imc-type--color-neutral-heaviest"></span>
          <span className="imc-breakpoint-display--hide-mobile">{getDictionaryValue("downloadPDF", "View PDF")}</span><span className="imc-breakpoint-display--hide-desktop">{getDictionaryValue("pdf", "PDF")}</span>
        </a>
      );
    }
    return null;
  }

  renderNextfloorButton() {
    let hasMobileCookie = false;
    if (typeof document !== "undefined") {
      hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
    }
    if (this.props.data != null) {
      let { location, data } = this.props;
      if (!!data && !!data.building && data.building.nextFloor != null) {
        let nextFloorUrl = location.pathname.slice(0, location.pathname.lastIndexOf("/") + 1).concat(data.building.nextFloor.number);
        if (!hasMobileCookie) {
          return (
            <Link  href={nextFloorUrl} className="imc-button--primary-inverted imc-button--next-floor  imc-margin--left--medium imc-section--padded-small" >
              {getDictionaryValue("floor", "Floor")} {data.building.nextFloor.number}
              <span className="imc-margin--left--small--desktop">
                <svg width="16" height="16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
                </svg>
              </span>
            </Link>
          );
        } else {
          return (
            <a href={nextFloorUrl} className="imc-button--primary-inverted imc-button--next-floor  imc-margin--left--medium imc-section--padded-small" >
              {getDictionaryValue("floor", "Floor")} {data.building.nextFloor.number}
              <span className="imc-margin--left--small--desktop">
                <svg width="16" height="16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
                </svg>
              </span>
            </a>
          );
        }
      }
    }
    return null;
  }

  renderPrevfloorButton() {
    let hasMobileCookie = false;
    if (typeof document !== "undefined") {
      hasMobileCookie = decodeURIComponent(document.cookie).includes("ImcMobileApp");
    }
    if (this.props.data != null) {
      let { location, data } = this.props;
      if (!!data && !!data.building && data.building.previousFloor != null) {
        let previousFloorUrl = location.pathname.slice(0, location.pathname.lastIndexOf("/") + 1).concat(data.building.previousFloor.number);
        if (!hasMobileCookie) {
          return (
            <Link href={previousFloorUrl} className="imc-button--primary-inverted imc-button--prev-floor  imc-margin--left--medium imc-section--padded-small" >
              <span className="imc-margin--right--small--desktop">
                <svg width="16" height="16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
                </svg>
              </span>
              {getDictionaryValue("floor", "Floor")} {data.building.previousFloor.number}
            </Link>
          );
        } else {
          return (
            <a href={previousFloorUrl} className="imc-button--primary-inverted imc-button--prev-floor  imc-margin--left--medium imc-section--padded-small" >
              <span className="imc-margin--right--small--desktop">
                <svg width="16" height="16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconBackArrow"></use>
                </svg>
              </span>
              {getDictionaryValue("floor", "Floor")} {data.building.previousFloor.number}
            </a>
          );
        }
      }
    }
    return null;
  }

  render() {
    let { data, lastLocation } = this.props;

    if (this.props.data != null && this.props.data.building != null) {
      let { building } = this.props.data;
      document.title = this.props.sitecoreContext.route.fields['Page Title'].value;
      document.title = document.title.replace('{building.name}', building.name);
      document.title = document.title.replace('{floor.number}', building.floor.number);
    }

    const buildingsMapUrl = getDictionaryValue("BuildingsMapUrl", "/Market-Map");

    return (<>
      <div className="imc-floor-plan">
        <div>
          <div className="imc-content--left imc-section--padded-left-large imc-section--padded-right-large imc-section--padded-top-large">
            <Link className="imc-floor-plan--link" href={buildingsMapUrl}>&larr; {getDictionaryValue("returnToBuildingList", "Building List")}</Link>
          </div>
          {this.renderHeader()}
        </div>
        <div>
          {this.renderMain()}
        </div>
        <Loading showLoading={data == null}></Loading>
      </div>
      <div className="imc-floor-plan--floorButtons imc-section--padded-left-medium imc-section--padded-medium imc-section--padded-right-medium">
        <div>
          {this.renderPdfDownload()}
        </div>
        {this.renderPrevfloorButton()}
        {this.renderNextfloorButton()}
      </div>
      </>
    );
  }
}

function mapStateToProps(state) {

  if (!!state.floorPlanReducer)
    return {
      data: state.floorPlanReducer.data,
      showLoading: state.floorPlanReducer.showLoading,
    }
  else {
    return {
      data: state.data,
      showLoading: state.showLoading,
    };
  }

}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

export default withSitecoreContext()(withLastLocation(withRouter(connect(mapStateToProps, mapDispatchToProps)(FloorPlanComponent))));
