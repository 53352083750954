import React from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

//modules
import { createAppState } from "modules/redux-app-state";

//Utils
import { getDictionaryValue } from 'utils/dictionary';
import SearchPubSubService from "modules/search/services/pubsub";
import UserUtility from "utils/userutility";

//actions
import * as multiSelectActions from '../actions/multiSelectActions';

// reducers
import multiSelectReducer from "../reducers/multiSelectReducer";

import SearchBarButton from './SearchBarButtonBase.jsx'


const appState = createAppState();

class MultiSelectButton extends SearchBarButton {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        appState.reducerRegistry.register({
            multiSelectReducer
        });

        this.customToogleMenu = this.customToogleMenu.bind(this);
        this.handleSelecteditemChanged = this.handleSelecteditemChanged.bind(this);
        this.consolidateSelectedItems = this.consolidateSelectedItems.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        // this.state = {
        //     selectedItems: [],
        // }
    }
    componentDidMount() {
        super.componentDidMount();
        this.searchPubSubService = SearchPubSubService.getInstance();
        this.searchPubSubService.multiSelectItemsChange().subscribe(this.handleSelecteditemChanged);
    }
    resetState() {
        // this.setState({
        //     selectedItems: [],
        // })
        this.toogleMenu(true);
    }
    componentDidUpdate(lastProps) {

        if (lastProps.addToListSuccess !== this.props.addToListSuccess) {
            if (this.props.addToListSuccess)
                this.resetState();
        }
    }
    
    hasSomeParentTheClass(element, classname) {
        if (element.className && element.className.split && element.className.split(' ').indexOf(classname) >= 0) return true;
        return !!element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
    }

    hasSomeParentClassNameLike(element, classname) {
        const hasClassNameLike = (element.className && element.className.indexOf('imc-catalog__item')>-1);
        if (hasClassNameLike) return true;
        
        return !!element.parentNode && this.hasSomeParentClassNameLike(element.parentNode, classname);
    }

    handleClickOutside(event) {
        const { actions } = this.props;
        
        if ((this.wrapperRef &&
            !this.wrapperRef.contains(event.target) && this.isOpen()// &&
            //!(event.target.className.indexOf && event.target.className.indexOf('imc-catalog__item') > -1)
            ) &&
            !this.hasSomeParentTheClass(event.target, 'multiSelectItem') &&
            !this.hasSomeParentTheClass(event.target, 'imc-exhibitorcard') &&
            !this.hasSomeParentTheClass(event.target, 'ReactModalPortal') &&
            !this.hasSomeParentTheClass(event.target, 'imc-pagination') &&
            !(event.target.className.indexOf('imc-loading') > -1)) {
            actions.viewChange(false);
            this.resetState();
        }
    }

    customToogleMenu() {
        const { actions } = this.props;
        actions.viewChange(!this.isOpen())
        this.toogleMenu();
    }

    /**
     * @method combineItems
     * @description take two items and combine their itemContents
     * @param {object} item1 item to add
     * @param {object} item2 item to add
     * @returns {object} combied items
     */
    combineItems(item1, item2) {
        const itemContent1 = item1.itemContents || [];
        const itemContent2 = item2.itemContents || [];

        return {
            ...item1,
            ...item2,
            itemContents: itemContent1.concat(itemContent2),
        };
    }

    /**
     * @method consolidateSelectedItems
     * @description Consolidate Selected Items
     * @param {array} items an array of items to add
     * @returns {array} items consolidated by itemId
     */
    consolidateSelectedItems() {
        const { selectedItems } = this.props;
        const consolidatedItems = [];
        const idIndexMapping = {};

        selectedItems.forEach((itemChanged) => {
            const item = itemChanged.actionItem;
            const idIndex = idIndexMapping[item.itemId];
            // check if entries with itemId has already been added.
            if (idIndex !== undefined) {
                const updatedEntry = this.combineItems(consolidatedItems[idIndex], item);
                consolidatedItems[idIndex] = updatedEntry;
            } else {
                idIndexMapping[item.itemId] = consolidatedItems.length;
                consolidatedItems.push(item);
            }
        });

        return consolidatedItems;
    }

    handleSelecteditemChanged(itemChanged) {
        const { actions } = this.props;
        actions.itemChanged(itemChanged);
        // let newSelected = this.state.selectedItems;
        // if (itemChanged.selected)
        //     newSelected.push(itemChanged);
        // else {
        //     newSelected = newSelected.filter(e => e.id !== itemChanged.id);
        // }
        // this.setState({
        //     selectedItems: newSelected,
        // })

    }




    render() {
        const { selectedItems } = this.props;
        const countItemsSelected = selectedItems.length;
        const isItemSelected = countItemsSelected > 0;
        return <div className="imc-searchform--bar" ref={this.setWrapperRef} id={this.id} >
            <button className={`imc-searchform--bar--button ${this.isOpen() ? 'open' : ''}`}
                onClick={this.customToogleMenu}>
                {getDictionaryValue('select', 'Select')}
            </button>
            <div className={`imc-searchform--bar--dropdown ${this.isOpen() ? 'open' : ''}`}>
                <div className="imc-searchform--bar--item imc-margin--left--mediumsmall--desktop">
                    <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium">
                        {getDictionaryValue('xItemsSelected', `(${countItemsSelected}) Items Selected`, { count: countItemsSelected })}
                    </span>
                </div>
            </div>
        </div>

    }
}


/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...multiSelectActions }, dispatch),
    };
}

/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    let _state = {
        lists: state.myLists,
        loader: state.isLoading,

    };
    if (state.multiSelectReducer) {
        _state.selectedItems = state.multiSelectReducer.selectedItems;
        _state.multiSelectMode = state.multiSelectReducer.multiSelectMode;
    }
    return _state;
}
MultiSelectButton.defaultProps = {
    countItemsSelected: 0,
    selectedItems: [],
}
export default withSitecoreContext()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiSelectButton))
);
