import React, { createRef } from 'react';
import Link from '../Link';
import { Image, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// Local dependencies
import NavigationMobile from './components/NavigationMobile.jsx';
import NavigationMobileSearch from './components/NavigationMobileSearch.jsx';
import { padContent, unPadContent } from 'utils/scrollhelpers';


class HeaderNavigationMobileComponent extends React.Component {

  /**
 * @method constructor
 * @description Constructs the Navigation component and sets the current selected tier to -1 (none)
 * @param {object} props Incoming props
 */
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      showMenu: false,
      scrollY: 0,
      scrollBarWidth: 0,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.updateWindowSize = this.updateWindowSize.bind(this);
    this.searchRef = React.createRef();
  }

  /**
 * Adds an event listener to set the container sizes
 */
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showMenu || this.state.showSearch) {
      padContent(this.state.scrollBarWidth, [`.imc-searchform--mobile`, `.suggestions-container--mobile`], this.state.scrollY);
    } else {
      if (prevState.showMenu !== this.state.showMenu || prevState.showSearch !== this.state.showSearch) {
        unPadContent([`.imc-searchform--mobile`, `.suggestions-container--mobile`]);
      }
    }
    if (this.state.showSearch) {
        if (this?.searchRef?.current?.input) this.searchRef.current.input.focus();
    }
  }

  /**
   * Updates the window size on resize
   */
  updateWindowSize() {

    this.setState({
      windowWidth: document.documentElement.clientWidth,
      scrollHeight: document.body.scrollHeight,
      navContainer: window.innerHeight,
    });

    // if (this.state.showSearch) this.closeSearch();
    if (this.state.showMenu) this.closeMenu();
  }

  toggleSearch(event) {
    event.preventDefault();
    let scrollBarWidth = (window.innerWidth - document.documentElement.clientWidth == 0) ? this.state.scrollBarWidth : window.innerWidth - document.documentElement.clientWidth;
    this.setState({
      showSearch: !this.state.showSearch,
      showMenu: false,
      scrollY: window.scrollY,
      scrollBarWidth
    });
  }
  closeSearch() {
    this.setState({
      showSearch: false,
    });
    unPadContent([`.imc-searchform--mobile`, `.suggestions-container--mobile`]);
    document.body.classList.remove('imc-navigation-mobile--body-open');
  }
  /**
   * Toggles the menu state
   * @param {object=} e Keydown event object
   */

  toggleMenu(e) {
    const code = e.keyCode || e.which;
    if (code === undefined || code === 13 || code === 32) {
      let scrollBarWidth = (window.innerWidth - document.documentElement.clientWidth == 0) ? this.state.scrollBarWidth : window.innerWidth - document.documentElement.clientWidth;
      this.setState({
        showMenu: !this.state.showMenu,
        showSearch: false,
        scrollY: window.scrollY,
        scrollBarWidth,
      });
      if (!this.state.showMenu) {
        document.body.classList.add('imc-navigation-mobile--body-open');
      } else {
        document.body.classList.remove('imc-navigation-mobile--body-open');
      }
    }
  }


  /**
  * Close the menu
  */
  closeMenu() {
    this.setState({
      showMenu: false,
      trapFocusActive: false,
    });
    unPadContent();
  }

  render() {
    const { fields, sitecoreContext, rendering } = this.props;
    const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    if (typeof fields === 'undefined' ){//|| (fields.data && fields.data.model === null)) {
      if (isEditing) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }


    const { showSearch, windowWidth, showMenu, navContainer } = this.state;
    const { isLoggedIn } = fields;
    return <>
        <div className={`imc-header__container imc-header__nav-mobile imc-section imc-breakpoint-display--hide-desktop  ${showMenu || showSearch ? 'active' : ''}`}>
        <div className="imc-header__search-mobile">
            <a role="menu" tabIndex={0} className="imc-header__search-mobile__link" onClick={(e) => { this.toggleSearch(e) }} >
            <svg width="36" height="36" className="imc-header__search-mobile-icon">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-search-v3"></use>
            </svg>
            </a>
            <NavigationMobileSearch
                showSearch={showSearch}
                windowWidth={windowWidth}
                navContainer={navContainer}
                closeSearch={this.closeSearch}
                searchRef={this.searchRef}
            />
        </div>
        <div className="imc-header__logo">
            <Link onClick={() => this.closeMenu() || this.closeSearch()} href="/"
                className="imc-content--block" data-xpath="header.logo" title={fields.logo.value.alt}>
                {(fields.logo.value.svgCode !== undefined && !isEditing) &&
                    <div dangerouslySetInnerHTML={{ __html: fields.logo.value.svgCode }} />
                }
                {(fields.logo.value.svgCode === undefined || isEditing) &&
                    <Image field={fields.logo} />
                }
            </Link>
        </div>
        <NavigationMobile
            mobileMenuLabel={fields.menuText.value}
            items={fields.navigation1st}
            showMenu={showMenu}
            toggleMenu={this.toggleMenu}
            closeMenu={this.closeMenu}
            isLoggedIn={isLoggedIn}
        />
        </div>
        <div onClick={() => this.closeMenu() || this.closeSearch()} className={`top-nav-overlay imc-modal--overlay ${showMenu || showSearch ? 'active' : ''}`}></div>
    </>;
  }
}



export default withSitecoreContext()(HeaderNavigationMobileComponent);
