import React from "react";
import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { createAppState } from "modules/redux-app-state";

// reducers
import digitalShowroomCatalogsOverviewReducer from "./reducers";
import digitalShowroomInfoReducer from "../Digital-Showroom-Info/reducers/digitalShowroomInfoReducer";
// action imports
import * as dataActions from "./actions/dataActions";

//utils
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import { getShopzioUrl, getCurrentPathCatalogsURL, locationIsLine, exhibitorIsLine } from 'utils/exhibitor';
import { getExhibitorCatalogURL } from "utils/exhibitor";
//modules
import SearchItemThumb from 'modules/searchItemThumb';
import Link from '../Link';
import ImcDataLayer from 'utils/datalayer';
import {isMarketCtaShouldBeShown} from '../../utils/general';
const digitalShowroomState = createAppState();


class DigitalShowroomCatalogsOverview extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomCatalogsOverviewReducer,
      digitalShowroomInfoReducer
    });
    this.fetchData = this.fetchData.bind(this);
    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      this.exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      this.lineId = sitecoreContext.lineProduct.lineId;
    }

  }

  /**
 * Kick off bound fetch data
 */
  componentDidMount() {
    if (this.exhibitorId !== null || this.lineId !== null)
      this.fetchData(this.exhibitorId, this.lineId);
  }


  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props;
    let exhibitorId, lineId;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
    }
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
      exhibitorId = sitecoreContext.exhibitor.id;
      lineId = sitecoreContext.lineProduct.lineId;
    }

    if (lineId != this.lineId || exhibitorId != this.exhibitorId) {
      this.exhibitorId = exhibitorId;
      this.lineId = lineId;
      this.fetchData(this.exhibitorId, this.lineId);
    }

  }

  /**
 * Fetch the article data
 * @param { string } exhibitorId  used to getInfo
 */
  fetchData(exhibitorId, lineId) {

    const pageId = this.props.sitecoreContext.route.itemId;
    if (locationIsLine(this.props.location)) {
      this.props.dataActions.getDigitalShowroomLineCatalogsOverview(lineId, pageId);
    }
    else {
      this.props.dataActions.getDigitalShowroomCatalogsOverview(exhibitorId, pageId);
    }
    this.props.dataActions.getExhibitorDetail(exhibitorId, pageId);
  }

  getTitle(exhibitor) {
    return exhibitorIsLine(exhibitor) ? exhibitor.lineName : exhibitor.companyName;
  }

  renderCatalogs(catalogs) {
    const { sitecoreContext } = this.props;

    const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    return (
      <div className="imc-products-overview--gallery">{
        catalogs.map((catalog, index) => {

          let isLine = false;
          // if (lineDetail) {
          //   isLine = true;
          // }
          const mainType = isLine ? 'line' : 'exhibitor';
          const catFav = {
            itemId: catalog.exhibitorId,
            itemType: mainType,
            label: catalog.name,
            contentName: '',
            itemContents: [{
              contentName: catalog.name,
              itemId: catalog.id,
              itemType: 'catalog',
            }],
          }
          let origin = '';
          if (typeof window !== 'undefined') {
            origin = window.location.origin;
          }

          return (
            <SearchItemThumb
              key={index}
              url={getExhibitorCatalogURL(catalog.exhibitorId, catalog.id)}
              image={catalog.previewImageLink}
              newWindow={false}
              showActionBar={true}
              actionItem={catFav}
              name={catalog.name}
              extraClass={'imc-catalog__item-sixth'}
              mainClass="imc-catalog"
              itemID={index}
              width={190}
              height={190}
              isNew={catalog.newProductForMarket}
            />
          )

        })
      }
      </div>
    )
  }

  render() {
    const { catalogs, catalogsCount, shopZioURL, location } = this.props;
    if (catalogsCount > 0) {
      return (
        <div className="imc-exhibitors--alternated-section imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
          <div className="imc-section imc-section--width-100-percent">
            <div className="imc-content--display-flex-wrap">
              <span className="imc-heading--h5">{getDictionaryValue('catalogs', 'Catalogs')}</span>
              <span className="imc-type--body-2-ui imc-padding--left--small">
                {catalogsCount > 0 ? getDictionaryValue('countCatalogsShown', `${catalogsCount} Catalogs Shown`, { count: catalogsCount })
                  : getDictionaryValue('noCatalogsShown', 'No Catalogs Shown')}
              </span>
            </div>
            <div className="imc-content--display-flex-wrap">
              {(catalogs && catalogs.length > 0) &&
                this.renderCatalogs(catalogs)
              }
            </div>
            <div className="imc-content--display-flex--right imc-content--full-width-mobile imc-products-overview--cta-bar imc-content--display-flex imc-content--display-flex-center">
              {shopZioURL && isMarketCtaShouldBeShown(this.props)  &&
                <div className="imc-products-overview--cta-bar__item imc-padding--right--medium--mobile ">
                  <Link
                    onClick={() => ImcDataLayer.pushInteractionEvent("exit", 'Connect on @Market', shopZioURL)}
                    href={getShopzioUrl(shopZioURL)} rel="noopener noreferrer" className="imc-button imc-button--atmarket imc-button--full-bleed-mobile">{renderDesktopMobileVariations('shopZio', 'Connect on @Market')}</Link>
                </div>
              }
              <div className="imc-products-overview--cta-bar__item imc-padding--left--xlarge--desktop imc-padding--left--medium--mobile">
                {catalogsCount > 0 ?

                  <Link href={getCurrentPathCatalogsURL(location.pathname)} className="imc-content--inline-block imc-button imc-button--primary-inverted imc-button--full-bleed-mobile">{renderDesktopMobileVariations('seeAllCatalogs', `See${(catalogsCount > 1) ? ' All' : ''} ${catalogsCount} Catalog${(catalogsCount > 1) ? 's': ''}`, { count: catalogsCount })}</Link>
                  :
                  <button className="imc-button imc-button--muted-inverted imc-button--full-bleed-mobile" disabled>
                    {`0 ${getDictionaryValue('catalogs', 'Catalogs')}`}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}
const defaultProps = {
  dataActions: {
    getDigitalShowroomContactInfo: () => { },
  },
  getInfoActions: {
    getDigitalShowroomCatalogsOverview: () => { },
  },
  catalogs: [],
  catalogsCount: 0,
};
const propTypes = {
  dataActions: PropTypes.object,
  getInfoActions: PropTypes.object,
  catalogs: PropTypes.array,
  catalogsCount: PropTypes.number,
};

/**
 * Maps state to props for connect
* @param {object} state State object
* @returns {{ search: *}} State to props mapping
  */
function mapStateToProps(state) {
  let _props = {};
  if (state.digitalShowroomCatalogsOverviewReducer)
    Object.assign(_props, {
      catalogs: state.digitalShowroomCatalogsOverviewReducer.catalogs,
      catalogsCount: state.digitalShowroomCatalogsOverviewReducer.catalogsCount,
      showLoading: state.digitalShowroomCatalogsOverviewReducer.showLoading,
    });
  if (state.digitalShowroomInfoReducer) {
    Object.assign(_props, {
      shopZioURL: state.digitalShowroomInfoReducer.shopZioURL,
    });
  }
  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

DigitalShowroomCatalogsOverview.propTypes = propTypes;
DigitalShowroomCatalogsOverview.defaultProps = defaultProps;

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomCatalogsOverview))
);
