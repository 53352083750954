import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubmitButton, TextField } from 'modules/formelements';
import { Loading } from 'modules/loading';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { getDictionaryValue } from 'utils/dictionary/index';
import UserUtility from 'utils/userutility';

const propTypes = {
    submitLabel: PropTypes.string,
    formDescription: PropTypes.string,
    searchName: PropTypes.string,
    helpText: PropTypes.string,
    nameAlreadyExist: PropTypes.string,
    errorSearchName: PropTypes.string,
    closeAction: PropTypes.func.isRequired,
    customSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
    submitLabel: 'Save Search',
    formDescription: 'Saving your Search will allow you to revisit these search terms on your Market Planner page.',
    searchName: 'Search Name:',
    helpText: 'Create a name for your saved search 25 characters or less.',
    nameAlreadyExist: 'The List name already exists. Type a different List name.',
    errorSearchName: 'Enter something',
};

/**
 * JSX component for the SearchActionBar. This component will hold
 * the select multiple, email, download, and save search functionality
 * @param { object } props includes query string for search
 * @constructor
 */
class SavedSearchModal extends Component {
    /**
     * @method constructor
     * @description
     * By default sets the expanded state to false
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        if (!props) {
            if (isExperienceEditorActive()) {
                return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
                <h1 className="alarm">
                    Data is not provided. Contact administrators, please.
                </h1>
            );
        }
        this.state = {
            value: '',
            savedList: [],
            listExist: false,
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSavedsearch = this.getSavedsearch.bind(this);
    }

    /**
     * Get list of saved searches
     */
    componentDidMount() {

        const userInfo = UserUtility.getUserData();
        this._serviceUrl = `/imc-api-idm/api/v1/lvm/search/savesearch/${userInfo.accountId}`;
        //this._serviceUrl = typeof window !== "undefined"?`${window.baseIdmAPIUrl}${serviceUrl}`:'';
        this.getSavedsearch(this._serviceUrl).then((response) => {
            if (response.code === 200) {
                this.setState({ savedList: response.data.savedSearch });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        });
    }
    /**
     * Returns object containing field name and value
     * @param {string} url API endpoint
     * @returns {object} Object returned containing field names and values
     */
    getSavedsearch(url) {
        this.setState({ loading: true });
        return fetch(url).then(response => response);
    }

    /**
     * @param {*} val wip
     * @param {*} final eip
     */
    handleChange(val, final) {
        this.setState({ value: final });
    }
    /**
     * @param {*} event Submit event
     */
    handleSubmit(event) {
        event.preventDefault();
        const { savedList, value } = this.state;
        let alreadyExistList = [];
        if (savedList && savedList.length > 0) {
            alreadyExistList = savedList.filter(item => item.searchName === value);
        }
        if (alreadyExistList.length) {
            this.setState({ listExist: true });
        } else {
            this.setState({ listExist: false });
            this.props.customSubmit(value);
        }
    }
    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        // display directions
        const { submitLabel, formDescription, searchName, helpText, nameAlreadyExist, errorSearchName } = this.props;
        const { listExist, loading } = this.state;
        return (
            // imc-exhibitorheader__actionbarwrapper
            <div className="imc-modal--overlay">
                <div className="imc-modal--box imc-savesearchmodal">
                    <p className="imc-savesearchmodal__description">{getDictionaryValue('saveSearches_formDescription', formDescription)}</p>
                    {listExist ?
                        <div className="imc-content imc-content--error imc-vr--large">
                            {getDictionaryValue('saveSearches_nameAlreadyExist', nameAlreadyExist)}
                        </div> : null
                    }
                    <form onSubmit={this.handleSubmit}>
                        <TextField
                            label={getDictionaryValue('saveSearches_searchName', searchName)}
                            name="searchName"
                            additionalClass="imc-savesearchmodal__text-field"
                            updateValue={this.handleChange}
                            errorEmpty={getDictionaryValue('saveSearches_errorSearchName', errorSearchName)}
                            isRequired
                            maxLength={25}
                            value={this.state.value}
                        />
                        <p className="imc-savesearchmodal__help-text imc-padding--top--smallmedium">{helpText}</p>
                        <div className="imc-content--full-width imc-content--right">
                            <SubmitButton
                                labelSubmit={getDictionaryValue('saveSearch', submitLabel)}
                                additionalClass="imc-button--secondary-inverted imc-content- imc-savesearchmodal__submit"
                            />
                        </div>
                    </form>
                    <button
                        className={'imc-modal--close imc-button--modal-close imc-button--round'}
                        onClick={this.props.closeAction}
                    >
                        {getDictionaryValue('close', 'Close')}</button>

                </div>
                <Loading showLoading={loading} />
            </div>
        );
    }
}

SavedSearchModal.propTypes = propTypes;
SavedSearchModal.defaultProps = defaultProps;

export default SavedSearchModal;