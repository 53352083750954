import React, { useState, useEffect } from "react";
import { getEventTypes } from '../../api/events';
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import renderCarouselItem from './EventSlide';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Swiper } from 'swiper/react';

interface FeaturedEventsProps {
  fields?: any;
}

const FeaturedEvents: React.FC<FeaturedEventsProps> = ({fields}) => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            const response = await getEventTypes("", true);
            setEvents(response.data);
            setIsLoading(false);
        };

        fetchEvents().catch(console.error);
    }, []);

    const breakpoints = {
        '500': {slidesPerView: 2, spaceBetween: 25},
        '800': {slidesPerView: 3, spaceBetween: 25},
        '1024': {slidesPerView: 4, spaceBetween: 25},
    };

    const renderCarousel = () => {
        return (
            <div className="event-features-carousel-component event-card--multi-card-container">
                <h2 className="section-title">Featured Events</h2>

                <div className="events-featured-swiper-container">
                    <Swiper
                        spaceBetween={25}
                        slidesPerView={1}
                        breakpoints={breakpoints}
                        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    >
                       {events.map(event => renderCarouselItem(event, fields))}
                    </Swiper>

                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>
        );
    };

    if (!fields) {
        if (isExperienceEditorActive()) {
            return <h1 className="alarm">Datasource isn't set.</h1>;
        }
        return (
            <h1 className="alarm">
                Data is not provided. Contact administrators, please.
            </h1>
        );
    }

    if (!isLoading) {
        return <div>{renderCarousel()}</div>;
    } else {
        return (<></>);
    }
};

export default FeaturedEvents;

