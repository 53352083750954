import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../../AppGlobals';
//Utils
import { getDictionaryValue } from 'utils/dictionary'

import SearchBarButton from './SearchBarButtonBase.jsx'
import SearchPubSubService from "modules/search/services/pubsub";
import qs from 'query-string';
import { bindActionCreators } from 'redux';
import {
    INFORMATION_IDENTIFIER,
    EXHIBITORS_IDENTIFIER,
    ARTICLES_IDENTIFIER,
    LINES_IDENTIFIER,
    EVENTS_IDENTIFIER,
    PRODUCTS_IDENTIFIER,
    CATALOGS_IDENTIFIER,
} from 'utils/exhibitor';
import { EmailQueryModal } from 'modules/emailQuery';
import { Loading } from 'modules/loading';
import { SEARCH_TYPE_EXHIBITOR, SEARCH_TYPE_PRODUCT, getFilterKeyFromPath } from 'utils/search'
import { downloadSearch } from 'modules/search/actions/searchActions'
import { getParamsFromShowroomUrl } from 'utils/exhibitor';
import UserUtility from '../../../utils/userutility';
import { serializeTags } from 'utils/serialize';
import { filterquerymodifier } from 'utils/filterquerymodifier';
import ErrorUtility from 'utils/errorutility';
import ImcDataLayer from 'utils/datalayer';

const GET_RESULTS_DICTIONARY_PREFIX = 'shareXResults_';
const propTypes = {

    // eslint-disable-next-line react/no-unused-prop-types
    data: PropTypes.object.isRequired

};
class ShareButton extends SearchBarButton {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.getMessage = this.getMessage.bind(this);
        this.toogleEmailModal = this.toogleEmailModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.downloadResults = this.downloadResults.bind(this);
        this.exportCSVFile = this.exportCSVFile.bind(this);
        this.convertToCSV = this.convertToCSV.bind(this);
        this.getDataToDownload = this.getDataToDownload.bind(this);
        this.getQueryString = this.getQueryString.bind(this);
        this.state = {
            countResults: 0,
            type: '',
            showEmailModal: false,
        }
    }
    componentDidMount() {
        super.componentDidMount();
        this.searchPubSubService = SearchPubSubService.getInstance();
        this.searchPubSubService.matchedResultsCountChange().subscribe(results => {
            this.setState({
                countResults: results.count,
                type: results.type,
            })
        });
    }

    getQueryString() {
        ImcDataLayer.pushInteractionEvent("search", "email share", this.props.query);
        let { location } = this.props;
        const qsParsed = qs.parse(location.search, { ignoreQueryPrefix: true });
        const term = qsParsed.q || '';
        const alphabetName = qsParsed.alphabetName || '';
        const type = qsParsed.type || '';
        const sort = qsParsed.sortorder || '';
        const page = qsParsed.page || '1';
        var filterType = getFilterKeyFromPath(location.search);
        if (typeof filterType === 'undefined') //FIx to handle directory type pages
            filterType = location.pathname.split('/').pop();
        const filters = qs.parse(qsParsed[filterType] || '');

        const { sitecoreContext } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        const apiKey = getSitecoreApiKey();
        let params = {
            'sc_apikey': apiKey,
        }
        if (term !== '') {
            params.term = term;
        }
        if (alphabetName !== '') {
            params.alphabetName = alphabetName;
        }
        if (type !== '') {
            switch (type) {
                case SEARCH_TYPE_EXHIBITOR:
                    params.type = 'exhibitorline';
                    break;
                case SEARCH_TYPE_PRODUCT:
                    params.type = 'product';
                    break;
            };
        }
        if (filters !== '') {
            params.f = filters;
        }
        if (sort !== '') {
            params.sortOrder = sort;
        }
        params.page = 1;
        params.pageSize = 5000;
        let searchPageParam = `&searchPage=${sitecoreContext.route.itemId}`;
        let querystring = serializeTags(params);
        querystring = filterquerymodifier(querystring);
        return querystring;
    }

    getDefaultDictionaryMessage(type, count) {
        let message = '';
        switch (type) {
            case EXHIBITORS_IDENTIFIER:
                message = `Share ${count} Exhibitor Results:`;
                break;
            case PRODUCTS_IDENTIFIER:
                message = `Share ${count} Product Results:`;
                break;
            case INFORMATION_IDENTIFIER:
                message = `Share ${count} Information Results:`;
                break;
            case LINES_IDENTIFIER:
                message = `Share ${count} Line Results:`;
                break;
            case ARTICLES_IDENTIFIER:
                message = `Share ${count} Article Results:`;
                break;
            case CATALOGS_IDENTIFIER:
                message = `Share ${count} Catalog Results:`;
                break;
            case EVENTS_IDENTIFIER:
                message = `Share ${count} Event Results:`;
                break;
            default:
                message = `Share ${count} Results:`;
                break;

        }
        return message;
    }

    getMessage() {
        const { countResults, type } = this.state;
        return getDictionaryValue(GET_RESULTS_DICTIONARY_PREFIX + type, this.getDefaultDictionaryMessage(type, countResults), { count: countResults });
    }
    toogleEmailModal() {
        if (!!this.state.showEmailModal)
            ImcDataLayer.pushInteractionEvent("search", "email", this.props.query);

        this.setState({
            showEmailModal: !this.state.showEmailModal,
        });
    }

    downloadResults() {
        ImcDataLayer.pushInteractionEvent("search", "download share", this.props.query);
        let { location } = this.props;
        const qsParsed = qs.parse(location.search, { ignoreQueryPrefix: true });
        const term = qsParsed.q || '';
        const alphabetName = qsParsed.alphabetName || '';
        const type = qsParsed.type || '';
        const sort = qsParsed.sortorder || '';
        const page = qsParsed.page || '1';
        var filterType = getFilterKeyFromPath(location.search);
        if (typeof filterType === 'undefined') //FIx to handle directory type pages
            filterType = location.pathname.split('/').pop();
        const filters = qs.parse(qsParsed[filterType] || '');
        this.fetchData(term, alphabetName, type, filters, sort, location.pathname, page);

    }


    fetchData(term, alphabetName, type, filters, sort, path, page) {
        const { sitecoreContext, location } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        this.setState({ showLoading: true });
        const apiKey = getSitecoreApiKey();
        let params = {
            'sc_apikey': apiKey,
        }
        if (term !== '') {
            params.term = term;
        }
        if (alphabetName !== '') {
            params.alphabetName = alphabetName;
        }
        if (type !== '') {
            switch (type) {
                case SEARCH_TYPE_EXHIBITOR:
                    params.type = 'exhibitorline';
                    break;
                case SEARCH_TYPE_PRODUCT:
                    params.type = 'product';
                    break;
            };
        }
        if (filters !== '') {
            params.f = filters;
        }
        if (sort !== '') {
            params.sortOrder = sort;
        }
        params.page = 1;
        params.pageSize = 500;
        if (!!sitecoreContext && !!sitecoreContext.exhibitor && !!sitecoreContext.exhibitor.id) {
            params.exhibitorId = sitecoreContext.exhibitor.id;
        }
        let searchPageParam = `&searchPage=${sitecoreContext.route.itemId}`;
        let querystring = serializeTags(params);
        querystring = filterquerymodifier(querystring);

        let resource;

        if (isLineSearch()) {
            resource = 'lines';
        } else {
            resource = 'exhibitors';
        }

        fetch(`${getSitecoreApiHost()}/imc-api/v2/${resource}/search?${querystring}${searchPageParam}`, {

            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Channel': UserUtility.getChannelData().name,
            }
        })
            .then(response => response.json())
            .then(data => {
                var headers = {
                    companyName: 'Company Name'.replace(/,/g, ''), // remove commas to avoid errors
                    showrooms: "Showrooms/Booths",

                };
                let items = [];
                this.getDataToDownload(data.data, items)
                this.exportCSVFile(headers, items);
                this.setState({
                    exhibitors: data.data,
                    exhibitorsCount: data.count,
                    showLoading: false
                })
            }).catch(err => {
                ErrorUtility.logError(err, getDictionaryValue('error.exhibitorsSearch', 'An error ocurred while performing the exhibitors search.'), 'ERROR');
                this.setState({
                    showLoading: false
                })
            })
    }

    getDataToDownload(searchResults, items) {

        searchResults.forEach(exhibitor => {
            if (exhibitor.companyDetails) {
                if (isLineSearch()) {
                    var companyName = exhibitor.lineName.replace(/,/g, '');
                } else {
                    var companyName = exhibitor.title.replace(/,/g, '');
                }

                let localLease = this.getLocalMarket(exhibitor.companyDetails);
                let filterdShowrooms = this.getShowrooms(localLease);

                var exportExhibitor = {
                    companyName: companyName,
                    showrooms: filterdShowrooms
                };
                items.push(exportExhibitor)

            }



        });

    }
    isLocalMarket(lease) {
        return (typeof window !== 'undefined' && 'channel' in lease && lease.channel.siteCode === window.channel.name);
    }
    getLocalMarket(exhibitor) {
        return exhibitor.activeLeases && exhibitor.activeLeases.filter(this.isLocalMarket);
    }

    getShowrooms(localLeases) {
        let added = []
        let showroomsStr = "";
        if (!!localLeases) {
            localLeases.map(function (lease, indexa) {
                return (lease.showrooms.map(function (showroom, index) {

                    if (!added.includes(showroom.showroom)) {
                        added.push(showroom.showroom);
                        showroomsStr = showroomsStr + " " + showroom.showroom;
                    }
                })
                )
            })
        }

        return showroomsStr;
    }
    convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

    exportCSVFile(headers, items) {
        if (headers) {
            items.unshift(headers);
        }
        var now = new Date();
        let day = now.getDate();
        let month = now.getMonth() + 1;
        let year = now.getFullYear();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        let seconds = now.getSeconds();
        var nowStr = "";
        if (month < 10) {
            nowStr = year.toString() + "0" + month.toString() + day.toString() + hours.toString() + minutes.toString() + seconds.toString();
        } else {
            nowStr = year.toString() + month.toString() + day.toString() + hours.toString() + minutes.toString() + seconds.toString();
        }
        var fileTitle = nowStr + "_exhibitors";
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    render() {
        const { countResults, showEmailModal } = this.state;
        const hasResults = countResults > 0;
        const { sitecoreContext } = this.props;
        let exhibitorId = null;
        if (!!sitecoreContext && !!sitecoreContext.exhibitor && !!sitecoreContext.exhibitor.id) {
            exhibitorId = sitecoreContext.exhibitor.id;
        }
        return <div className="imc-searchform--bar" ref={this.setWrapperRef} id={this.id}>
            <Loading showLoading={this.state.showLoading} showPageLoading={false} />
            <button className={`imc-searchform--bar--button ${this.isOpen() ? 'open' : ''}`}
                onClick={() => { this.toogleMenu(false) }}>
                {getDictionaryValue('share', 'Share')}
            </button>
            <div className={`imc-searchform--bar--dropdown ${this.isOpen() ? 'open' : ''}`}>
                <div className="imc-searchform--bar--item">
                    <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium">
                        {this.getMessage()}
                    </span>
                </div>
                <div className="imc-searchform--bar--item imc-padding--left--mediumsmall--desktop">
                    <button
                        className={`imc-button--full-bleed-mobile ${!hasResults ? "imc-button--muted-inverted" : "imc-button--primary-inverted"}`}
                        disabled={!hasResults}
                        onClick={this.downloadResults}
                    >
                        <span className="imc-icon-Download imc-margin--right--smallmedium imc-icon--center"></span>
                        {getDictionaryValue('download', 'Download')}

                    </button>
                </div>
                <div className="imc-searchform--bar--item imc-padding--left--mediumsmall--desktop">
                    <button
                        className={`imc-button--full-bleed-mobile ${!hasResults ? "imc-button--muted-inverted" : "imc-button--primary-inverted"}`}
                        disabled={!hasResults}
                        onClick={this.toogleEmailModal}
                    >
                        <span className="imc-icon-Email imc-margin--right--smallmedium imc-icon--center"></span>
                        {getDictionaryValue('email', 'Email')}
                    </button>
                </div>
            </div>
            {
                showEmailModal &&
                <EmailQueryModal
                    showModal={showEmailModal}
                    closePopup={this.toogleEmailModal}
                    // listHeading={this.props.labels.searchResults.emailModalTitle}
                    name={'Some name goes here'}
                    //query={this.state.query}
                    query={this.getQueryString()}
                    exhibitorId={exhibitorId}
                />
            }
        </div >

    }
}

function isLineSearch() {
    let currentUrl = window.location.href;
    if (currentUrl.indexOf('/lines') > 0) {
        return true;
    }
    return false;
}

function mapStateToProps(state) {
    return {
        data: state.exhibitors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, downloadSearch), dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(ShareButton)));