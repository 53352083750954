import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { getDictionaryValue } from 'utils/dictionary/index';
import {withRouter} from 'react-router';
import ImcDataLayer from 'utils/datalayer';
import { encode, decode } from 'utils/querystring';

class SortSearchResults extends React.Component {
	/**
	* @method contructor
	* @description Lifecycle method
	* @param {object} props incoming props
	*/
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			value: ''
		};
		this.changeSortOrder = this.changeSortOrder.bind(this);
		this.renderOptions = this.renderOptions.bind(this);
	}

	componentDidMount() {
		let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
		let sortOrder = "";
		
		//UXP-327
		if (Object.hasOwn(searchParams, 'sortorder')) {
			sortOrder = searchParams.sortorder;
			this.setState({ value: sortOrder });
		}else{
			let changed = false;
			const { options } = this.props;
			let defaultOption = options[0].key;
			this.setState({ value: defaultOption });
			let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
			if (defaultOption == "") {
				delete searchParams.sortorder;
			} else {
				if (Object.hasOwn(searchParams, 'sortorder')) {
					searchParams.sortorder = defaultOption;
				} else {
					searchParams.sortorder = defaultOption;
					changed = true;
				}
				
			}
			if(Object.hasOwn(searchParams, 'page')){
				delete searchParams.page;
			}
			if(changed)
				this.props.history.replace({  pathname: this.props.location.pathname,
                    search: encode(searchParams, false, false)
				})
					
			
		}
	}

    componentDidUpdate(lastProps) {
        if (lastProps.location !== this.props.location) {
            let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
            let sortOrder = "";
            if (Object.hasOwn(searchParams, 'sortorder')) {
                sortOrder = searchParams.sortorder;
                this.setState({ value: sortOrder });
            } else {
                this.setState({ value: ""});
            }
        }
    }

	changeSortOrder(event) {
		ImcDataLayer.pushInteractionEvent("search", "sort", event.target.value);
		this.setState({ value: event.target.value });
		let searchParams = decode(typeof window !== "undefined" ? window.location.search : null);
		if (event.target.value == "") {
			delete searchParams.sortorder
		} else {
            if (Object.hasOwn(searchParams, 'sortorder')) {
				searchParams.sortorder = event.target.value;
			} else {
				searchParams.sortorder = event.target.value;
			}

		}
		if (Object.hasOwn(searchParams, 'page')) {
			delete searchParams.page;
		}
		this.props.history.push({  pathname: this.props.location.pathname,
            search: encode(searchParams, false, false)
		})
	}

	renderOptions() {
		const { options } = this.props;
		if (Array.isArray(options))
			return (
				<>
					{options.map((item, index) => (
						<option key={index} value={item.key}>{item.value}</option>
					))}
				</>
			)
	}


	render() {

		return (
			<div className="imc-formfield imc-dropdown  imc-searchform--dropdown imc-searchform--dropdown--minwidth-auto">
				<label className="imc-visuallyhide" htmlFor="dropdown-sort-search">
					{getDictionaryValue('sortBy', 'Sort by')}
				</label>
				<select id="dropdown-sort-search" name="Sort by" onChange={this.changeSortOrder} value={this.state.value}>
					{this.renderOptions()}
				</select>
			</div>
		);
	}
}

export default withRouter(SortSearchResults);