import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import withTooltip from 'modules/withTooltip';
import RichTextSafe from './components/RichTextSafe';

class RichTextComponent extends React.Component {

    render() {
        const { fields, rendering,  } = this.props;
        var cssClass = "imc-rich-text";
        if (isExperienceEditorActive()) { // Experience editor view
            if (!fields) {
                return <h1 className="alarm">Datasource isn't set.{JSON.stringify(this.props)}</h1>;
            }
            cssClass += " imc-rich-text--ee";
        }
        if(this.props.fields && this.props.fields.extraClass!=null && this.props.fields.extraClass.fields.className.value !=null){
            cssClass += " "+this.props.fields.extraClass.fields.className.value;
        }
        return (
            <RichTextSafe {...this.props} className={cssClass} field={!!fields && fields.content} />
        );
    }
}

export default withTooltip(RichTextComponent);
