

import {
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { createAppState } from "modules/redux-app-state";
import PropTypes from "prop-types";
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { getDictionaryValue, renderDesktopMobileVariations } from 'utils/dictionary';
import Link from '../Link';
import * as dataActions from './actions/dataActions';
// reducers
import digitalShowroomSpecialsOverviewReducer from "./reducers";


const digitalShowroomState = createAppState();

class DigitalShowroomSpecialsOverview extends React.Component {
  constructor(props) {
    super(props);
    digitalShowroomState.reducerRegistry.register({
      digitalShowroomSpecialsOverviewReducer,
    });
    this.fetchData = this.fetchData.bind(this);
 }

  /**
  * Kick off bound fetch data
  */
  componentDidMount() {
    const { sitecoreContext } = this.props;
    if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
        this.fetchData(this.props.sitecoreContext.exhibitor.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props;
    if (sitecoreContext.exhibitor && prevProps.sitecoreContext.exhibitor && sitecoreContext.exhibitor.id !== prevProps.sitecoreContext.exhibitor.id) {
        this.fetchData(sitecoreContext.exhibitor.id);
    }
  }

  /**
  * Fetch the article data
  * @param { string } exhibitorId  used to getInfo
  */
  fetchData(exhibitorId) {
    this.props.dataActions.fetchSpecials(exhibitorId);
  }

  render() {
    const { specials } = this.props;
    let showSpecials = this.findSpecialDescriptions(specials);
    let specialsCount = (!!specials) ? specialsCount = showSpecials.length : 0;
    let specialMessage = '';
    if (!!specialsCount && specialsCount > 0) {
        if (specialsCount == 1) {
            specialMessage = getDictionaryValue('specialAvailable', 'Special Available');
        } else {
            specialMessage = getDictionaryValue('specialsAvailable', 'Specials Available');
        } 
        let showSpecials = this.findSpecialDescriptions(specials);
        console.log("Specials", showSpecials);
        return (
            <div className="imc-exhibitors--alternated-section imc-section--full-width-mobile imc-content--display-flex imc-content--display-flex-grow-1">
            <div className="imc-section imc-section--width-100-percent">
                <div className="imc-content--display-flex-wrap">
                <span className="imc-heading--h5">{getDictionaryValue('showSpecials', 'Show Specials')}</span>
                <span className="imc-type--body-2-ui imc-padding--left--small">
                    {specialsCount > 0 ? specialsCount + ' ' + specialMessage : ''}
                </span>
                </div>
                <div className="imc-content--display-flex-wrap">
                    {showSpecials.slice(0, 6).map((special, index) => {
                        return (
                        <span key={index}>
                            <br />
                            <span className="imc-type--title-1-ui">{special.description}</span>
                            <br />{(special.lineName != "") ? <span className="imc-type--color-neutral-heavy-medium">{special.lineName}</span> : <span className="imc-type--color-neutral-heavy-medium">{special.exhibitorName}</span>}
                            <br />
                        </span>
                        );
                    })}
                </div>
                <br />
                {this.renderButton(specialsCount)}
                </div>
            </div>);
    } else {
      return null;
    }
  }

  renderButton(count) {
    if (count > 0) {
      return <div className="imc-content--display-flex--right imc-content--full-width-mobile imc-products-overview--cta-bar imc-content--display-flex imc-content--display-flex-center">
        <div className="imc-products-overview--cta-bar__item imc-padding--left--xlarge--desktop imc-padding--left--medium--mobile">
          <Link href={window.location.pathname + '/specials'} className="imc-content--inline-block imc-button imc-button--primary-inverted imc-button--full-bleed-mobile">{renderDesktopMobileVariations('seeAllSpecials', `See${(count > 1) ? ' All' : ''} ${count} Special${(count > 1) ? 's': ''}`, { count: count })}</Link>
        </div>
      </div>
    } else {
      return null;
    }
  }

  findSpecialDescriptions(specials) {
    if (!!specials) {
        let specialsList = [];
        specials.forEach(market => {
            if (!!market.list) {
                market.list.forEach(special => {
                    specialsList.push(special);
                });
            }
        });
        return specialsList;
        } else {
            return [];
        }
    }

}

const defaultProps = {
  specials: [],
  specialsCount: 0,
  exhibitorId: null,
  dataActions: {
    fetchSpecials: () => { },
  }
};

const propTypes = {
  specials: PropTypes.array,
  specialsCount: PropTypes.number,
  exhibitorId: PropTypes.number,
  dataActions: PropTypes.object
};

function mapStateToProps(state) {
  let _props = {};

  if (!!state.digitalShowroomSpecialsOverviewReducer) {
    Object.assign(_props, {
      specials: state.digitalShowroomSpecialsOverviewReducer.specials,
      specialsCount: state.digitalShowroomSpecialsOverviewReducer.specialsCount,
      showLoading: state.digitalShowroomSpecialsOverviewReducer.showLoading,
    });
  } else {
    Object.assign(_props, {
      specials: state.specials,
      specialsCount: state.specialsCount,
      showLoading: state.showLoading,
    });
  }

  return _props;
}

/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
        */
function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

export default withSitecoreContext()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalShowroomSpecialsOverview))
);
