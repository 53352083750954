import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

// Modules
import { Image } from 'modules/image/index';
import Link from '../../components/Link';

// Utils
import ImcDataLayer from 'utils/datalayer';

class NewSearchItemThumb extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    newWindow: PropTypes.bool,
    product: PropTypes.object,
    url: PropTypes.string,
    isNew: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onProductClick = this.onProductClick.bind(this);
  }

  onProductClick() {
    if (!!this.props.product) {
      ImcDataLayer.PushProductClick(this.props.product);
    }
  }

  render() {
    const { name, image, isNew, newWindow, url } = this.props;

    return (
      <div className='searchItemThumb--container'>
        <div
          className={'searchItemThumb--image-container'}
        >
          {isNew &&
            <div class='searchItemThumb--new'>NEW</div>
          }
          <Image
            alt={name}
            src={image}
            className='searchItemThumb--image'
          />
          <Link className='after'
                href={url}
                target={newWindow ? '_blank' : ''}
                onClick={this.onProductClick}>
            <div className='searchItemThumb--button-container'>
              <div
                className='searchItemThumb--button'
                href={url}
                target={newWindow ? '_blank' : ''}
                onClick={this.onProductClick}
              >
                See Details
              </div>
            </div>
          </Link>
        </div>
        <p
          className='searchItemThumb--text'
        >
          {name}
        </p>
      </div>
    )
  }
}

NewSearchItemThumb.defaultProps = {
  multiSelectMode: false,
};
export default withSitecoreContext()(
  withRouter(NewSearchItemThumb)
);