import React from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const InsetContentBlockComponent = (props) => {
  if (!props.fields) {
    if (isExperienceEditorActive()) {
      return <h1 className='alarm'>Datasource isn't set.</h1>;
    }

    return (
      <h1 className='alarm'>
        Data is not provided. Contact administrators, please.
      </h1>
    );
  }
  
  var className = "";
  if(props.fields.class){
    className = props.fields.class.fields.className.value;
  }
  return (
    <section className={`imc-section imc-inset-content imc-section--padded-xlarge imc-section--full-width-mobile imc-content-nav-container imc-content--center imc-section--alt-white ${className}`}>
      <Placeholder name='imc-jss-content-section' rendering={props.rendering} />
    </section>
  );
}


export default InsetContentBlockComponent;