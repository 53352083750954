import React from "react";
import {
  Link,
  RichText,
  isExperienceEditorActive,
  Image,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import "./styles.css";
import ImcDataLayer from "utils/datalayer";

class FooterNavigationComponent extends React.Component {
  /**
   * @method constructor
   * @description
   * By default sets the expanded state to true
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);
    if (!props) {
      if (isExperienceEditorActive()) {
        return <h1 className="alarm">Datasource isn't set.</h1>;
      }
      return (
        <h1 className="alarm">
          Data is not provided. Contact administrators, please.
        </h1>
      );
    }
    this.state = {
      expanded: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render(sitecoreContext) {
    const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    const { fields, rendering } = this.props;
    if (isEditing && typeof fields === "undefined") {
      return (
        <h1 className="alarm">
          Datasource isn't set.{JSON.stringify(this.props)}
        </h1>
      );
    } else if (typeof fields === "undefined") {
      //return a nice footer placeholder
      return (
        <footer id="footer-content" className="imc-footer">
          <div
            className={`imc-section imc-section--padded imc-section--full-width-mobile imc-content--desktop-display-flex imc-content--display-flex-grow-1`}
          >
            <div className="imc-footer__content imc-content--center-mobile"></div>
            <div className="imc-footer__right imc-hide-print"></div>
          </div>
        </footer>
      );
    } else if (!fields || !fields.data || !fields.data.model) {
      //return a nice footer placeholder
      return (
        <footer id="footer-content" className="imc-footer">
          <div
            className={`imc-section imc-section--padded imc-section--full-width-mobile imc-content--desktop-display-flex imc-content--display-flex-grow-1`}
          >
            <div className="imc-footer__content imc-content--center-mobile">
              <Placeholder
                name="footer-languages-placeholder"
                rendering={rendering}
              />
            </div>
            <div className="imc-footer__right imc-hide-print"></div>
          </div>
        </footer>
      );
    }

    var helpfulClass =
      "imc-expand-collapse  imc-expand-collapse--mobile imc-expand-collapse--no-flex";

    if (this.state.expanded == true) {
      helpfulClass = helpfulClass + " imc-expand-collapse--expanded";
    }
    return (
      <footer
        id="footer-content"
        className="imc-section--padded imc-footer imc-section--full-width-mobile "
      >
        <div className={'imc-section imc-section--padded imc-section--full-width-mobile imc-content--desktop-display-flex imc-content--display-flex-grow-1'}>
          <div className="imc-footer__content imc-content--center-mobile ">
            <div className="imc-vr imc-footer-logo">
              <a
                href="/"
                className="imc-content--inline-block"
                data-xpath="footer.logo"
              >
                {fields.data.model.logo.data.value.svgCode !== undefined && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fields.data.model.logo.data.value.svgCode,
                    }}
                  />
                )}
                {fields.data.model.logo.data.value.svgCode === undefined && (
                  <Image
                    className="imc-footer__logo"
                    field={fields.data.model.logo.data.value}
                  />
                )}
              </a>
            </div>
            <div
              className="imc-content imc-content--invert imc-content--center-mobile imc-vr--xxxlarge imc-footer__address imc-hide-print"
              data-xpath="footer.address"
            >
              <a
                href={fields.data.model.mapUrl.data.value}
                className="imc-link imc-link--invert"
                target="_blank"
              >
                <RichText field={fields.data.model.address.data}></RichText>
              </a>
            </div>
            <Placeholder
              name="footer-languages-placeholder"
              rendering={rendering}
            />
          </div>
          {
            (fields.data.model.linkSectiom!=null && fields.data.model.linkSectiom.length > 0)?
              <div className="imc-footer__right imc-hide-print">
                <nav
                  className="imc-footer-links imc-content imc-content--invert"
                  data-expand-collapse="mobile"
                  data-add-flex="true"
                  data-scroll-to-top="true"
                  data-scroll-offset="imc-stickyheader"
                >
                  <div className={helpfulClass}>
                    <div
                      tabIndex="0"
                      role="button"
                      className="imc-expand-collapse__heading "
                    >
                      <a
                        onClick={this.handleClick}
                        tabIndex="0"
                        role="button"
                        aria-expanded="false"
                        aria-label='<div class="imc-footer-links__heading">Helpful Links</div>'
                      >
                        <div>
                          <div className="imc-footer-links__heading imc-button--white-font">
                            Helpful Links{" "}
                          </div>
                        </div>
                      </a>
                    </div>

                    {fields.data.model.linkSectiom.map((section) =>
                      section.column.map((column, index) => (
                        <div key={index} className="imc-expand-collapse__content ">
                          {column.link.map((link, index) => (
                            <div
                              key={index}
                              className="imc-footer-links__content_no_flex"
                            >
                              <div
                                id={index}
                                className="imc-footer-links__item imc-vr--large-desktop"
                              >
                                {
                                  <Link
                                    key={index}
                                    onClick={() =>
                                      ImcDataLayer.pushInteractionEvent(
                                        "navigation",
                                        "footer",
                                        link.nodeName
                                      )
                                    }
                                    field={link.link.data.value}
                                    className="imc-link imc-link--invert"
                                  />
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    )}
                  </div>
                </nav>
                <p className="imc-content imc-content--invert imc-content--right imc-footer__disclosure imc-content--center-mobile">
                  &copy; {new Date().getFullYear()}{" "}
                  {fields.data.model.copyright.data.value}
                </p>
              </div>
            :
            null
          }
        </div>
      </footer>
    );
  }
}


export default FooterNavigationComponent;