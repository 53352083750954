import { initialState } from './initialState';
import { MarketInterface, PlanInferface, ShowroomInterface } from './interface';
import { compareShowrooms, addShowroomToList, removeShowroomFromList, summarizeActivity } from '../helpers/helpers';

// Define the type of initialState
type InitialStateType = PlanInferface;

interface Action {
    type: string;
    plan?: any;
    isLoading?: boolean;
    exhibitorId?: any;
    lineGuid?: any;
    showroom?: ShowroomInterface;
    isDrawerOpen?: boolean;
    market?: MarketInterface;
}

const plan = (state: InitialStateType = initialState, action: Action): InitialStateType => {
    switch (action.type) {
        case 'FETCH_MARKET_SUCCESS':
            return {
                ...state,
                loaded: true,
                market: action?.plan?.market || state.market,
                isFetching: false,
            };
        case 'FETCH_PLAN_SUCCESS':
            const plan = {
                ...action?.plan,
                isFetching: false,
                isDrawerOpen: (state.isDrawerOpen) ? state.isDrawerOpen : false,
                loaded: true,
            };
            return {
                ...plan,
            } || initialState;
        case 'FETCH_PLAN_ERROR':
            return {
                ...state,
                isFetching: false,
                loaded: true,
            } || {
                ...initialState,
                isRegistered: false,
                loaded: true,
                isDrawerOpen: (state.isDrawerOpen) ? state.isDrawerOpen : false,
                isFetching: false,
            };
        case 'FETCH_PLAN_LOADING':
            return {
                ...state,
                isFetching: true,
            };
        case 'NOT_REGISTERED_SUCCESS':
            return {
                ...state,
                isRegistered: false,
                loaded: true,
                isFetching: false,
            }
        case 'TOGGLE_DRAWER':
            return {
                ...state,
                isDrawerOpen: action.isDrawerOpen,
            };
        case 'ADD_SHOWROOM_SUCCESS':
            let addPlan = {...state, showrooms: addShowroomToList(action.showroom, [...state.showrooms], action.lineGuid)};
            console.log(`New Plan`, addPlan);
            return {...addPlan,
                activitySummary: summarizeActivity(state.showrooms),
            };
        case 'REMOVE_SHOWROOM_SUCCESS':
            let removePlan =  {...state, showrooms: removeShowroomFromList(action.showroom, [...state.showrooms], (action.lineGuid && action?.showroom?.lines && action?.showroom?.lines?.length == 1) ? undefined : action.lineGuid)};
            return {...removePlan,
                activitySummary: summarizeActivity(state.showrooms),
            };
        case 'SET_LOADING_STATE':
            if (action.isLoading) {
                let loading = [
                    ...state.loading,
                    {
                        exhibitorId: action.exhibitorId,
                        lineGuid: action.lineGuid
                    }
                ];
                return {...state, loading: loading};
            } else {
                let loading = state.loading.filter((showroom: any) => !compareShowrooms(showroom, action, action.lineGuid));
                return {...state, loading: loading};
            }
        default:
            return state;
    }
};

export default plan;