import React, { useState } from "react";
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { PreferencesForm } from "./components/PreferencesForm";
import { RefuseEmailForm } from "./components/RefuseEmailForm";
import { getDictionaryValue } from "../../utils/dictionary";

const ChannelPreferenceCenterComponent = props => {
  const {
    title,
    preferencesSectionsTitleText,
    optOutChannelText,
    sections,
    optOutChannelWarningText,
    contactId,
    isChannelOptOut,
    isGlobalOptOut,
    channelOptOutListId,
    globalOptOutListId,
    errorWhileSavingMessage,
    savedSuccessfullyMessage,
    isVipOptOut,
    vipListId,
    messageId,
  } = props.fields;

  const [isDisabledPreferences, setDisabledPreferences] = useState(
    isGlobalOptOut
  );
  if (!props.fields || props.fields.renderingError) {
    if (isExperienceEditorActive()) {
      return (
          <h1 className="alarm">
            {(props &&
                props.fields &&
                props.fields.renderingError &&
                props.fields.renderingError.message) ||
            "Datasource isn't set."}
          </h1>
      );
    }
    return null;
  }

  return (
    <>
      <Text
        tag={"h1"}
        className={`imc-profile-heading imc-padding--bottom--xxlarge imc-padding--top--large`}
        field={{ value: title }}
      />
      <PreferencesForm
        categories={sections}
        unsubscribeLabel={optOutChannelText}
        unsubscribeDescription={optOutChannelWarningText}
        subTitle={preferencesSectionsTitleText}
        contactId={contactId}
        channelOptOutListId={channelOptOutListId}
        isChannelOptOut={isChannelOptOut}
        errorMessage={errorWhileSavingMessage}
        successMessage={savedSuccessfullyMessage}
        isGlobalOptOut={isDisabledPreferences}
        isVipOptOut = {isVipOptOut}
        vipListId = {vipListId}
        messageId = {messageId}
      />
      <RefuseEmailForm
        refuseOfEmailsLabel={getDictionaryValue(
          "channelPreferenceCenter_OptOutAllChannelsText",
          "If you no longer wish to receive any email from International Market Center, confirm \n" +
            "your email and submit. "
        )}
        warningLabel={getDictionaryValue(
          "channelPreferenceCenter_OptOutAllChannelsWarning",
          "Emails from Casual Market Atlanta are part of International Market Centers, which includes Atlanta Market, Atlanta Apparel and AmericasMart as well as Las Vegas Market and IMC High Point Market, among others. Unsubscribing from all will inhibit our ability to facilitate your experience on any of our digital sites and physical markets."
        )}
        contactId={contactId}
        isGlobalOptOut={isGlobalOptOut}
        globalOptOutListId={globalOptOutListId}
        errorMessage={errorWhileSavingMessage}
        successMessage={savedSuccessfullyMessage}
        messageId = {messageId}
        cbSetDisabledPreferences={isDisable =>
          setDisabledPreferences(isDisable)
        }
      />
    </>
  );
};

export default ChannelPreferenceCenterComponent;

ChannelPreferenceCenterComponent.propTypes = {
  fields: {
    contactId: PropTypes.string,
    sections: PropTypes.array,
    title: PropTypes.string,
    preferencesSectionsTitleText: PropTypes.string,
    optOutChannelText: PropTypes.string,
    optOutChannelWarningText: PropTypes.string,
    errorWhileSavingMessage: PropTypes.string,
    savedSuccessfullyMessage: PropTypes.string,
    isChannelOptOut: PropTypes.bool,
    channelOptOutListId: PropTypes.string,
    isGlobalOptOut: PropTypes.bool,
    globalOptOutListId: PropTypes.string,
    renderingError: PropTypes.string,
    unsubscribedSuccessfully: PropTypes.string
  }
};
ChannelPreferenceCenterComponent.defaultProps = {
  fields: {
    sections: [
      {
        subscriptionOptions: [
          {
            title: "Updates",
            optInListId: "9e6cdc65-f61d-44ed-ea6f-651864a7775c",
            checked: false
          }
        ],
        title: "Consumer communication profile:"
      },
      {
        subscriptionOptions: [
          {
            title: "Registration",
            optInListId: "118e3ea2-b713-47fa-8e6e-d24a8fa69578",
            checked: false
          },
          {
            title: "Third Party",
            optInListId: "97cfa4f9-da65-4906-88aa-9f740cc5525c",
            checked: false
          }
        ],
        title: "Buyer communication profile:"
      },
      {
        subscriptionOptions: [
          {
            title: "copies",
            optInListId: "ddf669cc-b388-4728-8de8-f3f8e1e0f0bd",
            checked: false
          },
          {
            title: "newsletter",
            optInListId: "3a44da37-2a88-4b43-be62-4fddb833ec9d",
            checked: false
          }
        ],
        title: "Exhibitor communication profile:"
      },
      {
        subscriptionOptions: [
          {
            title: "IMS Hight Point Market Press Release",
            optInListId: "f2f2567e-84eb-42d1-f7d9-ad4e062b9137",
            checked: true
          },
          {
            title: "Las Vegas Market Press Release",
            optInListId: "b4653cfe-e340-4744-d160-1b3714c59e52",
            checked: false
          }
        ],
        title: "Media communication profile:"
      }
    ],
    contactId: "380324ee-f6aa-0000-0000-05d59039ab87",
    title: "\u003Cchannel name\u003E Communication Porfile Center",
    preferencesSectionsTitleText:
      "I would like to receive the following information about \u003Cchannel name\u003E:",
    optOutChannelText: "Don't send me emails from \u003Cchannel name\u003E",
    optOutChannelWarningText:
      "Warning: Unsubscribing from all emails may limit your overall experience with \u003Cchannel name\u003E.",
    errorWhileSavingMessage: "server error while saving has occurred",
    savedSuccessfullyMessage: "saved successfully",
    unsubscribedSuccessfully: "Your email was successfully confirmed and you will no longer receive emails from International Market Centers.",
    isChannelOptOut: false,
    channelOptOutListId: "687e595e-ec68-45aa-e234-a2d59be83793",
    isGlobalOptOut: false,
    globalOptOutListId: "e5e64825-66aa-4021-df07-766969de9bef",
    vipListId: 'd7f1a11b-476e-4175-99b6-f5181b3e0c83',
    isVipOptOut: true
  }
};
