// Library dependencies
import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { isMobileOnly, isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';

import { RichText, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import StandaloneVideoComponent from '../Standalone-Video-Component';

// Connecting additional modules to Swiper
SwiperCore.use([Navigation, Pagination, Thumbs]);

class MediaCarouselComponent extends Component {

    /**
     * @method constructor
     * @description On instantiation dynamically sets the moduleName based on the `mediaGalleryId` and
     * creates aliases to methods
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
            navTopPossition: null,
            thumbsSwiper: null,
            nav: null,
            currentlyMobile: null,
        };

        this.createSlides = this.createSlides.bind(this);
        this.createThumbs = this.createThumbs.bind(this);
    }

    componentDidMount() {
        const { currentlyMobile } = this.state;

        if (!currentlyMobile && isMobileOnly) {
            this.setState({ currentlyMobile: isMobileOnly })
        }
    }

    createSlides(items) {
        return items.map((item, index) => {
            const video = item.fields.videoCardLink;

            if (video) {
                return (
                    <SwiperSlide key={`slide-${index}`}>
                        <StandaloneVideoComponent fields={{
                            videoLink: item.fields.videoCardLink.value,
                            placeholderImage: item.fields.thumbnail.value,
                        }}
                        />
                    </SwiperSlide>
                )
            }

            return (
                <SwiperSlide key={`slide-${index}`}>
                    <img
                        src={item.fields.imageCardImage.value.src}
                        alt={item.fields.imageCardImage.value.alt}
                    />
                </SwiperSlide>
            )
        })
    }

    createThumbs(items) {
        return items.map((item, index) => {
            const video = item.fields.videoCardLink;
            if (video) {
                return (
                    <SwiperSlide key={`thumb-${index}`}>
                        <StandaloneVideoComponent
                            fields={{
                                videoLink: item.fields.videoCardLink.value,
                                placeholderImage: item.fields.thumbnail.value,
                                fields: item.fields,
                                thumbClicked: false,
                            }}
                            videoShouldNotPlay
                        />
                    </SwiperSlide>
                )
            }

            return (
                <SwiperSlide key={`thumb-${index}`}>
                    <img
                        src={item.fields.imageCardImage.value.src}
                        alt={item.fields.imageCardImage.value.alt}
                    />
                </SwiperSlide>
            )
        })
    }

    /**
     * Render media gallery
     * @returns {XML} media gallery
                        */
    renderGallery() {
        let { carouselItems, description, title } = this.props.fields;
        const { currentlyMobile, currentIndex, thumbsSwiper } = this.state;
        const slides = this.createSlides(carouselItems);
        const thumbs = this.createThumbs(carouselItems);
        const width = currentlyMobile ? 320 : 600;

        return (
            <div
                style={{
                    width: `100%`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: `${width}px`,
                        overflow: 'hidden',
                    }}
                >

                    {/* Title and Description */}
                    {title && <h1
                        className={`imc-heading imc-heading--alpha-giga imc-heading--large imc-heading--center
                    imc-vr--medium`}
                        data-xpath="mediagallery.header"
                    >
                        {title.value}
                    </h1>}
                    {description && <p
                        className={`imc-content  imc-content--secondary
                    imc-content--center imc-vr--xxxlarge imc-content--delta-gamma`}
                        data-xpath="mediagallery.introtext"
                    > <RichText field={description} />
                    </p>}

                    {/* Main Carousel */}
                    <Swiper
                        id="main"
                        thumbs={{ swiper: thumbsSwiper }}
                        width={width}
                        slidesPerView={1}
                        onSlideChange={(swiper) => this.setState({ currentIndex: swiper.activeIndex })}

                        // Set nav state for use in custom buttons
                        onSwiper={(swiper) => {
                            this.setState({
                                nav: swiper.navigation
                            })
                        }}
                        navigation
                    >
                        {slides}
                    </Swiper>

                    {/* Nav buttons */}
                    {!isMobileOnly && (<div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <button className='imc-searchform--bar--button' onClick={this.state.nav?.onPrevClick}>
                            Prev
                        </button>
                        <button className='imc-searchform--bar--button' onClick={this.state.nav?.onNextClick}>
                            Next
                        </button>
                    </div>)}

                    {/* Current Image Description */}
                    {!isMobile && (
                        <>
                            <div className='carousel--description-container'>
                                <p className='carousel--description-text'>
                                    {carouselItems[currentIndex].fields?.imageCardDescription?.value ||
                                        carouselItems[currentIndex].fields?.videoCardDescription?.value}
                                </p>
                            </div>
                            {/* Thumbnail Gallery */}
                            <Swiper
                                id='thumbs'
                                width={width}
                                spaceBetween={5}
                                slidesPerView={3}
                                onSwiper={(swiper) => this.setState({
                                    thumbsSwiper: swiper,
                                })}
                                watchSlidesVisibility
                                watchSlidesProgress
                            >
                                {thumbs}
                            </Swiper>
                        </>
                    )}
                </div>
            </div>
        )
    }

    /**
    * @method render
    * @description Renders the MediaGallery DOM element
    * @returns {*} Rendered component
    */
    render() {
        const { fields } = this.props;

        if (!fields) {
            return null;
        }
        return this.renderGallery();
    }
}

export default withSitecoreContext()(MediaCarouselComponent);

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{ mediaGalleryDotNavigation, mediaGalleryLength, navType }}
                        */
MediaCarouselComponent.propTypes = {
    defaultNavPos: PropTypes.bool,
    dragSensitivity: PropTypes.number,
    draggable: PropTypes.bool,
    galleryType: PropTypes.oneOf(['full', 'media', 'notification', 'zoomableImgs', 'exhibitor']),
    mediaGalleryLength: PropTypes.number, // Number of items in the mediaGallery,
    children: PropTypes.arrayOf(PropTypes.object),
    galleryHeading: PropTypes.string, // Gallery Heading
    gallerySubheading: PropTypes.string, // Gallery Heading
    thumbSize: PropTypes.number, // Determines number of columns for the gallery view
    marginSpace: PropTypes.number, // Determines number of columns for the gallery view
    expandable: PropTypes.bool,
    slideContentTitle: PropTypes.string,
    exhibitorName: PropTypes.string,
    exhibitorId: PropTypes.string,
    lineName: PropTypes.string,
    productId: PropTypes.string,
    productCategory: PropTypes.string,
};

/**
 * @property defaultProps
 * @type {{
                            * mediaGalleryDotNavigation: boolean,
                            * mediaGalleryLength: number,
                            * navType: string
 * }}
                        */
MediaCarouselComponent.defaultProps = {
    defaultNavPos: false,
    dragSensitivity: 100,
    draggable: false,
    galleryType: 'media', // note: there is only one type currently
    mediaGalleryLength: 0,
    children: null,
    thumbSize: 119,
    marginSpace: 20,
    expandable: false,
    galleryHeading: null,
    gallerySubheading: null,
    slideContentTitle: null,
    exhibitorName: null,
    exhibitorId: null,
    lineName: null,
    productId: null,
    productCategory: null,
};