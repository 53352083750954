import React from 'react';
import { Placeholder, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { array } from 'yargs';
import GridColumnComponent from '../Grid-Column-Component';


// ---------------------- //
// define props interface //
// ---------------------- //
type FlexGridProps = {
    params: {
        columnCount: string;
        totalColumns: string;
        mobileColumnCount: string;
        tabletColumnCount: string;
        horizontalAlignment: string;
        verticalAlignment: string;
        columnLayout: string;
        twoColumnWidths: string;
        padding: string;
        reverseMobile: string;
        extraClasses: string;
    };
    rendering?: any;
};
// ---------------------- //


// ------------------ //
// FlexGrid Component //
// ------------------ //
const FlexGrid: React.FC<FlexGridProps> = (props) => {

    // ----------------------------- //
    // build grid classes from props //
    // ----------------------------- //
    const buildGridClasses = (params?: any) => {

        // return string for class names
        let className = "imc-gallery am-grid";

        // get values from serialized strings

        // Desktop Columns Per Row (default 2)
        const columnCount = (params.columnCount && JSON.parse(params.columnCount).className.value) ? parseInt(JSON.parse(params.columnCount).className.value) : 2;

        
        // Tablet Columns Per Row (default to Ceiling of columnCount / 2 OR default to 2 for 2 columnCount)
        const tabletColumnCount = (params.tabletColumnCount && JSON.parse(params.tabletColumnCount).className.value) ? parseInt(JSON.parse(params.tabletColumnCount).className.value) : (columnCount && columnCount == 2) ? 2 : Math.ceil(1.0 * columnCount / 2.0);
        
        // Mobile Columns Per Row (default to Ceiling of tabletColumnCount / 2)
        const mobileColumnCount = (params.mobileColumnCount && JSON.parse(params.mobileColumnCount).className.value) ? parseInt(JSON.parse(params.mobileColumnCount).className.value) : Math.ceil(1.0 * tabletColumnCount / 2.0);
        
        // only use this for 2 column grids
        const twoColumnWidths = (params.twoColumnWidths) ? JSON.parse(params.twoColumnWidths).className.value : null;

        // vertical alignment (empty default middle)
        const verticalAlignment = (params.verticalAlignment) ? ' ' + JSON.parse(params.verticalAlignment).className.value : '';

        // horizontal alignment (empty default is left)
        const horizontalAlignment = (params.horizontalAlignment) ? ' ' + JSON.parse(params.horizontalAlignment).className.value : '';

        // padding between columns ... default is none
        const padding = (params.padding) ? ' ' + JSON.parse(params.padding).className.value : '';

        // reverse grids when 1 column on tablet or mobile
        const reverseMobile = (params.reverseMobile) ? ' imc-gallery--reverse-mobile' : '';

        // any extra classes .. authorable
        let extraClasses = (params.extraClasses && JSON.parse(params.extraClasses).className) ? ' ' + JSON.parse(params.extraClasses).className.value : '';
        extraClasses += (params.extraClasses && JSON.parse(params.extraClasses).classes) ? ' ' + JSON.parse(params.extraClasses).classes.map((c?: any) => {
            return c.className.value;
        }).join(' ') : '';


        // add column count
        className += ` imc-gallery--1-${(columnCount) ? columnCount : "2"}`;

        // add tablet column count
        className += (tabletColumnCount) ? ` imc-gallery--1-${(tabletColumnCount) ? tabletColumnCount : "2"}--tablet` : '';

        // add mobile column count
        className += (mobileColumnCount) ? ` imc-gallery--1-${(mobileColumnCount) ? mobileColumnCount : "2"}--mobile` : '';

        // add column widths (50-50 for anything but 2 columns)
        className += ` imc-gallery--${(twoColumnWidths && columnCount && columnCount == 2) ? twoColumnWidths : "50-50" }`;

        // add vertical alignment
        className += verticalAlignment;

        // add horizontal alignment
        className += horizontalAlignment;

        // add padding class
        className += padding;

        // add reverse mobile class
        className += reverseMobile

        // add extra classes
        className += extraClasses;

        return className;

    }
    // ----------------------------- //
    
    // ----------- //
    // render JSX  //
    // ----------- //

    // handle rendering before params selected in Experience Editor
    if (!props || !props.params || !props.rendering) {
        return (
            <div>
                <strong>Loading Rendering Parameters</strong>
                <br />
                <svg width="100" height="100"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading" /></svg>
            </div>
        );
    }

    const totalColumns = (props.params.totalColumns && JSON.parse(props.params.totalColumns).className.value) ? parseInt(JSON.parse(props.params.totalColumns).className.value) : parseInt(JSON.parse(props.params.columnCount).className.value);
    const numbers = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
    return (
        <div className={`${buildGridClasses(props.params)}`}>
            {/* ------------------------------ */}
            {/* loop through the placeholders  */}
            {/*     and get  their items       */}
            {/*     and placeholder name       */}
            {/* without any integer characters */}
            {/* ------------------------------ */}
            {Array(totalColumns).fill(undefined).map((item, index) => {
                let placeholder = {
                    name: '',
                    data: [],
                }
                let characters = (index + 1).toString();
                numbers.forEach((number, integer) => {
                    let regex = new RegExp(integer.toString(), 'g')
                    characters = characters.replace(regex, number);
                });
                placeholder.name = `imc-grid-column-${characters}-placeholder`;
                placeholder.data = props.rendering.placeholders[`imc-grid-column-${characters}-placeholder`]
                return placeholder;
            }).map((placeholder) => {
                // ---------------------------- //
                // if a grid column placeholder //
                //     then don't wrap with     //
                //    additional grid column    //
                // ---------------------------- //
                if (props.rendering.placeholders[placeholder.name] && props.rendering.placeholders[placeholder.name].length == 1 && props.rendering.placeholders[placeholder.name][0].componentName == "Grid-Column-Component") {
                    return (
                        <Placeholder name={placeholder.name} rendering={props.rendering} />
                    );
                }
                // ---------------------------- //
                //  same condition but for EE   //
                // ---------------------------- //
                if (isExperienceEditorActive() && props.rendering.placeholders[placeholder.name] && props.rendering.placeholders[placeholder.name].length == 5 && props.rendering.placeholders[placeholder.name][3].componentName == "Grid-Column-Component") {
                    return (
                        <Placeholder name={placeholder.name} rendering={props.rendering} />
                    );
                }
                // ---------------------------- //
                //  default placeholder column  //
                // ---------------------------- //
                return (
                    <div key={placeholder.name} className="imc-gallery__item imc-gallery__item--minheight imc-content-nav-container--column">
                        <Placeholder name={placeholder.name} rendering={props.rendering} />
                    </div>
                );
                // ---------------------------- //
            })}
        </div>
    );
    // ----------- //

}
// ------------------ //

export default FlexGrid;