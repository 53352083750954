import {
    SET_EXHIBITOR_DETAIL,
    SET_EXHIBITOR_DETAIL_ERR,
    SET_LINE_DETAIL,
    SET_LINE_DETAIL_ERR,
    SHOW_LOADING,
    UPDATE_FROM_CONTEXT,
    CLEAR_STATE
} from './actionTypes';
import ExhibitorsAPI from 'api/exhibitor';


/**
 * Instantiates the Exhibitors API
 * @type {ExhibitorsAPI}
 * @private
 */
const _exhibitorsApi = new ExhibitorsAPI();



/**
 * Action to get Digital Showroom Exhibitor Details
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitorDetail(exhibitorId, pageId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });
        
        return _exhibitorsApi.getExhibitorDetail(exhibitorId, pageId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length === 1) {
                const exhibitor = response.data[0];
                dispatch({
                    type: SET_EXHIBITOR_DETAIL,
                    companyDirectoryImage: ('logoAndImage' in exhibitor && exhibitor.logoAndImage) ? exhibitor.logoAndImage.heroImageOriginal : '',
                    companyLogoImage: ('logoAndImage' in exhibitor && exhibitor.logoAndImage) ? exhibitor.logoAndImage.logoImageOriginal : '',
                    companyName: ('companyDetails' in exhibitor && exhibitor.companyDetails) ? exhibitor.companyDetails.companyName : '',
                    companySite: ('companyInformation' in exhibitor && exhibitor.companyInformation) ? exhibitor.companyInformation.companyWebsiteUrl : '',
                    copyBlock: ('companyInformation' in exhibitor && exhibitor.companyInformation) ? exhibitor.companyInformation.completeDescription : '',
                    lineName: exhibitor.lineName,
                    activeLeases: ('companyDetails' in exhibitor && exhibitor.companyDetails) ? exhibitor.companyDetails.activeLeases : [],
                    productCategories: exhibitor.productCategories,
                    shopZioURL: exhibitor.imcdiUrl,
                    productsCount: exhibitor.productsCount,
                    showroom3DUrl: exhibitor.showroom3DUrl,
                    exhibitId: exhibitorId,
                    lineId: null

                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_EXHIBITOR_DETAIL_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        }).catch((err) =>{
            dispatch({
                type: SET_EXHIBITOR_DETAIL_ERR,
                status: false,
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    };
}

export function updateIdsFromSitecoreContext(sitecoreContext) {
    return (dispatch, getState) => {
        let exhibitId, lineId;
        const state = getState();
        if (typeof sitecoreContext !== 'undefined') {
            if (typeof sitecoreContext.exhibitor !== 'undefined')
            exhibitId = sitecoreContext.exhibitor.id;
            if (typeof sitecoreContext.lineProduct !== 'undefined') {
            lineId = sitecoreContext.lineProduct.lineId;
            exhibitId = sitecoreContext.lineProduct.exhibitId;
            }
        }
        
       if(state.digitalShowroomInfoReducer.lineId != lineId || state.digitalShowroomInfoReducer.exhibitId != exhibitId)
        dispatch({
            type: UPDATE_FROM_CONTEXT,
            exhibitId: exhibitId,
            lineId: lineId
        });
    }
}

function switchImageExtension(image)
{
    return image.relativePath.substr(0, image.relativePath.lastIndexOf(".")) + image.fileName.substr(image.fileName.lastIndexOf("."));
}

/**
 * Action to get Digital Showroom Line Details
 * @param {array} exhibitorId Id of Exhibitor
 * @returns {function(*)} Promise object with fetch from showrooms api
 */
export function getExhibitorLineDetail(lineId, exhibitId) {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOADING,
            status: true,
        });

        return _exhibitorsApi.getExhibitorLineDetail(lineId, exhibitId).then((response) => {
            if (response && Array.isArray(response.data) && response.data.length >= 1) {
                const line = response.data.find(x=>x.exhibitorId == exhibitId);
                dispatch({
                    type: SET_LINE_DETAIL,
                    companyDirectoryImage: line.heroImage,
                    companyLogoImage: line.manufacturerLogoURL,
                    companyName: ('exhibitorName' in line) ? line.exhibitorName : '',
                    companySite: ('lineWebsite' in line) ? line.lineWebsite : '',
                    copyBlock: ('completeDescription' in line) ? line.completeDescription : '',
                    lineName: ('lineName' in line) ? line.lineName : '',
                    activeLeases: ('companyDetails' in line && line.companyDetails) ? line.companyDetails.activeLeases : [],
                    productCategories: line.productCategories,
                    shopZioURL: line.shopZioLink,
                    productsCount: line.productCount,
                    showroom3DUrl: line.showroom3DUrl,
                    lineId: lineId,
                    exhibitId: exhibitId
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            } else {
                dispatch({
                    type: SET_LINE_DETAIL_ERR,
                    status: false,
                });
                dispatch({
                    type: SHOW_LOADING,
                    status: false,
                });
            }
        }).catch((err) =>{
            dispatch({
                type: SET_LINE_DETAIL_ERR,
                status: false,
            });
            dispatch({
                type: SHOW_LOADING,
                status: false,
            });
        });
    };
}

export function clearState(){
    return (dispatch) => {
        dispatch({
            type: CLEAR_STATE
        });
    }
}