
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';
import SaveSearches from '../Save-Searches';
import { InformationCard } from 'modules/informationcard';
import SearchController from 'modules/search/controllers/SearchController.jsx';

import UserUtility from "utils/userutility";

/**
 * @property defaultProps
 * @type {{exhibitors: *, filters: *, selectedTab: *, resultsPerPage: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, exhibitorsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
  exhibitors: PropTypes.array.isRequired,
  featuredExhibitors: PropTypes.array.isRequired,
  filters: PropTypes.object,
  selectedTab: PropTypes.object,
  resultsPerPage: PropTypes.number.isRequired,
  pagenum: PropTypes.number.isRequired,
  //updateUrl: PropTypes.func.isRequired,
  dataActions: PropTypes.object,
  filterActions: PropTypes.object,
  actions: PropTypes.object,
  lineDetail: PropTypes.bool,
  exhibitorsCount: PropTypes.number,
  exhibitorLabels: PropTypes.object,
  filterErr: PropTypes.number,
  exhibitorName: PropTypes.string,
  filterKey: PropTypes.string,
  savedContent: PropTypes.object,
  location: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, exhibitors: {}, pagenum: number, resultsPerPage: number, filters: {},
 * dataActions: {fetchExhibitors: defaultProps.dataActions.fetchExhibitors},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, exhibitorsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
  lineDetail: false,
  exhibitors: [],
  featuredExhibitors: [],
  pagenum: 1,
  resultsPerPage: 15,
  dataActions: {
    fetchExhibitors: () => { },
    fetchFeaturedExhibitors: () => { },
  },
  filterActions: {
    getFilters: () => { },
  },
  actions: {
  },
  exhibitorsCount: 0,
  exhibitorLabels: {
    productCount: 'Showing $1 Exhibitors',
    productQueryPrefix: 'for',
    seeAllExhibitors: 'Click here to see all exhibitors',
  },
  filterErr: null,
  globalLabels: {
    pimCatalogImageUrl: 's3.amazonaws.com',
  },
  exhibitorName: '',
  filterKey: 'products',
  savedContent: {},
  location: {},
  tab: 'information',
};

/**
 * Key used for the Filter component
 * @type {string}
 */
const productsFilterKey = 'product';

/**
 * TabMyLists component
 * @constructor
 */
export default class SearchResultsInformationComponent extends SearchController {
  /**
   * @method constructor
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);
    // const queryObj = qs.parse(this.props.location.search);
    this.state = {
      // query: queryObj.q || '',
      errors: [],
      carouselKey: 0,
      pageNum: 1,
      loading: true,
    }
    this.renderInformationCard = this.renderInformationCard.bind(this);
    this.renderInformation = this.renderInformation.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.renderNoResults = this.renderNoResults.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
     * Render the Exhibitors cards as appropriate for display pagination
     * @returns {HTML} Array of Exhibitor Cards
     * @param {object} exhibitor json with exhibitor information
     * @param { integer } index indexed value of exhibitor in exhibitor result list
     */
  renderInformationCard(information, index) {
    const props = {
      title: information.pageTitle,
      pageDescription: information.pageDescription,
      destinationUrl: information.destinationUrl,
      tags: information.tags,
      pageBreadcrumbs: information.breadcrumbs,
    }
    return <InformationCard {...props} key={index} />
  }

  /**
   * @method constructor
   * @param {array} exhibitors Incoming props
   * @returns {*} Components
   */
  renderInformation(information) {
    return (information.length) ? information.map(
      (information, index) => (
        this.renderInformationCard(information, index)
      ),
    ) : '';
  }

  /**
   * Updates the query in the state
   * @param { string } fieldname Name of the field
   * @param { string } query Query to update to the state
   */
  updateQuery(fieldname, query) {
    this.setState({
      query,
    });
  }

  onSubmit() {

  }

  /**
   * No results content
   * @returns {*} JSX for no results
   */
  renderNoResults(term) {
    const {
      exhibitorLabels,
    } = this.props;
    // const query = qs.parse(location.search).q;
    return (
      <div className="render-results-title">
        No results for {term} found in Informations
      </div>
    );
  }

  /**
   * Renders the product results
   * @returns {*} Product results JSX
   */
  renderResults() {
    const { tabData } = this.props;
    const information = tabData.information || [];

    if (Array.isArray(information) && information.length > 0 && 'informationCount' in tabData) {
      const numberOfPages = Math.ceil(parseInt(tabData.informationCount, 10) / this.props.pageSize);
      return (
        <div
          className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                      imc-searchresults`}
        >
          <div className="imc-vr--xxlarge" >
            {information ? this.renderInformation(information) : []}
            {/* {this.renderInformationCard()} */}
          </div>
          <Pagination totalPages={numberOfPages} nonSearch />
        </div>
      );
    }
    return this.renderNoResults();
  }

  /**
   * @method render
   * @description Renders the DOM element
   * @returns {*} Rendered component
   */
  render() {
    const { rendering } = this.props;

    return (
      <>
        <div
          className={`imc-gallery imc-gallery--align-flex-start imc-gallery--25-75
                imc-gallery--padding-left imc-vr--collosal`}
        >
          <div className={'imc-gallery__item imc-exhibitors__panel'}>
            <Placeholder name="imc-category-filter" rendering={rendering} />
          </div>
          <div className="imc-gallery__item">
            <div className="">
              <Placeholder name="imc-typeahead" rendering={rendering} />
            </div>
            {this.renderResults()}
          </div>
        </div >
      </>
    );
  }
}

// /**
//  * Maps state to props for connect
//  * @param {object} state State object
//  * @returns {{search: *}} State to props mapping
//  */
// function mapStateToProps(state) {
//     return {
//         filters: state.exhibitorcategories.filters.filters,
//         filterErr: state.exhibitorcategories.filters.filterErr,
//         sortOptions: state.exhibitorcategories.sortoption.sort,
//         exhibitors: state.exhibitorcategories.exhibitors.exhibitors,
//         featuredExhibitors: state.exhibitorcategories.featuredExhibitors.exhibitors,
//         exhibitorsCount: state.exhibitorcategories.exhibitors.exhibitorsCount,
//         resultsUpToDate: state.exhibitorcategories.exhibitors.exhibitorsUpToDate,
//         loginUrl: state.labelConfigReducer.labels.account.loginUrl,
//         exhibitorLabels: state.labelConfigReducer.labels.exhibitor,
//         globalLabels: state.labelConfigReducer.labels.global,
//         globalVocab: state.labelConfigReducer.labels.globalVocab,
//         searchResults: state.labelConfigReducer.labels.searchResults,
//         commonLabels: state.labelConfigReducer.labels.common,
//         savedContent: state.savedContent,
//     };
// }

// /**
//  * Maps dispatch to props for connect
//  * @param {function} dispatch Dispatcher
//  * @returns {object} Action creators
//  */
// function mapDispatchToProps(dispatch) {
//     return {
//         dataActions: bindActionCreators({ ...dataActions }, dispatch),
//         filterActions: bindActionCreators({ ...filterActions }, dispatch),
//         productActions: bindActionCreators({ ...productActions }, dispatch),
//     };
// }

SearchResultsInformationComponent.propTypes = propTypes;
SearchResultsInformationComponent.defaultProps = defaultProps;

  // // Export the react component
  // export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResultsComponent));

