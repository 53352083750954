// Library dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Local dependencies
import NavigationMobileMenu from './NavigationMobileMenu.jsx';

/**
 * Component for displaying a navigation module and maintaining its state
 */
export default class NavigationMobile extends Component {

    /**
     * @method render
     * @description Renders the ModelCard DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { items, mobileMenuLabel, showMenu, toggleMenu, closeMenu, isLoggedIn } = this.props;
        return (
            <div
                className="imc-navigation-mobile imc-breakpoint-display--hide-desktop imc-content"
                data-xpath="navigation.mobile.container"
            >
                <a
                    role="menu"
                    tabIndex={0}
                    onClick={toggleMenu}
                    onKeyDown={e => toggleMenu(e)}
                    className="imc-navigation-mobile__link"
                    aria-expanded={showMenu}
                    aria-controls="imc-navigation-mobile-menu"
                    data-xpath="navigation.mobile.button"
                >
                    <svg width="36" height="36" className="imc-navigation-mobile__icon">
                        <title>{mobileMenuLabel}</title>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#juniper-hamburger"></use>
                    </svg>
                </a>
                <NavigationMobileMenu
                    showMenu={showMenu}
                    items={items}
                    menuLabel={mobileMenuLabel}
                    closeMenu={closeMenu}
                    isLoggedIn={isLoggedIn}
                />
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {{items}}
 */
NavigationMobile.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object), // Navigation configuration
    mobileMenuLabel: PropTypes.string, // Label for the menu label
    mobileOverviewLabel: PropTypes.string, // Label for overview items
    mobileBackLabel: PropTypes.string, // Label for back link
};

/**
 * @property defaultProps
 * @type {{menuLabel: string}}
 */
NavigationMobile.defaultProps = {
    mobileMenuLabel: 'Menu',
    mobileOverviewLabel: 'Overview',
    mobileBackLabel: 'Back',
};
