import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//AppState, Actions and Reducers
import { createAppState } from 'modules/redux-app-state';
import * as dataActions from 'modules/exhibitors/actions/dataActions';
import * as searchActions from 'modules/search/actions/searchActions';
import savedSearchReducer from 'modules/exhibitors/reducers/savedSearchReducer'
import search from "modules/search/reducers/searchReducer";

//Modules
import { DropDown } from 'modules/formelements';
//Local
import SearchBarButton from './SearchBarButtonBase.jsx'
import SavedSearchModal from './SavedSearchModal.jsx';

//Utils
import { getDictionaryValue } from 'utils/dictionary'
import { EXHIBITOR_IDENTIFIER } from 'utils/exhibitor';
import { resetPagination } from 'utils/resetpagination/index.js';
import UserUtility from 'utils/userutility';
import { decode, encode } from 'utils/querystring';
import ImcDataLayer from 'utils/datalayer';

const appState = createAppState();

class SavedSearchesButton extends SearchBarButton {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        appState.reducerRegistry.register({
            search, savedSearchReducer
        });
        this.renderSelectSavedSearches = this.renderSelectSavedSearches.bind(this);
        this.selectedSavedSearchChange = this.selectedSavedSearchChange.bind(this);
        this.saveSearch = this.saveSearch.bind(this);
        this.state = {
            selectedSearchId: '',
            saveSearchModal: false,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        const userInfo = UserUtility.getUserData();
        if(UserUtility.isLoggedIn())
        {
            this.props.dataActions.getSavedsearches(userInfo.accountId);
        }
    }


    /**
     * Save search action
     * @param { string } searchName  name of saved search
     */
    saveSearch(searchName) {
        const userInfo = UserUtility.getUserData();
        
        ImcDataLayer.pushInteractionEvent("search", "save", "Keyword: " + this.props.query);
        this.props.searchActions.saveSearch(searchName, EXHIBITOR_IDENTIFIER).then(() => {
            this.props.dataActions.getSavedsearches(userInfo.accountId);
        });
    }


    /**
 * Given a saved search optimized for mobile, wrap the search in exhibitor
 * for use in LVM
 * @param {*} queryIn Incoming search query from saved search
 * @returns {string} A query string for use in LVM
 */
    calculateQueryString(queryIn) {
        const queryObject = decode(queryIn);
        const { type, q, exactmatch, ...otherFilters } = queryObject;
        let writableObject = {};
        if (otherFilters[EXHIBITOR_IDENTIFIER]) {
            writableObject = {
                ...otherFilters,
            };
        } else {
            writableObject[EXHIBITOR_IDENTIFIER] = encode(otherFilters);
        }
        if (q) {
            writableObject.q = q;
        }
        if (type) {
            writableObject.type = type;
        }
        if (exactmatch) {
            writableObject.exactmatch = exactmatch;
        }

        return qs.stringify(writableObject);
    }
    selectedSavedSearchChange(updatedValue) {
        const { history, match, location, savedSearches } = this.props;
        this.setState({
            selectedSearchId: updatedValue
        })
        const selectedSavedSearch = savedSearches.savedSearch.find(s => s.savedSearchId === updatedValue);
        const newHistory = resetPagination(match, location);
        newHistory.search = selectedSavedSearch.searchTerm;
        //this.getSearchUrl(selectedSavedSearch)
        history.push(newHistory);
    }
    renderSelectSavedSearches() {
        const { savedSearches } = this.props;
        const { selectedSearchId } = this.state;
        const groupOptions = savedSearches.savedSearch.map(g => {
            return {
                code: g.savedSearchId,
                displayValue: g.searchName
            }
        });


        return <div className="imc-margin--left--mediumsmall">
            <DropDown
                additionalClass={`imc-dropdown imc-searchform--dropdown`}
                firstOption={getDictionaryValue('select', '-Select-')}
                hideLabel={true}
                label={getDictionaryValue('useASavedSearch', 'Use a Saved Search:')}
                sortedOptions={groupOptions}
                dropdownChange={this.selectedSavedSearchChange}
                value={selectedSearchId}
                name='Saved Searches'
            />
        </div>
    }
    renderSVG(titleKey, titleDefault) {
        return <svg
            width="14"
            height="18"
            role="img"
        >
            <title>{getDictionaryValue(titleKey, titleDefault)}</title>
            <g id="Path_269" data-name="Path 269" className="cls-1" fill="none" transform="translate(0.003)">
                <path stroke="none" d="M1.213,0H12.751a1.309,1.309,0,0,1,1.214,1.386V14.55c0,.765.212,1.45-.458,1.45,0,0-6.381-5.688-6.512-5.648S.6,15.825.6,15.825c-.671,0-.605-.51-.605-1.275V1.386A1.309,1.309,0,0,1,1.213,0Z" />
                <path className="cls-3" stroke="none" d="M 1.213318824768066 0.9999990463256836 C 1.13770866394043 0.9999990463256836 0.9988584518432617 1.146419525146484 0.9988584518432617 1.385669708251953 L 0.9988584518432617 14.15915012359619 C 1.58148193359375 13.65469074249268 2.339790344238281 12.99885559082031 3.097088813781738 12.34600925445557 C 6.418998718261719 9.482259750366211 6.434988975524902 9.47736930847168 6.703309059143066 9.395349502563477 L 6.847279071807861 9.351809501647949 L 6.996078968048096 9.351809501647949 C 7.425628662109375 9.351809501647949 7.60037899017334 9.501399993896484 8.737308502197266 10.47466945648193 C 9.287919044494629 10.94602012634277 10.04152870178223 11.60217952728271 10.97720909118652 12.42492961883545 C 11.68662357330322 13.04872417449951 12.39577484130859 13.67607402801514 12.96517848968506 14.1809663772583 L 12.96517848968506 1.385669708251953 C 12.96517848968506 1.146419525146484 12.82632923126221 0.9999990463256836 12.75071907043457 0.9999990463256836 L 1.213318824768066 0.9999990463256836 M 1.213318824768066 -9.5367431640625e-07 L 12.75071907043457 -9.5367431640625e-07 C 13.42144870758057 -9.5367431640625e-07 13.96517848968506 0.6203899383544922 13.96517848968506 1.385669708251953 L 13.96517848968506 14.54953956604004 C 13.96517848968506 15.31482982635498 14.17751884460449 16 13.5067892074585 16 C 13.5067892074585 16 7.170592784881592 10.35180854797363 6.996078491210938 10.35180854797363 C 6.995458602905273 10.35180854797363 6.994932651519775 10.35187721252441 6.994468688964844 10.35201930999756 C 6.863429069519043 10.39207935333252 0.6038684844970703 15.8247594833374 0.6038684844970703 15.8247594833374 C -0.06686115264892578 15.8247594833374 -0.001141548156738281 15.31482982635498 -0.001141548156738281 14.54953956604004 L -0.001141548156738281 1.385669708251953 C -0.001141548156738281 0.6203899383544922 0.5425891876220703 -9.5367431640625e-07 1.213318824768066 -9.5367431640625e-07 Z" />
            </g>
        </svg>;
    }

    render() {
        const { savedSearches, saveSearchModal, searchActions } = this.props;
        return <div className="imc-searchform--bar" ref={this.setWrapperRef} id={this.id}>
            <button className={`imc-searchform--bar--button imc-saved-searches--button ${this.isOpen() ? 'open' : ''}`}
                onClick={()=>{this.toogleMenu(false)}}>
                {this.renderSVG('savedSearches', 'Saved Searches')}
            </button>
            <div className={`imc-searchform--bar--dropdown ${this.isOpen() ? 'open' : ''}`}>
                {
                    (savedSearches && savedSearches.count > 0) &&
                    < div className="imc-searchform--bar--item imc-searchform--bar--item--caret">
                        <span className="imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title imc-margin--left--mediumsmall--desktop">{getDictionaryValue('useASavedSearch', 'Use a Saved Search:')}</span>
                        <div className="imc-searchform--bar--item--input">
                        {this.renderSelectSavedSearches()}
                        </div>
                    </div>
                }
                <div className="imc-searchform--bar--item imc-margin--left--mediumsmall--desktop">
                    <span className="imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title">{getDictionaryValue('saveThisSearch', 'Save This Search:')}</span>
                    <div className="imc-margin--left--mediumsmall imc-searchform--bar--item--input">
                        <button className="imc-button--muted-inverted imc-icon--bookmark imc-button--full-width"
                            onClick={this.props.searchActions.openSaveSearchModal}>
                            {this.renderSVG('saveSearch', 'Save Search')}
                            {getDictionaryValue('saveSearch', 'Save Search')}
                        </button>
                    </div>
                </div>
            </div>
            {saveSearchModal &&
                <SavedSearchModal
                    closeAction={searchActions.closeSaveSearchModal}
                    customSubmit={this.saveSearch}
                />
            }
        </div >

    }
}


/**
 * Maps state to props for connect
 * @param {object} state State object
 * @returns {{search: *}} State to props mapping
 */
function mapStateToProps(state) {
    let _props = {};
    if (state.search)
        Object.assign(_props, {
            saveSearchModal: state.search.saveSearchModal
        });
    if (state.savedSearchReducer) {
        Object.assign(_props, {
            savedSearches: state.savedSearchReducer.savedSearches,
        });
    }
    return _props;
}
/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {{actions: *}} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
        searchActions: bindActionCreators({ ...searchActions }, dispatch),
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearchesButton));