// Library dependencies
import React, { Component } from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// Local dependencies
import TypeAheadSearch from '../../Type-Ahead-Search';
/**
 * Component for displaying a navigation module and maintaining its state
 */
class NavigationMobileSearch extends Component {

    render() {
        const { showSearch, closeSearch, windowWidth, navContainer, rendering, searchRef } = this.props;
        const activeClass = showSearch ? 'imc-navigation-mobile__menu--active imc-navigation-mobile--overlay' : '';
        const searchFields = { "showMultiSelect": { "value": false }, "showSort": { "value": false }, "showShare": { "value": false }, "disableTypeahead": { "value": false }, "showClearSearch": { "value": false }, "maxLength": { "value": "" }, "hideSubmitButton": { "value": false }, "minLengthForSearch": { "value": "3" }, "showSavedSearches": { "value": false }, "extraClass": { "value": "imc-searchform--global" }, "resultsRedirectUrl": { "value": "/search/exhibitors" }, "showMessage": { "value": false }, "hideSortResultBy": { "value": false }, "searchType": [], "sortResultsBy": [], "hideFilterBy": { "value": false }, "hideSearchType": { "value": false } }

        return (<>
            {showSearch &&
                <div
                    className={`imc-navigation-mobile__menu ${activeClass}`}
                    id="imc-navigation-mobile-menu"
                    ref={(node) => { this.navContainer = node; }}
                >
                    <button className="imc-navigation-mobile__closeContainerSearch" onClick={() => { closeSearch() }}>
                        <svg width="36" height="36" className="imc-navigation-mobile__icon">
                            <title>Close Menu</title>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" className={`svg-white`} xlinkHref="#juniper-close"></use>
                        </svg>
                    </button>
                    <div
                        className="imc-navigation-mobile__menuContainer"
                        style={{
                            width: windowWidth,
                        }}
                    >
                        <div className="imc-navigation-mobile__menuContainerInner">
                            <TypeAheadSearch
                                btnClickRequired="false"
                                navContainer={navContainer}
                                mobileSearchButton={true}
                                fields={searchFields}
                                onAfterSubmit={closeSearch}
                                extraFormClass="imc-searchform--mobile"
                                searchRef={searchRef}
                                showClearSearch={true}
                                extraContainerClass={`suggestions-container--mobile`}
                            />
                        </div>
                    </div>
                </div>
            }
        </>)
    }
}


export default withSitecoreContext()(NavigationMobileSearch);
