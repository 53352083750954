import React from 'react';
import { withRouter } from "react-router";

//Utils
import { getDictionaryValue } from 'utils/dictionary'
import { encode, decode } from 'utils/querystring'

import SearchBarButton from './SearchBarButtonBase.jsx'

import FilterSearchByLetter from '../../Filter-Search-By-Letter';
import DefineSearchIntent from '../../Define-Search-Intent';
import SortSearchResults from '../../Sort-Search-Results';

class SortButton extends SearchBarButton {

    /**
     * Constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.resetFitlers = this.resetFilters.bind(this);
        this.state = {
            active: false,
            search: props.location.search,
        }
    }

    componentDidMount() {
        const { location } = this.props;
        super.componentDidMount();
        this.checkFilters(location.search);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (prevProps.location.search !== location.search) {
            this.checkFilters(location.search);
        }
    }

    checkFilters(search) {
		const searchParams = decode(search);

        if (Object.hasOwn(searchParams, 'type') || Object.hasOwn(searchParams, 'alphabetName') || Object.hasOwn(searchParams, 'sortorder')) {
            this.setState({ active: true, search: search});
        } else {
            this.setState({ active: false, search: search });
        }

    }

    resetFilters(search) {
		const searchParams = decode(search);
        delete searchParams.type;
        delete searchParams.alphabetName;
        delete searchParams.sortorder;
        this.props.history.replace({ search: encode(searchParams, false, false) });
        this.toogleMenu(false)
        this.setState({ active: false });
    }

    render() {
        const { hideSearchType, hideFilterBy, hideSortResultBy, search } = this.props;
        return (
            <div className="imc-searchform--bar" ref={this.setWrapperRef} id={this.id}>
                <button className={`imc-searchform--bar--button ${this.isOpen() ? 'open' : ''} ${this.state.active ? 'active' : ''}`}
                    onClick={()=>{this.toogleMenu(false)}}>
                    {getDictionaryValue('sort', 'Sort')}
                </button>
                <div className={`imc-searchform--bar--dropdown ${this.isOpen() ? 'open' : ''}`}>
                    <div className={`imc-searchform--bar--filter-wrapper`}>
                        {!hideSearchType &&
                            <div className="imc-searchform--bar--item imc-searchform--bar--item--caret">
                                <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title">{getDictionaryValue('searchWithin', 'Search Within:')}</span>
                                <div className="imc-searchform--bar--item--input"><DefineSearchIntent options={this.props.searchWithinOptions} />
                                </div>

                            </div>
                        }
                        {!hideSortResultBy &&
                            <div className="imc-searchform--bar--item imc-searchform--bar--item--caret">
                                <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title">{getDictionaryValue('sortBy', 'Sort By:')}</span>
                                <div className="imc-searchform--bar--item--input"><SortSearchResults options={this.props.sortOptions} /></div>

                            </div>
                        }
                        {!hideFilterBy &&
                            <FilterSearchByLetter />
                        }
                    </div>
                    <div className="imc-searchform--bar--item imc-searchform--bar--item--caret reset">
                        <span className="imc-type--title-3-ui imc-type--color--neutral-heavy-medium imc-searchform--bar--item--title" style={{'visibility': 'hidden'}}>&nbsp;</span>
                        <button class="imc-filter--reset-button" onClick={() => this.resetFilters(this.state.search)}>Reset</button>
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(SortButton);