import React, { Component } from 'react';
import SelectableIcon from 'modules/selectableicon';
import { Carousel, CarouselItem } from 'modules/carousel';
import EventCard from '../Event-List/components/EventCard';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';


//Utils
import UserUtility from "utils/userutility";
import ImcDataLayer from "utils/datalayer";
import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";

/**
 * @property defaultProps
 * @type {{products: *, filters: *, selectedTab: *, resultsPerPage: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, productsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
    filters: PropTypes.object,
    selectedTab: PropTypes.object,
    resultsPerPage: PropTypes.number.isRequired,
    pagenum: PropTypes.number.isRequired,
    //updateUrl: PropTypes.func.isRequired,
    exhibitorLabels: PropTypes.object,
    filterErr: PropTypes.number,
    exhibitorName: PropTypes.string,
    filterKey: PropTypes.string,
    savedContent: PropTypes.object,
    location: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, products: {}, pagenum: number, resultsPerPage: number, filters: {},
 * dataActions: {fetchProducts: defaultProps.dataActions.fetchProducts},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, productsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
    lineDetail: false,
    pagenum: 1,
    resultsPerPage: 15,
    filters: {},
    exhibitorLabels: {
        productCount: 'Showing $1 Products',
        productQueryPrefix: 'for',
        seeAllProducts: 'Click here to see all products',
    },
    filterErr: null,
    globalLabels: {
        pimCatalogImageUrl: 's3.amazonaws.com',
    },
    exhibitorName: '',
    filterKey: 'products',
    savedContent: {},
    location: {},
};

/**
 * TabMyLists component
 * @constructor
 */
class DigitalShowroomEventsList extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        // const queryObj = qs.parse(this.props.location.search);
        this.state = {
            errors: [],
            carouselKey: 0,
            pageNum: 1,
            loading: false,
            multiSelectMode: false,
        }
        this.renderAllItemsByTime = this.renderAllItemsByTime.bind(this);
        this.renderCards = this.renderCards.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        const { sitecoreContext } = this.props;
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
            this.exhibitId = sitecoreContext.exhibitor.id;
        }
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
            this.lineId = sitecoreContext.lineProduct.lineId;
        }
    }

   

    componentDidUpdate(prevProps){
        const {tabData} = this.props;
        if (!isExperienceEditorActive()) {
            
            if (tabData && tabData.events &&  Object.keys(tabData.events).length>0 && prevProps.tabData &&  prevProps.tabData.events != tabData.events) {
              
            }
        }
    }

    /**
     * No results content
     * @returns {*} JSX for no results
     */
    renderNoResults() {
        if (typeof window === 'undefined' || (typeof window != 'undefined' && !window.Sitecore) || !("currentTab" in this.props))
            return <></>;
        const label = this.props.fields.clickToProducts.value;
        let query = '';
        if (typeof window != 'undefined' || (typeof window != 'undefined' && !window.Sitecore)) {
            query = qs.parse(window.location.search).q;
        }
        const type = (this.props.currentTab && this.props.currentTab.type) ? this.props.currentTab.type.value : '';
        return (
            <div className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
            imc-searchresults`}>
                <div className="imc-vr--large imc-content imc-stacked">
                    There are no matching search results
                    {(query !== '' && query !== undefined) ?
                        <span> for <strong>&quot;{query}&quot;</strong></span> : null
                    }
                </div>
                <div className="imc-vr--large imc-content imc-stacked">
                    <a
                        href={`/exhibitor/${this.exhibitId}/${type.toLowerCase()}`}
                        className="imc-link imc-link--alt-darkred imc-link--caret-after">
                        {label}
                    </a>
                </div>
            </div>
        );
    }

    /**
     * Renders the product results
     * @returns {*} Product results JSX
     */
    renderResults() {
        const events = this.props.tabData.events || [];
        const {
            featuredProducts,
            tabData,
            sitecoreContext
        } = this.props;
        const eventCount = tabData.eventsCount;
        const size = this.props.currentTab && this.props.currentTab.size? this.props.currentTab.size.value: 30; 
        const numberOfPages =  Math.ceil(parseInt(eventCount,10) / size);
        if (Object.keys(events).length) {
            return (
                <div className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                    imc-searchresults`}>
                    <div className={`imc-gallery imc-gallery--align-flex-start imc-gallery--1-4 imc-vr--collosal
                        imc-fprod-cont imc-catalog`}>
                        {events ? this.renderAllItemsByTime() : []}
                    </div>
                    <Pagination totalPages={numberOfPages} nonSearch />
                </div>
            );
        }
        return this.renderNoResults();
    }

    renderAllItemsByTime() {
      const events = this.props.tabData.events || [];
      let cardResults;
      
      cardResults =  (
          
                  <div className='event-card--multi-card-container event-card--multi-card-container-showroom'>
                      {this.renderCards(events)}
                  </div>
              
          )
      
        return cardResults;
    }

    renderCards(eventList, date) {
      const learnMoreLabel = {
        value: "Learn More"
      };

      return eventList.map((cardItem) => {
          return (
              <EventCard
                  key={`cardItem${cardItem.id}`}
                  eventData={cardItem}
                  startDate={cardItem.startDateTime ? this.formatConsistentDate(new Date(cardItem.startDateTime)) : null}
                  endDate={cardItem.endDateTime ? this.formatConsistentDate(new Date(cardItem.endDateTime)) : null}
                  learnMoreLabel={learnMoreLabel}
              />
          )
      })
    }

    formatConsistentDate(date) {
      const day = format(date, 'dd');
      const dayOfWeek = format(date, 'eeee');
      const month = format(date, 'LLLL');
      const year = format(date, 'yyyy');

      return `${dayOfWeek}, ${month} ${day}, ${year}`
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            tabData,
        } = this.props;
        
        return (
            <div
                className={`imc-gallery imc-gallery--align-flex-start
                imc-gallery--padding-left imc-vr--collosal`}
            >
                <div className="imc-gallery__item imc-exhibitorheader imc-gallery__item__product-list">
                    <div className="imc-vr--large">
                        <Placeholder name='imc-search-bar' rendering={this.props.rendering} />
                    </div>
                    {tabData && tabData.events &&
                        this.renderResults()
                    }
                </div>
            </div>
        );
    }
}


/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
       };
}

DigitalShowroomEventsList.propTypes = propTypes;
DigitalShowroomEventsList.defaultProps = defaultProps;

// Export the react component
export default withSitecoreContext()(
    withRouter(connect(null, mapDispatchToProps)(DigitalShowroomEventsList))
);