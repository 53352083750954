import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
//modules
import Image from 'modules/image/components/Image';
//actions
import Link from '../../components/Link';
//utils
import { getDictionaryValue } from 'utils/dictionary';
import ImcDataLayer from 'utils/datalayer';

class SearchItemThumb extends Component {

    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {};
        this.itemClicked = this.itemClicked.bind(this);
        this.multiSelectChange = this.multiSelectChange.bind(this);
        this.productOnClick = this.productOnClick.bind(this);
    }

    componentDidUpdate(lastProps) {

        if (lastProps.isSuccess !== this.props.isSuccess) {
            if (this.multiSelect && this.props.isSuccess)
                this.props.actions.multiSelectReset();
        }
        if (lastProps.selectedItems !== this.props.selectedItems && this.multiSelect) {
            this.multiSelect.checked = this.props.selectedItems.some(e => e.id === this.multiSelect.id);
        }

        if (!!this.props.actionItem && (this.props.actionItem != lastProps.actionItem)) {
            this.props.actionItem.sourceProduct = this.props.product; //injects the original product object for tracking purposes (GTM)
        }
    }


    productOnClick() {
        if (!!this.props.product) {
            ImcDataLayer.PushProductClick(this.props.product);
        }

    }

    multiSelectChange() {
        const { actionItem, actions, url } = this.props;

        actions.itemChanged({ actionItem: actionItem, selected: this.multiSelect.checked, id: `SearchItemThumb${url}` });
    }

    /**
     * @method itemClicked
     * @param {object} item data
     */
    itemClicked(e) {
        const { sitecoreContext, multiSelectMode, newWindow, actionItem, url, actions } = this.props;
        if (multiSelectMode) {
            //this.multiSelect.checked = !this.multiSelect.checked;
            actions.itemChanged({ actionItem: actionItem, selected: !this.multiSelect.checked, id: `SearchItemThumb${url}` });
            //this.searchPubSubService.emitMultiSelectItemsChanged({ actionItem: actionItem, selected: this.multiSelect.checked, id: `SearchItemThumb${itemID}` });
            return false;
        }
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
    }


    render() {
        const { url, image, newWindow, name, key, mainClass, extraClass, lineName, multiSelectMode, isNew } = this.props
        const isLine = !(!lineName || 0 === lineName.length);
        const { sitecoreContext } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        let parsedUrl;
        if (!isEditing) {
            parsedUrl = url;
        } else {
            parsedUrl = '';
        }
        const height = this.props.height ? this.props.height : 154;
        const width = this.props.width ? this.props.width : 154;

        const titleSection = (
            <div className={`${mainClass}__item_title multiSelectItem`}>
                <div className={`${isLine ? 'imc-heading--delta' : ''} imc-type--body-6-ui ${mainClass}__item-full-width`}>
                    {name}
                </div>
                {isLine &&
                    <span className={'imc-type--body-6-ui imc-margin--top--xsmall'}>
                        {`${getDictionaryValue('by', 'By')} ${lineName}`}
                    </span>
                }
            </div>
        );

        return (
            <div
                key={key}
                className={`${mainClass}__item ${!!extraClass ? extraClass : ''}`}
            >
                <div className={`${mainClass}__item_inner-content`}>
                    <div className={`${mainClass}__item_image-wrapper`}>
                        {isNew &&
                            <div class={`${mainClass}__item_new`}>NEW</div>}
                        {/* it wraps the title in a link or a span depending on multiselectmode */}
                        {!multiSelectMode && <Link
                            href={parsedUrl}
                            target={newWindow ? '_blank' : ''}
                            onClick={this.productOnClick}
                        >
                            <Image
                                src={image}
                            />
                        </Link>}
                        {multiSelectMode && <span>
                            <Image
                                src={image}
                            />
                        </span>}
                    </div>
                    {/* it wraps the title in a link or a span depending on multiselectmode */}
                    {!multiSelectMode && <Link
                        href={parsedUrl}
                        target={newWindow ? '_blank' : ''}
                        onClick={this.productOnClick}
                    >
                        {titleSection}
                    </Link>}
                    {multiSelectMode && <span>
                        {titleSection}
                    </span>
                    }
                </div>
            </div>
        )
    }
}
SearchItemThumb.defaultProps = {
    multiSelectMode: false,
};
export default withSitecoreContext()(
    withRouter(SearchItemThumb)
);