import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import qs from 'query-string';
import { decode } from 'utils/querystring';
import { updateUrl } from 'utils/tagupdate';
import { Text } from '@sitecore-jss/sitecore-jss-react';


/**
 * Key to indicate all tags
 * @type {string}
 */
const ALL_TAGS = 'All Topics';

/**
 * TagCloud component updates the URL with tags
 * based off user's selections. See updateUrl comments
 * for URL structure. This functions similarly to the
 * Filter.jsx component.
 */
class TagCloud extends Component {
    /**
    * Component Constructor
    *@param {object} props incoming props
    */
    constructor(props) {
        super(props);
        let selectedArray = [ALL_TAGS];
        const { tagKey, filterKey, location } = props;
        const queryObject = qs.parse(location.search);
        const filters = decode(queryObject[filterKey]);
        if (filters[tagKey] && typeof filters[tagKey] === 'object') {
            selectedArray = filters[tagKey];
        }
        this.state = {
            selected: ALL_TAGS,
            selectedArray,
        };
        this.renderTags = this.renderTags.bind(this);
        this.renderNowViewing = this.renderNowViewing.bind(this);
    }

    /**
     * Updates state with new props
     * @param {object} nextProps Incoming props
     */
    componentWillReceiveProps(nextProps) {
        let selectedArray = [ALL_TAGS];
        const { tagKey, filterKey } = this.props;
        const queryObject = qs.parse(nextProps.location.search);
        const filters = decode(queryObject[filterKey]);
        if (filters[tagKey] && typeof filters[tagKey] === 'object') {
            selectedArray = filters[tagKey];
        }
        this.setState({
            selectedArray,
        });
    }

    /**
     * Updates the URL in the following manner:
     * ?{props.filterKey}=encode(filter=true&{props.tagKey=tag1|tag2|tag3})
     * @param { string } tag string to update url with
     */
    updateTagUrl(tag) {
        const { history, match, location, filterKey, tagKey } = this.props;
        updateUrl(tag, history, match, location, filterKey, tagKey);
    }

    /**
     * Renders all the tags needed for the article landing page
     * @returns {*} A list of all tags that are clickable
     */
    renderTags() {
        const { tags } = this.props;
        const { selectedArray } = this.state;
        let outTags = [ALL_TAGS];
        outTags = outTags.concat(tags);
        return (
            <ul className={'imc-cardtags imc-tagcloud__taglist'}>
                {outTags.map(tag => (
                    <li
                        className={'imc-tagcloud__tag imc-content--border imc-content--alt2 ' +
                            'imc-cardtags__tag imc-cardtags__tag--button-tag imc-vr--large ' +
                            `${selectedArray.indexOf(tag) > -1 ? 'imc-tagcloud__tag--active' : ''}`}
                        key={tag}
                    >
                        <button
                            className="imc-cardtags__tag--button imc-content"
                            onClick={() => this.updateTagUrl(tag)}
                        >
                            {tag}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    /**
     * Renders the "now viewing" section if tags are selected
     * @returns {*} "Now viewing" content
     */
    renderNowViewing() {
        const {
            landingLabels,
        } = this.props;
        const {
            selectedArray,
        } = this.state;
        const onlyAllTags = selectedArray.length === 1 && selectedArray.indexOf(ALL_TAGS) > -1;
        return (
            <div className="imc-content imc-tagcloud__now-viewing-wrapper">
                <p className="imc-content imc-tagcloud__now-viewing">
                    {selectedArray && selectedArray.length > 0 && !onlyAllTags &&
                        (<span>
                            <Text field={landingLabels.landingViewingTags}></Text>
                            {selectedArray.map((string,index) => this.renderNowViewingTag(string, index))}
                        </span>)
                    }
                    {(!selectedArray || selectedArray.length === 0 || onlyAllTags) &&
                        (<span>
                            <Text field={landingLabels.landingViewingAll}></Text>
                        </span>)
                    }
                </p>
            </div>
        );
    }
    /**
     * Render the now viewing tag
     * @param {*} tag Tag to be rendered!
     * @returns {XML} A rendered tag
     */
    renderNowViewingTag(tag, key) {
        return (
            <button
                className="imc-content imc-content--gamma imc-tagcloud__now-viewing-button"
                onClick={() => this.updateTagUrl(tag)}
                aria-label={`remove tag ${tag}`}
                key={key}
            >
                <span>{tag}</span><span className="imc-tagcloud__close imc-tagcloud__close--now-viewing">+</span>
            </button>
        );
    }

    /**
     * Renders the Search Form
     * @returns {XML} SearchForm JSX
     */
    render() {
        const {
            landingLabels,
        } = this.props;
        const classAddons = 'imc-section imc-section--full-width ' +
            'imc-vr--xlarge-desktop imc-vr--large imc-tagcloud__tagcontainer';
        return (
            <div className={`imc-section ${classAddons}`}>

                <h3 className="imc-content imc-tagcloud__filterlabel imc-vr">
                    <Text field={landingLabels.landingFilterLabel}></Text>
                </h3>
                {this.renderTags()}
                {this.renderNowViewing()}
            </div>
        );
    }
}

/**
 * @property propTypes
 * @description Defined property types for component
 * @type {*}
 */
TagCloud.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    tags: PropTypes.array,
    filterKey: PropTypes.string,
    tagKey: PropTypes.string,
    landingLabels: PropTypes.object,
};

/**
 * @property Default Prop Types
 * @description Defined property types for component
 * @type {*}
 */
TagCloud.defaultProps = {
    tags: [],
    articleLandingLabels: {},
    filterKey: 'articles',
    tagKey: 'tags',
    landingLabels: {
        landingFilterLabel: { value: 'Featured Topics'},
        landingViewingTags: { value: 'Viewing items related to ' },
        landingViewingAll: { value: 'You are now viewing items related to all topics.' },
    },
};

// Export the react component
export { TagCloud as UnwrappedTagCloud };
export default withRouter(TagCloud);

